class AccessibilityHandler {
  constructor(globalDocument = document) {
    globalDocument.addEventListener('keydown', event => {
      if (event.code !== 'Tab') { return; }

      globalDocument.documentElement.classList.add('e-keyboard-navigation');
    });

    globalDocument.addEventListener('mousedown', () => {
      globalDocument.documentElement.classList.remove('e-keyboard-navigation');
    });
  }
}

export default AccessibilityHandler;
