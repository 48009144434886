import { SUBSCRIBE_MESSAGE, UNSUBSCRIBE_MESSAGE, HASH_CHANGE_MESSAGE } from './constants.js';

export class TopHashChangeClient {

  constructor(global) {
    this._global = global;
    this._eventListeners = [];
    this._onMessage = this._onMessage.bind(this);
  }

  addEventListener(eventListener) {
    if (this._eventListeners.includes(eventListener)) { return; }

    this._eventListeners.push(eventListener);
    if (this._eventListeners.length === 1) {
      this._global.top.postMessage({ message: SUBSCRIBE_MESSAGE }, '*');
      this._global.addEventListener('message', this._onMessage);
    }
  }

  removeEventListener(eventListener) {
    const eventListenerIndex = this._eventListeners.indexOf(eventListener);
    if (eventListenerIndex === -1) { return; }

    this._eventListeners.splice(eventListenerIndex, 1);

    if (this._eventListeners.length === 0) {
      this._global.top.postMessage({ message: UNSUBSCRIBE_MESSAGE }, '*');
      this._global.removeEventListener('message', this._onMessage);
    }
  }

  _onMessage(event) {
    if (!event.data || event.data.message !== HASH_CHANGE_MESSAGE) { return; }

    this._eventListeners.forEach(eventListener => eventListener(event.data.data));
  }
}
