import { html } from 'uhtml';
import translator from '../../utils/translator';
import classNames from 'clsx';

export class EThemeSwitcherTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <e-theme-switcher-wrapper></e-theme-switcher-wrapper>
    `;
  }

  createThemingButton(theme) {
    const isDefault = theme.id === this.state.defaultTheme.id;
    const isBeta = theme.properties.status === 'beta';
    const cellClassName = classNames('e-cell e-cell-small', {
      'e-cell-4': this.state.availableThemes.length > 2,
      'e-cell-6': this.state.availableThemes.length <= 2
    });
    const buttonClassName = classNames('e-btn e-btn-tile', {
      'e-btn-active': theme.id === this.state.colorTheme.id
    });
    return html`
      <div class=${cellClassName}>
        <button
          type="button"
          class=${buttonClassName}
          data-testid="${'themeButton-' + theme.id}"
          @click=${() => this.events.onThemeButtonClick(theme)}
        >
          <div>${this.createThumbnail(theme)}</div>
          <div>
            ${translator.translate(`components.themepicker.dialog.themingButton.${theme.id}`)}
            ${isDefault ? ` (${translator.translate('components.themepicker.dialog.defaultThemeOption')})` : null}
            ${isBeta ? this.createBetaBadge() : null}
          </div>
          <div>
            <small>
              ${theme.name}
            </small>
          </div>
        </button>
      </div>
    `;
  }

  createThumbnail(theme) {
    if (theme.originalMode === 'auto') {
      return html`
        <div class="e-theme-switcher__split_thumbnail">
          <picture class="e-theme-switcher__left_thumbnail">
            ${theme.thumbnails?.light.map(thumbnail => html`<source srcset="${thumbnail}"></source>`)}
            <img src="${theme.thumbnails?.light[0]}">
          </picture>
          <picture class="e-theme-switcher__right_thumbnail">
            ${theme.thumbnails?.dark.map(thumbnail => html`<source srcset="${thumbnail}"></source>`)}
            <img src="${theme.thumbnails?.dark[0]}">
          </picture>
        </div>
      `;
    }

    return html`
      <picture>
        ${theme.thumbnail?.map(thumbnail => html`<source srcset="${thumbnail}"></source>`)}
        <img src="${theme.thumbnail?.[0]}">
      </picture>
    `;
  }

  createBetaBadge() {
    return html`
      <span class="e-label e-label-highlight">
        <e-translation key="components.themepicker.dialog.betaBadge"></e-translation>
      </span>
    `;
  }

  // Component Content
  createElement() {
    return html`
      <e-dialog
        width="800px"
        ref=${node => this.refs.dialog = node}
        headline=${translator.translate('components.themepicker.dialog.headline')}
      >
        <p>${translator.translate('components.themepicker.dialog.description')}</p>
        ${this.createBetaNotification()}
        <div class="e-grid e-grid-small" data-test-id="theme-switcher-list">
          ${this.state.availableThemes.map(theme => this.createThemingButton(theme))}
        </div>
        <e-dialog-footer>
          <e-dialog-footer-actions>
            <button class="e-btn e-btn-highlight" type="button" data-action="close">
              ${translator.translate('components.themepicker.dialog.closeButtonLabel')}
            </button>
          </e-dialog-footer-actions>
        </e-dialog-footer>
      </e-dialog>
    `;
  }

  createBetaNotification() {
    const hasBeta = this.state.availableThemes.some(theme => theme.properties.status === 'beta');

    if (!hasBeta) { return; }

    return html`
      <e-notification type="info" class="e-margin-bottom-m">
        ${translator.translate('components.themepicker.dialog.betaNotification')}
      </e-notification>
    `;
  }
}

export default EThemeSwitcherTemplate;
