import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import popupHandler from '../../utils/popup-handler';
import JSLogger from '../../utils/jslogger';

const logger = new JSLogger('dropdown');

class EDropdown extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();

    this._state = {};
    this._state.status = '';
    this._state.active = false;
    this._state.markupContent = '';
    this._state.markupClass = '';
    this._state.markupClassActive = '';
    this._state.dropdownAlign = '';
    this._state.dropdownSticky = false;
    this._state.dropdownFullwidth = false;
    this._state.innerClass = '';
    this._state.autoClose = false;
    this._state.originalContentClassName = '';
    this._state.isOpenDisabled = false;

    this._events = {};
    this._events.open = this._open.bind(this);
    this._events.escapePress = this._onEscapePress.bind(this);
    this._events.spacePress = this._onSpacePress.bind(this);
    this._events.clickToContent = this._clickToContent.bind(this);

    this._dom = {};
    this._dom.content = null;

    this._dom.focusOutCatcherElementStart = this._createFocusOutCatcher();
    this._dom.focusOutCatcherElementEnd = this._createFocusOutCatcher();

    this._dom.handler = document.createElement('e-dropdown-handler');
    this._dom.handler.addEventListener('click', this._events.open);
    this._dom.handler.addEventListener('keydown', this._events.spacePress);
    this._dom.handler.tabIndex = 0;
  }

  connectedCallback() {
    this._setAndDispatchStatus('attached');
    this.childrenChangedCallback([...this.children].map(child => [child]));
    this.appendChild(this._dom.handler);
    this._render();
  }

  childrenChangedCallback(nodes) {
    const foundElementList = nodes.filter(node => node.length && node[0].nodeType !== 3);

    if (!foundElementList.length) {
      return false;
    }

    [...foundElementList].forEach(this._storeFoundElements.bind(this));

    this._render();
  }

  disconnectedCallback() {
    popupHandler.close(this);
    this._setAndDispatchStatus('detached');
    document.removeEventListener('keydown', this._events.escapePress);
  }

  static get observedAttributes() {
    return [
      'data-markup-content',
      'data-markup-class',
      'data-markup-class-active',
      'data-dropdown-align',
      'data-dropdown-sticky',
      'data-dropdown-fullwidth',
      'data-inner-class',
      'data-autoclose',
      'data-open-disabled'
    ];
  }

  set dataMarkupContent(value) {
    if (value === this._state.markupContent) {
      return;
    }

    this._state.markupContent = value;
    this._render();
  }

  set dataMarkupClass(value) {
    if (value === this._state.markupClass) {
      return;
    }

    this._state.markupClass = value;

    this._render();
  }

  set dataMarkupClassActive(value) {
    if (value === this._state.markupClassActive) {
      return;
    }

    this._state.markupClassActive = value;
    this._render();
  }

  set dataInnerClass(value) {
    if (value === this._state.innerClass) {
      return;
    }

    this._state.innerClass = value;
    this._render();
  }


  set dataDropdownAlign(value) {
    if (value === this._state.dropdownAlign) {
      return;
    }

    this._state.dropdownAlign = value;
    this._render();
  }

  set dataDropdownSticky(value) {
    this._state.dropdownSticky = super._convertAttributeToBoolean(value);
    logger.log('dataDropdownSticky', `dropdownSticky is: ${this._state.dropdownSticky}`);
    this._render();
  }

  set dataDropdownFullwidth(value) {
    this._state.dropdownFullwidth = super._convertAttributeToBoolean(value);
    this._render();
  }

  set dataAutoclose(value) {
    this._state.autoClose = super._convertAttributeToBoolean(value);
    this._render();
  }

  get status() {
    return this._state.status;
  }

  set dataOpenDisabled(value) {
    this._state.isOpenDisabled = super._convertAttributeToBoolean(value);
    this._render();
  }

  toggle() {
    logger.log('public toggle');
    if (this._state.active) {
      popupHandler.close(this);
    } else {
      this.open();
    }
  }

  open() {
    logger.log('public open');
    if (this._state.active) {
      return;
    }
    this._open(null, true);
  }

  close() {
    logger.log('public close');
    if (!this._state.active) {
      return;
    }
    popupHandler.close(this);
  }

  _storeFoundElements(element) {
    if (element[0].nodeName !== 'E-DROPDOWN-HANDLER') {
      this._dom.content = element[0];
    } else {
      if (this.querySelectorAll('e-dropdown-handler').length > 1) {
        this.removeChild(this.querySelectorAll('e-dropdown-handler')[0]);
      }

      this._dom.handler = element[0];
      this._dom.handler.addEventListener('click', this._events.open);
      this._dom.handler.addEventListener('keydown', this._events.spacePress);
    }
  }

  _open(event, isForce) {
    if (this._state.isOpenDisabled) { return; }

    const isCheckbox = event && event.target.nodeName === 'INPUT' && event.target.type === 'checkbox';
    const isLabel = event && event.target.nodeName === 'LABEL';
    if (isCheckbox || isLabel) {
      return;
    }

    this._dom.content.insertAdjacentElement('afterbegin', this._dom.focusOutCatcherElementStart);
    this._dom.content.insertAdjacentElement('beforeend', this._dom.focusOutCatcherElementEnd);

    if (this._state.dropdownAlign === 'auto') {
      this._openPopper(isForce);
    } else {
      popupHandler.register({
        force: isForce,
        opener: this,
        openCallback: () => this._onOpen(),
        closeCallback: () => this._onClose(),
        element: this._dom.content
      });
    }

    document.addEventListener('keydown', this._events.escapePress);
  }

  _openPopper(isForce) {
    const popperElements = this._createPopperElements();
    popupHandler.openPopper({
      force: isForce,
      reference: this,
      opener: this,
      popperElement: popperElements.popper,
      popperConfig: {
        placement: 'bottom',
        removeOnDestroy: true,
        modifiers: {
          computeStyle: {
            gpuAcceleration: false
          },
          arrow: {
            element: popperElements.arrow
          }
        }
      },
      openCallback: () => this._onOpen(),
      closeCallback: () => this._onClose()
    });

    setTimeout(() => {
      popupHandler.updatePopper(this); // fix glitch when popup does not fit to window
    });
  }

  _createPopperElements() {
    const popper = document.createElement('div');
    popper.className = this._popperDropdownClass();
    popper.appendChild(this._dom.content);

    const arrow = document.createElement('div');
    arrow.className = 'e-popover__arrow';
    popper.appendChild(arrow);

    return { popper, arrow };
  }

  _onClose() {
    this._state.active = false;
    this.setAttribute('active', false);

    this._dom.handler.className = this._state.markupClass;
    this._dom.content.classList.remove('e-dropdown__content-visible');
    if (this._state.dropdownAlign === 'auto') {
      this._dom.content.classList.remove('e-popover');
    }
    this._dom.handler.focus();
    this._setAndDispatchStatus('closed');
  }

  _onOpen() {
    this.setAttribute('active', true);
    this._dom.handler.className += ' ' + this._state.markupClassActive;
    this._dom.content.classList.add('e-dropdown__content-visible');
    if (this._state.dropdownAlign === 'auto') {
      this._dom.content.classList.add('e-popover');
    }
    this._state.active = true;
    const firstItem = this._dom.content.querySelector('.e-dropdown__item:not(.e-dropdown__item-disabled)');

    if (!!firstItem) {
      firstItem.focus();
    }
    this._setAndDispatchStatus('opened');
  }

  _onEscapePress(event) {
    if (event.keyCode !== 27 && event.charCode !== 27) { return; }

    event.preventDefault();
    popupHandler.close(this);
  }

  _onSpacePress(event) {
    if (event.keyCode !== 32 && event.charCode !== 32) { return; }

    event.preventDefault();
    this._events.open();
  }

  _clickToContent(event) {
    if (event.target.nodeName !== 'LABEL') {
      popupHandler.close(this);
    }
  }

  _getState() {
    return this._state;
  }

  _popperDropdownClass() {
    const classes = ['e-dropdown', 'e-dropdown-popper'];

    classes.push('e-popover');

    if (this._state.dropdownSticky) {
      classes.push('e-dropdown-popper_sticky');
    }
    return classes.join(' ');
  }

  _setAndDispatchStatus(status) {
    this._state.status = status;
    this.dispatchEvent(new CustomEvent('status', {
      detail: {
        status: status
      }
    }));
  }

  _createFocusOutCatcher() {
    const element = document.createElement('span');
    element.classList.add('e-dropdown__focus_out_catcher');
    element.tabIndex = 0;

    element.addEventListener('focus', () => this.close());

    return element;
  }

  _render() {
    if (!this._dom.content) {
      return;
    }

    if (!this._state.originalContentClassName) {
      this._state.originalContentClassName = this._dom.content.className;
    }

    const hasAlign = ['right', 'top'].indexOf(this._state.dropdownAlign) !== -1;
    const handleAutocloseEvent = this._state.autoClose ? 'addEventListener' : 'removeEventListener';
    this._dom.content[handleAutocloseEvent]('click', this._events.clickToContent);

    this._dom.content.className = this._state.originalContentClassName + ' ' + this._state.innerClass;
    this._dom.content.classList.toggle('e-dropdown__content-align' + this._state.dropdownAlign, hasAlign);
    this._dom.content.classList.toggle('e-dropdown__content-sticky', this._state.dropdownSticky);
    this._dom.content.classList.toggle('e-dropdown__content-fullwidth', this._state.dropdownFullwidth);

    this._dom.handler.className = this._state.markupClass;

    if (this._state.markupContent) {
      this._dom.handler.innerHTML = this._state.markupContent;
    }

    this._setAndDispatchStatus('rendered');
  }
}

export default EDropdown;
