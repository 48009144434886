import { html } from 'uhtml';

export class EColorPickerTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`<div class="e-color-picker__wrapper"></div>`;
  }

  // Component Content
  createElement() {
    return html`
      <e-colorinput
        @click="${this.events.onColorinputClick}"
        @keydown="${this.events.onColorinputKeydown}"
        ref="${node => this.refs.colorinput = node}"
        readonly
      />
      <e-colorpicker
        ref="${node => this.refs.colorpicker = node}"
        @apply="${this.events.onColorpickerApply}"
        @change="${this.events.onColorpickerChange}"
        @cancel="${this.events.onColorpickerCancel}"
      />
    `;
  }
}

export default EColorPickerTemplate;
