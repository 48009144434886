import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { dateUtils } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { popupUtility } from '../../../utils/popup';
import configStore from '../../../utils/config-store';

import { EDatetimeState } from './state';
import { EDatetimeEvents } from './events.js';
import { EDatetimeTemplate } from './template';

class EDatetime extends EDSComponent {
  static componentName = 'datetime';

  init() {
    this.utils = {};
    this.state = null;
    this.events = null;
    this.template = null;

    this.state = new EDatetimeState(this);
    this.events = new EDatetimeEvents(this);
    this.template = new EDatetimeTemplate(this);

    this.template.createWrapper();
    this.template.createPanelElement();

    this.addEventListener('datetimepreset.change', this.events.handlePresetChange);
    this.addEventListener('click', this.events.onClick);
    this.addEventListener('keydown', this.events.onKeydown);

    this.utils.popup = null;
  }

  connectedCallback() {
    super._cleanupContainer('e-datetime-wrapper');

    this.requestRender().then(() => {
      this.insertAdjacentElement('beforeend', this.refs.wrapper);
      this.#createPopup();
    });
  }

  disconnectedCallback() {
    this.#destroyPopup();
    this.requestRender.clear();
  }

  static get observedAttributes() {
    return [
      'value', 'clear-hidden', 'error', 'disabled', 'type',
      'inline', 'min-date', 'max-date', 'today-hidden', 'reset-time', 'manual-apply',
      'start-disabled', 'end-disabled', 'presets-disabled'
    ];
  }

  set startDisabled(value) {
    this.state.startDisabled = AttributeUtils.convertToBoolean(value);
  }

  set endDisabled(value) {
    this.state.endDisabled = AttributeUtils.convertToBoolean(value);
  }

  get isRange() {
    return this.state.isRange;
  }

  get todayHidden() {
    return this.state.todayHidden;
  }

  set todayHidden(value) {
    this.state.todayHidden = AttributeUtils.convertToBoolean(value);
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    const parsedValue = AttributeUtils.convertToObject(value);
    const isValueRange = parsedValue?.start || parsedValue?.end;
    const newValue = isValueRange ? parsedValue : (value || '');

    this.state.value = newValue;
    this.refs.panel.value = newValue;
  }

  get content() {
    const format = this.state.hasTime ? 'fullDateWithHoursAndMins' : 'fullDate';

    if (this.state.isRange) {
      const startDate = dateUtils.formatByName(this.state.value.start, format, configStore.config);
      const endDate = dateUtils.formatByName(this.state.value.end, format, configStore.config);
      const spaceDashSpace = '\xa0\u2013\xa0';

      return `${startDate}${spaceDashSpace}${endDate}`;
    }

    return dateUtils.formatByName(this.state.value, format, configStore.config);
  }

  set clearHidden(value) {
    this.state.clearHidden = AttributeUtils.convertToBoolean(value);
  }

  set error(value) {
    this.state.error = AttributeUtils.convertToBoolean(value);
  }

  set disabled(value) {
    this.state.disabled = AttributeUtils.convertToBoolean(value);
  }

  set type(value) {
    this.state.type = value;
  }

  set inline(value) {
    this.state.inline = AttributeUtils.convertToBoolean(value);
  }

  set minDate(value) {
    this.state.minDate = value;
  }

  set maxDate(value) {
    this.state.maxDate = value;
  }

  get resetTime() {
    return this.state.resetTime;
  }

  set resetTime(value) {
    const parsedValue = AttributeUtils.convertToObject(value);
    const isValueRange = parsedValue?.start || parsedValue?.end;
    const newValue = isValueRange ? parsedValue : (value || '');

    this.state.resetTime = newValue;
  }

  get manualApply() {
    return this.state.manualApply;
  }

  set manualApply(value) {
    this.state.manualApply = AttributeUtils.convertToBoolean(value);
  }

  get presetsDisabled() {
    return this.state.isPresetsDisabled;
  }

  set presetsDisabled(value) {
    this.state.isPresetsDisabled = AttributeUtils.convertToBoolean(value);
  }

  open() {
    this.utils.popup.open();
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  #createPopup() {
    if (this.utils.popup !== null) {
      this.#destroyPopup();
    }

    this.utils.popup = popupUtility.createPopup(this.refs.input, this.refs.panel, {
      onAfterOpen: this.events.onPopupOpen,
      onAfterClose: this.events.onPopupClose
    });
  }

  #destroyPopup() {
    this.utils.popup?.destroy({ preventAutoFocus: true });
    this.utils.popup = null;
  }
}

export default EDatetime;
