import autoBind from 'auto-bind';

class ETimepickerAmpmEvents {
  constructor(component) {
    autoBind(this);
    this._component = component;
    this._state = component._state;
  }

  onArrowClick() {
    this._state.toggleAmPm();
    this._dispatchEvent();
  }

  onKeyDown(event) {
    const key = event.key.toLowerCase();

    if (key === 'a') {
      this._state.isAm = true;
      this._dispatchEvent();
    } else if (key === 'p') {
      this._state.isAm = false;
      this._dispatchEvent();
    } else if (key === 'arrowdown' || key === 'arrowup') {
      event.preventDefault();
      this._state.toggleAmPm();
      this._dispatchEvent();
    }
  }

  _dispatchEvent() {
    this._component.dispatchEvent(new CustomEvent('change'));
  }
}

export default ETimepickerAmpmEvents;
