import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import State from './state';

class ESlider extends HTMLCustomElement {
  init() {
    this._events = {};
    this._events.mouseDownHandler = this._handleMouseDown.bind(this);
    this._events.mouseMoveHandler = this._handleMouseMove.bind(this);
    this._events.mouseUpHandler = this._handleMouseUp.bind(this);
    this._events.keyDownHandler = this._handleKeyDown.bind(this);

    this._isMouseDown = false;
    this._mouseDownValue = 0;
    this._focusedBubbleIndex = 0;
    this._disabled = false;
    this._showSteps = false;
    this._noBubble = false;
    this._range = false;
    this._state = new State();

    this._dom = {};

    this._dom.bubbles = [];
    this._dom.slider = this._createElement('div', 'e-slider');
    this._dom.line = this._createElement('div', 'e-slider__line');
    this._dom.steps = this._createElement('div', 'e-slider__steps');
    this._dom.fill = this._createElement('div', 'e-slider__fill');
    this._dom.minLabel = this._createElement('div', 'e-slider__label e-slider__label-min');
    this._dom.maxLabel = this._createElement('div', 'e-slider__label e-slider__label-max');

    this._dom.line.appendChild(this._dom.steps);
    this._dom.line.appendChild(this._dom.fill);
    this._dom.slider.appendChild(this._dom.line);
    this._dom.slider.appendChild(this._dom.minLabel);
    this._dom.slider.appendChild(this._dom.maxLabel);

    this._appendBubble();
  }

  connectedCallback() {
    this._refresh();
    super._cleanupContainer('.e-slider');
    this.appendChild(this._dom.slider);

    this._registerClickOnLine();
    this._dom.bubbles.forEach(bubble => {
      bubble.addEventListener('mousedown', this._events.mouseDownHandler);
    });
  }

  disconnectedCallback() {
    this._dom.line.removeEventListener('mousedown', this._events.mouseDownHandler);
    this._dom.bubbles.forEach(bubble => {
      bubble.removeEventListener('mousedown', this._events.mouseDownHandler);
    });
    document.body.removeEventListener('mousemove', this._events.mouseMoveHandler);
  }

  static get observedAttributes() {
    return [
      'value',
      'min',
      'max',
      'step',
      'show-steps',
      'disabled',
      'min-label',
      'max-label',
      'range',
      'no-bubble'];
  }

  set value(value) {
    this._state.value = value;
    this._renderValue();
    this._dispatchEvent();
  }

  get value() {
    return this._state.value.length === 1 ? this._state.value[0] : this._state.value.join(',');
  }

  set min(value) {
    this._state.min = value;
    this._refresh();
  }

  get min() {
    return this._state.min;
  }

  set max(value) {
    this._state.max = value;
    this._refresh();
  }

  get max() {
    return this._state.max;
  }

  set step(value) {
    this._state.step = value;
    this._refresh();
  }

  get step() {
    return this._state.step;
  }

  set showSteps(value) {
    this._showSteps = this._convertAttributeToBoolean(value);
    this._renderSteps();
  }

  get showSteps() {
    return this._showSteps;
  }

  set noBubble(value) {
    this._noBubble = this._convertAttributeToBoolean(value);
    this._dom.bubbles.forEach(bubble => {
      bubble.hideBubble = this._noBubble;
      bubble.updatePopper();
    });
  }

  get noBubble() {
    return this._noBubble;
  }

  set disabled(value) {
    this._disabled = this._convertAttributeToBoolean(value);
    this._dom.slider.classList[this.disabled ? 'add' : 'remove']('e-slider-disabled');

    this._dom.bubbles.forEach(bubble => {
      bubble.tabIndex = this.disabled ? -1 : 0;
      bubble.disabled = this.disabled;
    });
  }

  get disabled() {
    return this._disabled;
  }

  set minLabel(value) {
    this._dom.minLabel.textContent = value;
  }

  get minLabel() {
    return this._dom.minLabel.textContent;
  }

  set maxLabel(value) {
    this._dom.maxLabel.textContent = value;
  }

  get maxLabel() {
    return this._dom.maxLabel.textContent;
  }

  set range(value) {
    this._range = this._convertAttributeToBoolean(value);
    this._state.range = this._range;

    this._range ? this._appendSecondBubble() : this._removeSecondBubble();

    this._renderValue();
  }

  get range() {
    return this._range ? {
      lowValue: this._state.value[0],
      highValue: this._state.value[1]
    } : {};
  }

  _createElement(name, className) {
    const element = document.createElement(name);
    element.className = className;
    return element;
  }

  _appendBubble() {
    const bubble = this._createBubbleElement();
    this._dom.slider.appendChild(bubble);
    this._dom.bubbles.push(bubble);

    const bubbleIndex = this._dom.bubbles.indexOf(bubble);

    this._registerBubbleControls(bubble, bubbleIndex);
  }

  _registerBubbleControls(bubble, bubbleIndex) {
    this._registerBubbleHover(bubble);
    this._registerBubbleFocus(bubble, bubbleIndex);
    this._registerBubbleBlur(bubble);
    this._registerDragHandlers(bubble);
    this._registerKeyboardHandlers(bubble);
  }

  _appendSecondBubble() {
    if (this._dom.bubbles.length === 1) {
      this._appendBubble();
    }
  }

  _removeSecondBubble() {
    if (this._dom.bubbles.length === 2) {
      const bubble = this._dom.bubbles[1];
      bubble.parentNode.removeChild(bubble);
      this._dom.bubbles.pop();
    }
  }

  _createBubbleElement() {
    const bubble = document.createElement('e-slider-bubble');
    bubble.tabIndex = this.disabled ? -1 : 0;
    bubble.boundary = this;

    return bubble;
  }

  _registerBubbleHover(bubble) {
    bubble.addEventListener('mouseenter', () => {
      if (this.disabled || this._isMouseDown) { return; };
      bubble.firstChild.classList.add('e-slider-bubble__handle-hover');
      bubble.hideBubble = false;
      bubble.updatePopper();
    });
    bubble.addEventListener('mouseleave', () => {
      bubble.firstChild.classList.remove('e-slider-bubble__handle-hover');

      if (document.activeElement !== bubble) {
        bubble.hideBubble = this._noBubble;
        bubble.updatePopper();
      }
    });
  }

  _registerBubbleFocus(bubble, bubbleIndex) {
    bubble.addEventListener('mousedown', bubble.focus);
    bubble.addEventListener('focus', () => {
      if (this.disabled) { return; };
      this._focusedBubbleIndex = bubbleIndex;
      bubble.firstChild.classList.add('e-slider-bubble__handle-active');
      bubble.hideBubble = false;
      bubble.updatePopper();
    });
  }

  _registerBubbleBlur(bubble) {
    bubble.addEventListener('blur', () => {
      bubble.firstChild.classList.remove('e-slider-bubble__handle-active');
      bubble.hideBubble = this._noBubble;
      bubble.updatePopper();
    });
  }

  _registerClickOnLine() {
    this._dom.line.addEventListener('mousedown', this._events.mouseDownHandler);
  }

  _registerDragHandlers(bubble) {
    bubble.addEventListener('mousedown', this._events.mouseDownHandler);
  }

  _handleMouseDown(event) {
    const isCorrectMouseButton = event.which === 1;
    if (!isCorrectMouseButton || this._disabled) {
      return;
    }

    this._isMouseDown = true;
    this._mouseDownValue = this._state.value[this._focusedBubbleIndex];

    const isLine = !event.target.classList.contains('e-slider-bubble');

    if (isLine) {
      if (this._range) {
        const positionValue = this._calculatePositionFromEvent(event.clientX);
        const middleValue = this._getMiddleValueFromState();

        this._focusedBubbleIndex = positionValue < middleValue ? 0 : 1;
      }

      this._events.mouseMoveHandler(event, isLine);
    }

    setTimeout(() => this._dom.bubbles[this._focusedBubbleIndex || 0].focus());

    document.body.addEventListener('mousemove', this._events.mouseMoveHandler);
    document.body.addEventListener('mouseup', this._events.mouseUpHandler);
    document.body.classList.add('noselect');
  }

  _getMiddleValueFromState() {
    const currentValue = this._state.value;
    return (currentValue[1] - currentValue[0]) / 2 + currentValue[0];
  }

  _handleMouseMove(event, isLine) {
    if (!this._isMouseDown) {
      return;
    }

    const positionValue = this._calculatePositionFromEvent(event.clientX);
    this._changeFocusAccordingToPosition(positionValue);
    this._updateFocusedPosition(positionValue);

    const isPositionChanged = positionValue === this._lastStoredValue;
    if (!isPositionChanged && !isLine) {
      this._lastStoredValue = positionValue;
      this._dispatchEvent();
    }
  }

  _handleMouseUp() {
    document.body.removeEventListener('mousemove', this._events.mouseMoveHandler);
    document.body.removeEventListener('mouseup', this._events.mouseUpHandler);
    document.body.classList.remove('noselect');

    this._isMouseDown = false;
    this._lastStoredValue = false;
    this._dispatchEvent();
  }

  _calculatePositionFromEvent(position) {
    const { left, width } = this._dom.line.getBoundingClientRect();
    const percentageOfMousePosition = (position - left) / width;
    return this._state.min + percentageOfMousePosition * (this._state.max - this._state.min);
  }

  _registerKeyboardHandlers(bubble) {
    bubble.addEventListener('keydown', this._events.keyDownHandler);
  }

  _handleKeyDown(event) {
    if (this._disabled) {
      return;
    }

    const keyCodeMap = {
      37: 'minus1',
      40: 'minus1',
      38: 'plus1',
      39: 'plus1',
      33: 'plus10percent',
      34: 'minus10percent',
      35: 'max',
      36: 'min',
      27: 'esc'
    };

    const navigationFn = keyCodeMap[event.keyCode];

    if (!navigationFn) {
      return;
    }

    event.preventDefault();

    const currentValue = this._state.value[this._focusedBubbleIndex];
    const navigation = {
      plus1: () => currentValue + this._state.step,
      minus1: () => currentValue - this._state.step,
      plus10percent: () => currentValue + Math.round((this._state.max - this._state.min) / 10),
      minus10percent: () => currentValue - Math.round((this._state.max - this._state.min) / 10),
      max: () => this._state.max,
      min: () => this._state.min,
      esc: () => {
        this._isMouseDown = false;
        this._dom.bubbles[this._focusedBubbleIndex].blur();

        return this._mouseDownValue;
      }
    };

    const positionValue = navigation[navigationFn]();
    this._changeFocusAccordingToPosition(positionValue);
    this._updateFocusedPosition(positionValue);
    this._dispatchEvent();
  }

  _changeFocusAccordingToPosition(positionValue) {
    if (!this._range) {
      return;
    }

    const nonFocusedBubbleIndex = +!this._focusedBubbleIndex;
    const nonFocusedBubbleValue = this._state.value[nonFocusedBubbleIndex];
    const isLargerNow = this._focusedBubbleIndex === 0 && positionValue > nonFocusedBubbleValue;
    const isSmallerNow = this._focusedBubbleIndex === 1 && positionValue < nonFocusedBubbleValue;

    if (isLargerNow || isSmallerNow) {
      this._state.update(this._focusedBubbleIndex, positionValue);
      this._focusedBubbleIndex = nonFocusedBubbleIndex;
      this._dom.bubbles[this._focusedBubbleIndex].focus();
    }
  }

  _updateFocusedPosition(positionValue) {
    const index = !this._range ? 0 : this._focusedBubbleIndex;
    this._state.update(index, positionValue);
    this._renderValue();
  }

  _refresh() {
    this._renderValue();
    this._renderSteps();
  }

  _renderValue() {
    const valuesCount = this._state.value.length;
    const bubblesCount = this._dom.bubbles.length;
    if (valuesCount !== bubblesCount) {
      return;
    }

    this._dom.fill.style.width = this._state.percent[0] + '%';
    this._dom.fill.style.left = '0%';

    if (this._range) {
      this._dom.fill.style.width = (this._state.percent[1] - this._state.percent[0]) + '%';
      this._dom.fill.style.left = this._state.percent[0] + '%';
    }

    this._dom.bubbles.forEach((bubble, i) => {
      bubble.left = `calc(${this._state.percent[i]}% - 16px)`;
      bubble.label = this._state.value[i].toString();
    });
  }

  _renderSteps() {
    this._dom.steps.innerHTML = '';

    if (!this._showSteps) {
      return;
    }

    const lengthOfStep = 100 * this._state.step / (this._state.max - this._state.min);

    if (lengthOfStep === 0) { return; }

    for (let i = lengthOfStep; i < 100; i += lengthOfStep) {
      const stepElement = this._createElement('div', 'e-slider__step');
      stepElement.style.left = i + '%';
      this._dom.steps.appendChild(stepElement);
    }
  }

  _dispatchEvent() {
    const eventDetails = {
      value: this.value,
      inprogress: this._isMouseDown
    };

    const updateEvent = new CustomEvent('update', {
      detail: Object.assign(eventDetails, this.range)
    });
    this.dispatchEvent(updateEvent);
  }
}

export default ESlider;
