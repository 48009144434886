import { HTMLCustomElement, convertAttributeToBoolean, convertAttributeToArray } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { popupUtility } from '../../../utils/popup';

import ESuggestInputState from './state';
import ESuggestInputTemplate from './template';
import ESuggestInputEvents from './events';

export class ESuggestInput extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.utils = {};
    this.state = new ESuggestInputState(this);
    this.events = new ESuggestInputEvents(this);
    this.template = new ESuggestInputTemplate(this);

    this.refs.wrapper = this.template.createWrapper();

    this.addEventListener('suggestinputoption.insert', this.events.onOptionInsert);
    this.addEventListener('suggestinputoption.update', this.events.onOptionUpdate);
  }

  connectedCallback() {
    this.requestRender().then(() => {
      this._createPopup();
      this._updateActionlistItems();
      this.insertAdjacentElement('afterbegin', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();

    this._destroyPopup();
  }

  // Attributes
  static get observedAttributes() {
    return ['value', 'options', 'disabled', 'placeholder', 'loading', 'error'];
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    this.state.value = value;
  }

  get options() {
    return this.state.options;
  }

  set options(value) {
    this.state.options = convertAttributeToArray(value);
    this._updateActionlistItems();
  }

  get placeholder() {
    return this.state.placeholder;
  }

  set placeholder(value) {
    this.state.placeholder = value;
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
  }

  get loading() {
    return this.state.isLoading;
  }

  set loading(value) {
    this.state.isLoading = convertAttributeToBoolean(value);
  }

  get error() {
    return this.state.isInErrorState;
  }

  set error(value) {
    this.state.isInErrorState = convertAttributeToBoolean(value);
  }

  // Actions
  open() {
    if (this.state.disabled || (!this.state.isLoading && this.state.isOptionsEmpty)) { return; }

    this.utils.popup.open();
  }

  close() {
    this.utils.popup.close();
  }

  // Rendering
  render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  // Private methods
  _createPopup() {
    if (this.utils.popup !== null) {
      this._destroyPopup();
    }

    this.utils.popup = popupUtility.createPopup(this.refs.input, this.refs.popup, {
      matchOpenerWidth: true,
      itemToFocusOnClose: this.refs.input
    });
  }

  _destroyPopup() {
    this.utils.popup?.destroy({ preventAutoFocus: true });
    this.utils.popup = null;
  }

  _updateActionlistItems() {
    if (!this.refs.actionlist) { return; }

    this.refs.actionlist.items = this.state.itemsForActionlist;
  }

  _dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: this.state.value,
      bubbles: true
    }));
  }
}

export default ESuggestInput;
