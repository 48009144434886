import State from '../../../utils/state';
import uuid from '../../../utils/uuid';

class VerticalnavItemState extends State {
  get defaultState() {
    return {
      uuid: uuid(),
      value: false,
      icon: false,
      status: '',
      menuStatus: '',
      badge: ''
    };
  }
}

export default VerticalnavItemState;
