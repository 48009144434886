import autoBind from 'auto-bind';

export class ERulelistItemEvents {
  #component;
  #state;

  constructor(component) {
    autoBind(this);

    this.#component = component;
    this.#state = component.state;
  }

  onSlotChange(slot) {
    const slotName = slot.name;
    const isSlotVisible = !!slot.assignedNodes().length;

    this.#state.setSlotVisibility(slotName, isSlotVisible);
  }

  onCloseClick() {
    this.#state.isOpen = false;
    const closeEvent = new CustomEvent('close');
    this.#component.dispatchEvent(closeEvent);
  }

  onOpenClick() {
    this.#state.isOpen = true;
    const openEvent = new CustomEvent('open');
    this.#component.dispatchEvent(openEvent);
  }
}

export default ERulelistItemEvents;
