import { HTMLCustomElement, dateUtils } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import template from './template.js';
import configStore from '../../js/utils/config-store/index.js';
import JSLogger from '../../js/utils/jslogger';
const logger = new JSLogger('decommission');

export default class ECalendar extends HTMLCustomElement {
  init() {
    this._startOfMonth = dateUtils.startOfMonth(new Date());
    this._startDate = null;
    this._endDate = null;
    this._selected = null;
    this._range = null;
    this._locale = configStore.config.language;
    this._sticky = false;
    this._events = {
      next: this._next.bind(this),
      prev: this._prev.bind(this),
      select: this._select.bind(this),
      now: this.now.bind(this)
    };
    this._configChangeCallback = this._configChangeCallback;
  }

  connectedCallback() {
    configStore.subscribe(this._configChangeCallback.bind(this));
    this._render();
    logger.log('ECalendar');
  }

  disconnectedCallback() {
  }

  static get observedAttributes() {
    return ['date', 'sticky', 'startdate', 'enddate', 'range'];
  }

  set date(value) {
    this.selected = value;
  }

  get date() {
    return this._selected;
  }

  get locale() {
    return this._locale;
  }

  set sticky(value) {
    if (this._sticky === super._convertAttributeToBoolean(value)) {
      return;
    }

    this._sticky = super._convertAttributeToBoolean(value);
    this._render();
  }

  get sticky() {
    return this._sticky;
  }

  set selected(value) {
    if (!value) {
      this._selected = null;
      this._range = null;
      this._render();
      return;
    } else if (value === this._selected || !dateUtils.isValid(value)) {
      return;
    }

    this._startOfMonth = dateUtils.startOfMonth(value);
    this._selected = value;
    this._render();
    this.dispatchEvent(this._updateEvent());
  }

  set startdate(value) {
    if (!value || value === this._startDate || !dateUtils.isValid(value)) {
      return;
    }

    this._startDate = value;
    this._render();
  }

  get startdate() {
    return this._startDate;
  }

  set enddate(value) {
    if (!value || value === this._endDate || !dateUtils.isValid(value)) {
      return;
    }

    this._endDate = value;
    this._render();
  }

  get enddate() {
    return this._endDate;
  }

  set range(value) {
    if (!value || value === this._range || !dateUtils.isValid(value)) {
      return;
    }

    this._range = value;
    this._render();
  }

  get range() {
    if (!this._range || !dateUtils.isValid(this._range)) {
      return null;
    }

    if (dateUtils.isBefore(this._selected, this._range)) {
      return { start: this._selected, end: this._range };
    } else {
      return { start: this._range, end: this._selected };
    }
  }

  now(event) {
    event.stopPropagation();
    const now = new Date();
    const startDisabled = this._startDate ? dateUtils.isBefore(now, this._startDate) : false;
    const endDisabled = this._endDate ? dateUtils.isAfter(now, this._endDate) : false;

    if (startDisabled || endDisabled) {
      return;
    }

    this.selected = dateUtils.format(now, dateUtils.formats.fullDate);
    this.dispatchEvent(new CustomEvent('now'));
  }

  _configChangeCallback() {
    this._locale = configStore.config.language;
    this._render();
  }

  _getState() {
    const startOfWeek = dateUtils.startOfWeek(this._startOfMonth, { weekStartsOn: 1 });
    const localeObject = dateUtils.getLocaleWithFallback(this._locale);
    return {
      title: dateUtils.format(this._startOfMonth, dateUtils.formats.monthAndYear, {
        locale: localeObject.lang
      }),
      today: dateUtils.format(new Date(), dateUtils.formats.day),
      isSticky: this._sticky,
      daysOfWeek: this._getDaysOfWeek(startOfWeek),
      daysOfMonth: this._getDaysOfMonth(startOfWeek)
    };
  }

  _render() {
    render(this, template(this._getState(), this._events));
  }

  _next(event) {
    event.stopPropagation();
    this._startOfMonth = dateUtils.setMonth(this._startOfMonth, dateUtils.getMonth(this._startOfMonth) + 1);
    this._render();
  };

  _prev(event) {
    event.stopPropagation();
    this._startOfMonth = dateUtils.setMonth(this._startOfMonth, dateUtils.getMonth(this._startOfMonth) - 1);
    this._render();
  };

  _select(event) {
    event.stopPropagation();
    const clickedElement = event.target;
    const isDay = clickedElement.classList.contains('e-calendar__day');
    const isDisabled = clickedElement.classList.contains('e-calendar__day-disabled');
    if (!isDay || isDisabled) {
      return;
    }

    this.selected = clickedElement.getAttribute('date');
  };

  _updateEvent() {
    return new CustomEvent('update', {
      'bubbles': true,
      'detail': {
        'date': this._selected,
        'dateObj': dateUtils.parse(this._selected)
      }
    });
  }

  _getDaysOfMonth(startDate) {
    const weeks = [];
    let done = false;
    let date = startDate;
    let monthIndex = dateUtils.getMonth(date);
    let count = 0;
    while (!done) {
      weeks.push(this._buildWeek(date));
      date = dateUtils.addWeeks(date, 1);
      done = count++ > 2 && monthIndex !== dateUtils.getMonth(date);
      monthIndex = dateUtils.getMonth(date);
    }
    return weeks;
  }

  _buildWeek(date) {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = dateUtils.addDays(date, i);
      const startDisabled = this._startDate ? dateUtils.isBefore(day, this._startDate) : false;
      const endDisabled = this._endDate ? dateUtils.isAfter(day, this._endDate) : false;

      days.push({
        number: dateUtils.getDate(day),
        isCurrentMonth: dateUtils.getMonth(day) === dateUtils.getMonth(this._startOfMonth),
        isDisabled: startDisabled || endDisabled,
        isToday: dateUtils.isToday(day),
        isSelected: dateUtils.isSameDay(day, this._selected),
        isInRange: this.range ?
          dateUtils.isWithinInterval(day, { start: this.range.start, end: this.range.end }) :
          false,
        date: dateUtils.format(day, dateUtils.formats.fullDate)
      });
    }
    return days;
  }

  _getDaysOfWeek(startDate) {
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      const day = dateUtils.addDays(startDate, i);
      const localeObject = dateUtils.getLocaleWithFallback(this._locale);
      daysOfWeek.push(dateUtils.format(day, dateUtils.formats.dayShortName, {
        locale: localeObject.lang
      }));
    }
    return daysOfWeek;
  };
}
