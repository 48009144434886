import { html } from 'uhtml';

class Template {
  constructor(emitter, state, refs) {
    this._emitter = emitter;
    this._state = state;
    this._refs = refs;
  }

  createElement() {
    this._hoursElement = this._getHoursElement();
    this._minutesElement = this._getMinutesElement();
    this._ampmElement = this._getAmpmSwitchElement();

    return html`
      <div class="e-inputgroup">
        ${this._hoursElement}
        ${this._minutesElement}
        ${this._state.state.isAmpm ? this._ampmElement : '' }
      </div>
    `;
  }

  _getHoursElement() {
    const state = this._state.state;

    return html`
      <e-timepicker-input
        ref=${node => this._refs.hoursElement = node}
        max=${state.maxHours}
        min=${state.minHours}
        value=${state.hours !== '' ? state.hours : ''}
        default-value=${state.defaultHours}
        empty-enabled=${state.emptyEnabled}
        placeholder=${state.translations.hours}
        @update=${this._onHoursChange.bind(this)}
        ?disabled=${state.disabled}
        error=${state.error}
      ></e-timepicker-input>
    `;
  }

  _getMinutesElement() {
    const state = this._state.state;

    return html`
      <e-timepicker-input
        ref=${node => this._refs.minutesElement = node}
        value=${state.minutes !== '' ? state.minutes : ''}
        empty-enabled=${state.emptyEnabled}
        placeholder=${state.translations.minutes}
        default-value=${state.defaultMinutes}
        @update=${this._onMinutesChange.bind(this)}
        ?disabled=${state.disabled}
        error=${state.error}
      ></e-timepicker-input>
    `;
  }

  _getAmpmSwitchElement() {
    const state = this._state.state;

    return html`
      <e-timepicker-ampm
        ref=${node => this._refs.ampmElement = node}
        .isAm=${state.partOfDay === 'am'}
        @change=${this._onAmpmChange.bind(this)}
        ?disabled=${state.disabled}
        error=${state.error}
      ></e-timepicker-ampm>
    `;
  }

  _onHoursChange(event) {
    const details = this._getDetails(event);

    if (this._isFocusChangeNeeded(details)) {
      this._refs.minutesElement.focus();
    }

    this._emitter.emit('hoursChange', details);
  }

  _onMinutesChange(event) {
    const details = this._getDetails(event);

    if (this._state.state.isAmpm && this._isFocusChangeNeeded(details)) {
      this._refs.ampmElement.focus();
    }

    this._emitter.emit('minutesChange', details);
  }

  _isFocusChangeNeeded(details) {
    const { characterCounter, isFocusNeeded } = details;
    return ((characterCounter === 1 && isFocusNeeded) || characterCounter === 2);
  }

  _getDetails(event) {
    const value = event.detail.value;

    return {
      value,
      characterCounter: event.detail.characterCounter,
      inProgress: event.detail.inProgress,
      isFocusNeeded: parseInt(value + '0', 10) > event.target.max,
      hoursElement: this._refs.hoursElement,
      minutesElement: this._refs.minutesElement
    };
  }

  _onAmpmChange(event) {
    const partOfDay = event.target.isAm ? 'am' : 'pm';
    this._emitter.emit('ampmChange', partOfDay);
  }
}

export default Template;
