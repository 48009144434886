import EDatagridFilter from '../datagrid-filter';
import JSLogger from '../../../utils/jslogger';
const logger = new JSLogger('datagrid-filter-select');

class EDatagridFilterSelect extends EDatagridFilter {
  init() {
    super.init();

    this._state.type = 'select';
    this._state.options = [];
    this._state.defaultValue = undefined;
    this._state.partialMatch = false;
    this._state.value = undefined;
    this._state.contentFormat = null;
  }

  static get observedAttributes() {
    return ['options', 'default-value', 'selected-option',
      'partial-match', 'value', 'content-format'].concat(super.observedAttributes);
  }

  set options(value) {
    const options = (typeof value === 'string' ? JSON.parse(value) : value) || [];

    this._state.options = options.map(option => {
      return typeof option === 'object' ? option : { value: option, label: option };
    });

    this._dispatchEvent();
  }

  set selectedOption(value) {
    logger.log('selectedOption found!');
    this._state.defaultValue = value;
    this._dispatchEvent();
  }

  set defaultValue(value) {
    this._state.defaultValue = value;
    this._dispatchEvent();
  }

  set partialMatch(value) {
    this._state.partialMatch = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set value(value) {
    this._state.value = value;
    this._dispatchEvent();
  }

  set contentFormat(value) {
    this._state.contentFormat = value;
    this._dispatchEvent();
  }
}

export default EDatagridFilterSelect;
