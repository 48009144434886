import generateUuid from '../uuid/index.js';

const REQUEST_TOKEN_MESSAGE = 'e:authenticationToken:request';
const RECEIVE_TOKEN_MESSAGE = 'e:authenticationToken:receive';
const BACKEND_URL_SUITE = '/bootstrap.php?r=frontendAuthentication/getToken&session_id={sessionId}&integration={integration}'; // eslint-disable-line max-len
const BACKEND_URL_EMS_ADMIN = '/emsadmin/index.php/frontendtokens/get-token?integration={integration}';

class AuthenticationTokenService {
  constructor(global) {
    this._global = global;
    this._deferredRegistry = {};
  }

  initialize() {
    this._global.addEventListener('message', this._handleMessage.bind(this));
  }

  getToken(url, integration) {
    if (this._global.top === this._global) {
      return this._sendRequestToBackend(url, integration).then(response => response.token);
    } else {
      const uuid = generateUuid();
      this._global.top.postMessage({
        message: REQUEST_TOKEN_MESSAGE,
        data: { uuid, url, integration }
      }, '*');
      return new Promise((resolve, reject) => this._deferredRegistry[uuid] = { resolve, reject });
    }
  }

  _sendRequestToBackend(url, integration) {
    return this._global.fetch(
      this._getBackendUrl(url, integration),
      { method: 'get', credentials: 'same-origin' }
    ).then(response => {
      if (response.status !== 200) { throw new Error('Server error in integration: ' + integration); }
      return response.json();
    }).then(({ success, errorMsg, data }) => {
      if (!success) { throw new Error(errorMsg + ' in integration: ' + integration); }
      return data;
    });
  }

  _getBackendUrl(url, integration) {
    const sessionId = this._getSessionId();
    return url.replace('{integration}', integration).replace('{sessionId}', sessionId);
  }

  _getSessionId() {
    const regex = /[?&]session_id\=(\w+)/;
    const result = regex.exec(this._global.location.search);
    return result && result[1];
  }

  _handleMessage(event) {
    const message = this._getMessageFromEvent(event);
    if (message === RECEIVE_TOKEN_MESSAGE) {
      this._recieveToken(event.data.data);
    } else if (message === REQUEST_TOKEN_MESSAGE) {
      this._requestToken(event);
    }
  }

  _getMessageFromEvent(event) {
    return event.data && event.data.message;
  }

  _recieveToken({ uuid, success, token, errorMessage }) {
    if (!this._deferredRegistry.hasOwnProperty(uuid)) { return; }
    if (success) {
      this._deferredRegistry[uuid].resolve(token);
    } else {
      this._deferredRegistry[uuid].reject(new Error(errorMessage));
    }
    delete this._deferredRegistry[uuid];
  }

  _requestToken({ origin, source, data: { data: { uuid, url = BACKEND_URL_SUITE, integration } } }) {
    this._sendRequestToBackend(url, integration)
      .then(({ token, allowedOrigins }) => {
        if (this._isRequesterAuthorized(origin, allowedOrigins)) {
          this._sendReceiveMessage({ source, uuid, success: true, token });
        } else {
          this._sendReceiveMessage({ source, uuid, success: false, errorMessage: 'Origin not allowed!' });
        }
      })
      .catch(error => this._sendReceiveMessage({ source, uuid, success: false, errorMessage: error.message }));
  }

  _sendReceiveMessage({ source, uuid, success, token, errorMessage }) {
    source.postMessage({
      message: RECEIVE_TOKEN_MESSAGE,
      data: { uuid, success, token, errorMessage }
    }, '*');
  }

  _isRequesterAuthorized(origin, allowedOrigins) {
    const matchingOrigins = allowedOrigins.filter(allowedOrigin => new RegExp(allowedOrigin).test(origin));
    return matchingOrigins.length > 0;
  }
}

export {
  AuthenticationTokenService,
  BACKEND_URL_SUITE,
  BACKEND_URL_EMS_ADMIN
};
