import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import Popper from 'popper.js';
import JSLogger from '../../js/utils/jslogger/index.js';
const log = new JSLogger('decommission');

class EBubble extends HTMLCustomElement {
  init() {
    this._dom = {};
    this._dom.popper = this._popperElement();
    this._dom.arrow = this._arrowElement();
    this._dom.content = this._contentElement();
    this._dom.bubble = document.createElement('div');
    this._dom.bubble.classList.add('e-bubble');
    this._boundary = 'viewport';
    this._color = '';
    this._type = '';
    this._placement = 'top';
    this._distance = '';
  }

  connectedCallback() {
    this._render();
    log.log('EBubble');
  }

  disconnectedCallback() {
    this._destroyPopper();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this[name] = newValue;
  }

  static get observedAttributes() {
    return ['label', 'percent', 'boundary', 'type', 'color', 'placement', 'distance'];
  }

  set placement(value) {
    this._placement = value;
    this.updatePopper();
  }

  set distance(value) {
    this._distance = value;
    this._dom.popper.classList.toggle('e-bubble-label-compact', this._distance === 'small');
    this.updatePopper();
  }

  set label(value) {
    this._label = value;
    this._dom.content.innerHTML = this.label;
    this.updatePopper();
  }

  get label() {
    return this._label;
  }

  set type(value) {
    const oldType = this.type;
    this._type = value;
    this._dom.bubble.classList.remove('e-bubble-' + oldType);
    this._dom.bubble.classList.add('e-bubble-' + this.type);
    if (this.type === 'base') {
      this._dom.popper.classList.add('e-bubble-label');
      this._dom.arrow.classList.remove('e-tt__arrow');
      this._dom.arrow.classList.add('e-bubble__line');
    } else {
      this._dom.popper.classList.remove('e-bubble-label');
      this._dom.arrow.classList.remove('e-bubble__line');
    }
  }

  get type() {
    return this._type;
  }

  set color(value) {
    const oldColor = this.color;
    this._color = value;
    this._dom.bubble.classList.remove('text-color-' + oldColor);
    this._dom.bubble.classList.add('text-color-' + this.color);
    this._dom.popper.classList.remove('background-color-' + oldColor);
    this._dom.popper.classList.add('background-color-' + this.color);
    this._dom.arrow.classList.remove('text-color-' + oldColor);
    this._dom.arrow.classList.add('text-color-' + this.color);
  }

  get color() {
    return this._color;
  }

  set percent(value) {
    this._percent = value;
    this._dom.bubble.style.left = this.percent + '%';
    this.updatePopper();
  }

  get percent() {
    return this._percent;
  }

  set boundary(value) {
    if (value === 'parent') {
      this._boundary = 'parent';
    } else if (value && typeof value === 'string') {
      this._boundary = document.querySelector(value);
    } else if (value && typeof value === 'object') {
      this._boundary = value;
    } else {
      this._boundary = 'viewport';
    }

    if (this._popper) {
      this._destroyPopper();
      this._popper = this._initPopper();
    }
  }

  get boundary() {
    return this._boundary;
  }

  updatePopper() {
    if (!this._popper) {
      return;
    }

    this._popper.update();
  }

  _render() {
    this.appendChild(this._dom.bubble);
    this._popper = this._initPopper();
  }

  _popperElement() {
    const popperElement = document.createElement('div');
    popperElement.className = 'e-tt e-tt-bubble';
    return popperElement;
  }

  _arrowElement() {
    const arrowElement = document.createElement('div');
    arrowElement.className = 'e-tt__arrow';
    return arrowElement;
  }

  _contentElement() {
    const popperContent = document.createElement('div');
    popperContent.className = 'e-tt__content';
    return popperContent;
  }

  _initPopper() {
    if (!this.parentNode) {
      return;
    }

    this._dom.popper.appendChild(this._dom.content);
    this._dom.popper.appendChild(this._dom.arrow);
    this.appendChild(this._dom.popper);
    return new Popper(this._dom.bubble, this._dom.popper, {
      placement: this._placement,
      removeOnDestroy: true,
      modifiers: {
        computeStyle: {
          gpuAcceleration: false
        },
        arrow: {
          element: this._dom.arrow
        },
        preventOverflow: {
          boundariesElement: this.boundary === 'parent' ? this.parentNode : this.boundary,
          padding: this.boundary === 'viewport' ? -this.offsetWidth / 2 : -7,
          escapeWithReference: this.boundary === 'viewport' ? true : false
        },
        flip: {
          enabled: false
        }
      }
    });
  }

  _destroyPopper() {
    if (!this._popper) {
      return;
    }

    this._popper.destroy();
    this._popper = null;
  }
}

export default EBubble;
