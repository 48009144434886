import { html } from 'uhtml';
import isMatch from 'lodash.ismatch';
import classnames from 'clsx';
import flipper from '../../../../utils/flipper';
import EDatagridItemActionTemplate from './item-action';
import EDatagirdPrioritySnackbarTemplate from './priority-snackbar';
import EDatagirdEmptyStateTemplate from './emptystate';

export default class EDatagridBodyTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;

    this._itemActionTemplate = new EDatagridItemActionTemplate(this._state, this._events);
    this._prioritySnackbarTemplate = new EDatagirdPrioritySnackbarTemplate(this._state, this._events);
    this._emptyStateTemplate = new EDatagirdEmptyStateTemplate(this._state, this._events, this._refs);
  }

  create() {
    const isBodyVisible = this._state.content ||
    this._state.serverState.isLoading ||
    this._state.serverState.serverErrorState;

    if (isBodyVisible) {
      return html`
        <tbody>
          ${this._skeleton()}
          ${this._prioritySnackbarTemplate.create()}
          ${this._rows()}
          ${this._emptyStateTemplate.create()}
        </tbody>
      `;
    }

    return html`<tbody></tbody>`;
  }

  _skeleton() {
    if (!this._state.serverState.isLoading) { return; }

    return new Array(this._state.paginationState.pageSize)
      .fill()
      .map(() => this._row({ raw: {}, formatted: {} }));
  };

  _row(rowData) {
    const classes = classnames('e-datagrid__row e-table__row', {
      'e-table__row-checked': this._state.priorityState.selectedToPrioritize === rowData.raw,
      'e-table__row-active': this._state.highlightedKey && !!rowData.raw[this._state.highlightedKey]
    });

    const disabledStyle = this._state.priorityState.isPriorityModeDisabledOnRow(rowData) ||
      this._state.serverState.isLoading ? 'opacity: .5;' : null;

    const twoLinesColumn = flipper.isOn('ui_datagrid_two_lines') ?
      this._state.columnDefinitions
        .filter(columnDefinition => !columnDefinition.parentUuid)
        .map(columnDefinition => this._column(rowData, columnDefinition)) :
      this._state.columnDefinitions.map(columnDefinition => this._column(rowData, columnDefinition));

    return html`
      <tr class="${classes}" style=${disabledStyle}>
        ${this._checkbox(rowData)}
        ${this._radioButton(rowData)}
        ${twoLinesColumn}
        ${this._itemActionsColumn(rowData)}
      </tr>
    `;
  };

  _checkbox(rowData) {
    if (!this._state.hasBulkCheckboxes) { return; }

    if (this._state.serverState.isLoading) {
      return html`<td>${this._skeletonText(true)}</td>`;
    }

    const isInSelection = this._state.bulkState.selection.some(selectedItem => isMatch(rowData.raw, selectedItem));
    const isSelectionInverted = this._state.bulkState.isSelectionInverted;
    const isChecked = (isInSelection && !isSelectionInverted) || (!isInSelection && isSelectionInverted);

    const firstTextColumn =
      this._state.columnDefinitions.find(column => column.type === '' && !column.renderHtml) ||
      this._state.columnDefinitions[0];

    const srLabelColumnContent = rowData.formatted[firstTextColumn.contentKey];
    const srLabel = this._state.translations.getBulkCheckboxLabel(srLabelColumnContent);

    return html`
      <td>
        <e-checkbox
          sr-label=${srLabel}
          ?checked=${isChecked}
          ?disabled=${this._state.serverState.isLoading}
          @change=${(event) => this._events.onBulkSelectRowCheckboxChange(event, rowData)}
        ></e-checkbox>
      </td>
    `;
  };

  _radioButton(rowData) {
    if (!this._state.hasPriorityRadioButtons) { return; }

    if (this._state.serverState.isLoading) {
      return html`<td>${this._skeletonText(true)}</td>`;
    }

    const isChecked = this._state.priorityState.selectedToPrioritize === rowData.raw;
    const isDisabled = this._state.priorityState.isPriorityModeDisabledOnRow(rowData) ||
      this._state.serverState.isLoading;

    const radioTemplate = html`
      <e-radio
        ?checked=${isChecked}
        ?disabled=${isDisabled}
        @change=${(event) => this._events.onPriorityModeRowRadioChange(event, rowData)}
      ></e-radio>
    `;

    const disabledTooltip = this._state.priorityState.priorityColumn.disabledTooltip;

    if (isDisabled && disabledTooltip) {
      return html`<td><e-tooltip content="${disabledTooltip}" width="200">${radioTemplate}</e-tooltip></td>`;
    }

    return html`<td>${radioTemplate}</td>`;
  };

  _column(rowData, columnDefinition) {
    let columnSettings;

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      columnSettings = this._state.columnSettingsState.activeSettings[columnDefinition.contentKey];
    } else {
      columnSettings = this._state.columnSettings[columnDefinition.contentKey];
    }

    const classes = classnames('e-table__col', {
      'e-hidden': columnSettings?.hidden && this._state.isHeaderVisible,
      'e-table__col-nowrap': columnDefinition.noWrap,
      'e-table__col-right': ['number', 'percent', 'currency', 'duration'].includes(columnDefinition.type)
    });

    if (columnDefinition.type === 'switch' && !this._state.serverState.isLoading) {
      return html`
        <td class="${classes}">${this._columnSwitch(columnDefinition, rowData)}</td>
      `;
    }
    const columnContent = flipper.isOn('ui_datagrid_two_lines') ?
      this._getColumnContent(columnDefinition, rowData) :
      this._formatColumnContent(columnDefinition, rowData);

    return html`
      <td class="${classes}">${columnContent}</td>
    `;
  };

  _columnSwitch(columnDefinition, rowData) {
    const hasLoadingSwitch = this._state.serverState.switchLoadingItems
      .filter(item => item.data === rowData.raw)
      .filter(row => row.columnContentKey === columnDefinition.contentKey);

    const isLoading = hasLoadingSwitch.length > 0;
    const isActive = !!rowData.formatted[columnDefinition.contentKey];
    const disabledByKey = !!(columnDefinition.disabledKey && rowData.formatted[columnDefinition.disabledKey]);
    const isDisabled = columnDefinition.disabled || disabledByKey;
    const isPermission = isDisabled && columnDefinition.disabledType === 'permission';
    const disabledTooltipText = columnDefinition.disabledTooltipKey ?
      rowData.formatted[columnDefinition.disabledTooltipKey] : columnDefinition.disabledTooltip;

    let label = isLoading ? columnDefinition.activatingLabel : columnDefinition.inactiveLabel;
    let tooltip = isDisabled ? disabledTooltipText : columnDefinition.activateTooltip;

    if (isActive) {
      label = isLoading ? columnDefinition.deactivatingLabel : columnDefinition.activeLabel;
      tooltip = isDisabled ? disabledTooltipText : columnDefinition.deactivateTooltip;
    }

    const onColumnSwitchChangeEvent = () =>
      this._events.onColumnSwitchChange(rowData, columnDefinition, isLoading, isDisabled);

    return html`
      <e-tooltip
        class="e-datagrid__switch_tooltip"
        content=${tooltip}
        permission=${isPermission}
        width="200"
        ?disabled="${isLoading}"
      >
        <div class="e-grid">
          <e-switch
            label=${label}
            ?checked=${isActive}
            ?disabled=${isLoading || isDisabled}
            @change=${onColumnSwitchChangeEvent}
          ></e-switch>
          ${this._createSwitchSpinner(isLoading)}
        </div>
      </e-tooltip>
    `;
  };

  _createSwitchSpinner(isLoading) {
    if (!isLoading) { return; }
    return html`<e-spinner data-size="table"></e-spinner>`;
  }

  _formatColumnContent(columnDefinition, rowData) {
    if (this._state.serverState.isLoading) { return this._skeletonText(); }

    if (!columnDefinition.renderHtml) { return rowData.formatted[columnDefinition.contentKey]; }

    return html([rowData.formatted[columnDefinition.contentKey]]);
  }

  _getColumnContent(columnDefinition, rowData) {
    const mainColumnContent = this._formatColumnContent(columnDefinition, rowData);

    const childrenColumns = this._state.columnDefinitions
      .filter(column => column.parentUuid === columnDefinition.uuid)
      .filter(column => {
        let columnSettings;

        if (flipper.isOn('ui_datagrid_column_hidden')) {
          columnSettings = this._state.columnSettingsState.activeSettings[column.contentKey];
        } else {
          columnSettings = this._state.columnSettings[column.contentKey];
        }

        return !columnSettings?.hidden;
      });

    if (childrenColumns.length) {
      const childrenColumnContent = childrenColumns.map(
        childColumnDefinition => html`<div>${this._formatColumnContent(childColumnDefinition, rowData)}</div>`
      );

      return html`${[html`<div>${mainColumnContent}</div>`, ...childrenColumnContent]}`;
    }

    return mainColumnContent;
  };

  _itemActionsColumn(rowData) {
    if (!this._state.itemActions.length || this._state.priorityState.isPriorityModeOpen) { return; }

    const itemActions = this._state.itemActions
      .filter(itemAction => !(itemAction.visibleKey && !(rowData.formatted[itemAction.visibleKey])))
      .map((itemAction, index) => {
        const isFirstChild = index === 0;
        const isLastChild = (this._state.itemActions.length - 1) === index;
        return this._itemActionTemplate.create(itemAction, rowData, isFirstChild, isLastChild);
      });

    const tdClasses = classnames('e-table__col e-table__col e-table__col-actions', {
      'e-datagrid__item_actions_wrapper': this._state.isHorizontalScrollActive
    });

    const itemActionsClasses = classnames('', {
      'e-datagrid__item_actions e-inputgroup e-inputgroup-inline': this._state.isHorizontalScrollActive
    });

    return html`
      <td
        class="${tdClasses}"
        @click=${(event) => this._events.onItemActionColumnClick(event)}
      >
        <div class="${itemActionsClasses}">
          ${itemActions}
        </div>
      </td>
    `;
  };

  _rows() {
    const hasContent = this._state.serverState.serverErrorState &&
      !(this._state.content && this._state.content.length);

    if (this._state.serverState.isLoading || hasContent || this._state.isEmpty) { return; }

    return this._state.visibleContent.map(rowData => this._row(rowData));
  };

  _skeletonText(isSmallAction = false) {
    const classes = classnames('e-skeleton', {
      'e-skeleton-xs': isSmallAction
    });

    return html`<div class=${classes}></div>`;
  };
}
