import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { EStepBarState } from './state';
import { EStepBarEvents } from './events';
import { EStepBarTemplate } from './template';
;
export class EStepBar extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            wrapper: null,
            steps: {}
        };
        this.state = new EStepBarState(this);
        this.events = new EStepBarEvents(this);
        this.template = new EStepBarTemplate(this);
        this.refs.wrapper = this.template.createWrapper();
        this.addEventListener('step.update', this.events.onStepUpdate);
    }
    connectedCallback() {
        super._cleanupContainer('.e-step-bar__wrapper');
        this.classList.add('e-step-bar');
        this.classList.toggle('e-step-bar-floating_with_navigation', this._pageHasNavigation());
        this.requestRender().then(() => {
            this.insertAdjacentElement('beforeend', this.refs.wrapper);
        });
    }
    get floating() {
        return this.state.isFloating;
    }
    set floating(value) {
        this.state.isFloating = convertAttributeToBoolean(value);
        this.classList.toggle('e-step-bar-floating', this.state.isFloating);
    }
    get label() {
        return this.state.label;
    }
    set label(value) {
        this.state.label = value ? String(value) : this.state.defaultLabel;
        this.refs.wrapper.setAttribute('aria-label', this.state.label);
    }
    // Rendering
    render() {
        if (!this.isConnected) {
            return;
        }
        render(this.refs.wrapper, this.template.createElement());
    }
    // Private methods
    _pageHasNavigation() {
        return !!document.querySelector('e-navigation');
    }
}
// Attributes
EStepBar.observedAttributes = ['floating', 'label'];
