import createUuid from '../uuid';
import { OPEN_MESSAGE, SERVICE_CLOSE_MESSAGE, CLIENT_CLOSE_MESSAGE, BUTTON_CLICK_MESSAGE } from './constants';

class NotificationClient {

  constructor() {
    this._notifications = {};
  }

  open(options = {}, top) {
    const targetWindow = options.targetWindow || top || window.top;
    const notificationId = createUuid();
    const buttonsWithUuid = this._addUuidToButtons(options.buttons);
    this._notifications[notificationId] = {
      buttons: this._getButtonListeners(buttonsWithUuid)
    };

    const buttonsForMessage = this._removeOnClick(buttonsWithUuid);
    const optionsForMessage = {
      ...options,
      buttons: buttonsForMessage,
      uuid: notificationId,
      targetWindow: undefined
    };

    targetWindow.postMessage({ message: OPEN_MESSAGE, data: optionsForMessage }, '*');

    return {
      close: () => this._close(notificationId, targetWindow)
    };
  };

  subscribe() {
    window.addEventListener('message', event => {
      if (!event.data) { return; }
      if (event.data.message === BUTTON_CLICK_MESSAGE) {
        this._handleClick(event.data.data);
      }
      if (event.data.message === SERVICE_CLOSE_MESSAGE) {
        this._cleanUp(event.data.data.notificationUuid);
      };
    });
  }

  _addUuidToButtons(buttons = []) {
    return buttons.map(button => ({ ...button, uuid: createUuid() }));
  }

  _getButtonListeners(buttons) {
    return buttons.reduce((buttonListeners, { uuid, onClick }) => ({ ...buttonListeners, [uuid]: { onClick } }), {});
  }

  _removeOnClick(buttons) {
    return buttons.map(button => ({ ...button, onClick: undefined }));
  }

  _handleClick({ notificationUuid, buttonUuid }) {
    const onClick = this._notifications[notificationUuid].buttons[buttonUuid].onClick;
    if (onClick) { onClick(); };
  }

  _close(notificationUuid, targetWindow) {
    this._cleanUp(notificationUuid);
    targetWindow.postMessage({ message: CLIENT_CLOSE_MESSAGE, data: { notificationUuid } }, '*');
  }

  _cleanUp(notificationUuid) {
    delete this._notifications[notificationUuid];
  }
}

export default NotificationClient;
