import getFeatureServiceUtil from '../feature-service/index.js';
import loader from '../loader';
import manifest from '../../../../dist/app.js';

export const uiAuditor = {
  init() {
    window.addEventListener('load', async () => {
      const featureService = await getFeatureServiceUtil();

      if (featureService.isOn('ui_auditor')) {
        loader.loadScript(manifest.uiAuditorJSUrl, { parentElement: document.body, isTypeModule: true });
        loader.loadStyle(manifest.uiAuditorCSSUrl, { parentElement: document.head });
      };
    });
  }
};
