class State {

  constructor() {
    this._min = 0;
    this._max = 100;
    this._step = 1;
    this._rawValue = [50];
    this._value = this._rawValue;

    this._isValueSet = false;
    this._range = false;
  }

  set value(value) {
    this._isValueSet = true;
    this._value = this._setValueAsArray(value);
    this._rawValue = this._value;
    this._value = this._makeValidValue(this._value);
  }

  get value() {
    return this._value;
  }

  set range(value) {
    this._range = value;

    if (this.range) {
      if (this.value.length === 1) {
        this.value = [this._min, this._isValueSet ? this.value[0] : this._max];
      }

      this._rawValue = this._isValueSet ? this.value : [this._min, this._max];
      this._value = this._rawValue;
    }
  }

  get range() {
    return this._range;
  }

  set min(value) {
    this._min = this._setNumericValue(value, '_min');
    this._value = this._makeValidValue(this._rawValue);
  }

  get min() {
    return this._min;
  }

  set max(value) {
    this._max = this._setNumericValue(value, '_max');
    this._value = this._makeValidValue(this._rawValue);
  }

  get max() {
    return this._max;
  }

  set step(value) {
    this._step = this._setNumericValue(value, '_step');
    this._value = this._makeValidValue(this._rawValue);
  }

  get step() {
    return this._step;
  }

  get percent() {
    return this._value.map(value => 100 * (value - this._min) / (this._max - this._min));
  }

  update(index, value) {
    let tempValue = this.value;
    tempValue[index] = value;

    this.value = tempValue.join(',');
  }

  _setValueAsArray(value) {
    const arrayValue = value.toString().split(',');
    return arrayValue.map((item, index) => this._setNumericValue(item, '_value', index));
  }

  _setNumericValue(value, property, index = false) {
    const parsedValue = parseFloat(value);
    const fallbackValue = index !== false ? this[property][index] : this[property];
    return !isNaN(parsedValue) ? parsedValue : fallbackValue;
  }

  _makeValidValue(valueArray) {
    if (!this._isValueSet) {
      return valueArray.length === 1 ? [(this._min + this._max) / 2] : [this._min, this._max];
    }

    const valueInsideBoundaries = [];

    valueArray.forEach(value => {
      const currentStep = Math.round((value - this._min) / this._step);
      valueInsideBoundaries.push(this._forceInsideValueBoundaries(this._step * currentStep + this._min));
    });

    return valueInsideBoundaries.sort((a, b) => a - b);
  }

  _forceInsideValueBoundaries(value) {
    if (value < this._min) {
      return this._min;
    } else if (value > this._max) {
      return this._max;
    }

    return value;
  }
}

export default State;
