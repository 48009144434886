import classNames from 'clsx';

import translator from '../translator/index.js';
import { messages, types } from './constants.js';
import purifier from '../dom-purify.js';
import flipper from '../flipper/index.js';

const confirmLabel = options => (options.confirm && options.confirm.label) ||
  translator.translate('components.confirmDialog.confirmLabel');

const cancelLabel = options => (options.cancel && options.cancel.label) ||
  translator.translate('components.confirmDialog.cancelLabel');

const handleButtonClick = (response, dialog, source, closeListener) => {
  dialog.removeEventListener('dialog.close', closeListener);
  dialog.close();
  source.postMessage({
    message: messages.RESPONSE,
    data: { response }
  }, '*');
};


const openDialog = function(global, options, source, type) {
  const dialog = global.document.createElement('e-dialog');

  const closeListener = () => source.postMessage({
    message: messages.RESPONSE,
    data: { response: 'cancel' }
  }, '*');

  dialog.addEventListener('dialog.close', closeListener);

  const buttons = {
    [cancelLabel(options)]: {
      className: 'e-flex__item e-dialog__cancelbutton',
      callback() {
        handleButtonClick('cancel', dialog, source, closeListener);
      }
    },
    [confirmLabel(options)]: {
      className: type === types.CONSEQUENTIAL ?
        'e-flex__item e-btn-primary e-dialog__confirmbutton' :
        'e-flex__item e-dialog__confirmbutton e-dialog__confirmbutton-destructive',
      callback() {
        handleButtonClick('confirm', dialog, source, closeListener);
      }
    }
  };

  const dialogClass = classNames('e-dialog-confirmation', {
    'e-dialog-confirmation-consequential': type === types.CONSEQUENTIAL,
    'e-dialog-confirmation-destructive': type === types.DESTRUCTIVE
  });

  const icon = '<e-icon icon="e-times-circle" color="error" class="e-dialog-confirmation__headline_icon"></e-icon>';


  if (flipper.isOn('ui_dialog_headline_sanitize')) {
    const sanitizeHeadline = options.disableSanitization ? options.headline : purifier.sanitize(options.headline);

    dialog.open({
      headline: type === types.DESTRUCTIVE ? `${icon}${sanitizeHeadline}` : sanitizeHeadline,
      content: options.disableSanitization ? options.content : purifier.sanitize(options.content),
      className: dialogClass,
      buttons: buttons
    });
  } else {
    dialog.open({
      headline: type === types.DESTRUCTIVE ? `${icon}${options.headline}` : options.headline,
      content: options.disableSanitization ? options.content : purifier.sanitize(options.content),
      className: dialogClass,
      buttons: buttons
    });
  }

};

export default openDialog;
