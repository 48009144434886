class State {
  constructor(renderFn = () => {}) {
    this.render = renderFn;
  }

  get defaultState() { return {}; }

  get state() {
    return this._state$ || (this.state = this.defaultState);
  }

  set state(value) {
    Object.defineProperty(this, '_state$', { configurable: true, value: value });
  }

  setState(state, render) {
    const target = this.state;
    const source = typeof state === 'function' ? state.call(this, target) : state;
    for (const key in source) { target[key] = source[key]; }
    if (render !== false) { this.render(); }
    return this;
  }

  upsertCollection(collectionName, value, idKey = 'id') {
    const collection = this.state[collectionName].slice();
    const storedItem = collection.find(storedItem => storedItem[idKey] === value[idKey]);

    if (storedItem) {
      const itemIndex = collection.indexOf(storedItem);
      collection[itemIndex] = value;
    } else {
      collection.push(value);
    }

    this.state[collectionName] = collection;
    this.render();
  }

  removeFromCollection(collectionName, id, idKey = 'id') {
    this.state[collectionName] = this.state[collectionName].filter(storedItem => storedItem[idKey] !== id);
    this.render();
  }
}

export default State;
