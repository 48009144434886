import { html } from 'uhtml';

export default class EDatagridPrioritySnackbarTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  create() {
    if (this._state.serverState.isLoading || !this._state.priorityState.isPriorityModeOpen) { return; }

    const selectedPosition = this._state.priorityState.selectedToPrioritizePosition;
    const moveUpDisabled = selectedPosition === 1 ? 'disabled' : null;
    const moveDownDisabled = selectedPosition === this._state.content.length ? 'disabled' : null;

    return html`
      <div class="e-snackbar e-snackbar-top e-snackbar-autowidth e-snackbar-sticky" id="snackbar-sticky">
        <div class="e-grid e-grid-small">
          <div class="e-snackbar__actions e-snackbar__actions-sticky e-cell e-cell-small">
            ${this._createPriorityButton(this._events.onPriorityTopButtonClick, moveUpDisabled, 'e-move-top')}
            ${this._createPriorityButton(this._events.onPriorityUpButtonClick, moveUpDisabled, 'e-move-up')}
            ${this._createPriorityButton(this._events.onPriorityDownButtonClick, moveDownDisabled, 'e-move-down')}
            ${this._createPriorityButton(this._events.onPriorityBottomButtonClick, moveDownDisabled, 'e-move-bottom')}
          </div>
          <div class="e-snackbar__actions e-cell e-cell-small">
            <button class="e-btn e-btn-secondary" type="button" @click=${this._events.onPriorityModeButtonClick}>
              <e-icon icon="check"></e-icon>
              <e-translation key="components.datagrid.priority.exitPriorityModeButton"></e-translation>
            </button>
          </div>
        </div>
      </div>
    `;
  }

  _createPriorityButton(eventHandler, isDisabled, icon) {
    const isRowVisible = this._state.visibleContent
      .map(row => row.raw)
      .indexOf(this._state.priorityState.selectedToPrioritize) !== -1;

    return html`
      <button
        class="e-btn e-btn-onlyicon e-btn-borderless"
        type="button"
        @click=${eventHandler}
        ?disabled=${isDisabled || !isRowVisible}
      >
        <e-icon icon="${icon}"></e-icon>
      </button>
    `;
  }
}
