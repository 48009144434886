export class EIllustratedMessageState {
  #hasIcon = false;
  #iconSize = null;
  #type = 'neutral';
  #layout = 'vertical';

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  get hasIcon() {
    return this.#hasIcon;
  }

  set hasIcon(value) {
    this.#hasIcon = value;
    this.requestRender();
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    this.#type = value;
    this.requestRender();
  }

  get layout() {
    return this.#layout;
  }

  set layout(value) {
    this.#layout = value;
    this.requestRender();
  }

  get iconSize() {
    const allowedIconSizes = ['small', 'large'];
    if (!allowedIconSizes.includes(this.#iconSize)) {
      return 'default';
    }

    return this.#iconSize;
  }

  set iconSize(value) {
    this.#iconSize = value;
    this.requestRender();
  }
}
