import isUrl from 'validator/lib/isURL.js';

const createFloatingPageElement = ({ url, name, id }) => {
  const isAlphanum = /^([a-zA-Z0-9_.-]*)$/;

  if (!isUrl(url, { require_tld: false, require_host: false })) {
    throw new Error('Not a valid url');
  }
  if (!isAlphanum.test(name)) {
    throw new Error('Not a valid name');
  }
  if (!isAlphanum.test(id)) {
    throw new Error('Not a valid id');
  }

  const floatingPageElement = global.document.createElement('e-floatingpage');
  floatingPageElement.innerHTML = `
  <div class="e-dialog e-dialog-visible e-dialog-active e-dialog-floatingpage" tabindex="-1">
    <div class="e-dialog__container e-dialog__container-floatingpage">
      <div class="e-dialog__content">
        <iframe
          name="${name}"
          id="${id}"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        >
        </iframe>
      </div>
    </div>
  </div>
  `;
  const iframe = floatingPageElement.querySelector('iframe');
  iframe.src = url;

  return floatingPageElement;
};

const getDataFromEvent = (event, property) => {
  return event.data && event.data.data && event.data.data[property] || '';
};

export default {
  subscribe: (global = window) => {
    global.addEventListener('message', event => {
      if (event.data.message === 'e:openFloatingPage') {
        const floatingPageElement = createFloatingPageElement({
          url: getDataFromEvent(event, 'url'),
          name: getDataFromEvent(event, 'name'),
          id: getDataFromEvent(event, 'id')
        });
        global.document.body.appendChild(floatingPageElement);
      } else if (event.data.message === 'e:closeFloatingPage') {
        const floatingPageElement = global.document.querySelector('e-floatingpage');
        if (!floatingPageElement) {
          return;
        }

        floatingPageElement.parentNode.removeChild(floatingPageElement);
      }
    });
  },

  open: (data, global = window) => {
    global.top.postMessage({ message: 'e:openFloatingPage', data }, '*');
  },

  close: (global = window) => {
    global.top.postMessage({ message: 'e:closeFloatingPage' }, '*');
  }
};
