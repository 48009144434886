import { HTMLCustomElement } from '@emartech/ui-framework-utils';

export class EDatagridEmptyState extends HTMLCustomElement {
  init() {
    this._state = {
      layoutType: 'table'
    };
  }

  connectedCallback() {
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('empty-state.delete'));
  }

  set layoutType(value) {
    this._state.layoutType = value;
    this._dispatchUpdateEvent();
  }

  _dispatchUpdateEvent() {
    this.dispatchEvent(new CustomEvent('empty-state.update', {
      bubbles: true,
      detail: { ...this._state }
    }));
  }
}

export default EDatagridEmptyState;
