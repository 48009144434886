import Logger from '../logger/index.js';
class JSLogger {
  #logger;

  constructor(namespace) {
    this.#logger = new Logger(namespace);
  }

  async log(message, additionalData) {
    await this._send('log', message, additionalData);
  }

  async error(message, error, additionalData) {
    await this._send('error', message, additionalData, error);
  }

  async warn(message, additionalData) {
    await this._send('warn', message, additionalData);
  }

  async success(message, additionalData) {
    await this._send('success', message, additionalData);
  };

  async _send(level, message, additionalData, error) {
    if (this.#logger.isPingdom(window.navigator.userAgent)) { return; }

    const logData = this.#logger.isDomainAllowed(window.location.hostname) ?
      await this.#logger.logData(message, { level, error, additional: additionalData }) :
      await this.#logger.logData('logging is not allowed for this domain', { level: 'warn' });

    const url = this.#logger.getServiceURL(logData);
    this.#sendRequest(url);
  }

  #sendRequest(url) {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.timeout = 2000;
    request.send();
  }
}

export default JSLogger;
