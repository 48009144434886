import { render } from 'uhtml';
import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { EAccordionEvents } from './events';
import { EAccordionTemplate } from './template';
import { EAccordionState } from './state';
export class EAccordion extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            wrapper: null
        };
        this.state = new EAccordionState(this);
        this.events = new EAccordionEvents(this);
        this.template = new EAccordionTemplate(this);
        this.refs.wrapper = this.template.createWrapper();
        this.addEventListener('accordion-item.update', this.events.onItemUpdate);
    }
    connectedCallback() {
        this.classList.add('e-accordion-list');
        this.requestRender().then(() => {
            this.appendChild(this.refs.wrapper);
        });
    }
    get borderless() {
        return this.state.isBorderless;
    }
    set borderless(value) {
        this.state.isBorderless = convertAttributeToBoolean(value);
    }
    get single() {
        return this.state.isSingle;
    }
    set single(value) {
        this.state.isSingle = convertAttributeToBoolean(value);
    }
    // Rendering
    render() {
        this.template.handleWrapperClassname();
        render(this.refs.wrapper, this.template.createElement());
    }
}
// Attributes
EAccordion.observedAttributes = ['borderless', 'single'];
export default EAccordion;
