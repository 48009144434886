import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class ELayoutBackButton extends HTMLCustomElement {
  init() {
    this._icon = this._createIcon();
    this._anchor = this._createAnchor();

    this.addEventListener('mouseenter', this._setWidth.bind(this));
    this.addEventListener('mouseleave', this._unsetWidth.bind(this));

    this.addEventListener('focusin', this._setWidth.bind(this));
    this.addEventListener('focusout', this._unsetWidth.bind(this));
  }

  connectedCallback() {
    super._cleanupContainer('e-icon');

    this.insertAdjacentElement('afterbegin', this._icon);
    this.tabIndex = -1;

    if (this.parentNode.tagName === 'A' || this.parentNode.tagName === 'BUTTON') {
      this.parentNode.addEventListener('focusin', this._setWidth.bind(this));
      this.parentNode.addEventListener('focusout', this._unsetWidth.bind(this));
    }
  }

  static get observedAttributes() {
    return ['href'];
  }

  get href() {
    return this._anchor.getAttribute('href');
  }

  set href(value) {
    if (value === null) {
      this._anchor.remove();
      return;
    }

    this._anchor.setAttribute('href', value);
    this.insertAdjacentElement('beforeend', this._anchor);
  }

  _createIcon() {
    const icon = document.createElement('e-icon');
    icon.className = 'e-layout-back-button__icon';
    icon.icon = 'e-move-left';
    icon.size = 'small';
    icon.color = 'inherit';

    return icon;
  }

  _createAnchor() {
    const anchor = document.createElement('a');
    anchor.classList.add('e-layout-back-button__link');

    return anchor;
  }

  _setWidth() {
    if (this._isOpen) { return; }

    this.classList.add('e-layout-back-button-open');

    this.style.width = 'auto';
    const width = this.offsetWidth;
    this.style.removeProperty('width');
    this.offsetWidth; // eslint-disable-line no-unused-expressions

    this.style.width = `${width}px`;
  }

  _unsetWidth() {
    if (!this._isOpen) { return; }

    this.classList.remove('e-layout-back-button-open');

    this.style.removeProperty('width');
  }

  get _isOpen() {
    return this.classList.contains('e-layout-back-button-open');
  }
}

export default ELayoutBackButton;
