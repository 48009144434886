import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import JSLogger from '../../../js/utils/jslogger/index.js';

import emojiConvert from '../../utils/emoji-convert';
import State from './state';
const logger = new JSLogger('decommission');

class EEmoji extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();

    this._onUpdateOriginalInput = this._onUpdateOriginalInput.bind(this);

    this._state = new State(this._render.bind(this));
    this._dom = {
      originalInput: undefined,
      visibleInput: this._createVisibleInput()
    };
    this._callOriginalInputOriginalSetter = () => {};
  }

  connectedCallback() {
    this._dom.originalInput = this.querySelector('input');
    this._setup();
    logger.log('EEmoji');
  }

  childrenChangedCallback(nodes) {
    if (this._dom.originalInput) {
      return;
    }

    const inputNodeList = nodes.filter(node => node[0].nodeName === 'INPUT');
    this._dom.originalInput = inputNodeList.length ? inputNodeList[0][0] : undefined;
    this._setup();
  }

  static get observedAttributes() {
    return ['error'];
  }

  set error(value) {
    this._state.setState({ 'isError': super._convertAttributeToBoolean(value) });
  }

  get value() {
    return this._state.state.value;
  }

  set value(value) {
    this._state.setState({
      value: {
        unicode: emojiConvert.decodeEntities(value),
        entity: emojiConvert.encodeEntities(value)
      }
    });
  }

  get selectionRange() {
    return {
      start: this._getOriginalInputSelectionStart(),
      end: this._getOriginalInputSelectionEnd()
    };
  }

  _setup() {
    if (!this._dom.originalInput) { return; }

    this._setupOriginalInput();
    this.appendChild(this._dom.visibleInput);
  }

  _setupOriginalInput() {
    this._handleTabNavigation();
    this._handleInitialValue();
    this._handleValueChanges();
    this._defineSelectionRange();
    this._setUpdateEvents();
  }

  _createVisibleInput() {
    const visibleInput = document.createElement('input');
    visibleInput.className = 'e-input e-emoji';
    return visibleInput;
  }

  _handleValueChanges() {
    const inputElementValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value').set;
    this._callOriginalInputOriginalSetter = inputElementValueSetter.bind(this._dom.originalInput);

    Object.defineProperty(this._dom.originalInput, 'value', {
      configurable: true,
      get: this._getOriginalInputValue.bind(this),
      set: this._setOriginalInputValue.bind(this)
    });
  }

  _defineSelectionRange() {
    Object.defineProperty(this._dom.originalInput, 'selectionStart', {
      configurable: true,
      get: this._getOriginalInputSelectionStart.bind(this)
    });

    Object.defineProperty(this._dom.originalInput, 'selectionEnd', {
      configurable: true,
      get: this._getOriginalInputSelectionEnd.bind(this)
    });
  }

  _setUpdateEvents() {
    const events = ['keydown', 'blur', 'keyup', 'cut', 'paste', 'drop', 'input'];
    events.map((event) => this._dom.visibleInput.addEventListener(event, this._onUpdateOriginalInput));
  }

  _onUpdateOriginalInput(event) {
    this._updateOriginalInput(event.target.value);
  }

  _handleInitialValue() {
    this.value = this._dom.originalInput.value;
    this._dom.visibleInput.value = this.value.unicode;
    this._dom.originalInput.value = this.value.entity;
  }

  _updateOriginalInput(value) {
    this.value = value;
    this._callOriginalInputOriginalSetter(this.value.entity);

    const changeEvent = document.createEvent('HTMLEvents');
    changeEvent.initEvent('change', true, false);
    this._dom.originalInput.dispatchEvent(changeEvent);
  }

  _getOriginalInputValue() {
    return this.value.entity;
  }

  _setOriginalInputValue(value) {
    this._updateOriginalInput(value);
    this._dom.visibleInput.value = this.value.unicode;
  }

  _getOriginalInputSelectionStart() {
    const subValue = this._dom.visibleInput.value.substring(0, this._dom.visibleInput.selectionStart);
    return emojiConvert.encodeEntities(subValue).length;
  }

  _getOriginalInputSelectionEnd() {
    const subValue = this._dom.visibleInput.value.substring(0, this._dom.visibleInput.selectionEnd);
    return emojiConvert.encodeEntities(subValue).length;
  }

  _handleTabNavigation() {
    this._dom.visibleInput.setAttribute('tabindex', '-1');

    this._dom.visibleInput.addEventListener('focus', () => {
      if (this._dom.originalInput.disabled) {
        this._dom.visibleInput.blur();
        return;
      }
      this._state.setState({ originalInputTabIndex: this._dom.originalInput.getAttribute('tabindex') });
      this._dom.originalInput.setAttribute('tabindex', '-1');
    });

    this._dom.originalInput.addEventListener('focus', () => this._dom.visibleInput.focus());

    this._dom.visibleInput.addEventListener('blur', () => {
      if (this._state.state.originalInputTabIndex === null) {
        this._dom.originalInput.removeAttribute('tabindex');
      } else {
        this._dom.originalInput.setAttribute('tabindex', this._state.state.originalInputTabIndex);
      }
    });
  }

  _render() {
    if (!this._dom.originalInput) {
      return;
    }

    this._dom.visibleInput.classList.toggle('e-input-error', this._state.state.isError);
    this._dom.originalInput.classList.toggle('e-input-error', this._state.state.isError);
  }
}

export default EEmoji;
