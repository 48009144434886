import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { EIllustratedMessageState } from './state';
import { EIllustratedMessageEvents } from './events';
import { EIllustratedMessageTemplate } from './template';

export class EIllustratedMessage extends HTMLCustomElement {
  //#region Lifecycle Hooks
  init() {
    this.state = new EIllustratedMessageState(this);
    this.events = new EIllustratedMessageEvents(this);
    this.template = new EIllustratedMessageTemplate(this);

    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.classList.add('e-illustrated-message');
    this.reflectPropertyAsAttribute('type');
    this.reflectPropertyAsAttribute('layout');
    this.requestRender();
  }
  //#endregion

  //#region Attributes
  static observedAttributes = ['type', 'layout'];
  static #allowedTypes = ['neutral', 'faint', 'error', 'warning', 'success'];
  static #allowedLayouts = ['vertical', 'horizontal'];

  get type() {
    return this.state.type;
  }

  set type(value) {
    if (!EIllustratedMessage.#allowedTypes.includes(value) || this.state.type === value) { return; }

    this.state.type = value;
    this.reflectPropertyAsAttribute('type');
  }

  get layout() {
    return this.state.layout;
  }

  set layout(value) {
    if (!EIllustratedMessage.#allowedLayouts.includes(value) || this.state.layout === value) { return; }

    this.state.layout = value;
    this.reflectPropertyAsAttribute('layout');
  }
  //#endregion

  //#region Rendering
  render() {
    return render(this.shadowRoot, this.template.createElement());
  }
  //#endregion

  //#region Private Methods
  reflectPropertyAsAttribute(property) {
    if (this.getAttribute(property) === this[property]) { return; }

    this.setAttribute(property, this[property]);
  }
  //#endregion
}
