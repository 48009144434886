import autoBind from 'auto-bind';

export class EMultiselectEvents {
  constructor(component) {
    autoBind(this);
    this.dispatchEvent = component.dispatchEvent.bind(component);
    this.togglePopup = component.togglePopup.bind(component);
    this.closePopup = component.closePopup.bind(component);

    this.refs = component.refs;
    this.state = component.state;
  }

  onUpdateOption(event) {
    event.stopPropagation();
    event.target.removeEventListener('option.delete', this.state.deleteOption);
    event.target.addEventListener('option.delete', this.state.deleteOption);

    this.state.upsertOption(Object.assign({}, event.detail, { component: event.target }));
  }

  onClick({ target }) {
    if (this.state.disabled || (target && target.classList.contains('e-tagselector__clear'))) { return; }

    this.state.popupOpened = true;
    this.togglePopup();

    if (!this.state.popupOpened) { return; }

    this.dispatchEvent(new CustomEvent('open'));
  }

  onKeydown(event) {
    if (this.state.disabled || (event.target && event.target.classList.contains('e-tagselector__clear'))) { return; }

    const handledKeys = ['ArrowDown', 'ArrowUp', 'Enter', 'Space'];

    if (!handledKeys.includes(event.code)) { return; }

    this.state.popupOpened = true;
    this.togglePopup();

    this.dispatchEvent(new CustomEvent('open'));
  }

  onClose() {
    this.state.popupOpened = false;

    this.dispatchEvent(new CustomEvent('close'));
  }

  onClearOption(option) {
    this.state.clearOption(option);

    this._dispatchChangeEvent();
  }

  onToggleAllOptions(event) {
    event.preventDefault();

    if (this.state.isAllOptionsSelected || !!this.state.selectionMax) {
      this.state.clearAllOptions();
    } else {
      this.state.selectAllOptions();
    }

    this._dispatchChangeEvent();
  }

  onClearAllOptions() {
    this.state.clearAllOptions();

    this._dispatchChangeEvent();

    this.refs.input.focus();
  }

  onActionlistChange({ detail }) {
    this.state.selectedOptions = detail.value;

    this._dispatchChangeEvent();
  }

  onActionlistSearch(event) {
    event.stopPropagation();
    const { detail } = event;
    this.state.isSearching = detail.value !== '';
    this._dispatchSearchEvent(detail.value);
  }

  onConfigChange() {
    this.state.setTranslations();
  }

  _dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: {
        selectedOptions: this.state.selectedOptions
      },
      bubbles: true
    }));
  }
  _dispatchSearchEvent(searchValue) {
    this.dispatchEvent(new CustomEvent('search', {
      detail: {
        value: searchValue
      },
      bubbles: true
    }));
  }
}

export default EMultiselectEvents;
