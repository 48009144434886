import uuid from '../../../utils/uuid';

export const cloneObject = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const sortItemsByType = (itemA, itemB) => {
  if (itemA.type === 'group' && itemB.type === 'option') { return -1; }
  if (itemA.type === 'option' && itemB.type === 'group') { return 1; }
  return 0;
};

export const flattenItems = (items) => {
  return items.flatMap(currentItem => {
    if (currentItem.type !== 'group') { return currentItem; }

    const groupId = uuid();
    const groupItem = { uuid: groupId, type: 'group', label: currentItem.label };
    const itemsInGroup = currentItem.items.map(item => ({ ...item, group: groupId }));

    return [groupItem, ...itemsInGroup];
  });
};
