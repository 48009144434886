import autoBind from 'auto-bind';
import { normalizeColor, trimHashmark } from '../../../utils/normalize-color';
import { getDataFromClipboard } from '../../../utils/clipboard';

export class EColorInputEvents {
  constructor(component) {
    autoBind(this);

    this.dispatchChangeEvent = component.dispatchChangeEvent;
    this.replaceSelectedText = component.replaceSelectedText;

    this.refs = component.refs;
    this.state = component.state;
  }

  onInput(event) {
    event.stopPropagation();

    this.state.inputValue = this.refs.input.value;
  }

  onChange(event) {
    event.stopPropagation();

    this.state.inputValue = normalizeColor(this.refs.input.value);
    this.dispatchChangeEvent();
  }

  onNativeColorInput(event) {
    event.stopPropagation();

    this.state.inputValue = normalizeColor(this.refs.nativeInput.value);
  }

  onNativeColorChange(event) {
    event.stopPropagation();

    this.state.inputValue = normalizeColor(this.refs.nativeInput.value);
    this.dispatchChangeEvent();
  }

  onPaste(event) {
    event.preventDefault();

    const clipboardText = getDataFromClipboard(event, 'text/plain');
    const trimmedText = trimHashmark(clipboardText);

    this.replaceSelectedText(trimmedText);
    this.dispatchChangeEvent();
  }

  onKeyDown(event) {
    if (event.key !== 'Enter') { return; }

    this.state.inputValue = normalizeColor(this.refs.input.value);
    this.refs.input.blur();
  }
};
