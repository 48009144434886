import { html } from 'uhtml';

class Template {
  constructor(emitter) {
    this._emitter = emitter;
    this._pickerElement = this._createPickerElement('start');
    this._pickerEndElement = this._createPickerElement('end');
  }

  setValueOnPickerElements(value) {
    this._pickerElement.value = value.start;
    this._pickerEndElement.value = value.end;
  }

  createElement(state) {
    this._pickerElement.inputLabel = state.getLabelText('start');
    this._pickerEndElement.inputLabel = state.getLabelText('end');
    state = state.state;
    const isTime = ['datetime', 'datetimerange'].indexOf(state.type) > -1;
    this._pickerElement.isTime = isTime;
    this._pickerElement.todayHidden = state.todayHidden;
    this._pickerElement.resetTime = state.resetTime.start;
    this._pickerEndElement.isTime = isTime;
    this._pickerEndElement.todayHidden = state.todayHidden;
    this._pickerEndElement.resetTime = state.resetTime.end;

    const isRange = ['daterange', 'datetimerange'].indexOf(state.type) > -1;
    const rangeEnd = isRange ? state.value.end : undefined;
    this._pickerElement.max = rangeEnd || state.max;
    this._pickerElement.min = state.min;
    this._pickerElement.rangeOtherEnd = rangeEnd;

    this._pickerEndElement.min = state.value.start || state.min;
    this._pickerEndElement.max = state.max;
    this._pickerEndElement.rangeOtherEnd = state.value.start;

    this._pickerElement.setAttribute('disabled', state.startDisabled);
    this._pickerEndElement.setAttribute('disabled', state.endDisabled);

    return html`
      <div class="e-grid e-grid-small">
        ${state.presets.length ? this._createPresets(state) : ''}
        ${this._pickerElement}${isRange ? this._pickerEndElement : []}
      </div>
      ${state.manualApply ? this._createFooter() : ''}
    `;
  }

  _createFooter() {
    return html`
      <div class="e-datetime__footer">
        <button
          type="button"
          class="e-btn e-btn-primary"
          data-testid="applyButton"
          @click=${this._onApply.bind(this)}
        >
          <e-translation key="components.datetime.applyButton"></e-translation>
        </button>
        <button
        type="button"
          class="e-btn e-btn-borderless"
          data-testid="cancelButton"
          @click=${this._onCancel.bind(this)}
        >
          <e-translation key="components.datetime.cancelButton"></e-translation>
        </button>
      </div>
    `;
  }

  _createPresets(state) {
    return html`
      <fieldset class="e-cell e-cell-small e-datetime__presets">
        <legend data-testid="datetimePresets" class="e-datetime__presets_legend">
          <e-translation key="components.datetime.panel.presets"></e-translation>
        </legend>
        <e-actionlist
          class="e-datetime__preset"
          .items="${this._presetMap(state)}"
          item-limit="0"
          ?disabled=${state.isPresetsDisabled}
          @change="${this._onPresetChange.bind(this)}"
        ></e-actionlist>
      </fieldset>
    `;
  }

  _presetMap(state) {
    return state.presets
      .sort((presetA, presetB) => {
        if (presetA.data.order < presetB.data.order) { return -1; }
        if (presetA.data.order > presetB.data.order) { return 1; }

        return 0;
      })
      .map(preset => this._createPreset(preset.data, state));
  };

  _createPreset(preset, state) {
    const value = JSON.stringify({ start: preset.value.start, end: preset.value.end });
    const isSelected = state.value.start === preset.value.start && state.value.end === preset.value.end;
    const isDisabled = !!(state.min && state.min > preset.value.start) ||
     !!(state.max && state.max < preset.value.end) ||
     state.isPresetsDisabled;

    return { type: 'item', content: preset.name, value, selected: isSelected, disabled: isDisabled };
  };

  _createPickerElement(type) {
    return html.node`
      <e-datetime-picker
        data-type="${type}"
        class="e-cell e-cell-small"
        @picker.change=${this._onPickerChange.bind(this)}
      ></e-datetime-picker>
    `;
  }

  _onPickerChange(event) {
    const type = event.target.dataset.type;
    const value = event.detail.value;
    this._emitter.emit('pickerChange', { type, value });
  }

  _onPresetChange(event) {
    const value = JSON.parse(event.detail.value);
    this._emitter.emit('presetsChange', { start: value.start, end: value.end });
  }

  _onCancel() {
    this._emitter.emit('onCancel');
  }

  _onApply() {
    this._emitter.emit('onApply');
  }
}

export default Template;
