import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';
import { EPaginationState } from './state.js';
import { EPaginationTemplate } from './template.js';
import { EPaginationEvents } from './events.js';

export class EPagination extends EDSComponent {
  static componentName = 'pagination';

  init() {
    this.state = new EPaginationState(this);
    this.events = new EPaginationEvents(this);
    this.template = new EPaginationTemplate(this);
  }

  connectedCallback() {
    this.requestRender();
  }

  static get observedAttributes() {
    return ['max-page', 'current-page', 'loading', 'label'];
  }

  set maxPage(value) {
    this.state.maxPage = parseInt(value);
    this.requestRender();
  }

  set currentPage(value) {
    this.state.currentPage = parseInt(value);
    this.requestRender();
  }

  set loading(value) {
    this.state.loading = AttributeUtils.convertToBoolean(value);
    this.requestRender();
  }

  set label(value) {
    this.state.label = value ? value : this.state.defaultLabel;
  }

  get loading() {
    return this.state.loading;
  }

  get maxPage() {
    return this.state.maxPage;
  }

  _render() {
    render(this, this.template.createElement());
  }
}
