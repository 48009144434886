import hexRgb from 'hex-rgb';

const hex = (value) => {
  return value.slice(1);
};

const rgb = (value) => {
  try {
    const { red: r, green: g, blue: b } = hexRgb(value);
    return { r, g, b };
  } catch {
    return { r: 0, g: 0, b: 0 };
  }
};

const isValid = (value) => {
  return !!value;
};

export default (value) => {
  return {
    hex: hex(value),
    rgb: rgb(value),
    isValid: isValid(value)
  };
};
