const current = {
  mainMenuItem: null,
  submenuItem: null
};

const findCurrentMenu = (menuItems, parent = null, global) => {
  menuItems.forEach(menuItem => {
    if (menuItem.active_matcher) {
      const regex = new RegExp(menuItem.active_matcher);

      if (regex.test(global.location.href)) {
        current.mainMenuItem = parent || menuItem;
        current.submenuItem = parent ? menuItem : null;
      }
    } else if (menuItem.submenu) {
      findCurrentMenu(menuItem.submenu, menuItem, global);
    }
  });
};

export default (data, global = window) => {
  const originalTitle = document.title;

  if (typeof data === 'string') {
    return;
  }

  current.mainMenuItem = null;
  current.submenuItem = null;

  findCurrentMenu(data, null, global);

  const mainMenutext = current.mainMenuItem ? current.mainMenuItem.text : null;
  const submenuText = current.submenuItem ? current.submenuItem.text : null;

  const title = [submenuText, mainMenutext, originalTitle].filter(item => item).join(' | ');

  if (title) {
    document.title = title;
  }
};
