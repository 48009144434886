import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import uuid from '../../../utils/uuid';
import HeaderTemplate from './templates/header';
import FooterTemplate from './templates/footer';

class ECriteriaTemplate extends HTMLCustomElement {
  init() {
    this._refs = {
      header: document.createElement('e-criteria-template-header'),
      footer: document.createElement('e-criteria-template-footer')
    };
    this._emitter = new EventEmitter();
    this._header = new HeaderTemplate();
    this._footer = new FooterTemplate(this._emitter);
    this._state = {
      uuid: uuid(),
      condition: '',
      key: ''
    };
    this._emitter.on('apply', this._apply.bind(this));
    this._emitter.on('cancel', this._cancel.bind(this));
    this.addEventListener('change', event => event.stopPropagation());
  }

  static get observedAttributes() {
    return ['key'];
  }

  connectedCallback() {
    super._cleanupContainer('e-criteria-template-header');
    super._cleanupContainer('e-criteria-template-footer');

    this.appendChild(this._refs.header);
    this.appendChild(this._refs.footer);

    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this._dispatchEvent('delete');
  }

  set key(value) {
    this._state.key = value;
    this._dispatchUpdateEvent();
  }

  set order(value) {
    this.style.order = value;
  }

  show(condition) {
    this.style.display = 'flex';
    this._state.condition = condition;
    this._render();
  }

  _render() {
    render(this._refs.header, this._header.render(this._state.condition.label));
    render(this._refs.footer, this._footer.render());
  }

  _apply() {
    this._dispatchEvent('apply');
    this.hide();
  }

  _cancel() {
    this._dispatchEvent('cancel');
    this.hide();
  }

  hide() {
    this.style.removeProperty('display');
  }

  _dispatchUpdateEvent() {
    if (!this.parentNode || !this._state.key) { return; }
    this._dispatchEvent('update');
  }

  _dispatchEvent(eventName) {
    this.dispatchEvent(new CustomEvent(`criteriatemplate.${eventName}`, { bubbles: true, detail: this._state }));
  }
}

export default ECriteriaTemplate;
