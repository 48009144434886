import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { ERulelistGroupState } from './state.js';
import { ERulelistGroupEvents } from './events.js';
import { ERulelistGroupTemplate } from './template.js';

export class ERulelistGroup extends EDSComponent {
  static componentName = 'rulelist-group';
  #shadowRoot = null;
  styleSheet = new CSSStyleSheet();

  // Lifecycle methods
  constructor() {
    super();

    this.#shadowRoot = this.attachShadow({ mode: 'open' });
    this.#shadowRoot.adoptedStyleSheets = [this.styleSheet];

    this.state = new ERulelistGroupState(this);
    this.events = new ERulelistGroupEvents(this);
    this.template = new ERulelistGroupTemplate(this);
    this.template.createWrapper();
  }

  connectedCallback() {
    this.reflectBooleanAttribute('closed', this.state.isClosed);

    super._cleanupContainer('e-rulelist-toggle-wrapper');
    this.appendChild(this.refs.toggleWrapper);

    this.requestRender();
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  // Attributes
  static get observedAttributes() {
    return ['closed', 'readonly', 'color'];
  }

  set closed(value) {
    this.state.isClosed = AttributeUtils.convertToBoolean(value);

    this.refs.togglableContainer?.style.removeProperty('grid-template-rows');
  }

  get readonly() {
    return this.state.isReadOnly;
  }

  set readonly(value) {
    this.state.isReadOnly = AttributeUtils.convertToBoolean(value);
  }

  set toggling(value) {
    this.state.isToggling = AttributeUtils.convertToBoolean(value);
  }

  get color() {
    return this.state.color;
  }

  set color(value) {
    this.state.color = AttributeUtils.convertToString(value);
  }

  // Rendering
  render() {
    if (!this.isConnected) { return; }

    render(this.#shadowRoot, this.template.createElement());
    render(this.refs.toggleWrapper, this.template.createToggleElement());
  }
}

export default ERulelistGroup;
