import { html } from 'uhtml';
import classNames from 'clsx';

export default class EDatagridActionTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  createDropdownItem(action) {
    const onClick = (event) => action.disabled ? null : this._events.onActionButtonClick(event, action);

    const permission = action.disabledType === 'permission';
    const url = action.url && !action.disabled ? action.url : null;
    const target = (url !== null) ? action.target : null;
    const testName = this._state.testHelpers ? action.label : null;
    const classes = classNames('e-dropdown__item', {
      'e-dropdown__item-disabled': action.disabled
    });

    if (action.disabled && action.disabledTooltip) {
      return html`
        <e-tooltip
          class="${classes}"
          placement="left"
          content=${action.disabledTooltip}
          width="200"
          test-name="${testName}"
          ?permission="${permission}"
        >${action.label}</e-tooltip>
      `;
    } else {
      return url === null ?
        html`
          <button
            type="button"
            class="${classes}"
            test-name="${testName}"
            ?disabled="${action.disabled}"
            @click="${onClick}"
          >${action.label}</button>
        ` :
        html`
          <a
            href="${url}"
            target="${target}"
            class="${classes}"
            test-name="${testName}"
            @click="${onClick}"
          >${action.label}</a>
        `;
    }
  };

  createDropdown() {
    if (this._state.actions.length <= 2) { return; }

    return html`
      <e-dropdown data-markup-class="e-btn e-btn-onlyicon e-btn-borderless">
        <e-dropdown-handler tabindex="0">
          <e-icon icon="dots"></e-icon>
        </e-dropdown-handler>
        <div class="e-dropdown__content">
          ${this._state.actions.slice(1).map(action => this.createDropdownItem(action))}
        </div>
      </e-dropdown>
    `;
  };

  createDisabledButton(action, buttonClasses) {
    const classes = `${buttonClasses} e-btn-disabled`;
    const testName = this._state.testHelpers ? action.label : null;
    if (action.disabledTooltip) {
      return html`
        <e-tooltip
          content=${action.disabledTooltip}
          permission="${action.disabledType === 'permission'}"
          width="200"
        >
          <button type="button" class="${classes}" test-name="${testName}">${action.label}</button>
        </e-tooltip>
      `;
    } else {
      return html`
        <button type="button" class="${classes}" test-name="${testName}">${action.label}</button>
      `;
    }
  };

  createEnabledButton(action, buttonClasses) {
    const testName = this._state.testHelpers ? action.label : null;
    const url = action.url;
    const target = (url !== null) ? action.target : null;
    return action.url ?
      html`
        <a
          class="${buttonClasses}"
          href=${url}
          target=${target}
          test-name="${testName}"
          @click="${(event) => this._events.onActionButtonClick(event, action)}"
        >${action.label}</a>` :
      html`
        <button
          type="button"
          class="${buttonClasses}"
          test-name="${testName}"
          @click="${(event) => this._events.onActionButtonClick(event, action)}"
        >${action.label}</button>`;
  };

  createButton(action, hasActionType = false) {
    const buttonClasses = classNames('e-btn', {
      [`e-btn-${this._state.actionType}`]: hasActionType,
      'e-btn-borderless': !hasActionType
    });

    return action.disabled ?
      this.createDisabledButton(action, buttonClasses) :
      this.createEnabledButton(action, buttonClasses);
  };

  createAdditionButton() {
    if (this._state.actions.length !== 2) { return; }
    return this.createButton(this._state.actions[1]);
  }

  create() {
    if (!this._state.actions.length) { return; }

    const primaryButton = this._state.actions[0];

    return html`
      <div class="e-buttongroup float-right">
        ${this.createAdditionButton()}
        ${this.createDropdown()}
        ${this.createButton(primaryButton, true)}
      </div>
    `;
  }
}
