import { createPopper as defaultCreatePopper } from '@popperjs/core';

import defaultFloatUtility from '../float/index.js';
import { getLastOpenedPopup } from './utils.js';
import { Popup } from './popup.js';

export class PopupUtility {
  constructor({ global = window, createPopper = defaultCreatePopper, floatUtility = defaultFloatUtility }) {
    this._global = global;
    this._createPopper = createPopper;
    this._floatUtility = floatUtility;

    this._onOutsideClick = this._outsideClickHandler.bind(this);
    this._onEscPress = this._escPressHandler.bind(this);

    global.document.addEventListener('click', this._onOutsideClick, true);
    global.document.addEventListener('keydown', this._onEscPress, true);
  }

  createPopup(opener, content, options = {}) {
    return new Popup(opener, content, options, {
      global: this._global,
      createPopper: this._createPopper,
      floatUtility: this._floatUtility
    });
  }

  _outsideClickHandler(event) {
    if (event && (event.defaultPrevented) || (event.e && event.e.isHandled)) { return; }

    const lastOpenedPopup = getLastOpenedPopup(this._global);

    if (lastOpenedPopup === undefined || lastOpenedPopup.type !== 'popup') { return; }

    event.e = {};
    event.e.isHandled = true;

    if (lastOpenedPopup.popup.closeOnOutsideClick === false) { return; }
    if (lastOpenedPopup.opener.contains(event.target) || lastOpenedPopup.element.contains(event.target)) { return; }

    lastOpenedPopup.popup.close({ preventAutoFocus: true });
    lastOpenedPopup.popup.runHook('onOutsideClickClose');
  }

  _escPressHandler(event) {
    if (event.code !== 'Escape') { return; }
    if (event && (event.defaultPrevented) || (event.e && event.e.isHandled)) { return; }

    const lastOpenedPopup = getLastOpenedPopup(this._global);

    if (lastOpenedPopup === undefined || lastOpenedPopup.type !== 'popup') { return; }

    event.e = {};
    event.e.isHandled = true;

    if (lastOpenedPopup.popup.closeOnEscPress === false) { return; }

    lastOpenedPopup.popup.close({ preventAutoFocus: false });
    lastOpenedPopup.popup.runHook('onEscPressClose');
  }
}

export default PopupUtility;
