import { SUBSCRIBE_MESSAGE, HASH_CHANGE_MESSAGE, UNSUBSCRIBE_MESSAGE } from './constants.js';

export class TopHashChangeService {
  constructor(global) {
    this._global = global;
    this._listeners = [];
    this._onMessage = this._onMessage.bind(this);
    this._onHashChange = this._onHashChange.bind(this);
  }

  subscribe() {
    this._global.addEventListener('message', this._onMessage);
    this._global.addEventListener('hashchange', this._onHashChange);
  }

  _onMessage({ data, source }) {
    if (!data) { return; }

    if (data.message === SUBSCRIBE_MESSAGE) {
      this._subscribe(source);
    } else if (data.message === UNSUBSCRIBE_MESSAGE) {
      this._unsubscribe(source);
    }
  }

  _onHashChange({ oldURL, newURL }) {
    this._listeners.forEach((source) => {
      source.postMessage({ message: HASH_CHANGE_MESSAGE, data: { oldURL, newURL } }, '*');
    });
  }

  _subscribe(subscriber) {
    this._listeners.push(subscriber);
  }

  _unsubscribe(subscriber) {
    const index = this._listeners.indexOf(subscriber);
    if (index === -1) { return; }

    this._listeners.splice(index, 1);
  }
}
