import constants from '../constants.js';

const ORDER_ASC = constants.order.options.asc;
const ORDER_DESC = constants.order.options.desc;
const DEFAULT_ORDER = constants.order.default;

class EDatagridFilterState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._isFiltersOpen = false;
    this._filters = [];
    this._filterValues = {};
    this._searchKeyword = { keyword: '', default: false };
    this._searchTooltip = '';
    this._sorting = { key: null, order: DEFAULT_ORDER };
    this._filteredItemCount = 0;
    this._searchKeys = [];
    this._searchIncludes = [];
    this._isSearchHidden = false;
  }

  get filters() {
    return this._filters;
  }

  set filters(value) {
    this._filters = value;
    this.requestRender();
  }

  get filterValues() {
    return this._transformFilterValues();
  }

  set filterValues(value) {
    this._filterValues = value;
    this.requestRender();
  }

  get isFiltersOpen() {
    return this._isFiltersOpen;
  }

  set isFiltersOpen(value) {
    this._isFiltersOpen = value;
    this.requestRender();
  }

  get filteredContent() {
    return this._coreState.transformedContent.filteredContent;
  }

  get filteredItemCount() {
    if (this._coreState.serverState.isServerSide) {
      return this._filteredItemCount;
    }

    return this.filteredContent.length;
  }

  set filteredItemCount(value) {
    this._filteredItemCount = value;
    this.requestRender();
  }

  get sorting() {
    return this._sorting;
  }

  set sortingKey(value) {
    if (this._sorting.key === value) { return; }
    this._sorting.key = value;
    this._coreState.serverState.requestGetDataFromServer();
    this.requestRender();
  }

  set sortingOrder(value) {
    const normalizedValue = (value === ORDER_ASC || value === ORDER_DESC) ? value : DEFAULT_ORDER;
    if (this._sorting.order === normalizedValue) { return; }
    this._sorting.order = normalizedValue;
    this._coreState.serverState.requestGetDataFromServer();
    this.requestRender();
  }

  set sorting(value) {
    if (this._sorting.key === value.key && this._sorting.order === value.order) { return; }
    this._sorting = value;
    this._coreState.serverState.requestGetDataFromServer();
    this.requestRender();
  }

  get isSearchHidden() {
    return this._isSearchHidden;
  }

  set isSearchHidden(value) {
    this._isSearchHidden = value;
    this.requestRender();
  }

  get searchKeyword() {
    return this._searchKeyword;
  }

  set searchKeyword(value) {
    this._searchKeyword = value;
    this.requestRender();
  }

  get searchTooltip() {
    return this._searchTooltip;
  }

  set searchTooltip(value) {
    this._searchTooltip = value;
    this.requestRender();
  }

  get searchKeys() {
    return this._searchKeys;
  }

  set searchKeys(value) {
    this._searchKeys = value;
    this.requestRender();
  }

  get searchIncludes() {
    return this._searchIncludes;
  }

  set searchIncludes(value) {
    this._searchIncludes = value;
    this.requestRender();
  }

  get hasAdvancedFilters() {
    return this._filters.some(filter => filter.advanced);
  }

  upsertFilter(options) {
    const filterIndex = this._filters.findIndex(filter => filter.uuid === options.uuid);
    const hasFilterValue = this.filterValues[options.contentKey];

    if (filterIndex > -1) {
      this._filters = this._filters.concat();
      this._filters[filterIndex] = options;
    } else {
      this._filters = this._filters.concat(options);
    }

    switch (options.type) {
      case 'checkbox':
        this._filterCheckBox(options);
        break;
      case 'switch':
        this._filterSwitch(options);
        break;
      case 'select':
        this._filterSelect(options, hasFilterValue);
        break;
      case 'multiselect':
        this._filterMultiselect(options, hasFilterValue);
        break;
      case 'daterange':
        this._filterDateRange(options);
        break;
    }

    this.requestRender();
  }

  deleteFilter(uuid) {
    this.filters = this.filters.filter(filter => filter.uuid !== uuid);
  }

  toggleSorting(key) {
    this._coreState.paginationState.pageNumber = 1;
    this.sorting = {
      key,
      order: key === this.sorting.key ? this._getToggledOrder() : DEFAULT_ORDER
    };
  }

  search(keyword) {
    if (this.searchKeyword.keyword === keyword) { return; }
    this._coreState.paginationState.pageNumber = 1;
    this.searchKeyword = {
      ...this.searchKeyword,
      keyword
    };
    this._coreState.serverState.requestGetDataFromServer();
  }

  filter(filter, value) {
    this._coreState.paginationState.pageNumber = 1;
    this._filterValues = Object.assign({}, this._transformFilterValues(), {
      [filter.contentKey]: {
        value,
        contentFormat: filter.contentFormat || false,
        partialMatch: filter.partialMatch || false,
        type: filter.type,
        custom: filter.custom,
        default: filter.defaultChecked || false
      }
    });

    if (
      isInvalidCheckbox(filter, value) ||
      isInvalidSwitch(filter, value) ||
      isInvalidSelect(filter, value) ||
      isInvalidMultiSelect(filter, value) ||
      isInvalidDateRange(filter, value)
    ) {
      const tmpFilterValues = this._transformFilterValues();
      delete tmpFilterValues[filter.contentKey];
      this._filterValues = tmpFilterValues;
    }

    if (!filter.custom) {
      this._coreState.serverState.requestGetDataFromServer();
    }

    this.requestRender();
  }

  _filterCheckBox(options) {
    if (options.defaultChecked) {
      this.filter(options, true);
    }

    if (options.checked !== null) {
      this.filter(options, options.checked);
    }
  }

  _filterSwitch(options) {
    if (options.defaultChecked) {
      this.filter(options, true);
    }
  }

  _filterSelect(options, hasFilterValue) {
    if (options.defaultValue && !hasFilterValue) {
      this.filter(options, options.defaultValue);
    }

    if (options.value) {
      this.filter(options, options.value);
    }
  }

  _filterMultiselect(options, hasFilterValue) {
    if (options.defaultValue && !hasFilterValue) {
      if (!options.hasOperator) {
        this.filter(options, options.defaultValue);
      } else {
        const operatorValue = options.selectedOperator || 'or';
        this.filter(options, { operator: operatorValue, value: options.defaultValue });
      }
    }

    if (options.value) {
      this.filter(options, options.value);
    }
  }

  _filterDateRange(options) {
    if (options.start.date && options.end.date) {
      this.filter(options, options);
    }
  }

  _transformFilterValues() {
    return Object.keys(this._filterValues).reduce((previous, current) => {
      const currentFilter = this._filterValues[current];

      if (currentFilter.type === 'select') {
        const selectValue = currentFilter.value;
        const customOptions = this._filters
          .filter(filter => filter.contentKey === current)
          .reduce((accumulator, optionData) => {
            const options = optionData.options.map(option => option.value || option);
            return accumulator.concat(options);
          }, []);

        const contentOptions = this._coreState.content ? this._coreState.content.map(row => row[current]) : [];
        const availableOptions = customOptions.length ? customOptions : contentOptions;
        const hasSelectedValueInContent = availableOptions.flat().includes(selectValue);

        if (hasSelectedValueInContent) {
          previous[current] = currentFilter;
        }
      } else {
        previous[current] = currentFilter;
      }

      return previous;
    }, {});
  }

  _getToggledOrder() {
    return this.sorting.order === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
  }
}

const isInvalidCheckbox = (filter, value) => filter.type === 'checkbox' && value === false;
const isInvalidSwitch = (filter, value) => filter.type === 'switch' && value === false;
const isInvalidSelect = (filter, value) => filter.type === 'select' && value === null;
const isInvalidMultiSelect = (filter, value) =>
  (filter.type === 'multiselect' && !filter.hasOperator && !value.length) ||
  (filter.type === 'multiselect' && filter.hasOperator && !value.value.length);
const isInvalidDateRange = (filter, value) =>
  filter.type === 'daterange' && (value.start.date === '' && value.end.date === '');

export default coreState => new EDatagridFilterState(coreState);
