import uuid from '../../../utils/uuid';
export var DisabledType;
(function (DisabledType) {
    DisabledType["PERMISSION"] = "permission";
})(DisabledType || (DisabledType = {}));
export var Spacing;
(function (Spacing) {
    Spacing["COZY"] = "cozy";
    Spacing["NONE"] = "none";
})(Spacing || (Spacing = {}));
export class ETabState {
    constructor() {
        this._uuid = uuid();
        this._spacing = Spacing.NONE;
    }
    get uuid() {
        return this._uuid;
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
    }
    get icon() {
        return this._icon;
    }
    set icon(value) {
        this._icon = value;
    }
    get badge() {
        return this._badge;
    }
    set badge(value) {
        this._badge = value;
    }
    get isDisabled() {
        return this._isDisabled;
    }
    set isDisabled(value) {
        this._isDisabled = value;
    }
    get isSelected() {
        return this._isSelected;
    }
    set isSelected(value) {
        this._isSelected = value;
    }
    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }
    get disabledTooltip() {
        return this._disabledTooltip;
    }
    set disabledTooltip(value) {
        this._disabledTooltip = value;
    }
    get disabledType() {
        return this._disabledType;
    }
    set disabledType(value) {
        this._disabledType = value;
    }
    get spacing() {
        return this._spacing;
    }
    set spacing(value) {
        this._spacing = value;
    }
}
