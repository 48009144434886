class ETimepickerAmpmState {
  constructor(component) {
    this.requestRender = component._render.bind(component);

    this._isAm = true;
    this._disabled = false;
    this._error = false;
  }

  get isAm() {
    return this._isAm;
  }

  set isAm(value) {
    this._isAm = value;
    this.requestRender();
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
    this.requestRender();
  }

  get error() {
    return this._error;
  }

  set error(value) {
    this._error = value;
    this.requestRender();
  }

  toggleAmPm() {
    this.isAm = !this._isAm;
  }
}

export default ETimepickerAmpmState;
