import classNames from 'clsx';
import { html } from 'uhtml';

export class ESwitchTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    const className = classNames('e-switch', {
      'e-switch-choose': this.state.secondaryLabel
    });

    return html`
      <div class="e-switch__wrapper">
        ${this.createSecondaryLabel()}
        <div class=${className}>
          ${this.createInput()}
          ${this.createSwitch()}
        </div>
        ${this.createPrimaryLabel()}
        ${this.createLabel()}
      </div>
    `;
  }

  createInput() {
    return html`
      <input
        ref=${node => this.refs.input = node}
        class="e-switch__input"
        type='checkbox'
        id=${this.state.id}
        name=${this.state.name}
        value=${this.state.value}
        ?disabled=${this.state.isDisabled}
        ?checked=${this.state.isChecked}
      />
    `;
  }

  createSwitch() {
    return html`<div class="e-switch__toggle"></div>`;
  }

  createSecondaryLabel() {
    if (!this.state.label || !this.state.secondaryLabel) { return; }

    return html`
      <button
        type="button"
        class="e-switch__label e-switch__label-secondary"
        @click=${[this.events.onSecondaryLabelClick, { capture: true }]}
      >${html([this.state.secondaryLabel])}</button>
    `;
  }

  createPrimaryLabel() {
    if (!this.state.label || !this.state.secondaryLabel) { return; }

    return html`
      <button
        type="button"
        class="e-switch__label e-switch__label-primary"
        @click=${[this.events.onPrimaryLabelClick, { capture: true }]}
      >${html([this.state.label])}</button>
    `;
  }

  createLabel() {
    if ((!this.state.label || this.state.secondaryLabel) && !this.state.helper) { return; }

    return html`
      <label class="e-switch__label" for=${this.state.id}>
        ${this.createLabelText()}
        ${this.createHelper()}
      </label>
    `;
  }

  createLabelText() {
    if (!this.state.label || this.state.secondaryLabel) { return; }

    return html`
      ${this.state.label}
    `;
  }

  createHelper() {
    if (!this.state.helper) { return; }

    return html`<e-tooltip content="${this.state.helper}" type="helper"></e-tooltip>`;
  }
}

export default ESwitchTemplate;
