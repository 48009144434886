const transformConditionTypesForActionlist = conditionTypes => conditionTypes.map(({ label, templateKey }) => {
  return { content: label, value: templateKey };
});

class InsertConditionTemplate {
  constructor(node, emitter) {
    this._conditionTypes = [];
    this._emitter = emitter;
    this._actionlist = node;
    this._initActionlist();
  }

  setConditionTypes(conditionTypes) {
    this._conditionTypes = conditionTypes;
    this._actionlist.items = transformConditionTypesForActionlist(this._conditionTypes);
  }

  _onActionlistChange(event) {
    const conditionType = this._conditionTypes.find(conditionType => conditionType.templateKey === event.detail.value);
    this._emitter.emit('insertCondition', conditionType);
  }

  _initActionlist() {
    this._actionlist.addEventListener('change', this._onActionlistChange.bind(this));
    this._actionlist.classList.add('e-popover');
    this._actionlist.classList.add('e-padding-none');
    this._actionlist.inPopover = true;
    this._actionlist.maxHeight = '248px';
    this._actionlist.autofocus = true;
  }

}

export default InsertConditionTemplate;
