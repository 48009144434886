import autoBind from 'auto-bind';
import color from '../../../utils/color';
import { predefinedColors } from './colors';

export class EColorpickerEvents {
  constructor(component) {
    autoBind(this);

    this.component = component;
    this.refs = component.refs;
    this.utils = component.utils;
    this.state = component.state;
  }

  onKeyDown(event) {
    if (event.code !== 'Enter') { return; }
    if (this.refs.colorinput.contains(event.target)) { return; }

    this._apply();
  }

  onColorInputChange(event) {
    this.onRecentColorClick(event.detail.value);
  }

  onCancelButtonClick() {
    this._cancel();
  }

  onApplyButtonClick() {
    if (!this.state.color) { return; }

    this._apply();
  }

  onSelectPredefined(color, isSelected) {
    if (isSelected) {
      this._apply();
      return;
    }

    const shadesOfColor = this._getShades(color);
    this.state.isShowingBaseColors = this.state.isShowingBaseColors && !shadesOfColor;
    this.state.predefinedColors = shadesOfColor || this.state.predefinedColors;

    const indexOfColor = this.state.predefinedColors.findIndex(shade => shade.color === color);
    this.state.activePredefinedColorIndex = indexOfColor;

    this._setColor(color);
  }

  onFocusPredefined() {
    if (!this.state.color) {
      this.state.activePredefinedColorIndex = 0;
    }
  }

  onBlurPredefined(event) {
    this._restoreBaseColors();

    if (event.relatedTarget === this.refs.backButton) {
      this.component.requestRender().then(() => {
        this.refs.predefinedColors.focus();
      });
    }
  }

  onMoveActivePredefined(event) {
    const maximumIndexOfColor = this.state.predefinedColors.length - 1;

    if (event.key === 'ArrowLeft') {
      this.state.activePredefinedColorIndex = this.state.activePredefinedColorIndex > 0 ?
        this.state.activePredefinedColorIndex - 1 :
        maximumIndexOfColor;
    }

    if (event.key === 'ArrowRight') {
      this.state.activePredefinedColorIndex = this.state.activePredefinedColorIndex < maximumIndexOfColor ?
        this.state.activePredefinedColorIndex + 1 :
        0;
    }
  };

  onKeyboardClickPredefined(event) {
    if (!['Enter', 'Space'].includes(event.code)) { return; }

    event.preventDefault();
    event.stopImmediatePropagation();

    const activeColor = this.state.predefinedColors[this.state.activePredefinedColorIndex].color;
    this.onSelectPredefined(activeColor, false);
  }

  onFocusRecents() {
    const indexOfColor = this.state.recentColors.findIndex(recentColor => recentColor === this.state.color);

    this.state.activeRecentColorIndex = this.state.color && indexOfColor >= 0 ? indexOfColor : 0;
  }

  onMoveActiveRecent(event) {
    const maximumIndexOfColor = this.state.recentColors.filter(color => color !== '').length - 1;

    if (event.key === 'ArrowLeft') {
      this.state.activeRecentColorIndex = this.state.activeRecentColorIndex > 0 ?
        this.state.activeRecentColorIndex - 1 :
        maximumIndexOfColor;
    }

    if (event.key === 'ArrowRight') {
      this.state.activeRecentColorIndex = this.state.activeRecentColorIndex < maximumIndexOfColor ?
        this.state.activeRecentColorIndex + 1 :
        0;
    }
  };

  onKeyboardClickRecent(event) {
    if (!['Enter', 'Space'].includes(event.code)) { return; }

    event.preventDefault();
    event.stopImmediatePropagation();

    const activeColor = this.state.recentColors[this.state.activeRecentColorIndex];
    this.onRecentColorClick(activeColor);
  }

  onBackButtonClick() {
    this._restoreBaseColors();
  }

  onRecentColorClick(color) {
    const isColorSelected = color === this.state.color;

    if (isColorSelected) {
      this._apply();
    } else {
      this._setColor(color);
    }
  }

  _apply() {
    this.component.close();
    this._dispatchEventWithColor('apply');
    this._updateStorage();
  }

  _cancel() {
    this.component.close();
    this._dispatchCancelEvent();
  }

  _setColor(color) {
    this.state.color = color;
    this._dispatchEventWithColor('change');
  }

  _getShades(color) {
    const definition = this.state.predefinedColors.filter(definition => definition.color === color)[0];
    return definition && definition.shades && definition.shades.slice();
  }

  _dispatchCancelEvent() {
    const event = new CustomEvent('cancel');
    this.component.dispatchEvent(event);
  }

  _dispatchEventWithColor(eventName) {
    this.component.dispatchEvent(new CustomEvent(eventName, {
      detail: Object.assign({ value: this.state.color }, color(this.state.color))
    }));
  }

  _updateStorage() {
    if (!this.state.key) { return; }

    this.state.updateRecentColors();
  }

  _restoreBaseColors() {
    if (this.state.isShowingBaseColors) { return; }

    this.state.predefinedColors = predefinedColors.slice();
    this.state.isShowingBaseColors = true;
    this.state.activePredefinedColorIndex = 0;
  }
}

export default EColorpickerEvents;
