import floatUtility from '../float';
import { createNotification, createContainer } from './template';
import {
  OPEN_MESSAGE, SERVICE_CLOSE_MESSAGE, CLIENT_CLOSE_MESSAGE, BUTTON_CLICK_MESSAGE, BOTTOM_RIGHT_PLACEMENT
} from './constants';

class NotificationService {

  constructor() {
    this._container = {};
    this._notifications = {};
    this._autoCloseTimeout = null;
  }

  subscribe() {
    window.addEventListener('message', event => {
      if (!event.data) { return; }

      if (event.data.message === OPEN_MESSAGE) {
        this._openNotification(event.data.data, event.source);
      } else if (event.data.message === CLIENT_CLOSE_MESSAGE) {
        this._cleanUp(event.data.data.notificationUuid);
      }
    });
  };

  _openNotification(options, sourceWindow) {
    const actions = this._createActions(options, sourceWindow);
    const notification = this._createNotification(options, actions);
    this._insertNotification(notification, options.placement);

    const navigationElement = document.querySelector('e-navigation');
    const openerElement = navigationElement ? navigationElement : null;

    floatUtility.float(this._container[options.placement], openerElement);

    notification.addEventListener('disappear', () => this._close(options.uuid, sourceWindow));
  }

  _createActions(options, sourceWindow) {
    return {
      handleButtonClick: buttonId => this._handleButtonClick(options.uuid, buttonId, sourceWindow),
      handleClose: () => this._close(options.uuid, sourceWindow)
    };
  }

  _createNotification(options, actions) {
    const notification = createNotification(options, actions);
    this._notifications[options.uuid] = notification;
    return notification;
  }

  _insertNotification(notification, placement) {
    const container = this._prepareContainer(placement);
    const position = placement === BOTTOM_RIGHT_PLACEMENT ? 'afterbegin' : 'beforeend';
    container.insertAdjacentElement(position, notification);
  }

  _prepareContainer(placement) {
    return this._getContainer(placement) || this._createContainer(placement);
  }

  _getContainer(placement) {
    return this._container[placement];
  }

  _createContainer(placement) {
    this._container[placement] = createContainer(placement);
    return this._container[placement];
  }

  _handleButtonClick(notificationUuid, buttonUuid, sourceWindow) {
    sourceWindow.postMessage({ message: BUTTON_CLICK_MESSAGE, data: { notificationUuid, buttonUuid } }, '*');
  }

  _close(notificationUuid, sourceWindow) {
    sourceWindow.postMessage({ message: SERVICE_CLOSE_MESSAGE, data: { notificationUuid } }, '*');
    this._cleanUp(notificationUuid);
  }

  _cleanUp(notificationUuid) {
    this._cleanUpDom(notificationUuid);
    delete this._notifications[notificationUuid];
  }

  _cleanUpDom(notificationUuid) {
    const notification = this._notifications[notificationUuid];
    if (!notification) { return; }

    const container = notification.parentElement;
    if (!container) { return; }

    const hasMoreNotifications = container.querySelectorAll('e-notification').length > 1;

    notification.remove();

    if (!hasMoreNotifications) {
      container.remove();
    }
  }
}

export default NotificationService;
