import { html } from 'uhtml';
import constants from '../constants';
import classNames from 'clsx';

const OPTION_PREFIX = constants.filter.select.optionPrefix;
const OPTION_ALL = constants.filter.select.all;
export default class EDatagridFilterTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  createSimple() {
    return this._createFilters(false);
  }

  createAdvanced() {
    return this._createFilters(true);
  }

  createAdvancedButton() {
    if (!this._state.filterState.hasAdvancedFilters) { return; }

    const buttonClass = classNames('e-btn e-btn-borderless', {
      'e-btn-active': this._state.filterState.isFiltersOpen
    });

    return html`
      <div class="e-datagrid__filter_button">
        <button type="button" class="${buttonClass}" @click="${this._events.onAdvancedFilterButtonClick}">
          <e-icon icon="e-filter"></e-icon>
          <e-translation key="components.datagrid.advancedFiltersButton"></e-translation>
        </button>
      </div>
    `;
  }

  _createFilters(isAdvanced) {
    return this._state.filterState.filters
      .filter(filter => filter.advanced === isAdvanced)
      .map(filter => {
        const filterValue = this._state.filterState.filterValues[filter.contentKey];

        if (filter.type === 'select') {
          return this._createSelect(filter, filterValue);
        } else if (filter.type === 'multiselect') {
          return this._createMultiselect(filter, filterValue);
        } else if (filter.type === 'checkbox') {
          return this._createCheckbox(filter, filterValue);
        } else if (filter.type === 'switch') {
          return this._createSwitch(filter, filterValue);
        } else if (filter.type === 'daterange') {
          return this._createDaterange(filter, filterValue);
        }
      });
  };

  _createMultiselect(filter, filterValue) {
    const options = this._getOptions(filter);
    const hasPredefinedOptions = filter.options && filter.options.length;
    const hasOptions = (this._state.hasContent || hasPredefinedOptions) && options.length;
    const isDisabled = !hasOptions;
    const isReadonly = this._state.serverState.isLoading;

    return html`
      <div class="e-datagrid__filter e-grid e-grid-vertical_center">
        <label class="e-field__label e-field__label-inline e-field__label-small e-margin-right-xs" for="${filter.uuid}">
          ${filter.label}
        </label>
        <div class="e-inputgroup e-inputgroup-inline">
          <e-multiselect
            inline
            id="${filter.uuid}"
            class="e-inputgroup__item e-inputgroup__item-first"
            label-merge-limit="${filter.labelMergeLimit}"
            placeholder="${filter.placeholder}"
            ?disabled="${isDisabled}"
            ?readonly="${isReadonly}"
            @change="${(event) =>this._events.onFilterChange(event, filter)}"
          >
            ${this._generateMultiselectOptions(filter, filterValue, options)}
          </e-multiselect>
          ${this._createMultiselectOperator(filter, filterValue)}
        </div>
      </div>
    `;
  };

  _createMultiselectOperator(filter, filterValue) {
    if (!filter.hasOperator) { return; }

    const hasOperatorAnySelected = !filterValue ||
      !filterValue.value.operator ||
      filterValue.value.operator === 'or';

    return html`
      <e-select
        class="e-inputgroup__item e-inputgroup__item-last e-datagrid__filter__operator"
        @change="${(event) => this._events.onFilterChange(event, filter)}"
      >
        <e-select-option
          value="or"
          ?selected="${ hasOperatorAnySelected ? 'selected' : ''}"
        >
          <e-translation key="components.multiselect.operators.any"></e-translation>
        </e-select-option>
        <e-select-option
          value="and"
          ?selected="${ !hasOperatorAnySelected ? 'selected' : '' }"
        >
          <e-translation key="components.multiselect.operators.all"></e-translation>
        </e-select-option>
      </e-select>
    `;
  };

  _generateMultiselectOptions(filter, filterValue, options) {
    return options.map(option => {
      const selectedOptionsInStorage = filter.hasOperator ?
        filterValue?.value?.value :
        filterValue?.value;

      const selectedOptions = Array.isArray(selectedOptionsInStorage) ? selectedOptionsInStorage : [];
      const isSelected = selectedOptions.includes(option.value);

      return html`
        <e-select-option value="${`${OPTION_PREFIX}${option.value}`}" ?selected=${isSelected}>
          ${option.label}
        </e-select-option>
      `;
    });
  };

  _createSelect(filter, filterValue) {
    const options = this._getOptions(filter);
    const hasPredefinedOptions = filter.options && filter.options.length;
    const hasOptions = (this._state.hasContent || hasPredefinedOptions) && options.length;
    const isDisabled = !hasOptions || this._state.serverState.isLoading;

    return html`
      <div class="e-datagrid__filter">
        <label class="e-field__label e-field__label-inline e-field__label-small" for="${filter.uuid}">
          ${filter.label}
        </label>
        <e-select
          @change="${(event) => this._events.onFilterChange(event, filter)}"
          class="e-select-inline e-select-large"
          id="${filter.uuid}"
          ?disabled="${isDisabled}"
        >
          <e-select-option
            value="${OPTION_ALL}"
            ?selected="${filterValue === undefined}"
          >
            ${this._state.translations.filterShowAll}
          </e-select-option>
          ${this._generateOptions(filter, filterValue, options)}
        </e-select>
      </div>
    `;
  };

  _generateOptions(filter, filterValue, options) {
    return options.map(option => {
      const selectedString = filterValue && filterValue.value === option.value ? 'selected' : null;
      return html`
      <e-select-option
        value="${`${OPTION_PREFIX}${option.value}`}"
        selected="${selectedString}"
      >
        ${option.label}
      </e-select-option>
    `;
    });
  };

  _createCheckbox(filter, filterValue) {
    return html`
      <div class="e-datagrid__filter">
        <e-checkbox
          id=${filter.uuid}
          label=${filter.label}
          helper=${filter.tooltip}
          ?disabled="${this._state.serverState.isLoading}"
          ?checked="${!!filterValue}"
          @change="${(event) => this._events.onFilterChange(event, filter)}"
        ></e-checkbox>
      </div>
    `;
  };

  _createSwitch(filter, filterValue) {
    return html`
      <div class="e-datagrid__filter">
        <e-switch
          id=${filter.uuid}
          label=${filter.label}
          helper=${filter.tooltip}
          ?disabled=${this._state.serverState.isLoading}
          ?checked=${!!filterValue}
          @change=${(event) => this._events.onFilterChange(event, filter)}
        ></e-switch>
      </div>
    `;
  };

  _createDaterange(filter, filterValue) {
    const start = filterValue ? filterValue.value.start.date : null;
    const end = filterValue ? filterValue.value.end.date : null;
    const value = start || end ? { start: start || '', end: end || '' } : '';

    return html`
      <div class="e-datagrid__filter">
        <label class="e-field__label e-field__label-inline e-field__label-small" for="${filter.uuid}">
          ${filter.label}
        </label>
        <e-datetime
          type="daterange"
          inline
          .value=${value}
          clear-hidden="${filter.clearHidden}"
          min-date="${filter.minDate}"
          max-date="${filter.maxDate}"
          @change=${(event) => this._events.onFilterChange(event, filter)}
        ></e-datetime>
      </div>
    `;
  };

  _convertArrayValue(options, item) {
    if (Array.isArray(item.value)) {
      for (let index = 0; index < item.value.length; ++index) {
        options.push({ value: item.value[index], label: item.label[index] });
      }
    } else {
      options.push(item);
    }

    return options;
  };

  _addOptionWithUniqueValue(options, item) {
    const existingOption = options.find(option => item.value === option.value);
    if (!existingOption) {
      options.push(item);
    }

    return options;
  };

  _sortAlphabetical(a, b) {
    const labelA = String(a.label);
    const labelB = String(b.label);
    if (labelA.toLowerCase() < labelB.toLowerCase()) {
      return -1;
    } else if (labelA.toLowerCase() > labelB.toLowerCase()) {
      return 1;
    }

    return 0;
  };

  _getOptions(filter) {
    if (filter.options.length > 0) {
      return filter.options;
    } else if (this._state.content && this._state.content.length) {
      return this._state.content
        .filter(row => row[filter.contentKey])
        .map(row => ({ value: row[filter.contentKey], label: row[filter.contentKey] }))
        .reduce(this._convertArrayValue, [])
        .reduce(this._addOptionWithUniqueValue, [])
        .sort(this._sortAlphabetical);
    }

    return [];
  };
}
