const nbsp = '\xa0';

export const splitSearchQuery = (keyword) => keyword
  .toLowerCase()
  .replace(/(^|\s)"(.+?)"(?=\s|$)/g, (_, wsBefore, word) => wsBefore + word.replace(/ /g, nbsp))
  .split(/ +/)
  .map(word => word.replace(new RegExp(nbsp, 'g'), ' '))
  .filter(word => word);

export default splitSearchQuery;
