import State from '../../../utils/state';

class DatetimeCalendarState extends State {
  get defaultState() {
    return {
      currentDate: new Date(),
      selectedDate: undefined,
      activeDate: undefined,
      minDate: undefined,
      maxDate: undefined,
      rangeOtherEnd: undefined,
      hoveredDate: null,
      monthList: [],
      yearList: [],
      dayNames: [],
      daysOfMonth: [],
      todayHidden: false,
      disabled: false
    };
  }
}

export default DatetimeCalendarState;
