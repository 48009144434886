import { html } from 'uhtml';
import purifier from '../dom-purify';
import { BOTTOM_RIGHT_PLACEMENT } from './constants';

const CONTAINER_CLASS = 'e-fixed_notification_container';
export const TOP_CONTAINER_CLASS = `${CONTAINER_CLASS}-top`;
export const BOTTOM_RIGHT_CONTAINER_CLASS = `${CONTAINER_CLASS}-bottom_right`;

const assertiveTypes = ['danger', 'error'];

export const createContainer = (placement) => {
  const placementClass = placement === BOTTOM_RIGHT_PLACEMENT ? BOTTOM_RIGHT_CONTAINER_CLASS : TOP_CONTAINER_CLASS;
  return html.node`
    <div class="${`${CONTAINER_CLASS} ${placementClass}`}"></div>
  `;
};

export const createNotification = ({
  content,
  title,
  subtitle,
  type,
  altStyle,
  icon,
  color,
  closable = true,
  helperLink,
  buttons,
  key,
  autoClose
} = {}, actions) => {
  return html.node`
    <e-notification
      type="${type}"
      alt-style="${altStyle || null}"
      icon="${icon}"
      color="${color}"
      closable="${closable}"
      key="${key}"
      onclose=${actions.handleClose}
      role=${assertiveTypes.includes(type) ? 'alert' : 'status'}
      aria-live=${assertiveTypes.includes(type) ? 'assertive' : 'polite'}
      aria-atomic="true"
      autoclose="${autoClose}"
    >
      ${title ? html`<e-notification-title>${title}</e-notification-title>` : null}
      ${createSubtitle(subtitle)}
      <e-notification-content>${getRenderContent(content)}</e-notification-content>
      ${createFooter({ helperLink, buttons }, actions)}
    </e-notification>
  `;
};

const getRenderContent = content => {
  if (content.type) {
    return content.type === 'html' ?
      html([purifier.sanitize(content.template)]) :
      content.template;
  } else {
    return content;
  }
};

const createSubtitle = subtitle => {
  return subtitle ?
    html`<e-notification-subtitle>${getRenderSubtitle(subtitle)}</e-notification-subtitle>` :
    null;
};

const getRenderSubtitle = subtitle => {
  if (subtitle.type) {
    return subtitle.type === 'html' ?
      html([purifier.sanitize(subtitle.template)]) :
      subtitle.template;
  } else {
    return subtitle;
  }
};

const createFooter = ({ helperLink, buttons }, source) => {
  if (!helperLink && !buttons.length) { return null; }

  return html`
    <e-notification-footer class="e-grid e-grid-xsmall e-grid-wrap e-grid-vertical_center">
      ${createHelperLink(helperLink)}
      ${createButtons(buttons, source)}
    </e-notification-footer>
  `;
};

const createHelperLink = ({ text, href } = {}) => {
  if (!text && !href) { return null; }
  return html`
    <div class="e-cell e-cell-xsmall">
      <a class="e-helperlink e-helperlink-nopadding" href="${href}" target="_blank" rel="noopener noreferrer">
        <e-icon class="e-helperlink__icon" icon="e-helperlink" size="small"></e-icon>
        <span class="e-helperlink__text">
          ${text}
        </span>
      </a>
    </div>
  `;
};

const createButtons = (buttons, actions) => {
  return buttons.map(button => html`
    <div class="e-cell e-cell-xsmall">
      <button
        class="${button.primary ? 'e-btn e-btn-primary' : 'e-btn'}"
        onclick=${() => actions.handleButtonClick(button.uuid)}
      >
        ${button.icon ? html`<e-icon icon=${button.icon}></e-icon>` : null}
        ${button.text}
      </button>
    </div>
  `);
};
