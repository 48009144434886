import { html } from 'uhtml';
import templateVariables from './template-variables';

export default (state, events, refs) => {
  const variables = templateVariables(state, events, refs);

  return html`
    <div class="e-upload">
      <div class="clearfix" onclick="${variables.openFileDialog}">
        <div class="e-grid e-grid-xsmall">
          <div class="e-cell e-cell-auto e-cell-xsmall">
            <input
              value="${state.inputValue}"
              class="${variables.inputClass}"
              type="text"
              readonly
              placeholder="${state.placeholder || ''}"
              ?disabled="${state.disabled}">
          </div>
          <div class="e-cell e-cell-xsmall">
            <button type="button" class="${variables.buttonClass}" ?disabled="${state.disabled}">
              <e-icon icon="folder-open"></e-icon>
              ${state.browse || state.button || 'Choose File'}
            </button>
          </div>
        </div>
      </div>
    </div>${variables.fileElement}`;
};
