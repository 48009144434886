
export class FloatUtility {

  static get navigationElementSelectors() {
    return ['e-navigation', '.e-navigation', '.e-shellbar'];
  }

  constructor(global = window) {
    this._currentZIndex = 0;

    this.init(global);
  }

  init(global) {
    this._global = global;
  }

  float(element, opener = null) {
    const floatContainer = this._getContainer(element, opener);

    element.style.zIndex = ++this._currentZIndex;

    if (element.parentNode !== floatContainer) {
      floatContainer.appendChild(element);
    }
  }

  remove(element) {
    element.remove();
  }

  _isElementInNavigation(element) {
    if (!element) {
      return false;
    }

    const isInNavigationContainer = element.closest('e-float-container.e-float-container-navigation') !== null;
    const isInNavigationElement = FloatUtility.navigationElementSelectors.some(navigationElementSelector =>
      element.closest(navigationElementSelector) !== null
    );

    return isInNavigationContainer || isInNavigationElement;
  }

  _getContainer(element, opener) {
    const containerClass = this._isElementInNavigation(element) || this._isElementInNavigation(opener) ?
      'e-float-container-navigation' :
      'e-float-container-default';
    const containerElement = this._global.document.querySelector(`e-float-container.${containerClass}`);

    if (containerElement === null) {
      const newContainer = this._global.document.createElement('e-float-container');
      newContainer.classList.add(containerClass);
      this._global.document.body.appendChild(newContainer);
      return newContainer;
    }

    return containerElement;
  }
}

export default FloatUtility;
