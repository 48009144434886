export class ESkeletonUtils {
    static getSupportedHeight(skeleton) {
        const element = skeleton.parentElement;
        return heightByTextualElement(element);
    }
}
const heightByTextualElement = (element) => {
    const textualNodeNames = ['p', 'h1', 'h2', 'h3', 'h4'];
    if (element.matches(textualNodeNames.join(','))) {
        return getComputedStyle(element).fontSize;
    }
    return null;
};
