import { ESkeletonUtils } from './utils';
export class ESkeletonState {
    constructor(component) {
        this._barCount = 1;
        this._component = component;
        this.requestRender = component.requestRender;
    }
    set barHeight(value) {
        this._barHeight = value;
        this.requestRender();
    }
    get barHeight() {
        return this._barHeight || ESkeletonUtils.getSupportedHeight(this._component);
    }
    get width() {
        return this._width;
    }
    set width(value) {
        this._width = value;
        this.requestRender();
    }
    get barCount() {
        return this._barCount;
    }
    set barCount(value) {
        this._barCount = value;
        this.requestRender();
    }
}
