import floatUtility from '../float/index.js';
import { getOpenedPopups } from '../popup/utils.js';

const findIndex = (list, callback) =>
  list.reduce((previous, current, index) => callback(current) ? index : previous, -1);

const get = (path, object) => path.reduce((currentLevel, key) => {
  return (currentLevel && typeof currentLevel === 'object') ? currentLevel[key] : undefined;
}, object);

const set = (path, value, object = {}) => {
  const [key, ...subpath] = path;
  return Object.assign({}, object, { [key]: path.length > 1 ? set(subpath, value, object[key]) : value });
};

class PopupHandler {

  constructor(global, createPopper) {
    this._global = global;
    this._boundaryElement = global.document.createElement('div');
    this._createPopper = createPopper;
    this._openedPopups = getOpenedPopups(this._global);
    this._closing = null;
    this._clickTarget = null;

    global.document.addEventListener('mousedown', this._onMouseDown.bind(this), true);
    global.document.addEventListener('click', this._onBodyClick.bind(this), true);
    global.document.addEventListener('keydown', this._onESC.bind(this), true);
  }

  openPopper({
    reference, popperElement, popperConfig, openCallback, beforeCloseCallback, closeCallback, opener, force, local,
    parent
  }) {
    if (this._closing === opener && !force) { return; }
    if (local) { parent = reference; }

    if (parent) {
      parent.appendChild(popperElement);
    } else {
      floatUtility.float(popperElement, opener);
    }

    const configPath = ['modifiers', 'preventOverflow', 'boundariesElement'];

    if (!get(configPath, popperConfig)) {
      this._boundaryElement.className = this._boundaryElementClassName();
      this._global.document.body.appendChild(this._boundaryElement);

      popperConfig = set(configPath, this._boundaryElement, popperConfig);
    }

    const popper = this._createPopper(reference, popperElement, popperConfig);
    this._openedPopups.push({
      type: 'legacyPopup',
      opener,
      element: popperElement,
      beforeCloseCallback,
      closeCallback,
      popper
    });

    if (openCallback) { openCallback(); }
    return popper;
  }

  register({ element, openCallback, beforeCloseCallback, closeCallback, opener, force }) {
    if (this._closing === opener && !force) { return; }

    this._openedPopups.push({
      type: 'legacyPopup',
      opener,
      element,
      beforeCloseCallback,
      closeCallback
    });

    if (openCallback) { openCallback(); }
  }

  isOpened(opener) {
    return this._findPopupIndex(opener) > -1;
  }

  close(opener) {
    const index = this._findPopupIndex(opener);
    if (index === -1) { return; }
    this._openedPopups.splice(index).reverse().forEach(popup => this._closePopup(popup));
  }

  updatePopper(opener) {
    const index = this._findPopupIndex(opener);
    if (index === -1) { return; }
    this._openedPopups[index].popper.update();
  }

  _findPopupIndex(opener) {
    return findIndex(this._openedPopups, popup => popup.opener === opener);
  }

  _boundaryElementClassName() {
    const className = [
      'e-popover_boundary',
      ...(this._global.document.querySelector('.e-topnav') ? ['e-popover_boundary-header'] : []),
      ...(this._global.document.querySelector('e-shellbar') ? ['e-popover_boundary-shellbar'] : []),
      ...(this._global.document.querySelector('e-navigation') ? ['e-popover_boundary-navigation'] : []),
      ...(this._global.document.querySelector('.e-steps') ? ['e-popover_boundary-footer'] : []),
      ...(this._global.document.querySelector('e-step-bar')?.floating ? ['e-popover_boundary-footer'] : [])
    ];

    return className.join(' ');
  }

  _onBodyClick(event) {
    const lastOpenedPopup = this._getLastOpenedPopup();

    if (!lastOpenedPopup || lastOpenedPopup.element.contains(this._clickTarget)) {
      this._closing = null;
      return;
    }

    if (lastOpenedPopup.beforeCloseCallback) { lastOpenedPopup.beforeCloseCallback(event); }

    if (event && (event.defaultPrevented) || (event.e && event.e.isHandled)) { return; }

    event.e = {};
    event.e.isHandled = true;

    this._openedPopups = this._openedPopups.filter(popup => popup.opener !== lastOpenedPopup.opener);

    this._closePopup(lastOpenedPopup);
    this._closing = lastOpenedPopup.opener;
  }

  _onMouseDown(event) {
    this._clickTarget = event.target;
  }

  _onESC(event) {
    if (event.key !== 'Escape') { return; }

    const lastOpenedPopup = this._getLastOpenedPopup();
    if (!lastOpenedPopup) {
      this._closing = null;
      return;
    }

    if (lastOpenedPopup.beforeCloseCallback) { lastOpenedPopup.beforeCloseCallback(event); }
    if (event && (event.defaultPrevented) || (event.e && event.e.isHandled)) { return; }

    event.e = {};
    event.e.isHandled = true;
    this._closePopup(this._openedPopups.pop());
  }

  _getLastOpenedPopup() {
    const openedPopups = this._openedPopups.filter(popup => popup.opener?.nodeName !== 'E-TOOLTIP');
    const lastPopup = openedPopups[openedPopups.length - 1];

    return lastPopup && lastPopup.type === 'popup' ? undefined : lastPopup;
  }

  _closePopup(popup) {
    if (this._boundaryElement.parentNode && !this._openedPopups.length) {
      this._boundaryElement.parentNode.removeChild(this._boundaryElement);
    }
    if (popup.popper) { popup.popper.destroy(); }
    if (popup.closeCallback) { popup.closeCallback(); }
  }
}

export default PopupHandler;
