import { convertAttributeToBoolean, convertAttributeToString, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { DisabledType, ETabState, Spacing } from './state';
export class ETab extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            content: null
        };
        this.utils = {
            mutationObserver: null
        };
        this.state = new ETabState();
        this.utils.mutationObserver = new MutationObserver(this._updateRefs.bind(this));
    }
    connectedCallback() {
        this.utils.mutationObserver.observe(this, { childList: true, subtree: true, attributes: true });
        this._updateRefs();
    }
    disconnectedCallback() {
        this._dispatchDeleteEvent();
    }
    get label() {
        return this.state.label;
    }
    set label(value) {
        this.state.label = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get icon() {
        return this.state.icon;
    }
    set icon(value) {
        this.state.icon = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get badge() {
        return this.state.badge;
    }
    set badge(value) {
        this.state.badge = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get disabled() {
        return this.state.isDisabled;
    }
    set disabled(value) {
        this.state.isDisabled = convertAttributeToBoolean(value);
        this._dispatchUpdateEvent();
    }
    get selected() {
        return this.state.isSelected;
    }
    set selected(value) {
        this.state.isSelected = convertAttributeToBoolean(value);
        this._dispatchUpdateEvent();
    }
    get id() {
        return this.state.id;
    }
    set id(value) {
        this.state.id = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get disabledTooltip() {
        return this.state.disabledTooltip;
    }
    set disabledTooltip(value) {
        this.state.disabledTooltip = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get disabledType() {
        return this.state.disabledType;
    }
    set disabledType(value) {
        this.state.disabledType = Object.values(DisabledType).includes(value) ?
            value :
            undefined;
        this._dispatchUpdateEvent();
    }
    get spacing() {
        return this.state.spacing;
    }
    set spacing(value) {
        this.state.spacing = Object.values(Spacing).includes(value) ?
            value :
            Spacing.NONE;
        this._dispatchUpdateEvent();
    }
    // Private methods
    _updateRefs() {
        const template = this.querySelector('e-tab-content');
        if (template) {
            this.refs.content = template;
        }
        this._dispatchUpdateEvent();
    }
    _getEventDetail() {
        return {
            uuid: this.state.uuid,
            label: this.state.label,
            icon: this.state.icon,
            badge: this.state.badge,
            disabled: this.state.isDisabled,
            selected: this.state.isSelected,
            content: this.refs.content,
            id: this.state.id,
            disabledTooltip: this.state.disabledTooltip,
            disabledType: this.state.disabledType,
            spacing: this.state.spacing
        };
    }
    _dispatchUpdateEvent() {
        this.dispatchEvent(new CustomEvent('tab.update', {
            bubbles: true,
            detail: this._getEventDetail()
        }));
    }
    _dispatchDeleteEvent() {
        this.dispatchEvent(new CustomEvent('tab.delete', {
            detail: this._getEventDetail()
        }));
    }
}
// Attributes
ETab.observedAttributes = [
    'label',
    'icon',
    'badge',
    'disabled',
    'selected',
    'id',
    'disabled-tooltip',
    'disabled-type',
    'spacing'
];
