import { html } from 'uhtml';
import classNames from 'clsx';

export class ERulelistGroupTemplate {
  #createReference;
  #state;
  #events;

  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.#state = component.state;
    this.#events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <e-rulelist-toggle-wrapper slot="_toggle" ref="${this.#createReference(['toggleWrapper'])}" />
    `;
  }

  // Component Content
  createElement() {
    const onSlotChange = (event) => this.#events.onSlotChange(event.target);

    const relationParts = classNames('relation', { 'hidden': !this.#state.isRelationVisible() });
    const headerWrapperParts = classNames('header-wrapper', { 'hidden': !this.#state.isHeaderVisible() });
    const feedbackParts = classNames('feedback', { 'hidden': !this.#state.isFeedbackVisible() });
    const itemsParts = classNames('items', { 'hidden': !this.#state.isItemsVisible() });
    const footerWrapperParts = classNames('footer-wrapper', { 'hidden': !this.#state.isFooterVisible() });

    return html`
      <slot name="relation" part="${relationParts}" @slotchange="${onSlotChange}" />
      <div part="group">
        <div part="${headerWrapperParts}">
          <slot name="header" part="header" @slotchange="${onSlotChange}" />
          <div part="header-actions-wrapper">
            ${this.#createHeaderActions(onSlotChange)}
            <slot name="_toggle" />
          </div>
        </div>
        <div part="togglable-container" ref="${this.#createReference(['togglableContainer'])}">
          <div part="togglable-content">
            <div part="togglable-content-frame">
              <slot name="feedback" part="${feedbackParts}" @slotchange="${onSlotChange}" />
              <slot name="items" part="${itemsParts}" @slotchange="${onSlotChange}" />
              <div part="${footerWrapperParts}">
                ${this.#createFooterActions(onSlotChange)}
                <slot name="footer" part="footer" @slotchange="${onSlotChange}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  createToggleElement() {
    const icon = this.#state.isClosed ? 'angle-down' : 'angle-up';

    return html`
      <button class="e-btn e-btn-borderless e-btn-onlyicon" @click="${this.#events.onToggleClick}">
        <e-icon icon="${icon}"></e-icon>
      </button>
    `;
  }

  #createHeaderActions(onSlotChange) {
    if (this.#state.isReadOnly) { return; }

    return html`
      <slot name="header-action" part="header-actions" @slotchange="${onSlotChange}" />
    `;
  }

  #createFooterActions(onSlotChange) {
    if (this.#state.isReadOnly) { return; }

    return html`
      <slot name="footer-action" part="footer-actions" @slotchange="${onSlotChange}" />
    `;
  }
}

export default ERulelistGroupTemplate;
