import autoBind from 'auto-bind';

const AUTO_CLOSE_DURATION = 5000;

export class ENotificationEvents {
  constructor(component) {
    autoBind(this);

    this.component = component;
    this.state = component.state;
    this.dispatchEvent = component.dispatchEvent.bind(component);
  }

  onCloseClick() {
    this.component.close();
  }

  onMouseEnter() {
    this.stopAutoCloseTimeout();
  }

  onMouseLeave() {
    if (this.component.contains(document.activeElement)) { return; }

    this.startAutoCloseTimeout();
  }

  onFocusIn() {
    this.stopAutoCloseTimeout();
  }

  onFocusOut(event) {
    if (this.component.contains(event.relatedTarget)) { return; }

    this.startAutoCloseTimeout();
  }

  dispatchAppearEvent() {
    this.dispatchEvent(new CustomEvent('appear', {
      detail: { component: this.component },
      bubbles: true
    }));
  }

  dispatchDisappearEvent() {
    this.dispatchEvent(new CustomEvent('disappear'));
  }

  dispatchCloseEvent() {
    this.dispatchEvent(new CustomEvent('close'));
  }

  startAutoCloseTimeout() {
    if (!this.state.isAutoClose || this.state.isAutoCloseRunning) { return; }

    this.state.autoCloseTimeout = setTimeout(() => {
      this.component.hide();
    }, AUTO_CLOSE_DURATION);
  }

  stopAutoCloseTimeout() {
    clearTimeout(this.state.autoCloseTimeout);
    this.state.autoCloseTimeout = null;
  }
}
