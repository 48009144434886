import { HTMLCustomElement, convertAttributeToString } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { EImageState } from './state';
import { EImageTemplate } from './template';

export class EImage extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.state = new EImageState(this);
    this.template = new EImageTemplate(this);
  }

  connectedCallback() {
    this.reflectStringAttribute('type', this.state.type);
    this.requestRender();
  }

  // Attributes
  static get observedAttributes() {
    return ['type', 'size', 'name'];
  }

  get type() {
    return this.state.type;
  }

  set type(value) {
    if (!EImageState.availableTypes.includes(value)) { return; }

    this.state.type = value;
    this.reflectStringAttribute('type', this.state.type);
  }

  get size() {
    return this.state.size;
  }

  set size(value) {
    let finalSize = value;
    if (!EImageState.availableSizes.includes(finalSize)) {
      finalSize = '';
    }

    this.state.size = finalSize;
    this.reflectStringAttribute('size', this.state.size);
  }

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = convertAttributeToString(value);
    this.reflectStringAttribute('name', this.state.name);
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }
}

export default EImage;
