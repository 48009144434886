import { convertAttributeToBoolean, convertAttributeToString, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { EStepState } from './state';
export class EStep extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.state = new EStepState();
    }
    connectedCallback() {
        this._dispatchUpdateEvent();
    }
    disconnectedCallback() {
        this._dispatchDeleteEvent();
    }
    get label() {
        return this.state.label;
    }
    set label(value) {
        this.state.label = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get icon() {
        return this.state.icon;
    }
    set icon(value) {
        this.state.icon = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get badge() {
        return this.state.badge;
    }
    set badge(value) {
        this.state.badge = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get tooltip() {
        return this.state.tooltip;
    }
    set tooltip(value) {
        this.state.tooltip = convertAttributeToString(value);
        this._dispatchUpdateEvent();
    }
    get disabled() {
        return this.state.isDisabled;
    }
    set disabled(value) {
        this.state.isDisabled = convertAttributeToBoolean(value);
        this._dispatchUpdateEvent();
    }
    get selected() {
        return this.state.isSelected;
    }
    set selected(value) {
        this.state.isSelected = convertAttributeToBoolean(value);
        this._dispatchUpdateEvent();
    }
    get countExcluded() {
        return this.state.isCountExcluded;
    }
    set countExcluded(value) {
        this.state.isCountExcluded = convertAttributeToBoolean(value);
        this._dispatchUpdateEvent();
    }
    // Private methods
    _getEventDetail() {
        return {
            uuid: this.state.uuid,
            label: this.state.label,
            icon: this.state.icon,
            badge: this.state.badge,
            tooltip: this.state.tooltip,
            disabled: this.state.isDisabled,
            selected: this.state.isSelected,
            countExcluded: this.state.isCountExcluded
        };
    }
    _dispatchUpdateEvent() {
        this.dispatchEvent(new CustomEvent('step.update', {
            bubbles: true,
            detail: this._getEventDetail()
        }));
    }
    _dispatchDeleteEvent() {
        this.dispatchEvent(new CustomEvent('step.delete', {
            detail: this._getEventDetail()
        }));
    }
}
// Attributes
EStep.observedAttributes = ['label', 'icon', 'badge', 'tooltip', 'disabled', 'selected', 'count-excluded'];
