const getFlipperService = (configStore) => () => {
  return configStore.getLoadedConfig().then(({ flippers }) => {
    return {
      isOn: flipper => flippers ? flippers.indexOf(flipper) > -1 : false,
      isOff: flipper => flippers ? flippers.indexOf(flipper) === -1 : true
    };
  });
};

export default getFlipperService;
