import { AttributeUtils } from '@emartech/eds-utils-component';
import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class EDialogAction extends HTMLCustomElement {
  init() {
    this.key = '';
    this._disabled = false;
    this._disabledTooltip = '';
    this._dom = {};
    this._dom.disabledTooltip = this._createDisabledTooltipElement();
    this._events = {};
    this._events.trigger = this._onTrigger.bind(this);
    this.addEventListener('click', this._events.trigger);
    this.addEventListener('keydown', event => {
      if (event.code !== 'Enter' && event.code !== 'Space') { return; }

      event.preventDefault();

      this._events.trigger(event);
    });
  }

  connectedCallback() {
    this.tabIndex = !!this._disabled ? -1 : 0;
    this.role = 'button';
  }

  static get observedAttributes() {
    return ['key', 'disabled', 'disabled-tooltip'];
  }

  set disabled(value) {
    this._disabled = AttributeUtils.convertToBoolean(value);

    this.tabIndex = this._disabled ? -1 : 0;

    if (!this._disabled && this.hasAttribute('disabled')) {
      this.removeAttribute('disabled');
    } else if (this._disabled && !this.hasAttribute('disabled')) {
      this.setAttribute('disabled', '');
    }

    this._renderTooltip();
  }

  set disabledTooltip(value) {
    this._disabledTooltip = value;
    this._dom.disabledTooltip.content = value;

    this._renderTooltip();
  }

  _createDisabledTooltipElement() {
    const element = document.createElement('e-tooltip');
    return element;
  }

  _renderTooltip() {
    if (this._disabled && this._disabledTooltip) {
      this.appendChild(this._dom.disabledTooltip);
    } else if (this._dom.disabledTooltip.parentNode) {
      this.removeChild(this._dom.disabledTooltip);
    }
  }

  _onTrigger(event) {
    if (this._isLink(event.target) || this._disabled) {
      return;
    }

    this.dispatchEvent(new CustomEvent('trigger'));
  }

  _isLink(element) {
    if (element.nodeName === 'A') {
      return true;
    }

    if (element.parentNode && element.parentNode !== this) {
      return this._isLink(element.parentNode);
    }

    return false;
  }
}

export default EDialogAction;
