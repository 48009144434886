import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import buttonTemplate from './template-button';
import dropdownTemplate from './template-dropdown';
const template = {
  button: buttonTemplate,
  dropdown: dropdownTemplate
};

class EUpload extends HTMLCustomElement {
  init() {
    this._refs = {
      fileElement: null
    };
    this._state = new Proxy({
      inputValue: '',
      button: '',
      placeholder: '',
      maxlength: '',
      accept: '',
      name: '',
      mediadb: '',
      mediadbCallback: null,
      browse: '',
      invalid: false,
      disabled: false
    }, {
      set: (obj, prop, value) => {
        if (value === obj[prop]) {
          return true;
        }

        obj[prop] = value;
        this._render();

        return true;
      },
      get: (obj, prop) => obj[prop]
    });

    this._events = {
      fileInputChange: this._fileInputChange.bind(this)
    };
  }

  connectedCallback() {
    this._render();
  }

  disconnectedCallback() { }

  static get observedAttributes() {
    return [
      'button',
      'placeholder',
      'maxlength',
      'accept',
      'name',
      'mediadb',
      'mediadb-callback',
      'browse',
      'value',
      'invalid',
      'disabled'
    ];
  }

  set button(value) {
    this._state.button = value;
  }

  set placeholder(value) {
    this._state.placeholder = value;
  }

  set maxlength(value) {
    this._state.maxlength = value;
  }

  set accept(value) {
    this._state.accept = value;
  }

  set name(value) {
    this._state.name = value;
  }

  set mediadb(value) {
    this._state.mediadb = value;
  }

  set mediadbCallback(value) {
    this._state.mediadbCallback = value;
  }

  set browse(value) {
    this._state.browse = value;
  }

  set value(value) {
    this._state.inputValue = value;

    if (!value) {
      this._refs.fileElement.value = '';
    }
  }

  set invalid(value) {
    this._state.invalid = super._convertAttributeToBoolean(value);
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
  }

  _render() {
    if (!this.isConnected) { return; }

    const templateName = this._state.mediadb ? 'dropdown' : 'button';
    render(this, template[templateName](this._state, this._events, this._refs));

    const mediadbItem = this.querySelector('.e-dropdown__mediadb_callback');
    if (mediadbItem) {
      mediadbItem.setAttribute('onclick', this._state.mediadbCallback);
    }
  }

  _fileInputChange(event) {
    if (!event.target.files.length) {
      return;
    }

    this._state.inputValue = event.target.files[0].name;
    this._dispatchUpdateEvent(event.target.files);

    this._render();
  }

  _dispatchUpdateEvent(files) {
    const eventDetails = {
      detail: {
        files: files
      }
    };
    const fileChangeEvent = new CustomEvent('update', eventDetails);
    this.dispatchEvent(fileChangeEvent);
  }
}

export default EUpload;
