export class SwitchService {
  constructor(themeHandler) {
    this.children = [];
    this.themeHandler = themeHandler;
  }

  listenOnChildrenSignal() {
    window.addEventListener('message', (event) => {
      if (event.data?.message !== 'e:themeHandler:childSignalEvent') { return; }

      const theme = this.themeHandler.currentTheme;
      this.#dispatchSwitchThemeEvent(event.source, theme);

      this.children.push(event.source);
    });
  }

  switchTheme(theme) {
    this.children.forEach(childFrame => {
      this.#dispatchSwitchThemeEvent(childFrame, theme);
    });
  }

  #dispatchSwitchThemeEvent(target, theme) {
    target.postMessage({
      message: 'e:themeHandler:switchThemeEvent',
      theme: theme.id,
      colorScheme: theme.originalMode === 'auto' ? theme.colorSchemes?.[theme.mode] : theme.id
    }, '*');
  }
}
