import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { dateUtils } from '@emartech/ui-framework-utils';
import configStore from '../../utils/config-store';

import { ETimeState } from './state';
import { ETimeEvents } from './events';

class ETime extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ETimeState(this);
    this.events = new ETimeEvents(this);
  }

  connectedCallback() {
    this.state.updateFormatsFromConfig(configStore.config);
    configStore.subscribe(this.events.onConfigChange);

    this.render();
  }

  disconnectedCallback() {
    configStore.unsubscribe(this.events.onConfigChange);
  }

  // Attributes
  static get observedAttributes() {
    return ['datetime', 'format'];
  }

  get datetime() {
    return this.state.value;
  }

  set datetime(value) {
    this.state.value = value;
  }

  get format() {
    return this.state.format;
  }

  set format(value) {
    this.state.format = value;
  }

  // Rendering
  render() {
    this.textContent = dateUtils.formatByName(this.state.value, this.state.format, this.state.formats);
  }
}

export default ETime;
