import autoBind from 'auto-bind';

export class EThemeSwitcherEvents {
  constructor(component) {
    autoBind(this);

    this.refs = component.refs;
    this.state = component.state;
    this.utils = component.utils;

    this.dispatchEvent = component.dispatchEvent.bind(component);
  }

  onThemeButtonClick(theme) {
    if (this.state.colorTheme.id === theme.id) { return; }

    this.state.colorTheme = theme;
    this.utils.themeHandler.switchTheme(theme);
    this.dispatchEvent(new CustomEvent('change', { detail: { colorTheme: theme.id } }));

    window.e.utils.trackEvent({
      category: 'themeSwitcher',
      action: 'clickedThemeSwitcherButton',
      label: theme.id
    });
  }

  onOpenerClick() {
    this.refs.dialog.open();
  }
}

export default EThemeSwitcherEvents;
