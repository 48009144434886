import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class ENotificationOverlay extends HTMLCustomElement {
  init() {
    this.addEventListener('appear', this._appear.bind(this));
    this.addEventListener('click', this._click.bind(this));
    this._notification = null;
  }

  _appear(event) {
    event.stopPropagation();
    this._notification = event.detail.component;
    this._notification.addEventListener('close', this._close.bind(this));
    this.classList.add('e-notification-overlay-visible');
  }

  _close() {
    this.classList.remove('e-notification-overlay-visible');
  }

  _click(event) {
    if (event.target === this && this._notification) {
      this._notification.close();
    }
  }
}

export default ENotificationOverlay;
