import JSLogger from '../jslogger';
const logger = new JSLogger('dialoglogger');

const safeExec = fn => (...args) => {
  try {
    fn(...args);
  } catch (error) {
    logger.error('Catched error', error);
  }
};

const openedDialogs = [];

const register = safeExec(dialog => {
  openedDialogs.push(dialog);
  if (openedDialogs.length > 3) {
    logger.warn('Registration leakage');
  }
});

const remove = safeExec(dialog => {
  const index = openedDialogs.indexOf(dialog);
  const closingDialogs = openedDialogs.splice(index);
  if (closingDialogs.length > 1) {
    const dialogIds = closingDialogs.map(dialog => dialog.getAttribute('id')).filter(id => !!id);
    const dialogIdsText = dialogIds.length ? ` (ids: ${dialogIds.join(' ')})` : '';
    logger.warn('Closing multiple dialogs!' + dialogIdsText);
  }
});

export default { register, remove };
