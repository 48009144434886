import { html } from 'uhtml';
import classNames from 'clsx';
export class EAccordionTemplate {
    constructor(component) {
        this.refs = component.refs;
        this.state = component.state;
        this.events = component.events;
    }
    createWrapper() {
        return html.node `<ol class="e-accordion-list__wrapper"></ol>`;
    }
    handleWrapperClassname() {
        this.refs.wrapper.classList.toggle('e-accordion-list__wrapper-borderless', this.state.isBorderless);
    }
    createElement() {
        return html `${this.state.items.map(item => this.createItem(item))}`;
    }
    createItem(item) {
        const itemIndex = this.state.items.indexOf(item);
        const isFirst = itemIndex === 0;
        const isLast = this.state.items.length - 1 === itemIndex;
        const itemClasses = classNames('e-accordion-list__item', {
            'e-accordion-list__item-last': isLast
        });
        const labelContainerClasses = classNames('e-accordion-list__label_container', {
            'e-accordion-list__label_container-disabled': item.disabled,
            'e-accordion-list__label_container-first': isFirst,
            'e-accordion-list__label_container-last': isLast && !this.state.activeItems.has(item.uuid)
        });
        const ariaControls = `content-${item.uuid}`;
        const buttonId = `button-${item.uuid}`;
        return html `
      <li class="${itemClasses}">
        <button
          type="button"
          class="${labelContainerClasses}"
          ?disabled="${item.disabled}"
          aria-expanded="${this.state.activeItems.has(item.uuid)}"
          aria-controls="${ariaControls}"
          @click="${() => this.events.onItemLabelClick(item)}"
          id="${buttonId}"
        >
          ${this.createIndicator(item)}
          ${this.createLabel(item)}
        </button>
        ${this.createContent(item)}
      </li>
    `;
    }
    createIndicator(item) {
        const indicatorIconClasses = classNames('e-accordion-list__indicator_icon', {
            'e-accordion-list__indicator_icon-active': this.state.activeItems.has(item.uuid)
        });
        return html `
      <div class="e-accordion-list__indicator">
        <e-icon icon="e-angle-down" color="inherit" class="${indicatorIconClasses}"></e-icon>
      </div>
    `;
    }
    createLabel(item) {
        return html `<div class="e-accordion-list__label">${item.label}</div>`;
    }
    createContent(item) {
        const ariaLabelledby = `button-${item.uuid}`;
        const contentId = `content-${item.uuid}`;
        if (!this.state.activeItems.has(item.uuid)) {
            return;
        }
        return html `
      <div
        class="e-accordion-list__content"
        role="region"
        aria-labelledby="${ariaLabelledby}"
        id="${contentId}"
      >${item.content}</div>
    `;
    }
}
