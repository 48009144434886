import { html } from 'uhtml';

const addStateClasses = (baseClass, state) => {
  const expanded = state.expanded ? ' is-expanded' : '';
  const editable = state.editable ? ' is-editable' : '';
  const media = state.media ? ' has-media' : '';
  return `${baseClass} is-${state.device} is-${state.type}${expanded}${editable}${media}`;
};

const closeIcon = html`<e-icon icon="close" color="shade"></e-icon>`;

export default (state) => {
  const ellipsis = html`<e-ellipsis content="${state.content}"></e-ellipsis>`;
  const content = html`<div class="e-device-push__headline">${state.headline}</div>
    <div class="e-device-push__headline">${state.subtitle}</div>
    <div class="${addStateClasses('e-device-push__content', state)}">${
  !state.expanded && state.device === 'ios_phone_default' ? ellipsis : state.content
}</div>
    <div class="${addStateClasses('e-device-push__more', state)}">Press for more</div>
  `;

  const container = html.node`<div class="${addStateClasses('e-device-push__container', state)}">${
    state.editable ? '' : content }
  </div>`;

  const media = state => {
    const style = `background-image: url('${state.media}')`;

    return html`
      <div class="${addStateClasses('e-device-push__media', state)}" style=${style}></div>
    `;
  };

  return html`
    <div class="${addStateClasses('e-device-push', state)}">
      <div class="${addStateClasses('e-device-push__notification', state)}">
        <div class="${addStateClasses('e-device-push__header', state)}">
          <img class="${addStateClasses('e-device-push__icon', state)}" src="${state.icon}">
          <span class="${addStateClasses('e-device-push__apptitle', state)}">${state.appTitle}</span>
          <span class="${addStateClasses('e-device-push__timeago', state)}">
            ${state.expanded && state.device === 'ios_phone_default' ? closeIcon : 'now'}
          </span>
        </div>
        ${!state.expanded || (state.media && state.device === 'ios_phone_default') ? media(state) : null}
        ${container}
        ${state.expanded && state.media && state.device !== 'ios_phone_default' ? media(state) : null}
      </div>
    </div>
  `;
};
