import manifest from '../../../../dist/app.js';
import Logger from '../logger/index.js';
import JSLogger from '../jslogger/index.js';
const logger = new JSLogger('CssLogger');

const fakeLogSelector = '.e-fakelog-jR7q8I:after';
const fakeLogMessage = 'fakelog!';

class CSSLogger {
  #logger;
  #logSelectors;
  #style;
  #isLoaded = false;

  constructor(namespace) {
    this.#logger = new Logger(namespace);
    this.#logSelectors = manifest.logSelectors;
  }

  async log() {
    if (this.#logger.isPingdom(window.navigator.userAgent)) { return; }

    if (!this.#logger.isDomainAllowed(window.location.hostname)) {
      logger.warn('logging is not allowed for this domain');
      return;
    }

    this.#listenToContentLoaded();

    const styleWithPromise = this.#logSelectors.map(async selector => {
      const rule = await this.#createRuleForSelector(selector, `${selector} found!`);
      const ruleForNoFlipper = await this.#createRuleForSelector(
        `html:not([class*="FLIPPER-"]) ${selector}`,
        `${selector} on a page with no flippers!`
      );
      return rule + ruleForNoFlipper;
    });

    const fakeLogRule = await this.#createRuleForSelector(fakeLogSelector, fakeLogMessage);

    this.#style = await Promise.all(styleWithPromise);
    this.#style.push(fakeLogRule);

    if (this.#isLoaded) {
      this.#injectStyle();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this.#injectStyle();
      }, false);
    }
  };

  async #createRuleForSelector(selector, message) {
    const data = await this.#logger.logData(message);
    const url = this.#logger.getServiceURL(data);
    return `${selector}{background-image:url("${url}");content:"";}`;
  }

  #listenToContentLoaded() {
    document.addEventListener('DOMContentLoaded', () => {
      this.#isLoaded = true;
    }, false);
  }

  #injectStyle() {
    document.body.insertAdjacentHTML('beforeend', `<style>${this.#style.join('')}</style>`);
  }
}

export default CSSLogger;
