import { dateUtils, convertAttributeToArray } from '@emartech/ui-framework-utils';

const filter = (content, [filterValues, columnDefinitions]) => {
  if (!Object.keys(filterValues).length || !columnDefinitions.length) { return content; }

  Object.keys(filterValues).forEach(contentKey => {
    if (filterValues[contentKey].custom) { return; }

    content = content.filter(row => {
      if (filterValues[contentKey].type === 'daterange') {
        const date = dateUtils.parse(row.raw[contentKey]);
        const start = dateUtils.parse(filterValues[contentKey].value.start.date);
        const end = dateUtils.parse(filterValues[contentKey].value.end.date);

        if (!dateUtils.isValid(start) || !dateUtils.isValid(end)) { return true; }

        return dateUtils.isWithinInterval(date, { start: start, end: end.setDate(end.getDate() + 1) });
      }

      if (filterValues[contentKey].type === 'checkbox' || filterValues[contentKey].type === 'switch') {
        return row.raw[contentKey] === true;
      }

      if (filterValues[contentKey].type === 'select') {
        if (filterValues[contentKey].partialMatch) {
          return row.raw[contentKey].includes(filterValues[contentKey].value);
        }

        if (filterValues[contentKey].contentFormat === 'array') {
          const contentArray = convertAttributeToArray(row.raw[contentKey]).map(item => String(item));
          return contentArray ?
            contentArray.includes(String(filterValues[contentKey].value)) :
            false;
        }

        return row.raw[contentKey] === filterValues[contentKey].value;
      }

      if (filterValues[contentKey].type === 'multiselect') {
        if (filterValues[contentKey].value?.operator) {
          const { operator, value } = filterValues[contentKey].value;

          if (filterValues[contentKey].contentFormat !== 'array') {
            return operator === 'and' ?
              value.every(v => row.raw[contentKey].includes(v)) :
              value.includes(row.raw[contentKey]);
          }

          const contentArray = convertAttributeToArray(row.raw[contentKey]).map(item => item.toString());

          if (contentArray.length === 0) { return false; }

          return operator === 'and' ?
            value.every(filterValue => contentArray.includes(filterValue.toString())) :
            value.some(filterValue => contentArray.includes(filterValue.toString()));
        }

        if (filterValues[contentKey].contentFormat === 'array') {
          const contentArray = convertAttributeToArray(row.raw[contentKey]).map(item => item.toString());

          return contentArray ?
            filterValues[contentKey].value.some(filterValue => contentArray.includes(filterValue.toString())) :
            false;
        }

        return filterValues[contentKey].value.includes(row.raw[contentKey]);
      }
    });
  });

  return content;
};

export default filter;
