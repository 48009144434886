export class EPaginationEvents {
  #state;
  #component;
  constructor(component) {
    this.#state = component.state;
    this.#component = component;

  }

  clickHandler(page, trigger, event) {
    event.preventDefault();

    this.#component.currentPage = page;

    this.#component.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      detail: {
        page: this.#state.currentPage,
        trigger
      }
    }));
  }
}
