import autoBind from 'auto-bind';
import { queryKeyboardFocusableAll } from '@emartech/ui-framework-utils';

class TooltipEvents {
  constructor(coreEvents) {
    autoBind(this);

    this.coreEvents = coreEvents;
    this.component = coreEvents.component;
    this.state = coreEvents.state;
    this.refs = coreEvents.refs;
  }

  onContentChange(mutationList) {
    const hasAriaChanged = mutationList && mutationList.every(mutation => {
      const isAria = mutation.type === 'attributes' && mutation.attributeName?.startsWith('aria-');
      const isRole = mutation.type === 'attributes' && mutation.attributeName === 'role';

      return isAria || isRole;
    });

    if (hasAriaChanged) { return; }

    const focusableElements = queryKeyboardFocusableAll(this.component, { includeHidden: true });

    const hasFocusableElement = focusableElements.some(element => {
      return !element.classList.contains('e-tooltip-content__focus_in_catcher') &&
        !element.classList.contains('e-tooltip-content__focus_out_catcher');
    });

    this.state.isTooltipContentFocusable = hasFocusableElement;
    this.component.createFocusCatchers();
    this.component.updatePopupAriaHidden();
    this.component.updateContentAriaDescription();
    this.component.updateComponentAriaDescription();
  }

  onMouseEnter() {
    this.coreEvents.popup.clearCloseTimeout();
    this.coreEvents.popup.open();
  }

  onMouseLeave() {
    this.coreEvents.popup.closeWithTimeout();
  }

  onFocusIn() {
    this.coreEvents.popup.open();
  }

  onFocusOut(event) {
    if (this._isTooltipContentContains(event.relatedTarget)) { return; }
    if (this.refs.popupContent?.contains(event.relatedTarget)) { return; }

    this.coreEvents.popup.close();

    this.toggleFocusInCatcher(true);
    this.toggleFocusOutCatcher(true);
  }

  onFocusInCatcherFocus(event) {
    const focusCameFromOutside = !this._isTooltipContentContains(event.relatedTarget) &&
      !this.refs.popupContent?.contains(event.relatedTarget);

    this.state.isFocusOnFocusCatcher = true;

    if (focusCameFromOutside) {
      this.focusFirstElement();
    } else if (this.state.isPopupContentFocusable) {
      this.coreEvents.popup.open();
      this.coreEvents.popup.focusLastElement();
    }

    this.toggleFocusInCatcher(false);
  }

  onFocusOutCatcherFocus(event) {
    const focusCameFromInside = this._isTooltipContentContains(event.relatedTarget);

    this.state.isFocusOnFocusCatcher = true;

    if (focusCameFromInside) {
      this.coreEvents.popup.focusFirstElement();
    } else if (this.state.isTooltipContentFocusable) {
      this.focusLastElement();

      this.toggleFocusInCatcher(true);
      this.toggleFocusOutCatcher(false);
    }
  }

  onFocusCatcherFocusOut() {
    this.state.isFocusOnFocusCatcher = false;
  }

  focusFocusInCatcher() {
    this.refs.focusInCatcher.focus();
  }

  focusFocusOutCatcher() {
    this.refs.focusOutCatcher.focus();
  }

  focusFirstElement() {
    let focusableElements = queryKeyboardFocusableAll(this.component, { includeHidden: true });
    focusableElements = focusableElements.filter(element => element !== this.refs.focusInCatcher &&
      element !== this.refs.focusOutCatcher);

    if (!focusableElements.length) { return; }

    const firstFocusableElement = focusableElements[0];
    firstFocusableElement.focus();
  }

  focusLastElement() {
    let focusableElements = queryKeyboardFocusableAll(this.component, { includeHidden: true });
    focusableElements = focusableElements.filter(element => element !== this.refs.focusInCatcher &&
      element !== this.refs.focusOutCatcher);

    if (!focusableElements.length) { return; }

    const lastFocusableElement = focusableElements.pop();
    lastFocusableElement.focus();
  }

  toggleFocusInCatcher(value) {
    this.refs.focusInCatcher.tabIndex = value ? 0 : -1;
  }

  toggleFocusOutCatcher(value) {
    this.refs.focusOutCatcher.tabIndex = value ? 0 : -1;
  }

  _isTooltipContentContains(target) {
    return this.component.contains(target) || this.component.shadowRoot.contains(target);
  }
}

export default TooltipEvents;
