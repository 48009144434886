export class EImageState {
  static defaultType = 'logo';
  static availableSizes = ['small', 'large'];
  static availableTypes = ['logo', 'illustration'];
  #type = EImageState.defaultType;
  #size = '';
  #name = '';

  constructor(component) {
    this.requestRender = component.requestRender;
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    this.#type = value;
    this.requestRender();
  }

  get size() {
    return this.#size;
  }

  set size(value) {
    this.#size = value;
    this.requestRender();
  }

  get name() {
    return this.#name;
  }

  set name(value) {
    this.#name = value;
    this.requestRender();
  }
}

export default EImageState;
