import { theming, convertAttributeToBoolean, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ENotificationEvents } from './events';
import { ENotificationState } from './state';
import { ENotificationTemplate } from './template';

class ENotification extends HTMLCustomElement {
  //#region lifecycle hooks
  init() {
    this.refs = {};
    this.state = new ENotificationState(this);
    this.events = new ENotificationEvents(this);
    this.template = new ENotificationTemplate(this);

    this.refs.wrapper = this.template.createWrapper();

    this.addEventListener('mouseenter', this.events.onMouseEnter);
    this.addEventListener('mouseleave', this.events.onMouseLeave);
    this.addEventListener('focusin', this.events.onFocusIn);
    this.addEventListener('focusout', this.events.onFocusOut);
  }

  connectedCallback() {
    this.insertAdjacentElement('beforeend', this.refs.wrapper);
    this.updateVisibilityFromLocalStorage();
    this.requestRender();
  }

  disconnectedCallback() {
    this._disappear();
  }
  //#endregion

  //#region attributes
  static observedAttributes = ['type', 'icon', 'color', 'key', 'closable', 'autoclose'];

  get type() {
    return this.state.type;
  }

  set type(value) {
    this.reflectStringAttribute('type', value);
    this.state.type = value;
  }

  get icon() {
    return this.state.icon;
  }

  set icon(value) {
    this.reflectStringAttribute('icon', value);
    this.state.icon = value;
  }

  get color() {
    return this.state.color;
  }

  set color(value) {
    if (value === null) {
      this.state.color = '';
      return;
    }

    this.state.color = value;
  }

  get key() {
    return this.state.key;
  }

  set key(value) {
    this.reflectStringAttribute('key', value);
    this.state.key = value;
    this.updateVisibilityFromLocalStorage();
  }

  get closable() {
    return this.state.isClosable;
  }

  set closable(value) {
    this.reflectBooleanAttribute('closable', value);
    this.state.isClosable = convertAttributeToBoolean(value);
  }

  get autoclose() {
    return this.state.isAutoClose;
  }

  set autoclose(value) {
    this.reflectBooleanAttribute('autoclose', value);
    this.state.isAutoClose = convertAttributeToBoolean(value);

    if (this.state.isAutoClose) {
      this.events.startAutoCloseTimeout();
    } else {
      this.events.stopAutoCloseTimeout();
    }
  }
  //#endregion

  //#region public methods
  close() {
    if (!this.state.isClosable) { return; }

    this._close();
  }

  hide() {
    this._disappear();
  }
  //#endregion

  //#region rendering
  render() {
    this.updateColor();

    render(this.refs.wrapper, this.template.createElement());
  }

  updateColor() {
    if (this.state.color === '') {
      this.style.removeProperty('color');
    } else {
      this.style.color = `var(${theming.generateToken(this.state.color, 'token')})`;
    }
  }

  updateVisibilityFromLocalStorage() {
    if (!this.isConnected) { return; }

    if (this._isHiddenInLocalStorage) {
      this._disappear();
    } else {
      this._appear();
    }
  }
  //#endregion

  //#region private methods
  get _isHiddenInLocalStorage() {
    if (!this.state.hasKey) {
      return false;
    }

    return window.localStorage.getItem(this.state.localStorageKey) === 'hidden';
  }

  _storeHiddenInLocalStorage() {
    window.localStorage.setItem(this.state.localStorageKey, 'hidden');
  }

  _close() {
    if (this.state.isClosed) { return; }

    this._disappear();

    if (this.state.hasKey) {
      this._storeHiddenInLocalStorage();
    }

    this.events.dispatchCloseEvent();
  }

  _appear() {
    if (!this.state.isClosed) { return; }

    this.state.isClosed = false;
    this.classList.add('e-notification-visible');
    this.events.dispatchAppearEvent();
  }

  _disappear() {
    if (this.state.isClosed) { return; }

    this.state.isClosed = true;
    this.classList.remove('e-notification-visible');
    this.events.dispatchDisappearEvent();
  }
  //#endregion
}

export default ENotification;
