import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { popupUtility } from '../../utils/popup';
import assetHandler from '../../utils/asset-handler';
import JSLogger from '../../../js/utils/jslogger/index.js';
import { EEmojipickerState } from './state';
import { EEmojipickerEvents } from './events';
import { EEmojipickerTemplate } from './template';

const logger = new JSLogger('decommission');

class EEmojipicker extends HTMLCustomElement {
  init() {
    this.refs = {};
    this.utils = {
      popup: null,
      mutationObserver: null
    };

    this.state = new EEmojipickerState(this);
    this.events = new EEmojipickerEvents(this);
    this.template = new EEmojipickerTemplate(this);

    this.utils.emojiCache = assetHandler.getAsset('emoji')
      .then(asset => this.state.collectEmojis(asset))
      .then(emojis => this.state.emojis = emojis);

    this.utils.mutationObserver = new MutationObserver(this.events.setRefs);

    this.refs.wrapper = this.template.createWrapper();
    this.refs.tab = [];
    this.refs.emojis = [];
  }

  connectedCallback() {
    this.utils.mutationObserver.observe(this, { childList: true, subtree: true });

    this.requestRender().then(() => {
      this.events.setRefs();
      this._createPopup();
      this.insertAdjacentElement('beforeEnd', this.refs.wrapper);
      logger.log('EEmojipicker');
    });
  }

  get activeTabIndex() {
    return this.state.activeTab;
  }

  set activeTabIndex(categoryIndex) {
    this.state.changeCategory(categoryIndex);
  }

  disconnectedCallback() {
    this.close();
  }

  open() {
    this.events.open();
  }

  close() {
    this.events.close();
  }

  _createPopup() {
    const handler = this.refs.handler || this.refs.customHandler;
    if (!handler || !this.refs.picker) { return; }

    this.utils.popup = popupUtility.createPopup(handler, this.refs.picker, {
      onAfterOpen: this.events.onOpen,
      onAfterClose: this.events.onClose,
      elementToFocusOnClose: handler
    });
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }
}

export default EEmojipicker;
