import { html } from 'uhtml';
import classNames from 'clsx';

import { getIconForType } from './utils';

export class ENotificationTemplate {
  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createWrapper() {
    return html.node`
      <div class="e-notification__wrapper"></div>
    `;
  }

  createElement() {
    const iconClassList = classNames('e-notification__icon', {
      [`e-notification__icon-${this.state.type}`]: this.state.type && !this.state.color
    });

    return html`
      ${this.createCloseButton()}
      <div class=${iconClassList}>
        ${this.state.isLoading ? this.createSpinner() : this.createIcon()}
      </div>
      ${this.createAutoCloseProgressBar()}
    `;
  }

  createIcon() {
    const icon = this.state.icon || getIconForType(this.state.type);

    return html`
      <e-icon icon="${icon}" color="inherit"></e-icon>
    `;
  }

  createSpinner() {
    return html`
      <e-spinner></e-spinner>
    `;
  }

  createCloseButton() {
    if (!this.state.isClosable) { return; }

    return html`
      <button
        type="button"
        class="e-btn e-btn-borderless e-btn-onlyicon e-notification__close"
        @click=${this.events.onCloseClick}
      >
        <e-icon icon="close"></e-icon>
      </button>
    `;
  }

  createAutoCloseProgressBar() {
    if (!this.state.isAutoCloseRunning) { return; }

    return html`
      <div class="e-notification__progress"></div>
    `;
  }
}
