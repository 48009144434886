import uuid from '../../../utils/uuid';
export class ERuleBlueprintState {
    constructor() {
        this._uuid = uuid();
    }
    get name() {
        return this._name;
    }
    set name(value) {
        this._name = value;
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
    }
    get uuid() {
        return this._uuid;
    }
    get validation() {
        return this._validation;
    }
    set validation(value) {
        this._validation = value;
    }
}
