import { render } from 'uhtml';
import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { EventEmitter } from '@emartech/ui-framework-utils';
import configStore from '../../../utils/config-store';
import translator from '../../../utils/translator';
import TimeInput from './time-input';
import State from './state';
import Template from './template';

class ETimepicker extends HTMLCustomElement {
  init() {
    this._timeinput = new TimeInput();
    this._state = new State(this._render.bind(this));
    this._emitter = new EventEmitter();
    this._refs = {
      wrapper: document.createElement('div'),
      hoursElement: null,
      minutesElement: null,
      ampmElement: null
    };
    this._refs.wrapper.className = 'e-timepicker__wrapper';
    this._template = new Template(this._emitter, this._state, this._refs);

    this._emitter.on('minutesChange', this._onMinutesChange.bind(this));
    this._emitter.on('hoursChange', this._onHoursChange.bind(this));
    this._emitter.on('ampmChange', this._onAmpmChange.bind(this));

    this._applyConfig = this._applyConfig.bind(this);
    this._applyConfig();
  }

  connectedCallback() {
    super._cleanupContainer('.e-timepicker__wrapper');
    this._applyConfig();
    configStore.subscribe(this._applyConfig);
    this.appendChild(this._refs.wrapper);
    this._render();
  }

  disconnectedCallback() {
    configStore.unsubscribe(this._applyConfig);
  }

  static get observedAttributes() {
    return ['value', 'error', 'empty-enabled', 'disabled'];
  }

  set value(value) {
    const [hoursFromValue, minutesFromValue] = value.split(':');
    const validHours = this._timeinput.validateValue(hoursFromValue, 23);
    const validMinutes = this._timeinput.validateValue(minutesFromValue, 59);

    this._state.setState({
      hours: this._state.state.isAmpm ? this._timeinput.convertTo12(validHours).value : validHours,
      minutes: validMinutes,
      partOfDay: this._timeinput.convertTo12(validHours).partOfDay
    });
  }

  get value() {
    if (this._state.state.emptyEnabled && this._state.state.hours === '') {
      return '';
    }

    const hours = this._state.state.hours || this._state.state.defaultHours;

    const convertedHours = this._state.state.isAmpm ?
      this._timeinput.convertTo24(hours, this._state.state.partOfDay) : hours;
    const minutes = this._state.state.minutes || this._state.state.defaultMinutes;

    return `${convertedHours}:${minutes}`;
  }

  set error(value) {
    this._state.setState({ error: super._convertAttributeToBoolean(value) });
  }

  get error() {
    return this._state.state.error;
  }

  set emptyEnabled(value) {
    this._state.setState({ emptyEnabled: super._convertAttributeToBoolean(value) });
  }

  get disabled() {
    return this._state.state.disabled;
  }

  set disabled(value) {
    this._state.setState({ disabled: super._convertAttributeToBoolean(value) });
  }

  _render() {
    if (!this.isConnected) { return; }

    render(this, this._template.createElement());
  }

  _convertHours(isAmpm, hours, partOfDay) {
    return isAmpm ?
      this._timeinput.convertTo12(hours, this._state.state.isAmpm, partOfDay).value :
      this._timeinput.convertTo24(hours, partOfDay);
  }

  _applyConfig() {
    const isAmpm = configStore.config.timeFormat.indexOf('a') !== -1;
    const hours = this._state.state.hours;
    const partOfDay = this._state.state.partOfDay;

    if (hours !== '') {
      this._state.setState({
        hours: this._convertHours(isAmpm, hours, partOfDay),
        partOfDay: this._timeinput.convertTo12(hours, this._state.state.isAmpm, partOfDay).partOfDay
      }, false);
    }

    this._state.setState({
      isAmpm,
      defaultHours: isAmpm ? '12' : '00',
      minHours: isAmpm ? 1 : this._state.defaultState.minHours,
      maxHours: isAmpm ? 12 : this._state.defaultState.maxHours
    });

    this._setTranslations();
  }

  _onHoursChange({ value, characterCounter, inProgress, minutesElement }) {
    const hours = this._state.state.isAmpm ? this._timeinput.convertTo24(value, this._state.state.partOfDay) : value;
    const partOfDay = this._timeinput.convertTo12(hours).partOfDay;

    if (this._state.state.emptyEnabled && minutesElement.value === '') {
      this._state.setState({ minutes: '00' }, false);
    }

    if (this._state.state.emptyEnabled && value === '') {
      this._state.setState({ minutes: '' }, false);
    }

    this._state.setState({ hours: value, partOfDay });

    this._onTimeChange({ characterCounter, inProgress });
  }

  _onMinutesChange({ value, characterCounter, inProgress, hoursElement }) {
    if (this._state.state.emptyEnabled && hoursElement.value === '') {
      this._state.setState({ hours: this._state.state.isAmpm ? '12' : '00' }, false);
    }

    if (this._state.state.emptyEnabled && value === '') {
      this._state.setState({ hours: '' }, false);
    }

    this._state.setState({ minutes: value });

    this._onTimeChange({ characterCounter, inProgress });
  }

  _onTimeChange({ characterCounter, inProgress }) {
    if (characterCounter === 2 || !inProgress) {
      this._dispatchUpdateEvent();
    }
  };

  _onAmpmChange(partOfDay) {
    this._state.setState({ partOfDay });
    this._dispatchUpdateEvent();
  }

  _dispatchUpdateEvent() {
    const format12 = this._timeinput.convertTo12(this._state.state.hours);

    this.dispatchEvent(new CustomEvent('update', {
      'detail': {
        'time': {
          '24h': this.value,
          '12h': format12.value + ':' + this._state.state.minutes + ' ' + this._state.state.partOfDay
        }
      }
    }));
  }

  _setTranslations() {
    this._state.setState({
      translations: {
        hours: translator.translate('components.timepicker.hours'),
        minutes: translator.translate('components.timepicker.minutes')
      }
    });
  }
}

export default ETimepicker;
