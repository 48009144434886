import State from '../../../utils/state';

class DatetimeState extends State {
  get defaultState() {
    return {
      value: { start: '', end: '' },
      type: 'date',
      min: undefined,
      max: undefined,
      presets: [],
      todayHidden: false,
      resetTime: { start: undefined, end: undefined },
      manualApply: false,
      startDisabled: false,
      endDisabled: false,
      isPresetsDisabled: false
    };
  }

  getLabelText(panelType) {
    return `components.datetime.panel.inputLabel.${this.state.type}.${panelType}`;
  }
}

export default DatetimeState;
