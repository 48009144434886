import { messages, types } from './constants.js';
import sendOpenMessage from './send-open-message.js';
import openDialog from './open-dialog.js';

export default {
  subscribe: (global = window) => {
    global.addEventListener('message', event => {
      if (event.data.message === messages.OPEN) {
        openDialog(global, event.data.data.options, event.source, event.data.data.type);
      }
    });
  },

  openConsequential: (options, global = window, top = window.top) => {
    sendOpenMessage(options, types.CONSEQUENTIAL, global, top);
  },

  openDestructive: (options, global = window, top = window.top) => {
    sendOpenMessage(options, types.DESTRUCTIVE, global, top);
  }
};
