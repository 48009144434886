import { convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import EDatagridFilter from '../datagrid-filter';
import JSLogger from '../../../utils/jslogger';
const logger = new JSLogger('datagrid-filter-multiselect');

class EDatagridFilterMultiselect extends EDatagridFilter {
  init() {
    super.init();

    this._state.type = 'multiselect';
    this._state.options = [];
    this._state.defaultValue = [];
    this._state.placeholder = '';
    this._state.value = undefined;
    this._state.labelMergeLimit = undefined;
    this._state.hasOperator = false;
    this._state.selectedOperator = null;
  }

  static get observedAttributes() {
    return [
      'options', 'default-value', 'selected-options', 'placeholder', 'value', 'content-format', 'label-merge-limit',
      'has-operator', 'selected-operator'
    ].concat(super.observedAttributes);
  }

  set options(value) {
    const options = (typeof value === 'string' ? JSON.parse(value) : value) || [];

    this._state.options = options.map(option => {
      return typeof option === 'object' ? option : { value: option, label: option };
    });

    this._dispatchEvent();
  }

  set selectedOptions(value) {
    logger.log('selectedOptions found!');
    this._state.defaultValue = (typeof value === 'string' ? JSON.parse(value) : value) || [];
    this._dispatchEvent();
  }

  set defaultValue(value) {
    this._state.defaultValue = (typeof value === 'string' ? JSON.parse(value) : value) || [];
    this._dispatchEvent();
  }

  set placeholder(value) {
    this._state.placeholder = value;
    this._dispatchEvent();
  }

  set value(value) {
    this._state.value = (typeof value === 'string' ? JSON.parse(value) : value) || [];
    this._dispatchEvent();
  }

  set contentFormat(value) {
    this._state.contentFormat = value;
    this._dispatchEvent();
  }

  set labelMergeLimit(value) {
    this._state.labelMergeLimit = value;
    this._dispatchEvent();
  }

  set hasOperator(value) {
    this._state.hasOperator = convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set selectedOperator(value) {
    value = value.trim();
    if (value !== 'or' && value !== 'and') { return; }

    this._state.selectedOperator = value;
    this._dispatchEvent();
  }
}

export default EDatagridFilterMultiselect;
