import classNames from 'clsx';
import { html } from 'uhtml';

export class ECheckboxTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      <div class="e-checkbox__wrapper">
        ${this.createInput()}
        ${this.createCheckbox()}
        ${this.createLabel()}
      </div>
    `;
  }

  createInput() {
    return html`
      <input
        ref=${node => this.refs.input = node}
        class="e-checkbox e-checkbox-onlycheckbox"
        type="checkbox"
        id=${this.state.id}
        name=${this.state.name}
        value=${this.state.value}
        ?disabled=${this.state.isDisabled}
        .checked=${this.state.isChecked}
        aria-label=${this.state.srLabel}
      />
    `;
  }

  createCheckbox() {
    const className = classNames('e-checkbox__box', {
      'e-checkbox__box-checked': this.state.isChecked,
      'e-checkbox__box-indeterminate': this.state.isIndeterminate
    });

    return html`<div class=${className} aria-hidden="true"></div>`;
  }

  createLabel() {
    if (!this.state.label && !this.state.helper) { return; }

    return html`
      <label class="e-checkbox__label" for=${this.state.id}>
        ${this.createLabelText()}
        ${this.createHelper()}
      </label>
    `;
  }

  createLabelText() {
    if (!this.state.label) { return; }

    return html`
      ${this.state.label}
    `;
  }

  createHelper() {
    if (!this.state.helper) { return; }

    return html`<e-tooltip content="${this.state.helper}" type="helper"></e-tooltip>`;
  }
}

export default ECheckboxTemplate;
