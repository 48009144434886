window.e = window.e || {};
window.e.init = true;
window.SUITE = window.SUITE || {};
window.SUITE.integration = window.SUITE.integration || {};

import {
  init as windowUtilsInit, windowUtils, uiLog, scriptInjector, themeHandler, setDocumentLanguage, defaultConfig
} from './utils/window.js';
windowUtilsInit();

window.e.utils = windowUtils;
window.e.defaultConfig = defaultConfig;

import ERing from './components/ring';
import ECircle from './components/circle';
import EEmoji from './components/emoji';
import EVerticalnavItem from './components/verticalnav/verticalnav-item';
import EVerticalnavMenu from './components/verticalnav/verticalnav-menu';
import EFunnelline from './components/funnelline';
import ETranslation from './components/translation';
import ETime from './components/time';
import ENumeric from './components/numeric';
import EDuration from './components/duration';
import EBubble from '../components/bubble';
import ECalendar from '../components/calendar';
import EDatelocale from './components/datelocale';
import EDevice from './components/device/device';
import EDeviceContent from './components/device/device-content';
import EDialog from './components/dialog/dialog';
import EDialoghandler from './components/dialoghandler';
import EDropdown from './components/dropdown';
import EEmojipicker from './components/emojipicker';
import EEmojiPicker from './components/emoji-picker';
import EInplaceeditor from './components/inplaceeditor';
import EIcon from './components/icon';
import EIframeLoader from './components/iframe/iframe-loader';
import ENumberinput from './components/numberinput';
import ESliderBubble from './components/slider/slider-bubble';
import ESlider from './components/slider/slider';
import ESpinner from './components/spinner';
import ETooltip from './components/tooltip/tooltip';
import ETimepickerInput from './components/timepicker/timepicker-input';
import ETimepickerAmpm from './components/timepicker/timepicker-ampm';
import ETimepicker from './components/timepicker/timepicker';
import EUpload from './components/upload';
import EValidator from './components/validator';
import EDevicePush from './components/device/device-push';
import EEllipsis from './components/ellipsis';
import ENotificationOverlay from './components/notification/notification-overlay';
import ENotification from './components/notification/notification';
import EDatagrid from './components/datagrid/datagrid';
import { EDatagridCard } from './components/datagrid/datagrid-card/index.js';
import EDatagridColumnItem from './components/datagrid/datagrid-column-item/index.js';
import EDatagridColumn from './components/datagrid/datagrid-column';
import EDatagridColumnNumeric from './components/datagrid/datagrid-column-numeric';
import EDatagridColumnTime from './components/datagrid/datagrid-column-time';
import EDatagridColumnPriority from './components/datagrid/datagrid-column-priority';
import EDatagridColumnSwitch from './components/datagrid/datagrid-column-switch';
import EDatagridColumnList from './components/datagrid/datagrid-column-list';
import EDatagridAction from './components/datagrid/datagrid-action';
import EDatagridFilterSelect from './components/datagrid/datagrid-filter-select';
import EDatagridFilterMultiselect from './components/datagrid/datagrid-filter-multiselect';
import EDatagridFilterCheckbox from './components/datagrid/datagrid-filter-checkbox';
import EDatagridFilterSwitch from './components/datagrid/datagrid-filter-switch';
import EDatagridFilterDaterange from './components/datagrid/datagrid-filter-daterange';
import EDatagridItemAction from './components/datagrid/datagrid-item-action';
import EDatagridBulkAction from './components/datagrid/datagrid-bulk-action';
import EDatagridBulkGroup from './components/datagrid/datagrid-bulk-group';
import EDatagridBulkToggle from './components/datagrid/datagrid-bulk-toggle';
import EDatagridEmptyState from './components/datagrid/datagrid-empty-state';
import EDatagridErrorState from './components/datagrid/datagrid-error-state';
import EColorpicker from './components/color/colorpicker';
import EColorinput from './components/color/colorinput';
import EColorPicker from './components/color/color-picker';
import EDialogAction from './components/dialog/dialog-action';
import EMultiselect from './components/multiselect';
import ESelect from './components/select/select';
import ESelectOption from './components/select/select-option';
import ESelectGroup from './components/select/select-group';
import EActionlist from '../components/actionlist/actionlist';
import EActionlistItem from '../components/actionlist/actionlist-item';
import EActionlistGroup from '../components/actionlist/actionlist-group';
import ECriteria from './components/criteria/criteria';
import ECriteriaTemplate from './components/criteria/criteria-template';
import ECriteriaCondition from './components/criteria/criteria-condition';
import EPopoverHandler from './components/_deprecated/popover-handler';
import ELayoutBackButton from './components/layout-back-button';
import EDatetime from './components/datetime/datetime';
import EDatetimePanel from './components/datetime/datetime-panel';
import EDatetimePicker from './components/datetime/datetime-picker';
import EDatetimeCalendar from './components/datetime/datetime-calendar';
import EDatetimePreset from './components/datetime/datetime-preset';
import EChartLoader from './components/chart-loader';
import ETruncate from './components/truncate';
import ESuggestInput from './components/suggest-input/suggest-input';
import ESuggestInputOption from './components/suggest-input/suggest-input-option';
import ESkeleton from './components/skeleton';
import ECheckbox from '../components/checkbox';
import ESwitch from './components/switch';
import ERadio from './components/radio';
import { ERuleBlueprint } from './components/rule/rule-blueprint';
import { ERuleRelation } from './components/rule/rule-relation';
import { ERuleBuilder } from './components/rule/rule-builder';
import { EConfetti } from './components/confetti';
import { ETabBar } from './components/tab/tab-bar';
import { ETab } from './components/tab/tab';
import { EPassword } from './components/password';
import { EStepBar } from '../components/step/step-bar';
import { EStep } from '../components/step/step';
import { EAccordionItem } from '../components/accordion/accordion-item';
import { EAccordion } from '../components/accordion/accordion';
import { EIllustratedMessage } from './components/illustrated-message';
import { EFormatter } from './components/formatter';
import { EValidation } from './components/validation';
import { EImage } from './components/image';
import EThemeSwitcher from './components/theme-switcher';
import { ERulelistGroup } from '../components/rulelist/rulelist-group';
import { ERulelistItem } from '../components/rulelist/rulelist-item';
import { ESeparator } from '../components/separator';
import { EPagination } from '../components/pagination/index.js';
import { ECard } from '../components/card/index.js';

window.customElements.define('e-ring', ERing);
window.customElements.define('e-circle', ECircle);
window.customElements.define('e-emoji', EEmoji);
window.customElements.define('e-verticalnav-item', EVerticalnavItem);
window.customElements.define('e-verticalnav-menu', EVerticalnavMenu);
window.customElements.define('e-funnelline', EFunnelline);
window.customElements.define('e-translation', ETranslation);
window.customElements.define('e-time', ETime);
window.customElements.define('e-numeric', ENumeric);
window.customElements.define('e-duration', EDuration);
window.customElements.define('e-bubble', EBubble);
window.customElements.define('e-calendar', ECalendar);
window.customElements.define('e-datelocale', EDatelocale);
window.customElements.define('e-device', EDevice);
window.customElements.define('e-device-content', EDeviceContent);
window.customElements.define('e-dialog', EDialog);
window.customElements.define('e-dialoghandler', EDialoghandler);
window.customElements.define('e-dropdown', EDropdown);
window.customElements.define('e-emojipicker', EEmojipicker);
window.customElements.define('e-emoji-picker', EEmojiPicker);
window.customElements.define('e-inplaceeditor', EInplaceeditor);
window.customElements.define('e-icon', EIcon);
window.customElements.define('e-iframe-loader', EIframeLoader);
window.customElements.define('e-numberinput', ENumberinput);
window.customElements.define('e-slider-bubble', ESliderBubble);
window.customElements.define('e-slider', ESlider);
window.customElements.define('e-spinner', ESpinner);
window.customElements.define('e-tooltip', ETooltip);
window.customElements.define('e-timepicker-input', ETimepickerInput);
window.customElements.define('e-timepicker-ampm', ETimepickerAmpm);
window.customElements.define('e-timepicker', ETimepicker);
window.customElements.define('e-upload', EUpload);
window.customElements.define('e-validator', EValidator);
window.customElements.define('e-device-push', EDevicePush);
window.customElements.define('e-ellipsis', EEllipsis);
window.customElements.define('e-notification-overlay', ENotificationOverlay);
window.customElements.define('e-notification', ENotification);
window.customElements.define('e-datagrid', EDatagrid);
window.customElements.define('e-datagrid-card', EDatagridCard);
window.customElements.define('e-datagrid-column-item', EDatagridColumnItem);
window.customElements.define('e-datagrid-column', EDatagridColumn);
window.customElements.define('e-datagrid-column-numeric', EDatagridColumnNumeric);
window.customElements.define('e-datagrid-column-time', EDatagridColumnTime);
window.customElements.define('e-datagrid-column-priority', EDatagridColumnPriority);
window.customElements.define('e-datagrid-column-switch', EDatagridColumnSwitch);
window.customElements.define('e-datagrid-column-list', EDatagridColumnList);
window.customElements.define('e-datagrid-action', EDatagridAction);
window.customElements.define('e-datagrid-filter-select', EDatagridFilterSelect);
window.customElements.define('e-datagrid-filter-multiselect', EDatagridFilterMultiselect);
window.customElements.define('e-datagrid-filter-checkbox', EDatagridFilterCheckbox);
window.customElements.define('e-datagrid-filter-switch', EDatagridFilterSwitch);
window.customElements.define('e-datagrid-filter-daterange', EDatagridFilterDaterange);
window.customElements.define('e-datagrid-item-action', EDatagridItemAction);
window.customElements.define('e-datagrid-bulk-action', EDatagridBulkAction);
window.customElements.define('e-datagrid-bulk-group', EDatagridBulkGroup);
window.customElements.define('e-datagrid-bulk-toggle', EDatagridBulkToggle);
window.customElements.define('e-datagrid-empty-state', EDatagridEmptyState);
window.customElements.define('e-datagrid-error-state', EDatagridErrorState);
window.customElements.define('e-pagination', EPagination);
window.customElements.define('e-colorpicker', EColorpicker);
window.customElements.define('e-colorinput', EColorinput);
window.customElements.define('e-color-picker', EColorPicker);
window.customElements.define('e-dialog-action', EDialogAction);
window.customElements.define('e-multiselect', EMultiselect);
window.customElements.define('e-select', ESelect);
window.customElements.define('e-select-option', ESelectOption);
window.customElements.define('e-select-group', ESelectGroup);
window.customElements.define('e-actionlist', EActionlist);
window.customElements.define('e-actionlist-item', EActionlistItem);
window.customElements.define('e-actionlist-group', EActionlistGroup);
window.customElements.define('e-criteria', ECriteria);
window.customElements.define('e-criteria-template', ECriteriaTemplate);
window.customElements.define('e-criteria-condition', ECriteriaCondition);
window.customElements.define('e-popover-handler', EPopoverHandler);
window.customElements.define('e-layout-back-button', ELayoutBackButton);
window.customElements.define('e-datetime', EDatetime);
window.customElements.define('e-datetime-panel', EDatetimePanel);
window.customElements.define('e-datetime-picker', EDatetimePicker);
window.customElements.define('e-datetime-calendar', EDatetimeCalendar);
window.customElements.define('e-datetime-preset', EDatetimePreset);
window.customElements.define('e-chart-loader', EChartLoader);
window.customElements.define('e-truncate', ETruncate);
window.customElements.define('e-suggest-input', ESuggestInput);
window.customElements.define('e-suggest-input-option', ESuggestInputOption);
window.customElements.define('e-skeleton', ESkeleton);
window.customElements.define('e-checkbox', ECheckbox);
window.customElements.define('e-switch', ESwitch);
window.customElements.define('e-radio', ERadio);
window.customElements.define('e-rule-blueprint', ERuleBlueprint);
window.customElements.define('e-rule-relation', ERuleRelation);
window.customElements.define('e-rule-builder', ERuleBuilder);
window.customElements.define('e-confetti', EConfetti);
window.customElements.define('e-tab-bar', ETabBar);
window.customElements.define('e-tab', ETab);
window.customElements.define('e-password', EPassword);
window.customElements.define('e-step-bar', EStepBar);
window.customElements.define('e-step', EStep);
window.customElements.define('e-accordion-item', EAccordionItem);
window.customElements.define('e-accordion', EAccordion);
window.customElements.define('e-illustrated-message', EIllustratedMessage);
window.customElements.define('e-formatter', EFormatter);
window.customElements.define('e-validation', EValidation);
window.customElements.define('e-image', EImage);
window.customElements.define('e-theme-switcher', EThemeSwitcher);
window.customElements.define('e-rulelist-group', ERulelistGroup);
window.customElements.define('e-rulelist-item', ERulelistItem);
window.customElements.define('e-separator', ESeparator);
window.customElements.define('e-card', ECard);

// <-- generate-component-placeholer -->
uiLog.log(); // csslog.js

scriptInjector.inject();

window.e.themeHandler = themeHandler;
themeHandler.init();

setDocumentLanguage();

import JSLogger from './utils/jslogger/index.js';
const logger = new JSLogger('app');
logger.log('init');
