const getFeaturesService = (configStore) => () => {
  return configStore.getLoadedConfig().then(({ features }) => {
    return {
      isOn: feature => Boolean(features?.includes(feature)),
      isOff: feature => !features?.includes(feature)
    };
  });
};

export default getFeaturesService;
