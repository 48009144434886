import TrackerClient from './client.js';
import TrackerService from './service.js';

const client = new TrackerClient();
const service = new TrackerService();

export default {
  trackEvent(details, top) {
    client.trackEvent(details, top);
  },

  init() {
    service.init();
  }
};
