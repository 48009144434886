import autoBind from 'auto-bind';
import JSLogger from '../../utils/jslogger';
const logger = new JSLogger('password');
export class EPasswordEvents {
    constructor(component) {
        autoBind(this);
        this.refs = component.refs;
        this.utils = component.utils;
        this.state = component.state;
        this._dispatchChangeEvent = component._dispatchChangeEvent.bind(component);
        this._dispatchInputEvent = component._dispatchInputEvent.bind(component);
        this._dispatchButtonEvent = component._dispatchButtonEvent.bind(component);
        this._dispatchCopyButtonEvent = component._dispatchCopyButtonEvent.bind(component);
    }
    onButtonClick() {
        this.state.isPasswordRevealed = !this.state.isPasswordRevealed;
        this._dispatchButtonEvent(this.state.isPasswordRevealed);
    }
    onCopyButtonClick() {
        this.utils.copyPopup.open();
        navigator.clipboard.writeText(this.state.inputValue).then(this._dispatchCopyButtonEvent, (e) => logger.warn('copy not worked', e));
    }
    onPasswordChange(event) {
        event.stopPropagation();
        this.state.inputValue = this.refs.input.value;
        this._dispatchChangeEvent();
    }
    onPasswordInput(event) {
        event.stopPropagation();
        this.state.inputValue = this.refs.input.value;
        this._dispatchInputEvent();
    }
}
