import autoBind from 'auto-bind';

export class ECheckboxEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
    this.refs = component.refs;
  }

  onClick(event) {
    if (this.state.isDisabled) {
      event.stopImmediatePropagation();
      return;
    }

    if (event.target !== this.refs.input) {
      event.stopImmediatePropagation();
      event.preventDefault();

      this.refs.input.click();
    }
  }

  onChange(event) {
    if (this.state.isDisabled) {
      event.stopImmediatePropagation();
      return;
    }

    this.state.isChecked = this.refs.input.checked;
  }
}

export default ECheckboxEvents;
