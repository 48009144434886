import translator from '../../../../utils/translator';
export const defaultRelations = [
    {
        uuid: '',
        label: translator.translate('components.ruleBuilder.relation.and'),
        description: translator.translate('components.ruleBuilder.relation.matchAll'),
        value: 'and',
        icon: 'e-logic-and'
    },
    {
        uuid: '',
        label: translator.translate('components.ruleBuilder.relation.or'),
        description: translator.translate('components.ruleBuilder.relation.matchAny'),
        value: 'or',
        icon: 'e-logic-or'
    }
];
