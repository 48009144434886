import { html } from 'uhtml';
import classNames from 'clsx';

class Template {
  constructor(node, toggleEvent) {
    this._element = node;
    this._element.className = 'e-verticalnav__toggle';
    this._element.addEventListener('click', toggleEvent);
  }

  get element() {
    return this._element;
  }

  render(state) {
    this._element.className = this._calculateClassName(state);

    return html`
      <button type="button" class="e-btn e-btn-borderless e-btn-onlyicon">
        <e-icon icon="e-move-right"></e-icon>
      </button>
    `;
  }

  _calculateClassName(state) {
    return classNames('e-verticalnav__toggle', {
      'e-hidden': state.noToggle,
      'e-verticalnav__toggle-active': state.extended
    });
  }
}

export default Template;
