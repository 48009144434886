import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';
import flipper from '../../../utils/flipper';

class ESelectOption extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      value: '',
      content: '',
      type: 'option',
      selected: false,
      disabled: false
    };

    this._observeContent();
  }

  connectedCallback() {
    this._updateContent();
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('option.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['value', 'selected', 'disabled'];
  }

  set value(value) {
    if (flipper.isOn('ui_select_option_reflect_attributes')) {
      if (value === this._state.value) { return; }

      this._state.value = value;

      if (typeof value === 'string') {
        this.reflectStringAttribute('value', this._state.value);
      }

      this._dispatchEvent();
    } else {
      this._state.value = value;
      this._dispatchEvent();
    }
  }

  get value() {
    return this._state.value;
  }

  set selected(value) {
    if (flipper.isOn('ui_select_option_reflect_attributes')) {
      const convertedValue = super._convertAttributeToBoolean(value);

      if (convertedValue === this._state.selected) { return; }

      this._state.selected = convertedValue;
      this.reflectBooleanAttribute('selected', this._state.selected);
      this._dispatchEvent();
    } else {
      this._state.selected = super._convertAttributeToBoolean(value);
      this._dispatchEvent();
    }
  }

  get selected() {
    return this._state.selected;
  }

  set disabled(value) {
    if (flipper.isOn('ui_select_option_reflect_attributes')) {
      const convertedValue = super._convertAttributeToBoolean(value);

      if (convertedValue === this._state.disabled) { return; }

      this._state.disabled = convertedValue;
      this.reflectBooleanAttribute('disabled', this._state.disabled);
      this._dispatchEvent();
    } else {
      this._state.disabled = super._convertAttributeToBoolean(value);
      this._dispatchEvent();
    }
  }

  _updateContent() {
    this._state.content = this.innerHTML;
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._getValue()) {
      return;
    }

    this.dispatchEvent(new CustomEvent('option.update', {
      bubbles: true,
      detail: Object.assign({}, this._state, { value: this._getValue() })
    }));
  }

  _getValue() {
    return this._state.value || this.textContent;
  }

  _observeContent() {
    if (!window.MutationObserver) {
      return;
    }
    const options = { childList: true, subtree: true, characterData: true };

    const observer = new MutationObserver(() => {
      this._updateContent();
      this._dispatchEvent();
    });

    observer.observe(this, options);
  }
}

export default ESelectOption;
