import classNames from 'clsx';
import { html } from 'uhtml';

export class EMultiselectFooterTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    if (!this.state.options.length || this.state.isSearching) { return html``; }

    return html`
      ${this.createContent()}
      <div class="e-tagselector_popup__toolbar e-popup-panel__actions">
        ${this.createActions()}
      </div>
    `;
  }

  createContent() {
    if (!this.state.selectionMax) { return; }

    const className = classNames('e-popup-panel__footer_content', {
      'e-popup-panel__footer_content-error': this.state.hasSelectionMaxError
    });

    return html`
      <div class="${className}" aria-live="polite">
        ${this.createContentSelectionText()}
        ${this.createContentSelectionMaxError()}
      </div>`;
  }

  createContentSelectionText() {
    if (this.state.hasSelectionMaxError) { return; }

    const selectedOptions = this.state.selectedOptions.length;
    const selectionMax = this.state.selectionMax;

    return html`
      <e-translation
        key="components.multiselect.popup.selectionMax"
        .value="${[`${selectedOptions} / ${selectionMax}`]}"
      ></e-translation>`;
  }

  createContentSelectionMaxError() {
    if (!this.state.hasSelectionMaxError) { return; }

    const selectedOptions = this.state.selectedOptions.length;
    const selectionMax = this.state.selectionMax;

    return html`
      <e-translation
        key="components.multiselect.popup.selectionMaxError"
        .value="${[`${selectedOptions} / ${selectionMax}`]}"
      ></e-translation>`;
  }

  createActions() {
    if (this.state.selectionMax && !this.state.selectedOptions.length) { return; }

    const selectMethodKey = this.state.isAllOptionsSelected || !!this.state.selectionMax ? 'deselectAll' : 'selectAll';
    const selectAllKey = `components.multiselect.popup.${selectMethodKey}`;

    const classes = classNames('e-btn e-btn-borderless', { 'e-btn-disabled': !this.state.isEditable });

    return html`
      <button
        type="button"
        class="${classes}"
        ref=${node => this.refs.toggleButton = node}
        ?disabled="${!this.state.isEditable}"
        @click=${this.events.onToggleAllOptions}
      ><e-translation key=${selectAllKey}></e-translation></button>
    `;
  }
}

export default EMultiselectFooterTemplate;
