import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { ERuleRelationState } from './state';
export class ERuleRelation extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = { component: this };
        this.state = new ERuleRelationState();
    }
    connectedCallback() {
        this._dispatchUpdateEvent();
    }
    disconnectedCallback() {
        this._dispatchDeleteEvent();
    }
    // Attributes
    static get observedAttributes() {
        return ['description', 'label', 'value', 'icon'];
    }
    set description(value) {
        this.state.description = value;
        this._dispatchUpdateEvent();
    }
    set label(value) {
        this.state.label = value;
        this._dispatchUpdateEvent();
    }
    set value(value) {
        this.state.value = value;
        this._dispatchUpdateEvent();
    }
    set icon(value) {
        this.state.icon = value;
        this._dispatchUpdateEvent();
    }
    // Private methods
    _toRelation() {
        return {
            uuid: this.state.uuid,
            description: this.state.description,
            label: this.state.label,
            value: this.state.value,
            icon: this.state.icon
        };
    }
    _dispatchUpdateEvent() {
        if (!this.state.description || !this.state.value) {
            return;
        }
        this.dispatchEvent(new CustomEvent('rule-relation.update', {
            bubbles: true,
            detail: this._toRelation()
        }));
    }
    _dispatchDeleteEvent() {
        this.dispatchEvent(new CustomEvent('rule-relation.delete', {
            bubbles: true,
            detail: this._toRelation()
        }));
    }
}
