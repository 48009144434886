import { dateUtils } from '@emartech/ui-framework-utils';

class DatetimeCalculator {
  constructor() {
    this._isTime = false;
    this._dateString = '';
    this._timeString = '00:00';
    this._min = undefined;
    this._max = undefined;
    this._isTimeValid = true;
    this._isDateValid = true;
  }

  set isTime(value) {
    this._isTime = value;
  }

  get datetimeString() {
    if (!this._isDateValid || !this._isTimeValid) { return dateUtils.invalidDateString; }
    if (!this._dateString) { return ''; }
    return this._isTime ? this._dateTimeString : this._dateString;
  }

  set datetimeString(value) {
    this.dateString = value;
    this.timeString = value === '' ? '00:00' : dateUtils.format(value, dateUtils.formats.hoursAndMins);
    this._isDateValid = this._isValid();
  }

  get dateString() {
    return this._isDateValid ? this._dateString : dateUtils.invalidDateString;
  }

  set dateString(value) {
    this._dateString = value === '' ? '' : this._formatDate(value);
    this._isDateValid = this._isValid();
    this._revalidate();
  }

  setDateInputString(value, format) {
    const newDate = dateUtils.parseByFormat(value, format);
    const dateString = dateUtils.format(newDate, dateUtils.formats.fullDate);

    this.dateString = dateString;
  }

  get timeString() {
    return this._timeString;
  }

  set timeString(value) {
    this._timeString = value;
    this._isTimeValid = this._isValid();
    this._revalidate();
  }

  get isDateValid() {
    return this._isDateValid;
  }

  get isTimeValid() {
    return this._isTimeValid;
  }

  set min(value) {
    this._min = value;
    this._revalidate();
  }

  get min() {
    return this._min;
  }

  set max(value) {
    this._max = value;
    this._revalidate();
  }

  get max() {
    return this._max;
  }

  get _dateTimeString() {
    return this._dateString + ' ' + this._timeString;
  }

  get isValidFormat() {
    return this._isValidFormat();
  }

  get isValidRange() {
    return this._isValidRange();
  }

  _revalidate() {
    if (this._isValid()) {
      this._isDateValid = true;
      this._isTimeValid = true;
    }
  }

  _formatDate(value) {
    return dateUtils.format(dateUtils.parseWithoutTime(value), dateUtils.formats.fullDate);
  }

  _isValid() {
    return this._isValidFormat() && this._isValidRange();
  }

  _isValidFormat() {
    return this._dateString !== dateUtils.invalidDateString;
  }

  _isValidRange() {
    return !dateUtils.isBefore(this._dateTimeString, this._min) && !dateUtils.isAfter(this._dateTimeString, this._max);
  }
}

export default DatetimeCalculator;
