import State from '../../utils/state';

class EmojiState extends State {
  get defaultState() {
    return {
      originalInputTabIndex: null,
      isError: false,
      value: {
        unicode: '',
        entity: ''
      }
    };
  }
}

export default EmojiState;
