import State from '../../utils/state';

class RingState extends State {
  get defaultState() {
    return {
      circles: [],
      type: 'default',
      maskColor: false,
      separated: false,
      radius: 50,
      width: 13
    };
  }
}

export default RingState;
