export const predefinedColors = [
  {
    color: '#f44336',
    shades: [
      { color: '#ffebee' },
      { color: '#ffcdd2' },
      { color: '#ef9a9a' },
      { color: '#e57373' },
      { color: '#ef5350' },
      { color: '#f44336' },
      { color: '#e53935' },
      { color: '#d32f2f' },
      { color: '#c62828' },
      { color: '#b71c1c' }
    ]
  }, {
    color: '#9c27b0',
    shades: [
      { color: '#f3e5f5' },
      { color: '#e1bee7' },
      { color: '#ce93d8' },
      { color: '#ba68c8' },
      { color: '#ab47bc' },
      { color: '#9c27b0' },
      { color: '#8e24aa' },
      { color: '#7b1fa2' },
      { color: '#6a1b9a' },
      { color: '#4a148c' }
    ]
  }, {
    color: '#3f51b5',
    shades: [
      { color: '#e8eaf6' },
      { color: '#c5cae9' },
      { color: '#9fa8da' },
      { color: '#7986cb' },
      { color: '#5c6bc0' },
      { color: '#3f51b5' },
      { color: '#3949ab' },
      { color: '#303f9f' },
      { color: '#283593' },
      { color: '#1a237e' }
    ]
  }, {
    color: '#03a9f4',
    shades: [
      { color: '#e1f5fe' },
      { color: '#b3e5fc' },
      { color: '#81d4fa' },
      { color: '#4fc3f7' },
      { color: '#29b6f6' },
      { color: '#03a9f4' },
      { color: '#039be5' },
      { color: '#0288d1' },
      { color: '#0277bd' },
      { color: '#01579b' }
    ]
  }, {
    color: '#009688',
    shades: [
      { color: '#e0f2f1' },
      { color: '#b2dfdb' },
      { color: '#80cbc4' },
      { color: '#4db6ac' },
      { color: '#26a69a' },
      { color: '#009688' },
      { color: '#00897b' },
      { color: '#00796b' },
      { color: '#00695c' },
      { color: '#004d40' }
    ]
  }, {
    color: '#8bc34a',
    shades: [
      { color: '#f1f8e9' },
      { color: '#dcedc8' },
      { color: '#c5e1a5' },
      { color: '#aed581' },
      { color: '#9ccc65' },
      { color: '#8bc34a' },
      { color: '#7cb342' },
      { color: '#689f38' },
      { color: '#558b2f' },
      { color: '#33691e' }
    ]
  }, {
    color: '#ffeb3b',
    shades: [
      { color: '#fffde7' },
      { color: '#fff9c4' },
      { color: '#fff59d' },
      { color: '#fff176' },
      { color: '#ffee58' },
      { color: '#ffeb3b' },
      { color: '#fdd835' },
      { color: '#fbc02d' },
      { color: '#f9a825' },
      { color: '#f57f17' }
    ]
  }, {
    color: '#ff9800',
    shades: [
      { color: '#fff3e0' },
      { color: '#ffe0b2' },
      { color: '#ffcc80' },
      { color: '#ffb74d' },
      { color: '#ffa726' },
      { color: '#ff9800' },
      { color: '#fb8c00' },
      { color: '#f57c00' },
      { color: '#ef6c00' },
      { color: '#e65100' }
    ]
  }, {
    color: '#795548',
    shades: [
      { color: '#efebe9' },
      { color: '#d7ccc8' },
      { color: '#bcaaa4' },
      { color: '#a1887f' },
      { color: '#8d6e63' },
      { color: '#795548' },
      { color: '#6d4c41' },
      { color: '#5d4037' },
      { color: '#4e342e' },
      { color: '#3e2723' }
    ]
  }, {
    color: '#000000'
  }, {
    color: '#9e9e9e',
    shades: [
      { color: '#fafafa' },
      { color: '#f5f5f5' },
      { color: '#eeeeee' },
      { color: '#e0e0e0' },
      { color: '#bdbdbd' },
      { color: '#9e9e9e' },
      { color: '#757575' },
      { color: '#616161' },
      { color: '#424242' },
      { color: '#212121' }
    ]
  }, {
    color: '#ffffff'
  }
];
