import { html } from 'uhtml';
import classNames from 'clsx';

export class EValidatorTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <e-validator-header></e-validator-header>
    `;
  }

  createElement() {
    return html`
    ${this.createToggleHeader()}
    ${this.createHeader()}
    ${this.createSubheader()}
  `;
  }

  createHeader() {
    if (this.refs.contentElement) { return; }

    return html`
      <div class="e-validator__header" @click="${this.events.onHeaderClick}">
        ${this.createActionIcon()}
        ${this.createSummaryContent()}
        ${this.createContent()}
        ${this.createAction()}
      </div>`;
  }

  createToggleHeader() {
    if (!this.refs.contentElement) { return; }

    const classList = classNames('e-validator__header', {
      'e-validator__toggle': this.refs.contentElement,
      'e-validator__toggle-opened': this.state.isContentVisible
    });

    return html`
      <button type="button" class="${classList}" @click="${this.events.onHeaderClick}">
        ${this.createActionIcon()}
        ${this.createSummaryContent()}
        ${this.createContent()}
        ${this.createAction()}
      </button>`;
  }

  createSummaryContent() {
    if (!this.state.summary) { return; }

    return html`
      <h2>${this.state.summary}</h2>
      <div class="e-validator__summary">
        ${this.createSummaryValue()}
      </div>
  `;
  }

  createSummaryValue() {
    return this.state.summaryValue.map((item, index) => html`
          <div class="${`e-validator__summary_item text-color-${this.state.summaryColor[index]}`}">
            <span>${this.state.summaryValue[index]}</span>
            <e-icon
              icon="${this.state.summaryType[index]}"
              color="${this.state.summaryColor[index] || 'faint'}"
              size="small"
            >
            </e-icon>
          </div>`);
  }

  createContent() {
    if (this.state.summary) { return; }

    return html`
      <div class="e-validator__icon">
        ${this.createLoadingIcon()}
        ${this.createContentIcon()}
      </div>
      <div class="e-validator__title">
        ${this.createAsteriskToMark()}
        ${this.createSubject()}
      </div>
  `;
  }

  createLoadingIcon() {
    if (this.state.status !== 'loading') { return; }

    return html`<e-spinner data-size="small"></e-spinner>`;
  }

  createContentIcon() {
    if (this.state.status === 'loading') { return; }

    return html`
      <e-icon
        icon="${this.state.iconName}"
        color="${this.state.iconColor}"
        size="small"
        type="inline"
      ></e-icon>
    `;
  }

  createActionIcon() {
    if (!this.refs.contentElement) { return; }

    return html`
      <div class="e-validator__action e-validator__action-icon">
        <e-icon icon="e-move-down"></e-icon>
      </div>
    `;
  }

  createAction() {
    if (!this.state.action) { return; }

    if (this.state.actionType === 'button') {
      return this.createActionButton();
    }

    return this.createActionLink();
  }

  createActionLink() {
    return html`
      <div class="e-validator__action">
        <a
          href="${this.state.actionHref || '#'}"
          target="_blank"
          rel="noopener noreferrer"
          @click=${this.events.onActionClick}
        >
          ${ this.state.action }
        </a>
      </div>
    `;
  }

  createActionButton() {
    return html`
      <div class="e-validator__action">
        <button
          class="e-btn e-btn-small"
          type="button"
          @click=${this.events.onActionClick}
        >
          ${ this.state.action }
        </button>
      </div>
    `;
  }

  createSubheader() {
    if (!this.state.subheader) { return; }

    return html`<div class="e-validator__subheader" title="${this.state.subheader}">${this.state.subheader}</div>`;
  }

  createSubject() {
    if (!this.state.subject) { return; }

    return html`<span class="e-validator__subject">${ html([this.state.subject])}</span>`;
  }

  createAsteriskToMark() {
    if (!this.state.header) { return; }

    const className = classNames('e-validator__mark', {
      'e-validator__mark-default': !['success', 'warning', 'error', 'info'].includes(this.state.status),
      'e-validator__mark-success': this.state.status === 'success',
      'e-validator__mark-warning': this.state.status === 'warning',
      'e-validator__mark-error': ['error', 'danger'].includes(this.state.status),
      'e-validator__mark-info': this.state.status === 'info'
    });

    return html`
      ${html([this.state.header.replace(/\*(.*?)\*/g, `<mark class="${className}">$1</mark>`)])}
    `;
  }
}
