import { EventEmitter } from '@emartech/ui-framework-utils';
const BACKEND_URL = '/bootstrap.php?r=translation';
const RECEIVE_EVENT_NAME = 'receive.';
const RECEIVE_ERROR_EVENT_NAME = 'receive.error.';

class TranslationService {
  constructor(configStore, global) {
    this._emitter = new EventEmitter();
    this._configStore = configStore;
    this._global = global;
  }

  initialize() {
    this._global.addEventListener('message', this._handleMessage.bind(this), false);
  }

  getTranslation(componentName) {
    if (this._global !== this._global.top) {
      return new Promise((resolve, reject) => {
        const emitter = this._emitter;

        emitter.on(RECEIVE_EVENT_NAME + componentName, function callback(translation) {
          resolve(translation);
          emitter.off(RECEIVE_EVENT_NAME + componentName, callback);
          emitter.off(RECEIVE_ERROR_EVENT_NAME + componentName, callback);
        });

        emitter.on(RECEIVE_ERROR_EVENT_NAME + componentName, function callback(error) {
          reject(new Error(error.message));
          emitter.off(RECEIVE_EVENT_NAME + componentName, callback);
          emitter.off(RECEIVE_ERROR_EVENT_NAME + componentName, callback);
        });

        this._sendRequestTranslationMessage(componentName);
      });
    } else {
      return this._requestTranslation(componentName);
    }
  }

  getTranslations(componentNames) {
    const componentName = componentNames.join(',');
    return this.getTranslation(componentName);
  }

  _requestTranslation(componentName) {
    return this._configStore.getLoadedConfig().then(({ environmentHost, sessionId }) => {
      return this._sendRequestToBackend(environmentHost, sessionId, componentName);
    });
  }

  _sendRequestToBackend(environmentHost, sessionId, componentName) {
    return this._global.fetch(
      this._getBackendUrl(environmentHost, sessionId, componentName),
      { method: 'get', credentials: 'same-origin' }
    ).then(response => {
      if (response.status !== 200) { throw new Error('Server error!'); }
      return response.json();
    });
  }

  _getBackendUrl(environmentHost, sessionId, componentName) {
    return `//${environmentHost}${BACKEND_URL}&session_id=${sessionId}&id=${componentName}`;
  };

  _handleMessage(event) {
    const { message, data } = this._getPayloadFromEvent(event);

    if (message === 'e:receiveTranslation') {
      if (data.error) {
        this._emitter.emit(RECEIVE_ERROR_EVENT_NAME + data.componentName, data.error);
      } else {
        this._emitter.emit(RECEIVE_EVENT_NAME + data.componentName, data.translation);
      }
    } else if (message === 'e:requestTranslation') {
      this._requestTranslation(data.componentName)
        .then(translation => {
          this._sendReceiveTranslationMessage(event, {
            translation,
            componentName: data.componentName
          });
        })
        .catch(error => {
          this._sendReceiveTranslationMessage(event, {
            error: {
              message: error.message
            },
            componentName: data.componentName
          });
        });
    }
  }

  _sendRequestTranslationMessage(componentName) {
    this._global.top.postMessage({
      message: 'e:requestTranslation',
      data: { componentName }
    }, '*');
  }

  _sendReceiveTranslationMessage(event, data) {
    event.source.postMessage({
      message: 'e:receiveTranslation',
      data
    }, '*');
  }

  _getPayloadFromEvent(event) {
    return event.data || {};
  }
}

export default TranslationService;
