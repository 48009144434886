export class EDurationState {
  #requestRender;

  #value = null;
  #precision = 'seconds';
  #maxPrecision = 'days';
  #pad = false;
  #showZeroParts = false;
  #translations = null;

  constructor(component) {
    this.#requestRender = component.requestRender.bind(component);
  }

  get value() {
    return this.#value;
  }

  set value(value) {
    this.#value = value;
    this.#requestRender();
  }

  get precision() {
    return this.#precision;
  }

  set precision(value) {
    this.#precision = value;
    this.#requestRender();
  }

  get maxPrecision() {
    return this.#maxPrecision;
  }

  set maxPrecision(value) {
    this.#maxPrecision = value;
    this.#requestRender();
  }

  get pad() {
    return this.#pad;
  }

  set pad(value) {
    this.#pad = value;
    this.#requestRender();
  }

  get showZeroParts() {
    return this.#showZeroParts;
  }

  set showZeroParts(value) {
    this.#showZeroParts = value;
    this.#requestRender();
  }

  get translations() {
    return this.#translations;
  }

  set translations(value) {
    this.#translations = value;
    this.#requestRender();
  }
}

export default EDurationState;
