import purifier from 'dompurify';

const isCustomElement = nodeName => {
  return !!(nodeName && nodeName.match(/^(ec?|tm)-[a-z-]+$/i));
};

const uponSanitizeElement = (node, data) => {
  if (isCustomElement(node.nodeName)) {
    data.allowedTags[data.tagName] = true;
  }
};

const uponSanitizeAttribute = (node, data) => {
  handleLinkElements(node, data);
  handleCustomElements(node, data);
};

const handleLinkElements = (node, data) => {
  if (!node.nodeName || node.nodeName !== 'A') { return; }
  if (!('target' in node) || node.target !== '_blank') { return; }

  data.allowedAttributes.target = true;
  node.setAttribute('rel', 'noopener noreferrer');
};

const handleCustomElements = (node, data) => {
  const nodeName = node.nodeName.toLowerCase();

  if (!isCustomElement(nodeName)) { return; }

  if (window.customElements.get(nodeName)) {
    window.customElements.get(nodeName).observedAttributes.forEach(attribute => {
      data.allowedAttributes[attribute] = true;
    });
  } else {
    for (const attribute of node.attributes) {
      data.allowedAttributes[attribute.name] = true;
    }
  }
};

purifier.addHook('uponSanitizeElement', uponSanitizeElement);
purifier.addHook('uponSanitizeAttribute', uponSanitizeAttribute);

export default purifier;
