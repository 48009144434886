import autoBind from 'auto-bind';

export class EEmojipickerEvents {
  constructor(component) {
    autoBind(this);

    this._component = component;
    this._state = component.state;
    this._refs = component.refs;
    this._utils = component.utils;
  }

  open() {
    this._utils.popup?.open();
  }

  close() {
    this._utils.popup?.close();
  }

  setRefs() {
    this._refs.input = this._refs.input ||
      this._component.querySelector('.e-emoji');

    this._refs.customHandler = this._component.querySelector('e-emojipicker-handler');
  }

  onTogglePicker(event) {
    const isAllowedToOpen = this._isAllowedToOpen();

    if (!isAllowedToOpen) {
      return;
    }

    event.stopPropagation();
    if (!this._state.opened) {
      this.open();
    } else {
      this.close();
    }
  }

  onOpenPickerByKeydown(event) {
    if (event.code !== 'Space') { return; }

    this.onTogglePicker(event);

    if (this._state.opened) {
      this._state.activeEmoji = 0;
    }
  }

  onCategoryChange(event) {
    event.stopPropagation();
    const categoryToActivate = event.target ? event.target.getAttribute('data-category') : false;
    if (!categoryToActivate) {
      return;
    }

    this._state.changeCategory(parseInt(categoryToActivate));
    this._refs.input?.focus();
  }

  onCategoryKeydown(event, index) {
    this._onCategoryChangeKeydown(event, index);
    this._onEmojiMoveInCategoryKeydown(event);
    this._onEmojiSelectionInCategoryKeydown(event);
  }

  _onCategoryChangeKeydown(event, index) {
    if (event.code !== 'Tab') {
      return;
    }
    event.preventDefault();

    let nextIndex = 0;

    if (event.shiftKey) {
      nextIndex = parseInt(index) - 1;
      if (index === 0) {
        nextIndex = this._state.emojis.length - 1;
      }
    } else if (index < this._state.emojis.length - 1) {
      nextIndex = parseInt(index) + 1;
    }

    this._changeCategory(nextIndex);
    this._state.activeEmoji = 0;
  }

  _changeCategory(index) {
    this._refs.tab[index].focus();
    this._state.changeCategory(index);
  }

  _onEmojiMoveInCategoryKeydown(event) {
    const allowedMovements = ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'];
    if (!allowedMovements.includes(event.code)) { return; }
    event.preventDefault();
    const emojisInRow = 9;
    let newActiveEmojiIndex = 0;
    switch (event.code) {
      case 'ArrowLeft':
        newActiveEmojiIndex = this._state.activeEmoji - 1;
        break;
      case 'ArrowUp':
        newActiveEmojiIndex = this._state.activeEmoji - emojisInRow;
        break;
      case 'ArrowRight':
        newActiveEmojiIndex = this._state.activeEmoji + 1;
        break;
      case 'ArrowDown':
        newActiveEmojiIndex = this._state.activeEmoji + emojisInRow;
        break;
    }

    if (newActiveEmojiIndex >= this._state.activeTabEmojis.length) {
      if (this._state.activeTab === this._state.emojis.length - 1) {
        this._changeCategory(0);
      } else {
        this._changeCategory(this._state.activeTab + 1);
      }
      newActiveEmojiIndex = 0;
    }

    if (newActiveEmojiIndex < 0) {
      if (this._state.activeTab === 0) {
        this._changeCategory(this._state.emojis.length - 1);
      } else {
        this._changeCategory(this._state.activeTab - 1);
      }
      newActiveEmojiIndex = this._state.activeTabEmojis.length - 1;
    }

    this._state.activeEmoji = newActiveEmojiIndex;
    this._component.requestRender().then(() => {
      this._scrollToActiveEmoji();
    });
  }

  _onEmojiSelectionInCategoryKeydown(event) {
    if (event.code !== 'Space' && event.code !== 'Enter') { return; }

    const emoji = this._refs.emojis[this._state.activeEmoji].getAttribute('data-emoji');

    if (!emoji) {
      return;
    }
    this._inject(emoji);
    this._fireChangeEvent();
    this._component.dispatchEvent(new CustomEvent('inject'));
    this._refs.tab[this._state.activeTab].focus();
  }

  _scrollToActiveEmoji() {
    if (!this._isEmojiVisible()) {
      this._refs.content.scrollTop = this._refs.emojis[this._state.activeEmoji].offsetTop - 100;
    }
  }

  _isEmojiVisible() {
    const { bottom, top } = this._refs.emojis[this._state.activeEmoji].getBoundingClientRect();
    const containerRect = this._refs.content.getBoundingClientRect();

    return top <= containerRect.top ?
      containerRect.top - top < 0 :
      bottom - containerRect.bottom < 0;
  }

  onEmojiClick(event) {
    event.preventDefault();
    event.stopPropagation();
    const emoji = event.target.getAttribute('data-emoji');
    if (!emoji) {
      return;
    }
    this._inject(emoji);
    this._fireChangeEvent();
    this._component.dispatchEvent(new CustomEvent('inject'));
  }

  onOpen() {
    this._state.opened = true;
    this._refs.tab[0]?.focus();
    this._component.dispatchEvent(new CustomEvent('open'));
    this._component.dispatchEvent(new CustomEvent('emojipicker.open'));
  }

  onClose() {
    this._state.opened = false;
    this._state.activeEmoji = null;
    this._component.dispatchEvent(new CustomEvent('close'));
    this._component.dispatchEvent(new CustomEvent('emojipicker.close'));
  }

  _fireChangeEvent() {
    const changeEvent = document.createEvent('HTMLEvents');
    changeEvent.initEvent('paste', true, false);
    this._refs.input.dispatchEvent(changeEvent);
  }

  _isAllowedToOpen() {
    const originalInput = this._component.querySelector('input');
    return originalInput !== null && !originalInput.disabled;
  }

  _inject(text) {
    if (typeof this._refs.input.insertText === 'function') {
      this._refs.input.insertText(text);
      return;
    }

    const scrollTop = this._refs.input.scrollTop;
    const caretPosition = this._refs.input.selectionStart;
    const start = (this._refs.input.value).substring(0, caretPosition);
    const end = (this._refs.input.value).substring(this._refs.input.selectionEnd, this._refs.input.value.length);
    const newCaretPosition = caretPosition + text.length;

    this._refs.input.value = start + text + end;
    this._refs.input.focus();
    this._refs.input.setSelectionRange(newCaretPosition, newCaretPosition);
    this._refs.input.scrollTop = scrollTop;
  }
}
