import classNames from 'clsx';
import { html } from 'uhtml';

export class EMultiselectInputTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    const className = classNames({
      'e-tagselector': true,
      'e-tagselector-disabled': this.state.disabled,
      'e-tagselector-readonly': this.state.readonly,
      'e-tagselector-active': this.state.popupOpened,
      'e-tagselector-invalid': this.state.invalid
    });

    const labels = this.state.selectedOptions.map(option => option.content).join(', ');

    return html`
      <div
        class=${className}
        tabindex="${this.state.disabled ? null : '0'}"
        ref=${node => this.refs.input = node}
        @keydown=${this.events.onKeydown}
        @click=${this.events.onClick}
        @focus=${this.events.onFocus}
        @blur=${this.events.onBlur}
        role="button"
        aria-label=${labels}
        aria-haspopup="listbox"
        aria-expanded=${this.state.popupOpened}
        aria-disabled=${this.state.disabled}
      >
        ${this.createPlaceholderElement()}
        <div class="e-tagselector__content">
          ${this.createOptionList()}
        </div>
        ${this.createClearButtonElement()}
      </div>
    `;
  }

  createOptionList() {
    return this.state.selectedOptions.length > this.state.labelMergeLimit ?
      this.createNumberedOptionElement() :
      this.state.selectedOptions.map(option => this.createOptionElement(option));
  }

  createPlaceholderElement() {
    if (this.state.selectedOptions.length) { return null; }

    return html`<div class="e-tagselector__placeholder">${this.state.placeholder}</div>`;
  }

  createOptionElement(option) {
    const className = classNames('e-label', {
      'e-label-disabled': option.disabled || !this.state.isEditable
    });

    return html`
      <div class="${className}" @click=${event => event.stopPropagation()}>
        <div class="break-all">${html([option.content])}</div>
        ${this.createCloseIcon(option)}
      </div>
    `;
  }

  createCloseIcon(option) {
    if (option.disabled || !this.state.isEditable) { return null; }

    const onClick = () => this.events.onClearOption(option.uuid);

    return html`
      <div
        class="e-label__close"
        @click=${onClick}
        aria-hidden="true"
      ></div>
    `;
  }

  createNumberedOptionElement() {
    const translationKey = this.state.selectedOptions.length > 1 ?
      'components.multiselect.selection.multipleItemsSelected' : 'components.multiselect.selection.oneItemSelected';

    const className = classNames('e-label', {
      'e-label-disabled': this.state.disabled
    });

    return html`
      <div class="${className}" @click=${event => event.stopPropagation()}>
        <div class="break-all">
          <e-translation key="${translationKey}" .value="${[this.state.selectedOptions.length]}"></e-translation>
        </div>
      </div>
    `;
  }

  createClearButtonElement() {
    if (!this.state.selectedOptions.length ||
        this.state.disabled ||
        this.state.readonly ||
        this.state.loading
    ) {
      return null;
    }

    return html`
      <button
        type="button"
        class="e-tagselector__clear"
        @click=${this.events.onClearAllOptions}
        aria-label=${this.state.translations.clearLabel}
      ></button>
    `;
  }
}

export default EMultiselectInputTemplate;
