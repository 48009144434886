import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import JSLogger from '../../../js/utils/jslogger';
const log = new JSLogger('funnelline');
import Template from './template';

class eFunnelline extends HTMLCustomElement {
  init() {
    this._state = {
      topPercent: 100,
      bottomPercent: 100,
      color: '#65B964',
      width: 240,
      get coordinates() {
        const coordinates = [];
        coordinates[0] = (this.width - (this.width * this.topPercent / 100)) / 2;
        coordinates[1] = this.width - coordinates[0];
        coordinates[2] = (this.width - (this.width * this.bottomPercent / 100)) / 2;
        coordinates[3] = this.width - coordinates[2];

        return coordinates;
      }
    };

    this._template = new Template(this);
  }

  connectedCallback() {
    this._render();
  }

  static get observedAttributes() {
    return ['top-percent', 'bottom-percent', 'color'];
  }

  set topPercent(value) {
    const parsedValue = parseFloat(value);
    this._state.topPercent = isNaN(parsedValue) ? 100 : parsedValue;
    this._render();
  }

  get topPercent() {
    return this._state.topPercent;
  }

  set bottomPercent(value) {
    const parsedValue = parseFloat(value);
    this._state.bottomPercent = isNaN(parsedValue) ? 100 : parsedValue;
    this._render();
  }

  get bottomPercent() {
    return this._state.bottomPercent;
  }

  set color(value) {
    this._state.color = value;
    this._render();
  }

  get coordinates() {
    log.log('eFunnelline coordinate getter found!');
    return this._state.coordinates;
  }

  _render() {
    this._template.render(this._state);
  }
}

export default eFunnelline;
