import { html } from 'uhtml';

export class EMultiselectActionlistTemplate {
  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      <e-actionlist
        in-popover
        autofocus
        multiple
        max-height="248px"
        spacing="none"
        ?loading="${this.state.loading}"
        ?always-show-search=${this.state.alwaysShowSearch}
        empty-state-text=${this.state.translations.emptyState}
        .items=${this.state.itemsForActionlist}
        item-limit="0"
        selection-max=${this.state.selectionMax}
        @change=${this.events.onActionlistChange}
        @search=${this.events.onActionlistSearch}
      ></e-actionlist>
    `;
  }
}

export default EMultiselectActionlistTemplate;
