import { HTMLCustomElement, ResizeObserver } from '@emartech/ui-framework-utils';
import { AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';
import configStore from '../../../utils/config-store';
import { popupUtility } from '../../../utils/popup';
import JSLogger from '../../../utils/jslogger';
const log = new JSLogger('datagrid');
import flipper from '../../../utils/flipper/index.js';
import EDatagridState from './state';
import EDatagridStateOld from './state.old';
import EDatagridEvents from './events';
import EDatagridTemplate from './template';
import { calculatePageSize } from './utils';

class EDatagrid extends HTMLCustomElement {
  init() {
    this.refs = {};
    this.refs.columnSettings = {};
    this.refs.viewSettingsPopup = document.createElement('div');
    this.utils = {};
    this.utils.columnSettingsPopups = {};

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      this.state = new EDatagridState(this);
    } else {
      this.state = new EDatagridStateOld(this);
    }

    this.events = new EDatagridEvents(this);
    this.template = new EDatagridTemplate(this);
    this.refs.wrapper = this.template.createWrapper();
    this.utils.resizeObserver = new ResizeObserver(this.events.onResize);
  }

  connectedCallback() {
    this.state.isConnected = true;
    this.state.updateFormats();
    this.state.updateTranslations();
    this.events.onLoadState();

    configStore.subscribe(this.events.onConfigChange);
    this.addEventListener('column.update', this.events.onUpdateColumn);
    this.addEventListener('action.update', this.events.onUpdateAction);
    this.addEventListener('filter.update', this.events.onUpdateFilter);
    this.addEventListener('item-action.update', this.events.onUpdateItemAction);
    this.addEventListener('bulk-action.update', this.events.onUpdateBulkAction);
    this.addEventListener('bulk-group.update', this.events.onUpdateBulkGroup);
    this.addEventListener('bulk-toggle.update', this.events.onUpdateBulkToggle);
    this.addEventListener('empty-state.update', this.events.onUpdateEmptyState);
    this.addEventListener('error-state.update', this.events.onUpdateErrorState);
    document.addEventListener('keydown', this.events.onEscapeAction);

    super._cleanupContainer('e-datagrid-wrapper');
    this.appendChild(this.refs.wrapper);
    this.requestRender().then(() => {
      if (this.state.autofocus) {
        this.refs.searchInput?.focus();
      }

      this.utils.resizeObserver.observe(this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.state.isConnected = false;
    this._closeColumnSettingsPopups();
    this.utils.resizeObserver.disconnect();

    configStore.unsubscribe(this.events.onConfigChange);
    this.removeEventListener('column.update', this.events.onUpdateColumn);
    this.removeEventListener('action.update', this.events.onUpdateAction);
    this.removeEventListener('filter.update', this.events.onUpdateFilter);
    this.removeEventListener('item-action.update', this.events.onUpdateItemAction);
    this.removeEventListener('bulk-action.update', this.events.onUpdateBulkAction);
    this.removeEventListener('bulk-group.update', this.events.onUpdateBulkGroup);
    document.removeEventListener('keydown', this.events.onEscapeAction);
  }

  static #allowedLayouts = ['table', 'card'];

  static get observedAttributes() {
    return [
      'id', 'data', 'bordered', 'loading', 'server-error-state', 'hide-pagination', 'pagination-hidden', 'action-type',
      'sorting-key', 'sorting-order', 'server', 'hide-search', 'search-hidden', 'item-count', 'search-tooltip',
      'page-size', 'page-size-hidden', 'test-helpers', 'search-keyword', 'search-keys', 'id-key',
      'server-select-all-visible', 'horizontal-scroll-disabled', 'autofocus', 'auto-focus', 'highlighted-key',
      'search-includes', 'layout'
    ];
  }

  get pageNumber() {
    return this.state.paginationState.pageNumber;
  }

  set id(value) {
    this.state.id = value;
    this.events.onLoadState();
    this.requestRender();
  }

  get data() {
    return this.state.content;
  }

  set data(value) {
    try {
      this.state.content = typeof value === 'string' ? JSON.parse(value) : value;
      this.state.bulkState.updateSelection();
    } catch (error) {
      return log.warn('Error while parsing data');
    }

    this.events.onLoadPageState();
  }

  set bordered(value) {
    this.state.isBordered = AttributeUtils.convertToBoolean(value);
  }

  set searchKeyword(value) {
    this.state.filterState.searchKeyword = { keyword: value, default: true };
  }

  set loading(value) {
    this.state.serverState.isLoading = AttributeUtils.convertToBoolean(value);
  }

  set serverErrorState(value) {
    this.state.serverState.serverErrorState = AttributeUtils.convertToBoolean(value);
  }

  set hidePagination(value) {
    this.paginationHidden = value;
  }

  set paginationHidden(value) {
    this.state.paginationState.hidePagination = AttributeUtils.convertToBoolean(value);
  }

  set hideSearch(value) {
    this.searchHidden = value;
  }

  set searchHidden(value) {
    this.state.filterState.isSearchHidden = AttributeUtils.convertToBoolean(value);
  }

  set actionType(value) {
    this.state.actionType = value;
  }

  set sortingKey(value) {
    this.state.filterState.sortingKey = value;
  }

  set sortingOrder(value) {
    this.state.filterState.sortingOrder = value;
  }

  set server(value) {
    this.state.serverState.server = value;
  }

  set customServer(value) {
    this.state.serverState.customServer = value;
  }

  get pageSize() {
    return this.state.paginationState.pageSize;
  }

  set pageSize(value) {
    this.state.paginationState.pageSize = calculatePageSize(value);
  }

  set pageSizeHidden(value) {
    this.state.paginationState.pageSizeHidden = AttributeUtils.convertToBoolean(value);
  }

  set testHelpers(value) {
    this.state.testHelpers = AttributeUtils.convertToBoolean(value);
  }

  set searchTooltip(value) {
    this.state.filterState.searchTooltip = value;
  }

  set searchKeys(value) {
    this.state.filterState.searchKeys = AttributeUtils.convertToArray(value);
    log.log('searchKeys usage');
  }

  get layout() {
    return this.state.layout;
  }

  set layout(value) {
    if (!EDatagrid.#allowedLayouts.includes(value) || this.state.layout === value) { return; }

    this.state.layout = value;
  }

  get searchIncludes() {
    return this.state.filterState.searchIncludes;
  }

  set searchIncludes(value) {
    this.state.filterState.searchIncludes = AttributeUtils.convertToArray(value);
  }

  set idKey(value) {
    this.state.idKey = value;
  }

  set serverSelectAllVisible(value) {
    this.state.bulkState.serverSelectAllVisible = AttributeUtils.convertToBoolean(value);
  }

  get selectedItems() {
    return JSON.parse(JSON.stringify(this.state.bulkState.selection));
  }

  set selectedItems(value) {
    this.state.bulkState.selection = AttributeUtils.convertToArray(value);
  }

  get horizontalScrollDisabled() {
    return this.state.isHorizontalScrollDisabled;
  }

  set horizontalScrollDisabled(value) {
    this.state.isHorizontalScrollDisabled = AttributeUtils.convertToBoolean(value);
  }

  get autofocus() {
    return this.autoFocus;
  }

  set autofocus(value) {
    log.log('deprecated autofocus');
    this.autoFocus = value;
  }

  get autoFocus() {
    return this.state.autofocus;
  }

  set autoFocus(value) {
    this.state.autofocus = AttributeUtils.convertToBoolean(value);
  }

  get highlightedKey() {
    return this.state.highlightedKey;
  }

  set highlightedKey(value) {
    this.state.highlightedKey = value;
  }

  fetchData() {
    this.state.serverState.requestGetDataFromServer({ forceRequest: true });
  }

  _render() {
    if (!this.state.isConnected) { return; }

    render(this.refs.wrapper, this.template.createElement());
    render(this.refs.viewSettingsPopup, this.template.createViewSettingsPopupElement());
    this._createColumnSettingsPopups();
  }

  _createColumnSettingsPopups() {
    const columnsWithoutInitializedPopups = Object.keys(this.refs.columnSettings).filter(contentKey =>
      !this.utils.columnSettingsPopups[contentKey]
    );

    for (const contentKey of columnsWithoutInitializedPopups) {
      if (this.utils.columnSettingsPopups[contentKey] !== null) {
        this._destroyPopup(contentKey);
      }

      const openerElement = this.refs.columnSettings[contentKey].button;
      const popupElement = this.refs.columnSettings[contentKey].popup;

      const popup = popupUtility.createPopup(openerElement, popupElement, {
        elementToFocusOnClose: openerElement,
        onAfterOpen: () => {
          if (flipper.isOn('ui_datagrid_column_hidden')) {
            this.state.columnSettingsState.setSettingsPanelVisibility(contentKey, true);
          } else {
            this.state.columnSettingsVisible = { ...this.state.columnSettingsVisible, [contentKey]: true };
          }
        },
        onAfterClose: () => {
          if (flipper.isOn('ui_datagrid_column_hidden')) {
            this.state.columnSettingsState.setSettingsPanelVisibility(contentKey, false);
          } else {
            this.state.columnSettingsVisible = { ...this.state.columnSettingsVisible, [contentKey]: false };
          }
        }
      });

      this.utils.columnSettingsPopups[contentKey] = popup;
    }
  }

  _closeColumnSettingsPopups() {
    for (const contentKey in this.refs.columnSettings) {
      this._destroyPopup(contentKey);
    }
  }

  _destroyPopup(contentKey) {
    this.utils.columnSettingsPopups[contentKey]?.destroy({ preventAutoFocus: true });
    this.utils.columnSettingsPopups[contentKey] = null;
  }
}

export default EDatagrid;
