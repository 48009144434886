import { html } from 'uhtml';

export class EIllustratedMessageTemplate {
  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      ${this.createIconSlot()}
      <div part="content">
        ${this.createTitleSlot()}
        ${this.createDescriptionSlot()}
        ${this.createActionsSlot()}
        ${this.createHelperlinkSlot()}
      </div>
    `;
  }

  createIconSlot() {
    const part = this.state.hasIcon ? `icon icon-${this.state.iconSize}` : 'hidden';

    return html`
      <slot
        part=${part}
        name="icon"
        @slotChange=${this.events.onIconChange}></slot>
    `;
  }

  createTitleSlot() {
    return html`
      <slot part="title" name="title"></slot>
    `;
  }

  createDescriptionSlot() {
    return html`
      <slot part="description" name="description"></slot>
    `;
  }

  createActionsSlot() {
    return html`
      <slot part="actions" name="actions"></slot>
    `;
  }

  createHelperlinkSlot() {
    return html`
      <slot part="helperlink" name="helperlink"></slot>
    `;
  }
}
