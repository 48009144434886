import { cssVariables } from './css-variables.js';

export class SwitchClient {
  constructor(themeHandler) {
    this.themeHandler = themeHandler;
  }

  sendSignal() {
    window.top.postMessage({ message: 'e:themeHandler:childSignalEvent' }, '*');
  }

  listenOnThemeSwitch() {
    window.addEventListener('message', this._onThemeSwitch.bind(this));
  }

  _onThemeSwitch(event) {
    if (event.data?.message !== 'e:themeHandler:switchThemeEvent') { return; }

    const newThemeId = event.data?.theme;
    const colorScheme = event.data?.colorScheme;

    const themeClasses = [...document.documentElement.classList].filter((className) =>
      className.startsWith('FLIPPER-theme-')
    );

    for (const className of themeClasses) {
      document.documentElement.classList.remove(className);
    }
    document.documentElement.classList.add(`FLIPPER-theme-${colorScheme}`);

    if (!event.data?.temporary) {
      this.themeHandler.storage.setItem('e.ui.colorTheme', newThemeId);
    }

    window.e.colorTheme = newThemeId;

    cssVariables.generateVariables(this.themeHandler);
  }
}
