import isUrl from 'validator/lib/isURL.js';
import {
  GET_TOP_ORIGIN_MESSAGE,
  GET_TOP_URL_MESSAGE,
  NAVIGATE_MESSAGE,
  RECEIVE_TOP_ORIGIN_MESSAGE,
  RECEIVE_TOP_URL_MESSAGE
} from './constants.js';

class NavigationService {
  constructor(global = window) {
    this._global = global;
  }

  subscribe() {
    this._global.addEventListener('message', event => {
      if (!event.data) { return; }

      if (event.data.message === NAVIGATE_MESSAGE) {
        if (!event.data.data?.url) { return; }

        const url = event.data.data.url;
        if (!isUrl(url, { require_tld: false, require_host: false })) {
          throw new Error('Not a valid url');
        }

        this._global.location.href = encodeURI(event.data.data.url);
      } else if (event.data.message === GET_TOP_URL_MESSAGE) {
        if (!event.data.data?.uuid) { return; }

        event.source.postMessage({ message: RECEIVE_TOP_URL_MESSAGE, data: {
          url: this._global.location.href,
          uuid: event.data.data.uuid
        } }, '*');
      } else if (event.data.message === GET_TOP_ORIGIN_MESSAGE) {
        if (!event.data.data?.uuid) { return; }

        event.source.postMessage({ message: RECEIVE_TOP_ORIGIN_MESSAGE, data: {
          origin: this._global.location.origin,
          uuid: event.data.data.uuid
        } }, '*');
      }
    });
  };
}

export default NavigationService;
