export default {
  order: {
    default: 'asc',
    options: {
      asc: 'asc',
      desc: 'desc'
    }
  },
  filter: {
    select: {
      all: 'all',
      optionPrefix: 'option-'
    }
  }
};
