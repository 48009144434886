import { html } from 'uhtml';
import classNames from 'clsx';
import themeHandler from '../../utils/theme-handler/index.js';

export class EImageTemplate {
  constructor(component) {
    this.state = component.state;
  }

  // Component Content
  createElement() {
    const isLogo = this.state.type === 'logo';
    const isIllustration = this.state.type === 'illustration';
    const isLarge = this.state.size === 'large';
    const isSmall = this.state.size === 'small';

    const imageClasses = classNames({
      'e-image__logo': isLogo,
      'e-image__logo-large': isLarge && isLogo,
      'e-image__logo-small': isSmall && isLogo,
      'e-image__illustration': isIllustration,
      'e-image__illustration-large': isLarge && isIllustration,
      'e-image__illustration-small': isSmall && isIllustration
    });

    const imagePath = themeHandler.getCurrentImageTheme()?.[this.state.type]?.[this.state.name]?.file;
    const src = `${themeHandler.cdnURL}/eds-themes/${themeHandler.manifestVersion}/${imagePath}`;

    return html`
      <div class="e-image__wrapper">
        <picture>
          <source srcset="${src}" />
          <img src="${src}" role="presentation" class="${imageClasses}" />
        </picture>
      </div>
    `;
  }
}

export default EImageTemplate;
