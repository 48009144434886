import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../js/utils/uuid/index.js';

class EActionlistItem extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      value: '',
      content: '',
      selected: false,
      active: false,
      disabled: false,
      color: ''
    };

    this._observeContent();
  }

  connectedCallback() {
    this._state.content = this.innerHTML;
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('actionlistitem.delete', { detail: { uuid: this._state.uuid } }));
  }

  static get observedAttributes() {
    return ['value', 'selected', 'disabled', 'color'];
  }

  set value(value) {
    this._state.value = value;
    this._dispatchEvent();
  }

  set selected(value) {
    this._state.selected = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  get color() {
    return this._state.color;
  }

  set color(value) {
    this._state.color = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._state.content) { return; }

    this.dispatchEvent(new CustomEvent('actionlistitem.update', {
      bubbles: true,
      detail: Object.assign({}, this._state, { value: this._state.value || this._state.content })
    }));
  }

  _observeContent() {
    const observer = new MutationObserver(() => {
      this._state.content = this.innerHTML;
      this._dispatchEvent();
    });
    observer.observe(this, { childList: true, subtree: true });
  }
}

export default EActionlistItem;
