import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import template from './template';

class EDeviceContent extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();
    this._state = {};
    this._state.fromName = '';
    this._state.subject = '';
    this._state.preheader = '';
    this._state.type = 'messagelist';
    this._state.time = '4:17';
    this._state.date = 'Monday, April 17';

    this._dom = {};
    this._dom.container = document.createElement('e-device-content-container');
    this._dom.customElements = [];
  }

  connectedCallback() {
    this.appendChild(this._dom.container);
    this.childrenChangedCallback([].map.call(this.children, child => [child]));
    this._render();
  }

  childrenChangedCallback(nodes) {
    const excludedNode = this._dom.container;
    const foundElementList = nodes.filter(node => {
      return node.length && node[0].nodeType !== 3 && excludedNode !== node[0];
    });

    foundElementList.forEach(foundElement => {
      this._dom.customElements.push(foundElement);
      this._render();
    });
  }

  attributeChangedCallback(name, oldValue, value) {
    super.attributeChangedCallback(name, oldValue, value);
    this._render();
  }

  static get observedAttributes() {
    return ['from-name', 'subject', 'preheader', 'type', 'editable'];
  }

  set fromName(value) {
    this._state.fromName = value;
  }

  set subject(value) {
    this._state.subject = value;
  }

  set preheader(value) {
    this._state.preheader = value;
  }

  set type(value) {
    this._state.type = value;
    this._state.timeago = value === 'messagelist' ? '4:17 AM' : '17m ago';
  }

  set editable(value) {
    this._state.editable = super._convertAttributeToBoolean(value);
  }

  _render() {
    render(this._dom.container, template(this._state, this._dom.customElements));
  }
}

export default EDeviceContent;
