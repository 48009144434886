import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ECheckboxState } from './state.js';
import { ECheckboxEvents } from './events.js';
import { ECheckboxTemplate } from './template.js';

export class ECheckbox extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ECheckboxState(this);
    this.events = new ECheckboxEvents(this);
    this.template = new ECheckboxTemplate(this);

    this.addEventListener('click', this.events.onClick);
    this.addEventListener('change', this.events.onChange);
  }

  connectedCallback() {
    this.render();
  }

  // Attributes
  static get observedAttributes() {
    return ['data-id', 'label', 'helper', 'name', 'value', 'checked', 'indeterminate', 'disabled', 'sr-label'];
  }

  get dataId() {
    return this.state.id;
  }

  set dataId(value) {
    this.state.id = value;
  }

  get label() {
    return this.state.label;
  }

  set label(value) {
    this.state.label = value;
  }

  get helper() {
    return this.state.helper;
  }

  set helper(value) {
    this.state.helper = value;
  }

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    this.state.value = value;
  }

  get checked() {
    return this.state.isChecked;
  }

  set checked(value) {
    this.state.isChecked = convertAttributeToBoolean(value);
    this.refs.input.checked = this.state.isChecked;

    if (this.state.isChecked) {
      this.refs.input.indeterminate = false;
    }
  }

  get indeterminate() {
    return this.state.isIndeterminate;
  }

  set indeterminate(value) {
    this.state.isIndeterminate = convertAttributeToBoolean(value);
    this.refs.input.indeterminate = this.state.isIndeterminate;

    if (this.state.isIndeterminate) {
      this.refs.input.checked = false;
    }
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
  }

  get srLabel() {
    return this.state.srLabel;
  }

  set srLabel(value) {
    this.state.srLabel = value;
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }
}

export default ECheckbox;
