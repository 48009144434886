import Storage from '../storage.js';

class Flipper {
  init(global = window) {
    this._global = global;
    this._storage = new Storage(this._global);
    this._addClasses(this.flips);
  }

  get flips() {
    const flippers = this._getWindowFlippers() ||
      this._urlFlippers ||
      this._urlHashFlippers ||
      this._sessionFlippers ||
      [];

    this._sessionFlippers = flippers;
    return flippers;
  }

  set _sessionFlippers(flippers) {
    this._storage.setItem('e.ui.flippers', JSON.stringify(flippers), 'session');
  }

  get _sessionFlippers() {
    let flippers = this._storage.getItem('e.ui.flippers', 'session');

    if (!flippers) {
      return false;
    }

    try {
      return JSON.parse(flippers);
    } catch (e) {
      return false;
    }
  }

  get _urlFlippers() {
    const searchParams = new URLSearchParams(this._global.location.search);

    return this._getFlippersFromQueryParameters(searchParams);
  }

  get _urlHashFlippers() {
    try {
      const searchParams = new URLSearchParams(this._global.location.hash.split('?')[1]);

      return this._getFlippersFromQueryParameters(searchParams);
    } catch (error) {
      return false;
    }
  }

  _addClasses(flippers) {
    if (!flippers) {
      return;
    }

    flippers.forEach((flipper) => {
      this._global.document.documentElement.classList.add('FLIPPER-' + flipper);
    });
  }

  isOn(flipper) {
    const flippers = this.flips;
    return flippers ? (flippers.indexOf(flipper) >= 0) : false;
  }

  isOff(flipper) {
    const flippers = this.flips;
    return flippers ? (flippers.indexOf(flipper) < 0) : true;
  }

  _getFlippersFromQueryParameters(urlParams) {
    if (!urlParams.get('ui_flippers')) {
      return false;
    }

    return urlParams.get('ui_flippers').split(',').map(encodeURIComponent);
  }

  _getWindowFlippers() {
    if (this._global.uiFlippers) {
      return this._global.uiFlippers;
    }

    if (this._global.e && this._global.e.uiFlippers) {
      return this._global.e.uiFlippers;
    }
  }
}

export default Flipper;
