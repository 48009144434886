import EDatagridFilter from '../datagrid-filter';
import { convertAttributeToBoolean } from '@emartech/ui-framework-utils';

class EDatagridFilterDaterange extends EDatagridFilter {
  init() {
    super.init();

    this._state.type = 'daterange';
    this._state.minDate = undefined;
    this._state.maxDate = undefined;
    this._state.start = {
      date: ''
    };
    this._state.end = {
      date: ''
    };
    this._state.clearHidden = false;
  }

  static get observedAttributes() {
    return ['start', 'end', 'clear-hidden', 'min-date', 'max-date'].concat(super.observedAttributes);
  }

  set start(value) {
    this._state.start.date = value;
    this._dispatchEvent();
  }

  set end(value) {
    this._state.end.date = value;
    this._dispatchEvent();
  }

  set clearHidden(value) {
    this._state.clearHidden = convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set minDate(value) {
    this._state.minDate = value;
    this._dispatchEvent();
  }

  set maxDate(value) {
    this._state.maxDate = value;
    this._dispatchEvent();
  }
}

export default EDatagridFilterDaterange;
