import validKeys from './valid-keys.js';

const isNumber = (key) => {
  return new RegExp('[0-9.]').test(key);
};

const hasMinus = (string) => {
  return string.indexOf('-') !== -1;
};

const hasDot = (string) => {
  return string.indexOf('.') !== -1;
};

const cursorPosition = (target, position) => {
  return target.selectionStart === position;
};

export default ({ key, metaKey, ctrlKey, target }, isInteger = false, currentSelection = '') => {
  if (isInteger && key === '.') {
    return false;
  }

  if (!hasDot(currentSelection)) {
    if (key === '.' && (hasDot(target.value) || cursorPosition(target, 0))) {
      return false;
    }
  }

  if (!hasMinus(currentSelection)) {
    if (key === '-' && (hasMinus(target.value) || !cursorPosition(target, 0))) {
      return false;
    }

    if (isNumber(key) && hasMinus(target.value) && cursorPosition(target, 0)) {
      return false;
    }
  }

  return validKeys.indexOf(key) !== -1 || metaKey || ctrlKey;
};
