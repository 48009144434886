class State {
  constructor() {
    this.inProgress = false;
    this.characterCounter = 0;
    this._defaultMin = 0;
    this._min = this._defaultMin;
    this._defaultMax = 59;
    this._max = this._defaultMax;
    this._shouldPad = true;
    this._defaultValue = this._zeroFill(this._min);
    this._value = '';
    this._emptyEnabled = false;
    this._placeholder = '';
    this._disabled = false;
    this._isError = false;
  }

  set value(value) {
    if (value === '') {
      this._value = value;
    } else {
      this._value = this._validValue(parseInt(value, 10));
    }
  }

  get value() {
    if (!this._emptyEnabled && this._value === '') {
      return this.defaultValue;
    }

    return this._value;
  }

  set defaultValue(value) {
    this._defaultValue = this._validValue(parseInt(value, 10));
  }

  get defaultValue() {
    return this._defaultValue;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }

  set min(value) {
    this._min = isNaN(parseInt(value, 10)) ? this._defaultMin : parseInt(value, 10);
    this._updateValue();
  }

  get min() {
    return this._min;
  }

  set max(value) {
    this._max = isNaN(parseInt(value, 10)) ? this._defaultMax : parseInt(value, 10);
    this._updateValue();
  }

  get max() {
    return this._max;
  }

  get maxDigits() {
    return this.max.toString().length < 2 ? 2 : this.max.toString().length;
  }

  set shouldPad(value) {
    this._shouldPad = value;
    this.value = this._value;
  }

  get emptyEnabled() {
    return this._emptyEnabled;
  }

  set emptyEnabled(value) {
    this._emptyEnabled = value;
  }

  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value) {
    this._placeholder = value;
  }

  get isError() {
    return this._isError;
  }

  set isError(value) {
    this._isError = value;
  }

  change(dir) {
    this.inProgress = false;
    this.characterCounter = 0;

    if (dir === 'up') {
      const newValue = parseInt(this.value, 10) + 1;
      this.value = newValue === this.max + 1 ? this.min : newValue;
    } else if (dir === 'down') {
      const newValue = parseInt(this.value, 10) - 1;
      this.value = newValue === this.min - 1 ? this.max : newValue;
    }
  }

  input(value) {
    if (!this.inProgress) {
      this.stop();
    }

    const currentValue = (this.characterCounter > 0 ? this.value : '');
    const input = parseInt(value, 10);
    this.characterCounter++;
    this.inProgress = this.characterCounter === this.maxDigits ? false : true;
    this.value = currentValue + input;
  }

  stop() {
    this.inProgress = false;
    this.characterCounter = 0;
    this._updateValue();
  }

  isEmptyAvailable() {
    return this._emptyEnabled && this._value === '';
  }

  _validValue(value) {
    value = isNaN(value, 10) ? 0 : value;
    value = this._boundary(value);
    value = this._zeroFill(value);

    return value + '';
  }

  _updateValue() {
    if (this._value !== '') {
      this._value = this._validValue(parseInt(this._value, 10));
    }
    this._defaultValue = this._validValue(parseInt(this._defaultValue, 10));
  }

  _boundary(value) {
    if (this.inProgress) {
      return value;
    }

    return Math.min(Math.max(value, this.min), this.max);
  }

  _zeroFill(number) {
    const numberAsString = number.toString();

    if (this._shouldPad) {
      return numberAsString.padStart(this.maxDigits, 0);
    }

    return numberAsString;
  }
}

export default State;
