import autoBind from 'auto-bind';

export class ESelectEvents {
  constructor(component) {
    autoBind(this);
    this.open = component.open.bind(component);
    this.close = component.close.bind(component);

    this.dispatchEvent = component.dispatchEvent.bind(component);
    this._dispatchChangeEvent = component._dispatchChangeEvent.bind(component);
    this.requestRender = component.requestRender;

    this.refs = component.refs;
    this.state = component.state;
    this.utils = component.utils;
  }

  onInputClick() {
    if (this.state.isPopupDisabled) { return; }

    this.utils.popup.toggle();
    this.requestRender();
  }

  onConfigChange() {
    this.state.updateTranslations();
  }

  onClearButtonClick(event) {
    event.stopImmediatePropagation();

    this.utils.popup.close();
    this.state.unselectAllOptions();
    this._dispatchChangeEvent({ detail: { value: '' } });

    this.utils.nativeHandler.updateNativeSelectElementFromState();
    this.utils.nativeHandler.dispatchChangeEvent();

    this.refs.input.focus();
  }

  onKeyboardNavigation(event) {
    if (this.utils.popup.isOpened || (event.target && event.target.classList.contains('e-select__clear'))) { return; }

    const handledKeys = ['ArrowDown', 'ArrowUp', 'Enter', 'Space'];

    if (!handledKeys.includes(event.code)) { return; }

    event.preventDefault();
    this.utils.popup.open();
  }

  onItemDelete(event) {
    this.state.deleteItem(event.detail);
  }

  onOptionUpdate(event) {
    event.target.addEventListener('option.delete', this.onItemDelete);
    this.state.upsertItem(event.detail);
  }

  onGroupUpdate(event) {
    event.target.addEventListener('group.delete', this.onItemDelete);
    this.state.upsertItem(event.detail);
  }

  onActionlistChange(event) {
    if (this.state.selectedOption?.value === event.detail.value) {
      this.utils.popup.close();
      return;
    }

    this.state.selectOptionByValue(event.detail.value);
    this.utils.popup.close();
    this._dispatchChangeEvent(event);

    this.utils.nativeHandler.updateNativeSelectElementFromState();
    this.utils.nativeHandler.dispatchChangeEvent();
  }

  onActionlistSearch(event) {
    this.utils.popup.updatePosition();
    this.dispatchEvent(new CustomEvent('search', { detail: { value: event.detail.value } }));
  }

  onOpen() {
    this.utils.nativeHandler.updateOptionsFromNativeSelectElement();
    this.refs.component.setAttribute('focused', '');
    this.dispatchEvent(new CustomEvent('open'));
  }

  onClose() {
    this.refs.component.removeAttribute('focused');
    this.dispatchEvent(new CustomEvent('close'));
  }
}

export default ESelectEvents;
