export class EDatetimeState {
  #value = '';
  #inputValue = '';
  #clearHidden = false;
  #internalError = false;
  #error = false;
  #disabled = false;
  #type = 'date';
  #inline = false;
  #minDate = undefined;
  #maxDate = undefined;
  #presets = [];
  #todayHidden = false;
  #resetTime = undefined;
  #manualApply = false;
  #startDisabled = false;
  #endDisabled = false;
  #isPresetsDisabled = false;
  #isPopupOpened = false;

  constructor(component) {
    this.requestRender = component.requestRender;
  }

  get value() {
    return this.#value;
  }

  set value(value) {
    this.#value = value;
    this.requestRender();
  }

  get inputValue() {
    return this.#inputValue;
  }

  set inputValue(value) {
    this.#inputValue = value;
    this.requestRender();
  }

  get clearHidden() {
    return this.#clearHidden;
  }

  set clearHidden(value) {
    this.#clearHidden = value;
    this.requestRender();
  }

  get internalError() {
    return this.#internalError;
  }

  set internalError(value) {
    this.#internalError = value;
    this.requestRender();
  }

  get error() {
    return this.#error;
  }

  set error(value) {
    this.#error = value;
    this.requestRender();
  }

  get disabled() {
    return this.#disabled;
  }

  set disabled(value) {
    this.#disabled = value;
    this.requestRender();
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    const validValues = ['date', 'datetime', 'daterange', 'datetimerange'];
    if (!validValues.includes(value)) { return; }

    this.#type = value;
    this.requestRender();
  }

  get inline() {
    return this.#inline;
  }

  set inline(value) {
    this.#inline = value;
    this.requestRender();
  }

  get minDate() {
    return this.#minDate;
  }

  set minDate(value) {
    this.#minDate = value;
    this.requestRender();
  }

  get maxDate() {
    return this.#maxDate;
  }

  set maxDate(value) {
    this.#maxDate = value;
    this.requestRender();
  }

  get presets() {
    return this.#presets;
  }

  set presets(value) {
    this.#presets = value;
    this.requestRender();
  }

  get todayHidden() {
    return this.#todayHidden;
  }

  set todayHidden(value) {
    this.#todayHidden = value;
    this.requestRender();
  }

  get resetTime() {
    return this.#resetTime;
  }

  set resetTime(value) {
    this.#resetTime = value;
    this.requestRender();
  }

  get manualApply() {
    return this.#manualApply;
  }

  set manualApply(value) {
    this.#manualApply = value;
    this.requestRender();
  }

  get startDisabled() {
    return this.#startDisabled;
  }

  set startDisabled(value) {
    this.#startDisabled = value;
    this.requestRender();
  }

  get endDisabled() {
    return this.#endDisabled;
  }

  set endDisabled(value) {
    this.#endDisabled = value;
    this.requestRender();
  }

  get isPresetsDisabled() {
    return this.#isPresetsDisabled;
  }

  set isPresetsDisabled(value) {
    this.#isPresetsDisabled = value;
    this.requestRender();
  }

  get isPopupOpened() {
    return this.#isPopupOpened;
  }

  set isPopupOpened(value) {
    this.#isPopupOpened = value;
    this.requestRender();
  }

  get isRange() {
    return ['daterange', 'datetimerange'].includes(this.#type);
  }

  get hasTime() {
    return ['datetime', 'datetimerange'].includes(this.#type);
  }

  get hasError() {
    return this.#error || this.#internalError;
  }

  upsertPreset(presetData) {
    const preset = this.#getPresetByUuid(presetData.uuid);
    const index = this.#presets.indexOf(preset);

    if (index > -1) {
      const presets = this.#presets.slice();
      presets[index] = presetData;
      this.presets = presets;
    } else {
      this.presets = this.#presets.concat(presetData);
    }
  }

  #getPresetByUuid(uuid) {
    return this.#presets.filter(preset => preset.uuid === uuid)[0];
  }
}
