import { html } from 'uhtml';
import EDatagridCardEmptyStateTemplate from './emptystate.js';

export default class EDatagridCardViewBodyTemplate {

  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
    this._cardEmptyStateTemplate = new EDatagridCardEmptyStateTemplate(this._state, this._events, this._refs);
  }

  create() {
    return html`
      ${this.createItemListWrapper()}
      ${this._cardEmptyStateTemplate.create()}
    `;
  }

  createItemListWrapper() {
    return html`
      ${this.createItemList()}
    `;
  }

  createItemWrapper({ itemData }) {
    return html`
      <e-card>
        ${this.createItem({ itemData })}
      </e-card>
    `;
  }

  createFieldWrapper({ field, itemData }) {
    return html`
      <div class="e-datagrid__field" slot="content">
        ${this.createField({ field, itemData })}
      </div>
    `;
  }

  createItemList() {
    return this._state.visibleContent
      .map((itemData) => this.createItemWrapper({ itemData }));
  }

  createItem({ itemData }) {
    return html`
      ${this.createFieldList({ itemData })}
    `;
  }

  createFieldList({ itemData }) {
    return this._state.columnDefinitions
      .map(field => this.createFieldWrapper({ field, itemData }));
  }

  createField({ field, itemData }) {
    return itemData.formatted[field.contentKey];
  }
}
