import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import popupHandler from '../../../utils/popup-handler';

const DEFAULT_PLACEMENT = 'bottom';

class EPopoverHandler extends HTMLCustomElement {
  init() {
    this.addEventListener('click', () => this._open({ force: false }));
    this._state = {
      popover: null,
      disabled: false,
      removeOnDestroy: false,
      adjustWidth: false,
      placement: DEFAULT_PLACEMENT,
      beforeCloseCallback: () => {}
    };
    this._arrowElement = this._createArrowElement();
  }

  connectedCallback() {
    this.dispatchEvent(new CustomEvent('popover.connect', { bubbles: true }));
  }

  static get observedAttributes() {
    return ['popover', 'opened', 'disabled', 'remove-on-destroy', 'local', 'adjust-width', 'placement'];
  }

  set popover(value) {
    this._state.popover = typeof value === 'string' ? document.getElementById(value) : value;
  }

  set opened(value) {
    if (this._convertAttributeToBoolean(value)) {
      this._open({ force: true });
    } else {
      this._close();
    }
  }

  set disabled(value) {
    this._state.disabled = this._convertAttributeToBoolean(value);
  }

  set removeOnDestroy(value) {
    this._state.removeOnDestroy = this._convertAttributeToBoolean(value);
  }

  set local(value) {
    this._state.local = this._convertAttributeToBoolean(value);
  }

  set adjustWidth(value) {
    this._state.adjustWidth = this._convertAttributeToBoolean(value);
  }

  set placement(placement) {
    this._state.placement = placement || DEFAULT_PLACEMENT;
  }

  set beforeCloseCallback(callbackFunction) {
    this._state.beforeCloseCallback = callbackFunction;
  }

  get opened() {
    return popupHandler.isOpened(this);
  }

  get disabled() {
    return this._state.disabled;
  }

  get local() {
    return this._state.local;
  }

  get placement() {
    return this._state.placement;
  }

  get _popoverElement() {
    return this._state.popover;
  }

  update() {
    popupHandler.updatePopper(this);
  }

  _open({ force }) {
    if (this._state.disabled || this.opened) {
      return;
    }

    this._popoverElement.appendChild(this._arrowElement);

    popupHandler.openPopper({
      reference: this,
      opener: this,
      force,
      local: this._state.local,
      popperElement: this._popoverElement,
      popperConfig: {
        modifiers: {
          arrow: {
            element: this._arrowElement
          }
        },
        removeOnDestroy: this._state.removeOnDestroy,
        placement: this._state.placement,
        onCreate: data => this._onOpen(data)
      },
      closeCallback: () => this._onClose(),
      beforeCloseCallback: this._state.beforeCloseCallback
    });
  }

  _close() {
    popupHandler.close(this);
  }

  _onOpen() {
    if (this._state.adjustWidth) {
      this._calcPopoverSize();
    }
    this._dispatchEvent('open');
  }

  _onClose() {
    this._popoverElement.style.removeProperty('min-width');
    this._dispatchEvent('close');
  }

  _dispatchEvent(eventName) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: { popoverElement: this._popoverElement } }));
  }

  _calcPopoverSize() {
    this._popoverElement.style.minWidth = `${this.offsetWidth}px`;
  }

  _createArrowElement() {
    const element = document.createElement('div');
    element.className = 'e-popover__arrow';
    return element;
  }
}

export default EPopoverHandler;
