import autoBind from 'auto-bind';
export class ERuleBlueprintEvents {
    constructor(component) {
        autoBind(this);
        this.refs = component.refs;
    }
    onMutation() {
        this.refs.component.updateRefs();
    }
}
