import { html } from 'uhtml';
import classnames from 'clsx';

export default class EDatagridItemActionTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  create(itemAction, rowData, isFirstChild, isLastChild) {
    const isDisabled = this._isItemActionDisabled(itemAction, rowData);
    const href = (!isDisabled && rowData.raw[itemAction.urlKey]) ? rowData.raw[itemAction.urlKey] : null;
    const disabledType = isDisabled && itemAction.disabledType === 'permission';
    const classNames = classnames('e-datagrid__item_action ', {
      'e-btn e-btn-onlyicon e-btn-borderless e-inputgroup__item': this._state.isHorizontalScrollActive,
      'e-inputgroup__item-first': isFirstChild && this._state.isHorizontalScrollActive,
      'e-inputgroup__item-last': isLastChild && this._state.isHorizontalScrollActive
    });

    const itemActionElement = href !== null ?
      this._itemActionLink(itemAction, rowData, href, classNames) :
      this._itemActionButton(itemAction, rowData, classNames);

    const tooltip = this._getTooltip(itemAction, rowData);

    return html`
      <e-tooltip content="${tooltip}" permission="${disabledType}" width="200">
        ${itemActionElement}
      </e-tooltip>
    `;
  }

  _itemActionLink(itemAction, rowData, href, classNames) {
    if (this._isItemActionLoading(itemAction, rowData)) {
      return this._itemActionSpinner();
    }

    const isDisabled = this._isItemActionDisabled(itemAction, rowData);

    return html`
      <a
        class="${classNames}"
        href=${href}
        target=${href !== null ? itemAction.target : null}
        test-name=${this._state.testHelpers ? itemAction.icon : null}
        @click=${(event) => this._events.onItemActionClick(event, itemAction, rowData, isDisabled)}
      >
        ${this._itemActionIcon(itemAction, isDisabled)}
      </a>
    `;
  }

  _itemActionButton(itemAction, rowData, classNames) {
    if (this._isItemActionLoading(itemAction, rowData)) {
      return this._itemActionSpinner();
    }

    const isDisabled = this._isItemActionDisabled(itemAction, rowData);

    return html`
      <button
        class="${classNames}"
        type="button"
        test-name=${this._state.testHelpers ? itemAction.icon : null}
        @click=${(event) => this._events.onItemActionClick(event, itemAction, rowData, isDisabled)}
      >
        ${this._itemActionIcon(itemAction, isDisabled)}
      </button>
    `;
  }

  _itemActionIcon(itemAction, isDisabled) {
    return html`
      <e-icon icon="${itemAction.icon}" ?disabled="${isDisabled}" type="table"></e-icon>
    `;
  }

  _itemActionSpinner() {
    return html`
      <div class="e-datagrid__item_action e-datagrid__item_action-loading">
        <e-spinner data-size="table"></e-spinner>
      </div>
    `;
  }

  _getTooltip(itemAction, rowData) {
    const isDisabled = this._isItemActionDisabled(itemAction, rowData);
    const { disabledTooltip, tooltip, disabledTooltipKey } = itemAction;
    if (isDisabled && disabledTooltipKey && rowData.raw[disabledTooltipKey]) { return rowData.raw[disabledTooltipKey]; }
    if (isDisabled && disabledTooltip) { return disabledTooltip; }
    return tooltip;
  };

  _isItemActionDisabled(itemAction, rowData) {
    const disabledByKey = !!(itemAction.disabledKey && rowData.raw[itemAction.disabledKey]);
    return itemAction.disabled || disabledByKey || this._state.serverState.isLoading;
  }

  _isItemActionLoading(itemAction, rowData) {
    return !!(itemAction.loadingKey && rowData.raw[itemAction.loadingKey]);
  }
}
