import { validatorIcons } from './utils';

export class EValidatorState {
  constructor(component) {
    this.requestRender = component.requestRender;

    this._status = '';
    this._iconColor = 'faint';
    this._iconName = 'e-info-circle';
    this._header = null;
    this._subject = null;
    this._subheader = null;
    this._action = null;
    this._actionHref = null;
    this._actionType = 'link';
    this._summary = false;
    this._summaryValue = [];
    this._summaryType = [];
    this._summaryColor = [];
    this._isContentVisible = false;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
    this.requestRender();
  }

  get iconColor() {
    return this._iconColor;
  }

  set iconColor(value) {
    this._iconColor = this.isValidIcon(value) ? value : 'faint';
    this.requestRender();
  }

  get iconName() {
    return this._iconName;
  }

  set iconName(value) {
    this._iconName = this.isValidIcon(value) ? validatorIcons[value] : 'e-info-circle';
    this.requestRender();
  }

  get header() {
    return this._header;
  }

  set header(value) {
    this._header = value;
    this.requestRender();
  }

  get subject() {
    return this._subject;
  }

  set subject(value) {
    this._subject = value;
    this.requestRender();
  }

  get subheader() {
    return this._subheader;
  }

  set subheader(value) {
    this._subheader = value;
    this.requestRender();
  }

  get action() {
    return this._action;
  }

  set action(value) {
    this._action = value;
    this.requestRender();
  }

  get actionHref() {
    return this._actionHref;
  }

  set actionHref(value) {
    this._actionHref = value;
    this.requestRender();
  }

  get actionType() {
    return this._actionType;
  }

  set actionType(value) {
    this._actionType = value;
    this.requestRender();
  }

  get summary() {
    return this._summary;
  }

  set summary(value) {
    this._summary = value;
    this.requestRender();
  }

  get summaryValue() {
    return this._summaryValue;
  }

  set summaryValue(value) {
    this._summaryValue = value.split(',') || [];
    this.requestRender();
  }

  get summaryType() {
    return this._summaryType;
  }

  set summaryType(value) {
    const types = value.split(',');

    this._summaryType = types.map((item) => this.isValidIcon(item) ? validatorIcons[item] : 'e-info-circle') || [];

    this.requestRender();
  }

  get summaryColor() {
    return this._summaryColor;
  }

  set summaryColor(value) {
    const types = value.split(',');

    this._summaryColor = types.map((item) => this.isValidIcon(item) ? item : 'faint') || [];

    this.requestRender();
  }

  get isContentVisible() {
    return this._isContentVisible;
  }

  set isContentVisible(value) {
    this._isContentVisible = value;
    this.requestRender();
  }

  isValidIcon(icon) {
    return Object.keys(validatorIcons).indexOf(icon) !== -1;
  }
}
