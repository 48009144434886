import autoBind from 'auto-bind';

export class EIllustratedMessageEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
  }

  onIconChange(event) {
    const slot = event.target;
    const hasIcon = slot.assignedNodes().length !== 0;
    this.state.hasIcon = hasIcon;

    if (hasIcon) {
      this.state.iconSize = slot.assignedNodes()[0].size;
    }
  }
}
