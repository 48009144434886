import { html } from 'uhtml';
import classNames from 'clsx';

export class ESelectTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
    this.utils = component.utils;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <div class="e-selectnew__wrapper"></div>
    `;
  }

  // Component Content
  createElement() {
    const className = classNames('e-selectnew', {
      'e-selectnew-native': this.refs.nativeSelect,
      'e-selectnew-borderless': this.state.isBorderless,
      'e-selectnew-focused': this.state.isFocused,
      'e-selectnew-error': this.state.isInErrorState,
      'e-selectnew-inline': this.state.isInline,
      'e-selectnew-disabled': this.state.isDisabled,
      'e-selectnew-readonly': this.state.isReadonly,
      [`e-selectnew-${this.state.size}`]: this.state.size
    });

    const buttonLabel = this.state.selectedOption ?
      this.state.selectedOption?.content :
      this.state.placeholder;

    return html`
      <div
        ref=${node => this.refs.input = node}
        class=${className}
        tabindex=${this.state.tabindex}
        @click=${this.events.onInputClick}
        role="button"
        aria-label="${buttonLabel}"
        aria-haspopup="listbox"
        aria-expanded=${this.utils.popup?.isOpened ?? false}
        aria-disabled=${this.state.isDisabled}
      >
        ${this.state.selectedOption ? this.createValue() : this.createPlaceholder()}
        ${this.createIcon()}
        ${this.createHiddenInput()}
        ${this.createClearButton()}
      </div>
      ${this.createActionlist()}
    `;
  }

  createValue() {
    if (this.refs.nativeSelect) { return; }

    return html`
      <div class="e-selectnew__value" aria-hidden="true">${ html([this.state.selectedOption?.content]) }</div>
    `;
  }

  createPlaceholder() {
    return html`
      <div class="e-selectnew__placeholder">${this.state.placeholder}</div>
    `;
  }

  createIcon() {
    if (this.refs.nativeSelect) { return; }

    if (this.state.isBorderless) {
      return html`
        <e-icon icon="e-angle-down" color="inherit" size="small" class="e-selectnew__icon"></e-icon>
      `;
    }

    return html`
      <e-icon icon="e-angle-down" size="small" class="e-selectnew__icon"></e-icon>
    `;
  }

  createClearButton() {
    if (!this.state.hasClearButton) { return; }

    return html`
      <button
        aria-label="${this.state.translations.clearLabel}"
        type="button"
        class="e-select__clear"
        @click=${this.events.onClearButtonClick}>
      </button>
    `;
  }

  createHiddenInput() {
    if (this.refs.nativeSelect) { return; }

    return html`
      <input
        type="hidden"
        ?disabled=${this.state.isDisabled}
        value=${this.state.selectedOption?.value || ''}
        name=${this.state.name}
      />`;
  }

  createActionlist() {
    return html`
      <e-actionlist
        ref=${node => this.refs.actionlist = node}
        in-popover
        auto-focus
        max-height="248px"
        spacing="none"
        .items=${this.state.itemsForActionlist}
        .itemLimit=${this.state.itemLimit}
        ?always-show-search=${this.state.alwaysShowSearch}
        ?search-hidden=${this.state.isSearchHidden}
        ?disable-min-width=${this.state.isMinWidthDisabled}
        ?loading=${this.state.isLoading}
        @change=${this.events.onActionlistChange}
        @search=${this.events.onActionlistSearch}
      ></e-actionlist>
    `;
  }
}

export default ESelectTemplate;
