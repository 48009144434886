import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class eDialogHandler extends HTMLCustomElement {
  init() {
    this._dialog = '';
    this._disabled = false;
  }

  connectedCallback() {
    this.tabIndex = this._disabled ? -1 : 0;
    this.addEventListener('click', this._click, true);
    this.addEventListener('click', this._open);
    this.addEventListener('keydown', this._onKeydown, true);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._click, true);
    this.removeEventListener('click', this._open);
    this.removeEventListener('keydown', this._onKeydown, true);
  }

  static get observedAttributes() {
    return ['dialog', 'disabled'];
  }

  set dialog(value) {
    this._dialog = value;
  }

  set disabled(value) {
    this._disabled = this._convertAttributeToBoolean(value);
    this.tabIndex = this._disabled ? -1 : 0;
  }

  _click(event) {
    if (!this._disabled) { return; }

    event.stopImmediatePropagation();
  }

  _onKeydown(event) {
    const handledKeys = ['Space', 'Enter'];
    if (!handledKeys.includes(event.code)) { return; }

    this._open();
  }

  _open() {
    if (!this._dialog) { return; }
    const dialog = document.querySelector(this._dialog);

    if (dialog && dialog.open) {
      dialog.open({ opener: this });
    }
  }
}

export default eDialogHandler;
