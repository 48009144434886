export default {
  loadScript(src, { parentElement = document.head, isTypeModule = false } = {}) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.setAttribute('src', src);

      if (isTypeModule) {
        script.setAttribute('type', 'module');
      };

      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);

      parentElement.appendChild(script);
    });
  },

  loadStyle(src, { parentElement = document.head } = {}) {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.setAttribute('rel', 'stylesheet');
      style.setAttribute('href', src);

      style.addEventListener('load', resolve);
      style.addEventListener('error', reject);

      parentElement.appendChild(style);
    });
  }
};
