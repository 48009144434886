import configStore from '../config-store/index.js';

const get = (key, object) => key.split('.')
  .reduce((actualLevel, property) => actualLevel && actualLevel[property], object);

class Translator {
  translate(key, values = []) {
    const translation = this._getTranslation(key);
    return this._interpolate(translation, values);
  }

  translatePart(key, index) {
    const translation = this._getTranslation(key);
    return translation.split('%s')[index] || '';
  }

  _getTranslation(key) {
    return get(`e.config.translations.${key}`, window) ||
      get(key, configStore.config.translations) ||
      get(key, configStore.defaultConfig.translations) ||
      key;
  }

  _interpolate(translation, values) {
    const parts = translation.split('%s');
    return values
      .slice(0, parts.length - 1)
      .reduce((parts, value, index) => {
        parts.splice(index * 2 + 1, 0, value);
        return parts;
      }, parts)
      .join('');
  }
}

export default Translator;
