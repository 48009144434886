export class ENotificationState {
  #type = '';
  #color = '';
  #icon = '';
  #key = null;
  #autoCloseTimeout = null;
  #isClosed = true;
  #isClosable = false;
  #isAutoClose = false;

  constructor(component) {
    this.requestRender = component.requestRender;
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    this.#type = value;
    this.requestRender();
  }

  get color() {
    return this.#color;
  }

  set color(value) {
    this.#color = value;
    this.requestRender();
  }

  get icon() {
    return this.#icon;
  }

  set icon(value) {
    this.#icon = value;
    this.requestRender();
  }

  get key() {
    return this.#key;
  }

  set key(value) {
    this.#key = value;
    this.requestRender();
  }

  get isClosed() {
    return this.#isClosed;
  }

  set isClosed(value) {
    this.#isClosed = value;
    this.requestRender();
  }

  get isClosable() {
    return this.#isClosable;
  }

  set isClosable(value) {
    this.#isClosable = value;
    this.requestRender();
  }

  get isAutoClose() {
    return this.#isAutoClose;
  }

  set isAutoClose(value) {
    this.#isAutoClose = value;
    this.requestRender();
  }

  get autoCloseTimeout() {
    return this.#autoCloseTimeout;
  }

  set autoCloseTimeout(value) {
    this.#autoCloseTimeout = value;
    this.requestRender();
  }

  get isLoading() {
    return this.#type === 'loading';
  }

  get hasKey() {
    return this.key !== null;
  }

  get localStorageKey() {
    return `ui:notification:${this.#key}`;
  }

  get isAutoCloseRunning() {
    return this.#autoCloseTimeout !== null;
  }
}
