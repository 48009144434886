import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class ETruncate extends HTMLCustomElement {
  init() {
    this._maxLength = Infinity;
    this._end = 0;
    this._content = '';
  }

  static get observedAttributes() {
    return ['content', 'max-length', 'end'];
  }

  get content() {
    return this._content;
  }

  set content(value) {
    this._content = value || '';
    this._render();
  }

  get end() {
    return this._end;
  }

  set end(value) {
    this._end = parseInt(value) || 0;
    this._render();
  }

  get maxLength() {
    return this._maxLength;
  }

  set maxLength(value) {
    this._maxLength = parseInt(value) || Infinity;
    this._render();
  }

  get isTruncated() {
    return this._maxLength < this._content.length;
  }

  get visibleContent() {
    if (!this.isTruncated) {
      return this._content;
    }

    const startLength = Math.max(this._maxLength - this._end, 0);
    const endLength = Math.min(this._end, this._maxLength);

    const startSubstring = this._content.slice(0, startLength);

    if (endLength === 0) {
      return `${startSubstring}…`;
    }

    const endSubstring = this._content.slice(-endLength);

    return `${startSubstring}…${endSubstring}`;
  }

  _getContent() {
    this._content = this.getAttribute('content');
  }

  _render() {
    if (this.isTruncated) {
      this.setAttribute('title', this._content);
      this.setAttribute('aria-label', this._content);
    } else {
      this.removeAttribute('title');
      this.removeAttribute('aria-label');
    }

    this.textContent = this.visibleContent;
  }
}

export default ETruncate;
