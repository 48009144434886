import EDatagridFilterCheckbox from '../datagrid-filter-checkbox';

class EDatagridFilterSwitch extends EDatagridFilterCheckbox {
  init() {
    super.init();

    Object.assign(this._state, { type: 'switch' });
  }
}

export default EDatagridFilterSwitch;
