import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import State from './state';
import { convertAttributeToBoolean } from '@emartech/ui-framework-utils';

class ETimepickerInput extends HTMLCustomElement {
  init() {
    this._state = new State();
    this._actionTimeout = null;
    this._actionTimeoutInterval = null;
    this._createDom();
    this.addEventListener('keydown', event => this._keyDown(event));
    this.addEventListener('blur', event => this._blur(event));
    this._onMouseUp = this._onMouseUp.bind(this);
  }

  connectedCallback() {
    this.classList.add('e-input', 'e-timepicker-input', 'e-timepicker__input');
    this.appendChild(this._dom.input);
    this.appendChild(this._dom.actions);
    this._renderDisabled();
    this._renderError();
  }

  static get observedAttributes() {
    return ['value', 'min', 'max', 'nopad', 'empty-enabled', 'placeholder', 'default-value', 'disabled', 'error'];
  }

  set value(value) {
    this._state.value = value;
    this._renderValue();
  }

  get value() {
    return this._state.value;
  }

  set min(value) {
    this._state.min = value;
    this._renderValue();
  }

  get min() {
    return this._state.min;
  }

  set max(value) {
    this._state.max = value;
    this._renderValue();
  }

  get max() {
    return this._state.max;
  }

  set nopad(value) {
    this._state.shouldPad = !convertAttributeToBoolean(value);
    this._updateValue();
  }

  get emptyEnabled() {
    return this._state.emptyEnabled;
  }

  set emptyEnabled(value) {
    this._state.emptyEnabled = convertAttributeToBoolean(value);
    this._renderValue();
  }

  get placeholder() {
    return this._state.placeholder;
  }

  set placeholder(value) {
    this._state.placeholder = value;
    this._renderValue();
  }

  set defaultValue(value) {
    this._state.defaultValue = value;
    this._renderValue();
  }

  get defaultValue() {
    return this._state.defaultValue;
  }

  get disabled() {
    return this._state.disabled;
  }

  set disabled(value) {
    this._state.disabled = convertAttributeToBoolean(value);

    this._renderDisabled();
    this._renderValue();
  }

  get error() {
    return this._state.isError;
  }

  set error(value) {
    this._state.isError = convertAttributeToBoolean(value);

    this._renderError();
    this._renderValue();
  }

  _renderError() {
    this.classList.toggle('e-input-error', this._state.isError);
  }

  _renderDisabled() {
    if (this._state.disabled) {
      this._addDisabled();
    } else {
      this._removeDisabled();
    }
  }

  _addDisabled() {
    this.setAttribute('tabindex', '-1');
    this.classList.add('e-input-disabled');
    this._dom.input.classList.add('e-timepicker-input__content-disabled');
    this._dom.actions.up.setAttribute('disabled', '');
    this._dom.actions.down.setAttribute('disabled', '');
  }

  _removeDisabled() {
    this.setAttribute('tabindex', '0');
    this.classList.remove('e-input-disabled');
    this._dom.input.classList.remove('e-timepicker-input__content-disabled');
    this._dom.actions.up.removeAttribute('disabled');
    this._dom.actions.down.removeAttribute('disabled');
  }

  _createPlaceholder() {
    const placeholder = document.createElement('span');
    placeholder.className = 'e-timepicker-input__placeholder';
    placeholder.textContent = this._state.placeholder;
    return placeholder;
  }

  _createDom() {
    this._dom = {};
    this._dom.input = document.createElement('span');
    this._dom.input.className = 'e-timepicker-input__content';

    this._renderValue();

    this._dom.actions = document.createElement('div');
    this._dom.actions.setAttribute('aria-hidden', 'true');
    this._dom.actions.className = 'e-timepicker-input__actions';
    ['up', 'down'].forEach(direction => this._createArrow(direction));
    this._dom.actions.addEventListener('mousedown', this._onMouseDown.bind(this));
  }

  _createArrow(direction) {
    const arrowClass = 'e-timepicker__arrow e-timepicker__arrow-' + direction;

    this._dom.actions[direction] = document.createElement('button');
    this._dom.actions[direction].type = 'button';
    this._dom.actions[direction].className = 'e-btn e-btn-borderless e-btn-onlyicon ' + arrowClass;
    this._dom.actions[direction].setAttribute('action', direction);

    const icon = document.createElement('e-icon');
    icon.icon = 'e-move-' + direction;

    this._dom.actions[direction].appendChild(icon);
    this._dom.actions.appendChild(this._dom.actions[direction]);
  }

  _onMouseDown(event) {
    const action = event.target.getAttribute('action');
    if (!action) {
      return;
    }

    if (event.type === 'mousedown' && event.button === 0) {
      this._fireAction(action);
      this._actionTimeout = setTimeout(() => {
        this._actionTimeoutInterval = setInterval(() => this._fireAction(action), 100);
      }, 450);
      document.addEventListener('mouseup', this._onMouseUp);
    }
  }

  _onMouseUp() {
    clearTimeout(this._actionTimeout);
    clearInterval(this._actionTimeoutInterval);
    document.removeEventListener('mouseup', this._onMouseUp);
  }

  _fireAction(action) {
    this._state.change(action);
    this._updateValue();
  }

  _renderValue() {
    if (this._state.isEmptyAvailable()) {
      this._dom.input.innerHTML = '';
      this._dom.input.appendChild(this._createPlaceholder());
    } else {
      if (this._state.value === '') {
        this._dom.input.textContent = this._state.defaultValue;
      } else {
        this._dom.input.textContent = this._state.value;
      }
    }
    this._renderAria();
  }

  _renderAria() {
    setTimeout(() => {
      this.setAttribute('role', 'spinbutton');
      this.setAttribute('aria-valuenow', parseInt(this._state.value, 10));
      this.setAttribute('aria-valuemin', this._state.min);
      this.setAttribute('aria-valuemax', this._state.max);
      this.setAttribute('aria-valuetext', this._state.value);
      this.setAttribute('aria-disabled', this._state.disabled);
      this.setAttribute('aria-invalid', this._state.isError);
    });
  }

  _updateValue() {
    this._renderValue();
    this._throwEvent();
  }

  _blur() {
    this._state.stop();
    this._updateValue();
  }

  _keyDown(event) {
    if (this._state.disabled) { return; }

    this._specialKeys(event);
    this._handleDeletion(event);

    const isModifierKey = event.shiftKey || event.altKey;
    let keyCode = event.keyCode;
    const isNumber = keyCode > 47 && keyCode < 58;
    const isNumpad = keyCode > 95 && keyCode < 106;

    if (isModifierKey || (!isNumber && !isNumpad)) {
      return false;
    }

    keyCode = isNumpad ? keyCode - 48 : keyCode;
    this._state.input(String.fromCharCode(keyCode));
    this._updateValue();
  }

  _handleDeletion(event) {
    const isCtrlD = event.ctrlKey === true && event.key.toLowerCase() === 'd';

    if (event.key === 'Backspace' || event.key === 'Delete' || isCtrlD) {
      this._state.value = '';
      this._updateValue();
    }
  }

  _specialKeys(event) {
    const keyEvents = {
      8: () => true,
      27: () => this.blur(),
      38: () => this._fireAction('up'),
      40: () => this._fireAction('down')
    };

    if (keyEvents[event.keyCode]) {
      event.preventDefault();
      event.stopPropagation();
      keyEvents[event.keyCode]();
    }
  }

  _throwEvent() {
    const updateEvent = new CustomEvent('update', {
      detail: {
        value: this._state.isEmptyAvailable() ? '' : this._state.value,
        characterCounter: this._state.characterCounter,
        inProgress: this._state.inProgress
      }
    });
    this.dispatchEvent(updateEvent);
  }
}

export default ETimepickerInput;
