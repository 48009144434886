export class ESeparatorState {
  #isFullheight = false;
  #hasContent = false;
  #layout = 'horizontal';
  #type = 'neutral';

  constructor(component) {
    this.component = component;
  }

  get layout() {
    return this.#layout;
  }

  set layout(value) {
    this.#layout = value;
    this.component.requestRender();
  }

  get isVertical() {
    return this.#layout === 'vertical';
  }

  get hasContent() {
    return this.#hasContent;
  }

  set hasContent(value) {
    this.#hasContent = value;
    this.component.requestRender();
  }

  get isFullheight() {
    return this.#isFullheight;
  }

  set isFullheight(value) {
    this.#isFullheight = value;
    this.component.requestRender();
  }

  get type() {
    return this.#type;
  }

  set type(value) {
    this.#type = value;
    this.component.requestRender();
  }
}

