import { queryKeyboardFocusableAll } from '@emartech/ui-framework-utils';
import autoBind from 'auto-bind';
import dialogLogger from '../../../utils/dialog-logger';
import floatUtility from '../../../utils/float';

let dialogCounter = 0;

export class EDialogEvents {
  constructor(component) {
    autoBind(this);

    this.component = component;
    this.refs = component.refs;
    this.state = component.state;
  }

  onMouseDown(event) {
    this.state.lastClickTarget = event.target;
  }

  onOutsideClick() {
    if (this.component.state.noClose || this.state.lastClickTarget !== this.refs.dialog) { return; }

    this.onClose();
  }

  onKeydownESC(event) {
    if (this.component.state.noKeys || this.component.state.noClose || event.key !== 'Escape') { return; }

    event.preventDefault();
    this.onClose();
  }

  onOpen(options) {
    if (this.state.visible) { return; }

    dialogLogger.register(this.component);

    this.component.state.setOptions(options);

    if (!this.component.state.local) {
      this.refs.dialog.style.zIndex = ++dialogCounter;
    }

    if (!options?.opener) {
      const activeElement = document.activeElement;
      const isOpenerInIframe = activeElement.nodeName === 'IFRAME' && activeElement.contentDocument;

      this.component.state.opener = isOpenerInIframe ? activeElement.contentDocument.activeElement : activeElement;
    }

    this._showDialog(() => {
      this.state.visible = true;

      if (!this.component.state.cache || !this.state.cached) {
        this.component._buildContent();
        this.state.cached = this.component.state.cache;
      } else {
        floatUtility.float(this.refs.dialog, this.component);
      }

      this._toggleEvents(this.state.visible);

      if (this.component.state.disableScroll) {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflowY = 'auto';
      }
    });

    this.refs.dialogParent = this.refs.dialog.parentNode;

    if (!this.refs.dialogParent) { return; }

    const firstDialog = this.refs.dialogParent.querySelector('.e-dialog-first');

    if (!firstDialog) {
      this.refs.dialog.classList.add('e-dialog-first');
    }

    const lastDialog = this.refs.dialogParent.querySelector('.e-dialog-last');

    if (lastDialog) {
      lastDialog.classList.remove('e-dialog-last');
    }

    this.refs.dialog.classList.add('e-dialog-last');
  }

  onClose() {
    if (!this.state.visible) { return; }

    dialogLogger.remove(this.component);
    this._dispatchEvent('beforeClose');

    this._hideDialog();

    const isDialogLast = this.refs.dialog.classList.contains('e-dialog-last');

    if (isDialogLast && this.refs.dialogParent) {
      const openDialogs = [...this.refs.dialogParent.querySelectorAll('.e-dialog')];
      const newLastOpenDialog = openDialogs.at(-1);

      this.refs.dialog.classList.remove('e-dialog-last');
      newLastOpenDialog?.classList.add('e-dialog-last');
    }
  }

  onTransitionClose() {
    this._onHideDialog();
    this.refs.dialog.classList.remove('e-dialog-visible');

    this.refs.dialog.classList.remove('e-dialog-first');
    this.refs.dialog.removeEventListener('transitionend', this._onTransitionEnd);

    this.state.closing = false;
  }

  onDisconnect() {
    this.state.visible = false;

    if (this.refs.dialog.parentNode) {
      floatUtility.remove(this.refs.dialog);

      dialogCounter--;
    }

    this._toggleEvents(this.state.visible);
  }

  onFocusOutCatcherBackwardFocus() {
    const focusableElements = queryKeyboardFocusableAll(this.refs.container);
    focusableElements?.[focusableElements.length - 1]?.focus();
  }

  onFocusOutCatcherForwardFocus() {
    const focusableElements = queryKeyboardFocusableAll(this.refs.container);
    focusableElements?.[0]?.focus();
  }

  onConfigChange() {
    this.state.updateTranslations();
  }

  _toggleEvents(isVisible) {
    document[isVisible ? 'addEventListener' : 'removeEventListener']('keydown', this.onKeydownESC);

    [].forEach.call(this.refs.dialog.querySelectorAll('[data-action="close"]'), (element) => {
      element[isVisible ? 'addEventListener' : 'removeEventListener']('click', this.onClose);
    });
  }

  _dispatchEvent(eventName) {
    const event = new CustomEvent('dialog.' + eventName, {
      'detail': {
        'component': this.component
      }
    });
    this.component.dispatchEvent(event);
  }

  _showDialog(callback) {
    callback();

    this.refs.dialog.className = this.component.className + ' e-dialog ' + this.component.state.className;
    this.refs.dialog.classList.toggle('e-dialog-local', this.state.local);
    this.refs.dialog.classList.add('e-dialog-visible');
    this.refs.dialog.classList.toggle('e-dialog-nonavigation', !document.querySelector('e-navigation'));
    this.refs.dialog.offsetHeight; // eslint-disable-line no-unused-expressions
    this.refs.dialog.classList.add('e-dialog-active');

    this.component.setSize();
    this.component._setFocus();
    this._dispatchEvent('open');
  }

  _hideDialog() {
    if (this.state.closing || this.state.preventClose) { return; }

    this.state.closing = true;

    this.refs.dialog.addEventListener('transitionend', this._onTransitionEnd);

    this.refs.dialog.classList.remove('e-dialog-active');

    if (!this.component._elementHasTransition(this.refs.dialog)) {
      this.onTransitionClose();
    }
  }

  _onTransitionEnd(event) {
    if (event.target !== this.refs.dialog) { return; }

    this.onTransitionClose();
  }

  _onHideDialog() {
    this.state.visible = false;
    this._dispatchEvent('close');
    this._toggleEvents(this.state.visible);

    if (!this.component.state.cache) {
      this.component._restoreHeaderBackButton();
      this.component._restoreHeader();
      this.component._restoreHeadlineSlot();
      this.component._restoreFooter();
      this.component._restoreContent();
      this.component._restoreCustomFooter();
    }

    if (this.component.state.disableScroll) {
      document.documentElement.style.removeProperty('overflow');
      document.body.style.removeProperty('overflow-y');
    }

    if (!this.component.state.local) {
      dialogCounter--;
    }

    this.component.state.opener?.focus({ preventScroll: true });
  }
}

export default EDialogEvents;
