import EDatagridColumn from '../datagrid-column';
const validType = ['number', 'percent', 'currency', 'currency-code'];

class EDatagridColumnNumeric extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      currency: '',
      humanize: '',
      precision: 0,
      noWrap: true,
      type: 'number'
    });
  }

  static get observedAttributes() {
    return ['precision', 'humanize', 'currency'].concat(super.observedAttributes);
  }

  set type(value) {
    this._state.type = validType.indexOf(value) !== -1 ? value : 'number';
    this._dispatchEvent();
  }

  set currency(value) {
    this._state.currency = value;
    this._dispatchEvent();
  }

  set humanize(value) {
    this._state.humanize = value;
    this._dispatchEvent();
  }

  set precision(value) {
    this._state.precision = parseInt(value) || 0;
    this._dispatchEvent();
  }
}

export default EDatagridColumnNumeric;
