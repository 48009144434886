export const svgTemplate = boundings => {
  const height = boundings.height || 200;
  const width = boundings.width;

  return `
    <svg class="e-chart-loader__svg" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <g class="e-chart-loader__grid" transform="translate(-1 -1)">
        <line x1="2" y1="2" x2="100%" y2="2"></line>
        <line x1="2" y1="25%" x2="100%" y2="25%"></line>
        <line x1="2" y1="50%" x2="100%" y2="50%"></line>
        <line x1="2" y1="75%" x2="100%" y2="75%"></line>
      </g>
      <g class="e-chart-loader__axes" transform="translate(-1 -1)">
        <line x1="2" y1="1.5" x2="2" y2="100%"></line>
        <line x1="2" y1="100%" x2="100%" y2="100%"></line>
      </g>
    </svg>
  `;
};

export const animationTemplate = boundings => {
  const classNames = ['e-chart-loader__animation'];

  if (boundings.width < 200 || boundings.height < 200) {
    classNames.push('e-chart-loader__animation-small');
  }

  return `<div class="${classNames.join(' ')}"></div>`;
};
