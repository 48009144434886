import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridFilter extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      contentKey: '',
      label: '',
      advanced: false,
      custom: false
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('filter.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['content-key', 'label', 'advanced', 'custom'];
  }

  set contentKey(value) {
    this._state.contentKey = value;
    this._dispatchEvent();
  }

  set label(value) {
    this._state.label = value;
    this._dispatchEvent();
  }

  set advanced(value) {
    this._state.advanced = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set custom(value) {
    this._state.custom = this._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._hasRequiredState()) {
      return false;
    }

    this.dispatchEvent(new CustomEvent('filter.update', {
      bubbles: true,
      detail: Object.assign({}, this._state)
    }));

    return true;
  }

  _hasRequiredState() {
    return this._state.contentKey;
  }
}

export default EDatagridFilter;
