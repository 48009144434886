import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import State from './state';
import Template from './template';
import DatetimeCalculator from './datetime-calculator';

class EDatetimePicker extends HTMLCustomElement {
  init() {
    this._state = new State(this.requestRender.bind(this));
    this._emitter = new EventEmitter();
    this._datetimeCalculator = new DatetimeCalculator();
    this._template = new Template(this._emitter);

    this._emitter.on('calendarChange', this._calendarChangeHandler.bind(this));
    this._emitter.on('dateinputChange', this._dateInputChangeHandler.bind(this));
    this._emitter.on('timepickerChange', this._timeChangeHandler.bind(this));
  }

  connectedCallback() {
    super._cleanupContainer(this._template.elementNodeName);
    this.appendChild(this._template.element);
    this.requestRender();
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  static get observedAttributes() {
    return ['value', 'today-hidden', 'input-label', 'disabled'];
  }

  get value() {
    return this._state.state.datetimeString;
  }

  set value(value) {
    this._datetimeCalculator.datetimeString = value;
    this._applyCalculatedState({ dateInputString: this._datetimeCalculator.dateString });
  }

  set isTime(value) {
    this._datetimeCalculator.isTime = value;
    this._state.setState({ isTime: value });
  }

  get isTime() {
    return this._state.state.isTime;
  }

  set min(value) {
    const previousDatetimeString = this._state.state.datetimeString;
    this._datetimeCalculator.min = value;
    this._applyCalculatedState();
    if (this._state.state.datetimeString !== previousDatetimeString) {
      this._dispatchChangeEvent();
    }
  }

  get min() {
    return this._state.state.min;
  }

  set max(value) {
    const previousDatetimeString = this._state.state.datetimeString;
    this._datetimeCalculator.max = value;
    this._applyCalculatedState();
    if (this._state.state.datetimeString !== previousDatetimeString) {
      this._dispatchChangeEvent();
    }
  }

  get max() {
    return this._state.state.max;
  }

  set todayHidden(value) {
    this._state.setState({ todayHidden: value });
  }

  get todayHidden() {
    return this._state.state.todayHidden;
  }

  set rangeOtherEnd(value) {
    this._state.setState({ rangeOtherEnd: value });
  }

  get rangeOtherEnd() {
    return this._state.state.rangeOtherEnd;
  }

  set resetTime(value) {
    this._state.setState({ resetTime: value });
  }

  get resetTime() {
    return this._state.state.resetTime;
  }

  get inputLabel() {
    return this._state.state.inputLabel;
  }

  set inputLabel(value) {
    this._state.setState({ inputLabel: value });
  }

  get disabled() {
    return this._state.state.disabled;
  }

  set disabled(value) {
    this._state.setState({ disabled: this._convertAttributeToBoolean(value) });
  }

  _render() {
    this._template.render(this._state.state);
  }

  _dateInputChangeHandler(value) {
    this._datetimeCalculator.setDateInputString(value, this._state.state.configFormats.dateFormat);
    this._applyCalculatedState({ dateInputString: value });
    this._dispatchChangeEvent();
  }

  _calendarChangeHandler(value) {
    this._datetimeCalculator.dateString = value;

    if (this._state.state.resetTime) {
      this._datetimeCalculator.timeString = this._state.state.resetTime;
    }

    this._applyCalculatedState({ dateInputString: value });
    this._dispatchChangeEvent();
  }

  _timeChangeHandler(value) {
    this._datetimeCalculator.timeString = value;
    this._applyCalculatedState();
    this._dispatchChangeEvent();
  }

  _applyCalculatedState(extension) {
    this._state.setState({
      datetimeString: this._datetimeCalculator.datetimeString,
      dateString: this._datetimeCalculator.dateString,
      timeString: this._datetimeCalculator.timeString,
      isDateValid: this._datetimeCalculator.isDateValid,
      isDateValidFormat: this._datetimeCalculator.isValidFormat,
      isDateValidRange: this._datetimeCalculator.isValidRange,
      isTimeValid: this._datetimeCalculator.isTimeValid,
      min: this._datetimeCalculator.min,
      max: this._datetimeCalculator.max,
      ...extension
    });
  }

  _dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('picker.change', { detail: {
      value: this._state.state.datetimeString
    } }));
  }
}

export default EDatetimePicker;
