import { render } from 'uhtml';
import {
  HTMLCustomElement,
  EventEmitter,
  convertAttributeToArray,
  convertAttributeToBoolean
} from '@emartech/ui-framework-utils';
import HeaderTemplate from './templates/header';
import AddConditionTemplate from './templates/add-condition';
import InsertConditionTemplate from './templates/insert-condition';
import uuid from '../../../utils/uuid';
import configStore from '../../../utils/config-store';
import translator from '../../../utils/translator';
import JSLogger from '../../../../js/utils/jslogger/index.js';
const logger = new JSLogger('decommission');

const isCheckbox = element => element.tagName.toUpperCase() === 'INPUT' && element.type === 'checkbox';

class ECriteria extends HTMLCustomElement {
  init() {
    this._state = {
      conditions: [],
      relation: 'and',
      value: [],
      label: '',
      editMode: false,
      templates: [],
      conditionTypes: [],
      translations: {},
      conditionValueLabelFormatter: null,
      addNewTooltip: null,
      hasTooltip: false,
      addNewDisabled: false
    };

    this._refs = {
      header: document.createElement('e-criteria-header'),
      addCondition: document.createElement('e-criteria-addcondition')
    };
    this._emitter = new EventEmitter();
    const actionlist = document.createElement('e-actionlist');
    this._conditionInsert = new InsertConditionTemplate(actionlist, this._emitter);
    this._addCondition = new AddConditionTemplate({ refs: this._refs, popover: actionlist, emitter: this._emitter });
    this._headerTemplate = new HeaderTemplate(this._emitter);

    this._configChangeCallback = this._updateTranslations.bind(this);
    this._setTranslations();

    this._emitter.on('addConditionButton', () => this._conditionInsert.setConditionTypes(this._state.conditionTypes));
    this._emitter.on('insertCondition', this._onConditionInsert.bind(this));
    this._emitter.on('changeRelation', this._onChangeRelation.bind(this));
    this.addEventListener('criteriatemplate.update', this);
    this.addEventListener('criteriatemplate.apply', this);
    this.addEventListener('criteriatemplate.cancel', this);
    this.addEventListener('criteriacondition.edit', this);
    this.addEventListener('criteriacondition.delete', this);
  }

  static get observedAttributes() {
    return ['label', 'condition-types', 'value', 'add-new-tooltip', 'add-new-disabled'];
  }

  set addNewTooltip(value) {
    this._state.addNewTooltip = value;
    this._state.hasTooltip = !!value;
    this._render();
  }

  set addNewDisabled(value) {
    this._state.addNewDisabled = convertAttributeToBoolean(value);
    this._render();
  }

  set label(value) {
    this._state.label = value;
    this._render();
  }

  set conditionTypes(value) {
    this._state.conditionTypes = convertAttributeToArray(value);
    this._appendConditions();
    this._render();
  }

  set value(value) {
    const parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
    this._state.value = parsedValue.conditions.map(condition => {
      return Object.assign({}, condition, { id: condition.id || uuid() });
    });

    this._state.relation = parsedValue.relation;
    this._renderHeader();

    this._updateConditions();
  }

  get value() {
    return {
      relation: this._state.relation,
      conditions: this._state.conditions.map(({ id, conditionTypeKey, values }) => ({ id, conditionTypeKey, values }))
    };
  }

  set conditionValueLabelFormatter(value) {
    this._state.conditionValueLabelFormatter = value;
    this._appendConditions();
  }

  connectedCallback() {
    configStore.subscribe(this._configChangeCallback);
    super._cleanupContainer('e-criteria-header');
    super._cleanupContainer('e-criteria-addcondition');

    this.appendChild(this._refs.header);
    this.appendChild(this._refs.addCondition);

    this._render();
    logger.log('ECriteria');
  }

  disconnectedCallback() {
    configStore.unsubscribe(this._configChangeCallback);
  }

  setCondition(value) {
    const valueFromState = this._getConditionById(value.id);

    if (valueFromState) {
      const index = this._state.value.indexOf(valueFromState);
      this._state.value[index] = value;
    } else {
      this._state.value.push(value);
    }

    this._updateConditions();
  }

  updateConditionValues(id, values) {
    const conditionFromState = this._getConditionById(id);

    values.forEach(conditionValue => {
      const conditionValueFromState = conditionFromState.values.find(stateValue => {
        return stateValue.name === conditionValue.name;
      });
      if (conditionValueFromState) {
        conditionValueFromState.value = conditionValue.value;
      } else {
        conditionFromState.values.push(conditionValue);
      }
    });
  }

  handleEvent(event) {
    if (event.type === 'criteriatemplate.update') {
      this._onTemplateUpdate(event);
    } else if (event.type === 'criteriatemplate.apply') {
      this._onTemplateApply(event);
    } else if (event.type === 'criteriatemplate.cancel') {
      this._onTemplateClose();
    } else if (event.type === 'criteriatemplate.delete') {
      this._onTemplateDelete(event);
    } else if (event.type === 'criteriacondition.delete') {
      this._onConditionDelete(event);
    } else if (event.type === 'criteriacondition.edit') {
      this._onConditionEdit(event);
    }
  }

  _updateTranslations() {
    this._setTranslations();
    this._render();
  }

  _setTranslations() {
    this._state.translations = {
      relation: {
        and: translator.translate('components.criteria.relation.and'),
        or: translator.translate('components.criteria.relation.or')
      }
    };
  }

  _getConditionById(id) {
    return this._state.value.find(storedValue => storedValue.id === id);
  }

  _getConditionByElement(element) {
    return this._state.conditions.find(condition => condition.element === element);
  }

  _render() {
    this._renderHeader();
    this._renderCondition();
  }

  _renderHeader() {
    render(this._refs.header, this._headerTemplate.render(this._state));
  }

  _renderCondition() {
    render(this._refs.addCondition, this._addCondition.render(this._state));
  }

  _updateConditions() {
    this._clearConditions();
    this._createConditions();
    this._appendConditions();
    this._renderCondition();
  }

  _clearConditions() {
    this._state.conditions.forEach(condition => this.removeChild(condition.element));
  }

  _createConditions() {
    this._state.conditions = this._state.value
      .map(value => this._buildCondition(value))
      .filter(value => value);
  }

  _buildCondition(value) {
    const conditionType = this._getConditionType(value.conditionTypeKey);
    if (!conditionType) { return false; }

    const template = this._getTemplate(conditionType.templateKey);
    if (!template) { return false; }

    return {
      id: value.id || uuid(),
      conditionTypeKey: value.conditionTypeKey,
      values: value.values,
      label: conditionType.label,
      element: document.createElement('e-criteria-condition'),
      template: template
    };
  }

  _getConditionType(key) {
    return this._state.conditionTypes.find(conditionType => key === conditionType.key);
  }

  _getTemplate(key) {
    return this._state.templates.find(template => key === template.key);
  }

  _appendConditions() {
    this._state.conditions.forEach((condition, index) => {
      condition.element.header = condition.label;
      condition.element.values = condition.values;
      condition.element.typeKey = condition.conditionTypeKey;
      condition.element.order = index;
      condition.element.valueLabelFormatter = this._state.conditionValueLabelFormatter;
      this.appendChild(condition.element);
    });
  }

  _enterEditMode() {
    this._state.editMode = true;
    this._toggleEditMode();
  }

  _leaveEditMode() {
    this._state.editMode = false;
    this._toggleEditMode();
  }

  _toggleEditMode() {
    this._state.conditions.forEach(condition => condition.element.disabled = this._state.editMode);
    this._renderCondition();
  }

  _onChangeRelation(value) {
    this._state.relation = value;
    this._renderHeader();
    this._dispatchChangeEvent('relation');
  }

  _onConditionInsert(conditionType) {
    this._addCondition.close();
    const condition = this._buildCondition({
      conditionTypeKey: conditionType.key,
      values: []
    });

    this._setInputs(condition);

    this._dispatchEditEvent(condition);

    condition.template.element.order = this._state.conditions.length;
    condition.template.element.show(condition);

    this._enterEditMode();
  }

  _onConditionDelete(event) {
    const condition = this._getConditionByElement(event.target);
    const deletedConditionId = this._state.conditions.find(condition => condition.element === event.target).id;

    this._state.value = this._state.value.filter(value => value.id !== deletedConditionId);

    this._updateConditions();
    this._dispatchChangeEvent('delete', condition);
  }

  _onConditionEdit(event) {
    const condition = this._getConditionByElement(event.target);
    this._setInputs(condition);

    this._dispatchEditEvent(condition);

    condition.element.hide();
    condition.template.element.order = condition.element.order;
    condition.template.element.show(condition);

    this._enterEditMode();
  }

  _dispatchEditEvent(condition) {
    this.dispatchEvent(new CustomEvent('templateOpen', {
      detail: {
        values: condition.values,
        template: condition.template
      }
    }));
  }

  _setInputs(condition) {
    const valueDictionary = condition.values.reduce((dictionary, { name, value }) => {
      dictionary[name] = value;
      return dictionary;
    }, {});

    this._getInputElements(condition.template.element).forEach(input => {
      if (isCheckbox(input)) {
        input.checked = valueDictionary[input.name] || false;
      } else {
        input.value = valueDictionary[input.name] || '';
      }
    });
  }

  _onTemplateUpdate(event) {
    const templateElement = event.target;

    templateElement.removeEventListener('criteriatemplate.delete', this);
    templateElement.addEventListener('criteriatemplate.delete', this);

    this._storeTemplate(templateElement, event.detail);
    this._updateConditions();
  }

  _onTemplateApply(event) {
    const valuesFromTemplate = this._getValuesFromTemplate(event.detail.condition.template.element);
    const changeType = this._getConditionById(event.detail.condition.id) ? 'edit' : 'create';
    this.setCondition(Object.assign({}, event.detail.condition, { values: valuesFromTemplate }));
    this._dispatchChangeEvent(changeType, event.detail.condition);
    this._onTemplateClose();
  }

  _dispatchChangeEvent(type, condition) {
    const conditionDetails = condition ? {
      id: condition.id,
      conditionTypeKey: condition.conditionTypeKey,
      template: condition.template
    } : {};
    this.dispatchEvent(new CustomEvent('change', {
      detail: { type, ...conditionDetails }
    }));
  }

  _getValuesFromTemplate(templateElement) {
    return this._getInputElements(templateElement).map(element => {
      if (isCheckbox(element)) {
        return { name: element.name, value: element.checked };
      } else {
        return { name: element.name, value: element.value };
      }
    });
  }

  _getInputElements(templateElement) {
    return Array.from(templateElement.querySelectorAll('[e-criteria-input]'));
  }

  _onTemplateClose() {
    this.dispatchEvent(new CustomEvent('templateClose'));
    this._state.conditions.forEach(condition => condition.element.show());
    this._leaveEditMode();
  }

  _onTemplateDelete(event) {
    this._state.templates = this._state.templates.filter(template => template.uuid !== event.detail.uuid);
  }

  _storeTemplate(element, details) {
    const item = this._state.templates.find(item => item.uuid === details.uuid);
    const index = this._state.templates.indexOf(item);
    const template = {
      key: details.key,
      uuid: details.uuid,
      element
    };

    if (index > -1) {
      this._state.templates = this._state.templates.slice();
      this._state.templates[index] = template;
    } else {
      this._state.templates.push(template);
    }
  }
}

export default ECriteria;
