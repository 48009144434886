import { cloneObject, sortItemsByType } from './utils';
import translator from '../../../utils/translator/index.js';
export class ESelectState {
  #itemLimit = null;
  constructor(component) {
    this.requestRender = component.requestRender;

    this._items = [];
    this._name = '';
    this._size = null;
    this._placeholder = '';
    this._tabindex = '0';

    this._isFocused = false;
    this._isBorderless = false;
    this._isInline = false;
    this._isInErrorState = false;
    this._alwaysShowSearch = false;
    this._isSearchHidden = false;
    this._isClearVisible = false;
    this._isMinWidthDisabled = false;
    this._isDisabled = false;
    this._isReadonly = false;
    this._isLoading = false;
    this._translations = {};
  }

  get items() {
    return this._items;
  }

  set items(value) {
    this._items = value;
    this.requestRender();
  }

  get itemsForActionlist() {
    return cloneObject(this._items)
      .sort(sortItemsByType)
      .reduce((nestedItems, item) => {
        if (item.group) {
          const group = nestedItems.find(option => option.uuid === item.group);

          if (!group) { return nestedItems; };

          group.items = group.items || [];
          group.items.push(item);
        } else {
          if (!item.items) { item.items = []; }

          nestedItems.push(item);
        }

        return nestedItems;
      }, []);
  }

  get selectedOption() {
    return this._items.find(item => item.selected);
  }

  upsertItem(itemData) {
    const itemIndex = this._items.findIndex(item => item.uuid === itemData.uuid);

    if (itemIndex === -1) {
      this._items.push({ ...itemData });
    } else {
      this._items[itemIndex] = { ...itemData };
    }

    this.requestRender();
  }

  deleteItem(itemData) {
    const itemIndex = this.items.findIndex(item => item.uuid === itemData.uuid);

    if (itemIndex === -1) { return; }

    this._items.splice(itemIndex, 1);
    this.requestRender();
  }

  selectOptionByValue(value) {
    this._items.forEach(item => item.selected = value === item.value);
    this.requestRender();
  }

  unselectAllOptions() {
    this._items.forEach(item => item.selected = false);
    this.requestRender();
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
    this.requestRender();
  }

  get size() {
    return this._size;
  }

  set size(value) {
    this._size = value;
    this.requestRender();
  }

  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value) {
    this._placeholder = value;
    this.requestRender();
  }

  get tabindex() {
    return !this._isDisabled && !this._isReadonly ? this._tabindex : null;
  }

  set tabindex(value) {
    this._tabindex = value;
    this.requestRender();
  }

  get isInErrorState() {
    return this._isInErrorState;
  }

  set isInErrorState(value) {
    this._isInErrorState = value;
    this.requestRender();
  }

  get isFocused() {
    return this._isFocused;
  }

  set isFocused(value) {
    this._isFocused = value;
    this.requestRender();
  }

  get isBorderless() {
    return this._isBorderless;
  }

  set isBorderless(value) {
    this._isBorderless = value;
    this.requestRender();
  }

  get isInline() {
    return this._isInline;
  }

  set isInline(value) {
    this._isInline = value;
    this.requestRender();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._isDisabled = value;
    this.requestRender();
  }
  get alwaysShowSearch() {
    return this._alwaysShowSearch;
  }

  set alwaysShowSearch(value) {
    this._alwaysShowSearch = value;
    this.requestRender();
  }

  get isSearchHidden() {
    return this._isSearchHidden;
  }

  set isSearchHidden(value) {
    this._isSearchHidden = value;
    this.requestRender();
  }

  get isClearVisible() {
    return this._isClearVisible;
  }

  set isClearVisible(value) {
    this._isClearVisible = value;
    this.requestRender();
  }

  get isMinWidthDisabled() {
    return this._isMinWidthDisabled;
  }

  set isMinWidthDisabled(value) {
    this._isMinWidthDisabled = value;
    this.requestRender();
  }

  get isReadonly() {
    return this._isReadonly;
  }

  set isReadonly(value) {
    this._isReadonly = value;
    this.requestRender();
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
    this.requestRender();
  }

  get translations() {
    return this._translations;
  }

  get itemLimit() {
    return this.#itemLimit;
  }

  set itemLimit(value) {
    this.#itemLimit = value;
    this.requestRender();
  }

  get isPopupDisabled() {
    return this._isDisabled || this._isReadonly;
  }

  get hasClearButton() {
    return !this._isDisabled &&
      !this._isReadonly &&
      this._isClearVisible &&
      this._placeholder !== '' &&
      this._items.some(item => item.selected);
  }

  updateTranslations() {
    this._translations.clearLabel = translator.translate('components.select.clearLabel');
    this.requestRender();
  }
}

export default ESelectState;
