import JSLogger from '../jslogger/index.js';
import flipper from '../flipper/index.js';
import { AlertHandler } from './alert/alert.js';

export class PostMessageHandler {

  constructor(global = window) {
    this.global = global;
    this.logger = new JSLogger('postmessagehandler');
  };

  subscribe() {
    this.global.addEventListener('message', event => {
      if (!event.data || typeof event.data !== 'string') { return; }

      try {
        if (flipper.isOn('ui_integration_remove_alert')) {
          const message = JSON.parse(event.data);

          if (message.event === AlertHandler.eventName) {
            AlertHandler.handleMessage(message.data, this.global);
          }
        }
      } catch (error) {
        this.logger.error('parse error', error);
      }
    });
  };
}
