import { convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { html } from 'uhtml';
import classNames from 'clsx';

export class EDialogTemplate {
  constructor(component) {
    this.component = component;
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html.node`
      <div
        @mousedown=${this.events.onMouseDown}
        @click=${this.events.onOutsideClick}
      >
        ${this.createContainer()}
      </div>
    `;
  }

  createContainer() {
    return html`
      ${this.createFocusOutCatcher(this.events.onFocusOutCatcherBackwardFocus)}
      <div
        ref=${node => this.refs.container = node}
        class="e-dialog__container"
      >
        ${this.createHeader()}
        ${this.createContent()}
        ${this.createFooter()}
      </div>
      ${this.createFocusOutCatcher(this.events.onFocusOutCatcherForwardFocus)}
    `;
  }

  createHeader() {
    return html`
      <header
        ref=${node => this.refs.header = node}
        class="e-dialog__header"
      ></header>
    `;
  }

  createHeaderContent() {
    return html`
      ${this.createHeaderTitle()}
      ${this.createHeadlineSlot()}
      ${this.createHeaderCloseButton()}
    `;
  }

  createHeaderTitle() {
    if (!this.state.headline) { return; }

    return html`
      <span class="e-dialog__title">
        ${html([this.state.headline])}
      </span>
    `;
  }

  createHeadlineSlot() {
    return html`
        <div
          class="e-dialog__headline-slot"
          ref=${node => this.refs.headlineSlot = node}
          data-testid="headlineSlot"
        ></div>
    `;
  }

  createHeaderCloseButton() {
    if (this.state.noClose) { return; }

    const buttonClasses = classNames(`e-btn e-btn-borderless e-btn-onlyicon e-dialog__close
     e-dialog__header_actions`, {
      'e-btn-borderless_white': this.state.isLightCoverTone
    });
    return html`
      <e-tooltip content="${this.state.translations.closeButtonTooltip}" class="e-dialog__close_tooltip">
        <button
          type="button"
          class="${buttonClasses}"
          @click=${this.events.onClose}
        >
          <e-icon icon="close" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    `;
  }

  createHeaderBackButton() {
    return html`
      <e-tooltip content="${this.state.translations.backButtonTooltip}">
        <button
          type="button"
          class="e-btn e-btn-borderless e-btn-onlyicon e-dialog__back_button"
        >
          <e-icon icon="e-move-left"></e-icon>
        </button>
      </e-tooltip>
    `;
  }

  createContent() {
    return html`
      <div
        ref=${node => this.refs.content = node}
        class="e-dialog__content"
      ></div>
    `;
  }

  createFooter() {
    if (!this.state.hasButtons) { return; }

    return html.node`
      <div
        ref=${node => this.refs.footer = node}
        class="e-dialog__footer"
      >
        ${this.createFooterContent()}
      </div>
    `;
  }

  createCustomFooter({ slot, actions }) {
    const slotElement = slot ?
      html`<div class="e-dialog__footer_slot" ref=${node => this.refs.customFooterSlot = node}></div>` : null;
    const actionsElement = actions ?
      html`<div class="e-dialog__footer_actions" ref=${node => this.refs.customFooterActions = node}></div>` : null;

    return html.node`
      <div
        ref=${node => this.refs.customFooter = node}
        class="e-dialog__footer e-dialog__footer_custom"
      >
        ${slotElement}
        ${actionsElement}
      </div>
    `;
  }

  createFooterContent() {
    return html`
      <div
        class="e-flex e-flex-condensed"
      >

        ${Object.keys(this.state.buttons).map(label => this.createButton(label))}
      </div>
    `;
  }

  createButton(label) {
    const buttonState = this.state.buttons[label];
    const buttonClass = String(buttonState.className);
    const className = classNames('e-btn', buttonState.className, {
      'e-btn-borderless':
        !buttonClass.includes('e-btn-') &&
        !buttonClass.includes('e-dialog__confirmbutton-destructive')
    });

    return html`
      <button
        type="button"
        id=${buttonState.id || ''}
        class=${className}
        ?disabled=${convertAttributeToBoolean(buttonState.disabled)}
        ?data-autofocus=${buttonState.autofocus}
        data-action=${buttonState.action}
        @click=${buttonState.callback?.bind(this.component)}
      >${label}</button>
    `;
  }

  createFocusOutCatcher(focusEvent) {
    return html`
      <div
        class="e-dialog__focus_out_catcher"
        tabindex="0"
        @focus=${focusEvent}
      ></div>
    `;
  }
}
