import { html } from 'uhtml';
import classNames from 'clsx';

import translator from '../../../utils/translator/index.js';

export class EDatetimeTemplate {
  constructor(component) {
    this.createReference = component.createReference.bind(component);
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
    this.utils = component.utils;
  }

  createWrapper() {
    return html.node`<e-datetime-wrapper ref="${this.createReference(['wrapper'])}"></e-datetime-wrapper>`;
  }

  createPanelElement() {
    return html.node`
      <e-datetime-panel
        class="e-datetime-popover"
        ref="${this.createReference(['panel'])}"
        role="dialog"
        aria-modal="true"
        @panel.change=${this.events.onPanelChange}
        @panel.cancel=${this.events.onCancelClose}
        @panel.apply=${this.events.onApplyClose}>
      </e-datetime-panel>
    `;
  }

  createElement() {
    const classes = classNames('e-datetime', {
      'e-datetime-inline': this.state.inline
    });

    return html`<div class="${classes}">${this.#createInput()}</div>`;
  }

  #createInput() {
    this.#updatePanel();

    const valueContainerClasses = classNames('e-margin-left-xs', {
      'e-margin-right-2xl': !this.state.clearHidden,
      'e-margin-right-s': this.state.clearHidden
    });

    const timeClasses = classNames('e-input e-datetime__input', {
      'e-input-error': this.state.hasError,
      'e-input-disabled': this.state.disabled,
      'e-input-focus': this.state.isPopupOpened
    });

    return html`
      <div
        ref="${this.createReference(['input'])}"
        class="${timeClasses}"
        tabindex="0"
        aria-haspopup="dialog"
        aria-expanded="${this.state.isPopupOpened}"
      >
        <e-icon
          class="e-datetime__calendar_icon"
          color=${this.state.disabled ? 'disabled' : 'text'}
          icon="calendar-o"
          size="small"></e-icon>
        <div class="${valueContainerClasses}">${this.#time()}</div>
        ${this.#createDropdownIcon()}
        ${this.#createClearButton()}
      </div>
   `;
  }

  #createClearButton() {
    const valueIsEmpty = this.state.value === '' ||
      (this.state.value && this.state.value.start === '' && this.state.value.end === '');
    if (valueIsEmpty || this.state.clearHidden || this.state.disabled) { return; }

    const classes = classNames('e-datetime__clear', {
      'e-datetime__clear-error': this.state.hasError
    });

    return html`
      <button
        type="button"
        class="${classes}"
        ref="${this.createReference(['input', 'clear-button'])}"
        @click=${this.events.onClear}
        aria-label="${translator.translate('components.datetime.clearButton')}"
      ></button>
    `;
  }

  #createDropdownIcon() {
    if (this.state.disabled) { return; }

    return html`
      <e-icon
        class="e-datetime__dropdown_icon"
        icon="e-angle-down">
      </e-icon>
    `;
  }

  #updatePanel() {
    if (!this.refs.panel) { return; }

    this.refs.panel.type = this.state.type;
    this.refs.panel.min = this.state.minDate;
    this.refs.panel.max = this.state.maxDate;
    this.refs.panel.presets = this.state.presets;
    this.refs.panel.todayHidden = this.state.todayHidden;
    this.refs.panel.resetTime = this.state.resetTime;
    this.refs.panel.manualApply = this.state.manualApply;
    this.refs.panel.setAttribute('start-disabled', this.state.startDisabled);
    this.refs.panel.setAttribute('end-disabled', this.state.endDisabled);
    this.refs.panel.presetsDisabled = this.state.isPresetsDisabled;
  }

  #getFormat(type) {
    const hasTime = ['datetime', 'datetimerange'].indexOf(type) > -1;
    return hasTime ? 'ymdhi' : 'ymd';
  }

  #time() {
    if (!this.state.isRange) {
      return this.#showValue('single');
    }
    const rangeValue = this.state.value.start || this.state.value.end ? html`
      ${this.#showValue('start')}
      <span> &ndash; </span>
      ${this.#showValue('end')}
    ` : '';

    return rangeValue;
  }

  #showValue(type) {
    const value = type === 'single' ? this.state.value : this.state.value[type];

    if (value === 'Invalid Date') {
      return html`
        <e-translation key="components.datetime.invalidDate"></e-translation>
      `;
    }

    return html`
      <e-time datetime=${value} format=${this.#getFormat(this.state.type)}></e-time>
    `;
  }

}

export default EDatetimeTemplate;
