import { convertAttributeToString, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { ESkeletonState } from './state';
import { ESkeletonTemplate } from './template';
export class ESkeleton extends HTMLCustomElement {
    init() {
        this.state = new ESkeletonState(this);
        this.template = new ESkeletonTemplate(this);
        this.refs = {
            wrapper: this.template.createWrapper()
        };
    }
    connectedCallback() {
        this.requestRender().then(() => {
            this.insertAdjacentElement('beforeend', this.refs.wrapper);
        });
    }
    // Attributes
    static get observedAttributes() {
        return [
            'width',
            'bar-count',
            'bar-height'
        ];
    }
    set width(value) {
        this.state.width = convertAttributeToString(value);
    }
    set barCount(value) {
        this.state.barCount = parseInt(value);
    }
    set barHeight(value) {
        this.state.barHeight = convertAttributeToString(value);
    }
    render() {
        render(this.refs.wrapper, this.template.createElement());
    }
}
export default ESkeleton;
