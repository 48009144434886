import { ConfettiElement } from './types/ConfettiElement';
export const CONFETTI_TRIGGER_MESSAGE = 'e:confetti:trigger';
export class ConfettiHandler {
    constructor(global = window) {
        this._global = global;
        this._confettiElements = [];
        this._canvasDimensions = { width: 0, height: 0 };
        this._isUpdating = false;
    }
    subscribe() {
        window.addEventListener('message', this._handlePostMessage.bind(this));
    }
    _handlePostMessage(event) {
        if (event.data.message !== CONFETTI_TRIGGER_MESSAGE) {
            return;
        }
        if (window.top !== window && !window.isTop) {
            window.parent.postMessage(event.data, '*');
            return;
        }
        const position = event.data.position;
        const size = event.data.size;
        const sourceIFrameWindow = event.source;
        const sourceIFrameIndex = this._findIFrameIndex(sourceIFrameWindow, this._global);
        const isSourceIFrameFound = sourceIFrameIndex !== -1;
        const sourceIFrame = isSourceIFrameFound ?
            this._global.frames[sourceIFrameIndex].frameElement :
            null;
        const sourceIFramePosition = sourceIFrame === null ? new DOMRect(0, 0, 0, 0) : sourceIFrame.getBoundingClientRect();
        const startPosition = this._getConfettiStartPosition(position, sourceIFramePosition);
        this._setupCanvas();
        this._setAvailableColors();
        this._generateConfettiElements(startPosition, size);
        this._startUpdating();
    }
    _getConfettiStartPosition(elementPosition, sourceIFramePosition) {
        return {
            x: sourceIFramePosition.x + elementPosition.x + elementPosition.width / 2,
            y: sourceIFramePosition.y + elementPosition.y
        };
    }
    _findIFrameIndex(sourceWindow, currentWindow) {
        for (let index = 0; index < currentWindow.frames.length; ++index) {
            const currentFrame = currentWindow.frames[index];
            if (currentFrame === sourceWindow || this._findIFrameIndex(sourceWindow, currentFrame)) {
                return index;
            }
        }
        return -1;
    }
    _generateConfettiElements(startPosition, size) {
        for (let i = 0; i < size.number; i++) {
            const newConfettiElement = new ConfettiElement(startPosition, this._canvasDimensions, size.initialSpeed, this._availableColors);
            this._confettiElements.push(newConfettiElement);
        }
    }
    _startUpdating() {
        if (this._isUpdating) {
            return;
        }
        this._isUpdating = true;
        this._updateConfetti();
        this._canvasElement.dispatchEvent(new CustomEvent('confetti.animationStarted', { bubbles: true }));
    }
    _stopUpdating() {
        this._isUpdating = false;
        this._canvasElement.dispatchEvent(new CustomEvent('confetti.animationStopped', { bubbles: true }));
    }
    _updateConfetti() {
        for (const confettiElement of this._confettiElements) {
            confettiElement.update();
        }
        this._removeOffscreenConfettiElements();
        this._updateCanvas();
        if (this._confettiElements.length === 0) {
            this._stopUpdating();
        }
        if (this._isUpdating) {
            this._global.requestAnimationFrame(this._updateConfetti.bind(this));
        }
    }
    _removeOffscreenConfettiElements() {
        const dimensions = this._canvasDimensions;
        this._confettiElements = this._confettiElements.filter(confetti => confetti.isWithinDimension(dimensions));
    }
    _setupCanvas() {
        if (!this._canvasElement || !this._canvasElement.isConnected) {
            this._isUpdating = false;
            this._createCanvas();
        }
        this._canvasContext = this._canvasElement.getContext('2d');
        this._canvasDimensions = {
            width: this._canvasElement.offsetWidth,
            height: this._canvasElement.offsetHeight
        };
        this._canvasElement.width = this._canvasDimensions.width;
        this._canvasElement.height = this._canvasDimensions.height;
    }
    _createCanvas() {
        this._canvasElement = this._global.document.createElement('canvas');
        this._canvasElement.classList.add('e-confetti__canvas');
        this._global.document.body.appendChild(this._canvasElement);
    }
    _updateCanvas() {
        if (!this._canvasElement) {
            return;
        }
        this._clearCanvas();
        this._confettiElements.forEach(confettiElement => this._drawConfettiElement(confettiElement));
    }
    _drawConfettiElement(confettiElement) {
        this._canvasContext.save();
        this._canvasContext.translate(confettiElement.position.x, confettiElement.position.y);
        this._canvasContext.rotate(confettiElement.rotation);
        const width = (confettiElement.size * confettiElement.scale.x);
        const height = (confettiElement.size * confettiElement.scale.y);
        this._canvasContext.fillStyle = confettiElement.color;
        this._canvasContext.fillRect(-.5 * width, -.5 * height, width, height);
        this._canvasContext.restore();
    }
    _clearCanvas() {
        if (!this._canvasElement) {
            return;
        }
        this._canvasContext.clearRect(0, 0, this._canvasElement.width, this._canvasElement.height);
    }
    _setAvailableColors() {
        const computedStyle = window.getComputedStyle(document.documentElement);
        const listOfColors = [
            { lime: ['400', '700'] },
            { mint: ['400', '700'] },
            { green: ['400', '700'] },
            { teal: ['400', '700'] },
            { azure: ['400', '700'] },
            { lime: ['300', '600'] },
            { mint: ['300', '600'] },
            { green: ['300', '600'] },
            { teal: ['300', '600'] },
            { azure: ['300', '600'] }
        ];
        this._availableColors = listOfColors.map(color => {
            const [name, values] = Object.entries(color)[0];
            return {
                front: computedStyle.getPropertyValue(`--token-${name}-${values[0]}`),
                back: computedStyle.getPropertyValue(`--token-${name}-${values[1]}`)
            };
        });
    }
}
