import Storage from '../../../utils/storage';
import { predefinedColors } from './colors';

const storage = new Storage();

export class EColorpickerState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._color = '';
    this._key = '';
    this._predefinedColors = predefinedColors.slice();
    this._isShowingBaseColors = true;
    this._isOpened = false;
    this._activePredefinedColorIndex = null;
    this._activeRecentColorIndex = null;
  }

  get color() {
    return this._color;
  }

  set color(value) {
    this._color = value;
    this.render();
  }

  get key() {
    return this._key;
  }

  set key(value) {
    this._key = value;
  }

  get recentColors() {
    return JSON.parse(storage.getItem(`e.colorpicker.${this.key}.recents`)) || ['', '', '', '', '', '', ''];
  }

  updateRecentColors() {
    const recentColors = this.recentColors;

    const index = recentColors.indexOf(this.color);

    if (index !== -1) {
      recentColors.splice(index, 1);
    } else {
      recentColors.pop();
    }

    recentColors.unshift(this.color);

    storage.setItem(`e.colorpicker.${this.key}.recents`, JSON.stringify(recentColors));
  }

  get predefinedColors() {
    return this._predefinedColors;
  }

  set predefinedColors(value) {
    this._predefinedColors = value;
  }

  get isShowingBaseColors() {
    return this._isShowingBaseColors;
  }

  set isShowingBaseColors(value) {
    this._isShowingBaseColors = value;
    this.render();
  }

  get isOpened() {
    return this._isOpened;
  }

  set isOpened(value) {
    this._isOpened = value;
    this.render();
  }

  get activePredefinedColorIndex() {
    return this._activePredefinedColorIndex;
  }

  set activePredefinedColorIndex(value) {
    this._activePredefinedColorIndex = value;
    this.render();
  }

  get activeRecentColorIndex() {
    return this._activeRecentColorIndex;
  }

  set activeRecentColorIndex(value) {
    this._activeRecentColorIndex = value;
    this.render();
  }
}

export default EColorpickerState;
