export default async function promiseTimeout(promise, fallback, timeout = 2000) {
  let timer;
  const fallbackPromise = new Promise((resolve, reject) => {
    timer = setTimeout(() => fallback ? resolve(fallback) : reject('Timeout without fallback'), timeout);
  });

  return Promise
    .race([promise, fallbackPromise])
    .finally(() => clearTimeout(timer));
};
