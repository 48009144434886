import { html } from 'uhtml';
import classNames from 'clsx';

export class ESuggestInputTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  // Wrapper Element
  createWrapper() {
    return html.node`
      <div class="e-suggest-input__wrapper"></div>
    `;
  }

  // Component Content
  createElement() {
    return html`
      ${this.createInputFieldElement()}
      ${this.createPopupElement()}
    `;
  }

  createInputFieldElement() {
    const className = classNames('e-input', 'e-suggest-input__input', {
      'e-suggest-input__input-error': this.state.isInErrorState
    });

    return html`
      <input
        ref=${node => this.refs.input = node}
        class=${className}
        type="text"
        .value=${this.state.value}
        .placeholder=${this.state.placeholder}
        ?disabled=${this.state.isDisabled}
        @input=${this.events.onInput}
        @click=${this.events.onClick}
        @keydown=${this.events.onKeydown}
      />
    `;
  }

  createPopupElement() {
    return html`
      <div ref=${node => this.refs.popup = node} class="e-suggest-input__option-list">
        ${this.createActionlist()}
        ${(!this.state.itemsForActionlist.length && !this.state.isLoading) ? this.createUseValueButton() : null}
        ${this.createFooter()}
      </div>
    `;
  }

  createActionlist() {
    const className = classNames('e-suggest-input__actionlist', {
      'e-suggest-input__actionlist-hidden': this.state.isFilteredOptionsEmpty
    });

    return html`
      <e-actionlist
        ref=${node => this.refs.actionlist = node}
        class=${className}
        in-popover
        max-height="248px"
        search-hidden
        selection-disabled
        empty-state-text=""
        spacing="none"
        item-limit="0"
        ?loading=${this.state.isLoading}
        ?always-show-active=${!this.state.isUseValueButtonActive}
        @change=${this.events.onActionlistChange}
      ></e-actionlist>
    `;
  }

  createUseValueButton() {
    const className = classNames('e-suggest-input__use_value e-btn e-btn-borderless', {
      'e-suggest-input__use_value-active': this.state.isUseValueButtonActive
    });

    return html`
        <button
        type="button"
        class=${className}
        tabindex="-1"
        @click=${this.events.onUseValueClick}
      >Use '${this.state.value}'</button>
    `;
  }

  createFooter() {
    if (!this.state.isUseValueButtonVisible || !this.state.itemsForActionlist.length) { return; }

    return html`
     <div class="e-popup-panel__footer">
      <div class="e-popup-panel__actions">
        ${this.createUseValueButton()}
      </div>
     </div>
    `;
  }
}

export default ESuggestInputTemplate;
