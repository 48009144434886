import autoBind from 'auto-bind';
import JSLogger from '../../../js/utils/jslogger/index.js';

const logger = new JSLogger('rulelist');

export class ERulelistGroupEvents {
  #state;
  #refs;

  constructor(component) {
    autoBind(this);

    this.#state = component.state;
    this.#refs = component.refs;
  }

  onSlotChange(slot) {
    const slotName = slot.name;
    const isSlotVisible = !!slot.assignedNodes().length;

    this.#state.setSlotVisibility(slotName, isSlotVisible);
  }

  onToggleClick() {
    this.#state.isClosed = !this.#state.isClosed;
    this.#state.isToggling = true;

    const toggleAnimaton = this.#refs.togglableContainer.animate(
      [
        { gridTemplateRows: this.#state.isClosed ? '1fr' : '0fr' },
        { gridTemplateRows: this.#state.isClosed ? '0fr' : '1fr' }
      ],
      { duration: 200 }
    );

    toggleAnimaton.onfinish = () => {
      try {
        toggleAnimaton.commitStyles();
      } catch (error) {
        logger.error('Animation error', error);
      }
      this.#state.isToggling = false;
    };
  }
}

export default ERulelistGroupEvents;
