import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class EDatagridAction extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      label: '',
      name: '',
      disabled: false,
      disabledTooltip: '',
      disabledType: '',
      url: '',
      dialog: '',
      target: ''
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('action.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['name', 'label', 'disabled', 'disabled-tooltip', 'disabled-type', 'url', 'dialog', 'target'];
  }

  set name(value) {
    this._state.name = value;
    this._dispatchEvent();
  }

  set label(value) {
    if (this._state.label === value) {
      return;
    }

    this._state.label = value;
    this._dispatchEvent();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set disabledTooltip(value) {
    this._state.disabledTooltip = value;
    this._dispatchEvent();
  }

  set disabledType(value) {
    this._state.disabledType = value;
    this._dispatchEvent();
  }

  set url(value) {
    if (this._state.url === value) {
      return;
    }

    this._state.url = value;
    this._dispatchEvent();
  }

  set dialog(value) {
    if (this._state.dialog === value) {
      return;
    }

    this._state.dialog = value;
    this._dispatchEvent();
  }

  set target(value) {
    this._state.target = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this.parentNode || !this._state.label) {
      return;
    }

    this.dispatchEvent(new CustomEvent('action.update', {
      bubbles: true,
      detail: Object.assign({}, this._state)
    }));
  }
}

export default EDatagridAction;
