import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import template from './template';
import themeHandler from '../../utils/theme-handler';

class EIcon extends HTMLCustomElement {
  init() {
    this._utils = { themeHandler };

    this._state = {};
    this._state.icon = 'settings';

    this._state.size = '';
    this._state.disabled = false;
    this._state.color = '';
    this._state.rotation = '';
    this._state.type = '';
    this._wrapper = this._createWrapper();
  }

  connectedCallback() {
    super._cleanupContainer('.e-icon-wrapper');
    this.appendChild(this._wrapper);

    if (customElements.get('e-icon')) {
      this.__proto__ = customElements.get('e-icon').prototype;
    }

    this._render();
  }

  static get observedAttributes() {
    return ['icon', 'size', 'disabled', 'color', 'rotation', 'type'];
  }

  set icon(value) {
    const isStringValue = typeof value === 'string' || value instanceof String;

    if (!isStringValue || value === this._state.icon) { return; }

    const iconName = this._getIconName(value);
    if (iconName === this._state.icon) { return; }

    this._state.icon = iconName;

    this.setAttribute('icon', this._state.icon);
    this._render();
  }

  get icon() {
    return this._state.icon;
  }

  set size(value) {
    if (value === this._state.size) { return; }

    this._state.size = value;
    this.setAttribute('size', this._state.size);
    this._render();
  }

  get size() {
    return this._state.size;
  }

  set disabled(value) {
    const booleanValue = super._convertAttributeToBoolean(value);

    if (booleanValue === this._state.disabled) { return; }

    this._state.disabled = booleanValue;
    this.setAttribute('disabled', `${this._state.disabled}`);
    this._render();
  }

  get disabled() {
    return this._state.disabled;
  }

  set color(value) {
    if (value === this._state.color) { return; }

    this._state.color = value;
    this.setAttribute('color', this._state.color);
    this._render();
  }

  get color() {
    return this._state.color;
  }

  set rotation(value) {
    if (value === this._state.rotation) { return; }

    this._state.rotation = value;
    this.setAttribute('rotation', this._state.rotation);
    this._render();
  }

  get rotation() {
    return this._state.rotation;
  }

  set type(value) {
    if (value === this._state.type) { return; }

    this._state.type = value;
    this.setAttribute('type', this._state.type);
    this._render();
  }

  get type() {
    return this._state.type;
  }

  _createWrapper() {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('aria-hidden', 'true');
    wrapper.classList.add('e-icon-wrapper');
    return wrapper;
  }

  _render() {
    if (!this._state.icon) {
      return;
    }
    render(this._wrapper, template(this._state, this._utils));
  }

  _getIconName(iconName) {
    const glyphs = this._utils.themeHandler.getCurrentIconTheme().glyphs;

    const rawIconName = iconName?.replace(/^e-/, '');

    if (glyphs[iconName] || glyphs[rawIconName]) {
      return rawIconName;
    }

    const alias = Object.keys(glyphs).find(glyphKey => {
      const aliases = glyphs[glyphKey].aliases;
      return aliases.includes(iconName) || aliases.includes(rawIconName);
    });

    return alias ?? this._state.icon;
  }
}

export default EIcon;
