import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { EFormatterState } from './state';

export class EFormatter extends HTMLCustomElement {
  init() {
    this.state = new EFormatterState(this);
  }

  static observedAttributes = ['name'];

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
    this._dispatchChangeEvent();
  }

  set formatter(value) {
    this.state.formatter = value;
    this._dispatchChangeEvent();
  }

  connectedCallback() {
    this._dispatchChangeEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('remove', {
      detail: {
        name: this.state.name
      }
    }));
  }

  _dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      detail: {
        name: this.state.name,
        formatter: this.state.formatter
      }
    }));
  }
}

export default EFormatter;
