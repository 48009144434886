import { TRACK_EVENT_MESSAGE } from './constants.js';

class TrackerClient {

  trackEvent(details, top = window.top) {
    top.postMessage({ message: TRACK_EVENT_MESSAGE, data: { details } }, '*');
  };

}

export default TrackerClient;
