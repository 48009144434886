import TooltipEvents from './events/tooltip';
import PopupEvents from './events/popup';

class ETooltipEvents {
  constructor(component) {
    this.component = component;
    this.utils = component.utils;
    this.state = component.state;
    this.refs = component.refs;

    this._tooltipEvents = new TooltipEvents(this);
    this._popupEvents = new PopupEvents(this);
  }

  get popup() {
    return this._popupEvents;
  }

  get tooltip() {
    return this._tooltipEvents;
  }
}

export default ETooltipEvents;
