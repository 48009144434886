import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ESwitchState } from './state';
import { ESwitchEvents } from './events';
import { ESwitchTemplate } from './template';

export class ESwitch extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ESwitchState(this);
    this.events = new ESwitchEvents(this);
    this.template = new ESwitchTemplate(this);

    this.addEventListener('click', this.events.onSwitchClick);
  }

  connectedCallback() {
    this.render();
  }

  // Attributes
  static get observedAttributes() {
    return ['data-id', 'label', 'label-secondary', 'helper', 'name', 'value', 'checked', 'disabled'];
  }

  get dataId() {
    return this.state.id;
  }

  set dataId(value) {
    this.state.id = value;
  }

  get label() {
    return this.state.label;
  }

  set label(value) {
    this.state.label = value;
  }

  get labelSecondary() {
    return this.state.secondaryLabel;
  }

  set labelSecondary(value) {
    this.state.secondaryLabel = value;
  }

  get helper() {
    return this.state.helper;
  }

  set helper(value) {
    this.state.helper = value;
  }

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    this.state.value = value;
  }

  get checked() {
    return this.state.isChecked;
  }

  set checked(value) {
    this.state.isChecked = convertAttributeToBoolean(value);
    this.refs.input.checked = this.state.isChecked;
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }
}

export default ESwitch;
