class Template {
  constructor(node) {
    this._element = node;
  }

  render(data) {
    const mask = `mask-${parseInt(Math.random() * 10000)}`;
    const maskURL = `url(#${mask})`;
    const maskEmbossed = `${mask}-embossed`;
    const maskURLEmbossed = `url(#${maskEmbossed})`;
    const backgroundFill = data.background ? `rgba(${data.color}, .2)` : '#f3f3f3';
    const colorFill = `rgb(${data.color})`;
    const alphaColorFill = `rgba(${data.color}, .2)`;

    const backgroundCircle = `
      <circle class="e-ring__path" cx="${data.center}" cy="${data.center}" r="${data.radius}" fill="${backgroundFill}"
        mask="${maskURL}"></circle>
    `;

    const fullCircleEmbossed = `
      <circle class="e-ring__path" cx="${data.center}" cy="${data.center}" r="${data.radius + 5}"
        fill="${alphaColorFill}" mask="${maskURLEmbossed}"></circle>
    `;

    const fullCircle = `
      <circle class="e-ring__path" cx="${data.center}" cy="${data.center}" r="${data.radius}" fill="${colorFill}"
        mask="${maskURL}"></circle>

      ${data.type !== 'concentric' && data.type !== 'indicator' ? fullCircleEmbossed : ''}
    `;

    const incompleteCircleEmbossedPath = [
      `M${data.center},${data.center} L${data.embossPosition.x1},${data.embossPosition.y1}`,
      `A${data.embossRadius},${data.embossRadius}`,
      `0 ${data.percent > 50 ? 1 : 0},1 ${data.embossPosition.x2},${data.embossPosition.y2} z`
    ];
    const incompleteCircleEmbossed = `
      <path class="e-ring__path e-ring__path-stroke" d="${incompleteCircleEmbossedPath.join(' ')}"
        fill="${alphaColorFill}" mask="${maskURLEmbossed}" stroke-width="2px"></path>
    `;

    const incompleteCircleClass = data.separated ? 'e-ring__path e-ring__path-stroke' : 'e-ring__path';
    const incompleteCirclePath = [
      `M${data.center},${data.center} L${data.position.x1},${data.position.y1} A${data.radius},${data.radius}`,
      `0 ${data.percent > 50 ? 1 : 0},1 ${data.position.x2},${data.position.y2} z`
    ];

    const incompleteCircle = `
      <path class="${incompleteCircleClass}" d="${incompleteCirclePath.join(' ')}" fill="${colorFill}"
        mask="${maskURL}" stroke-width="2px"
        stroke="${(data.type === 'indicator' && parseInt(data.radius) > 20) ? data.stroke : 'transparent'}"></path>

      ${data.type !== 'concentric' && data.type !== 'indicator' ? incompleteCircleEmbossed : ''}
    `;

    const maskCircle = `
      <circle cx="${data.center}" cy="${data.center}" r="${data.radius - data.width}" fill="${data.maskColor}">
      </circle>
    `;

    this._element.innerHTML = `
      <svg width="${data.size}" height="${data.size}">
        <defs>
          <mask id="${mask}">
            <circle cx="${data.center}" cy="${data.center}" r="${data.radius}" fill="#fff"></circle>
            <circle cx="${data.center}" cy="${data.center}" r="${data.radius - data.width}" fill="#000"></circle>
          </mask>
          <mask id="${maskEmbossed}">
            <circle cx="${data.center}" cy="${data.center}" r="${data.radius + data.width}" fill="#fff"></circle>
            <circle cx="${data.center}" cy="${data.center}" r="${data.radius - data.width - 1}" fill="#000"></circle>
          </mask>
        </defs>

        ${(data.defaultBackground || data.background) && data.percent !== 100 ? backgroundCircle : ''}

        ${data.percent === 100 ? fullCircle : ''}
        ${data.percent !== 100 && data.percent !== 0 ? incompleteCircle : ''}

        ${data.maskColor ? maskCircle : ''}
      </svg>
    `;
  }
}

export default Template;
