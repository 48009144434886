export class EEmojipickerState {
  constructor(component) {
    this._requestRender = component.requestRender;

    this._opened = false;
    this._activeTab = 0;
    this._activeEmoji = null;
    this._emojis = [];
  }

  get opened() {
    return this._opened;
  }

  set opened(value) {
    this._opened = value;
    this._requestRender();
  }

  get activeTab() {
    return this._activeTab;
  }

  set activeTab(value) {
    return this._activeTab = value;
    this._requestRender();
  }

  get emojis() {
    return this._emojis;
  }

  set emojis(value) {
    this._emojis = value;
    this._requestRender();
  }

  get activeTabEmojis() {
    return this._emojis?.[this._activeTab] || [];
  }

  get activeEmoji() {
    return this._activeEmoji;
  }

  set activeEmoji(value) {
    this._activeEmoji = value;
    this._requestRender();
  }

  collectEmojis(emojis) {
    if (!emojis) {
      return [];
    }

    const categoryArray = [
      'Smileys & Emotion',
      'People & Body',
      'Animals & Nature',
      'Food & Drink',
      'Activities',
      'Travel & Places',
      'Objects',
      'Symbols',
      'Flags'
    ];

    const categorizedEmojis = categoryArray
      .map(category => emojis.data[category].sort((a, b) => a.sort_order - b.sort_order))
      .map(category => category.map(data => data.entity));

    return categorizedEmojis;
  }

  changeCategory(newActiveTab) {
    this._activeTab = newActiveTab;
    this._requestRender();
  }
}
