import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class EIframeLoader extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();
    this._src = false;

    this._events = {};
    this._events.loaded = this._removeLoader.bind(this);

    this._dom = {};
    this._dom.iframe = null;
    this._dom.loader = this._createLoader();
  }

  connectedCallback() {
    this.childrenChangedCallback([].map.call(this.children, child => [child]));
  }

  disconnectedCallback() {
    if (!this._dom.iframe) {
      return;
    }

    this._dom.iframe.removeEventListener('load', this._events.loaded);
  }

  static get observedAttributes() {
    return [];
  }

  childrenChangedCallback(nodes) {
    const foundElementList = nodes.filter(node => node.length && node[0].nodeName === 'IFRAME');
    if (!foundElementList.length) {
      return;
    }
    this.insertBefore(this._dom.loader, this.children[0]);
    this._dom.iframe = foundElementList.length ? foundElementList[0][0] : null;
    this._dom.iframe.addEventListener('load', this._events.loaded);
  }

  _createLoader() {
    const spinner = document.createElement('e-spinner');
    spinner.setAttribute('data-fullscreen', true);
    return spinner;
  }

  _removeLoader() {
    if (!this.contains(this._dom.loader)) {
      return;
    }

    this.removeChild(this._dom.loader);
  }
}

export default EIframeLoader;
