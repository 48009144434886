import { convertAttributeToBoolean, convertAttributeToString, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import { EPasswordEvents } from './events';
import { EPasswordState } from './state';
import { EPasswordTemplate } from './template';
import popupUtility from '../../utils/popup';
var InputState;
(function (InputState) {
    InputState["HIDE"] = "hide";
    InputState["REVEAL"] = "reveal";
})(InputState || (InputState = {}));
;
;
export class EPassword extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            input: null,
            copyPopupContent: null,
            copyButton: null
        };
        this.utils = { copyPopup: null };
        this.state = new EPasswordState(this);
        this.events = new EPasswordEvents(this);
        this.template = new EPasswordTemplate(this);
        this.refs.copyPopupContent = this.template.createCopyPopup();
    }
    connectedCallback() {
        this.requestRender().then(() => {
            this.utils.copyPopup = popupUtility.createPopup(this.refs.copyButton, this.refs.copyPopupContent, {
                closeOnOutsideClick: true,
                closeOnOEscPress: false,
                placement: 'top',
                type: 'success',
                noPadding: true,
                arrow: false,
                autoClose: true
            });
        });
    }
    get value() {
        return this.state.inputValue;
    }
    set value(value) {
        this.state.inputValue = convertAttributeToString(value);
    }
    get name() {
        return this.state.inputName;
    }
    set name(value) {
        this.state.inputName = convertAttributeToString(value);
    }
    get placeholder() {
        return this.state.placeholder;
    }
    set placeholder(value) {
        this.state.placeholder = convertAttributeToString(value);
    }
    get disabled() {
        return this.state.isDisabled;
    }
    set disabled(value) {
        this.state.isDisabled = convertAttributeToBoolean(value);
    }
    get readonly() {
        return this.state.isReadonly;
    }
    set readonly(value) {
        this.state.isReadonly = convertAttributeToBoolean(value);
    }
    get revealHidden() {
        return this.state.isRevealButtonHidden;
    }
    set revealHidden(value) {
        this.state.isRevealButtonHidden = convertAttributeToBoolean(value);
    }
    get copyVisible() {
        return this.state.isCopyButtonVisible;
    }
    set copyVisible(value) {
        this.state.isCopyButtonVisible = convertAttributeToBoolean(value);
    }
    // Rendering
    render() {
        render(this, this.template.createElement());
    }
    // Private methods
    _dispatchChangeEvent() {
        this.dispatchEvent(new CustomEvent('change', {
            detail: {
                value: this.state.inputValue
            }
        }));
    }
    _dispatchInputEvent() {
        this.dispatchEvent(new CustomEvent('input', {
            detail: {
                value: this.state.inputValue
            }
        }));
    }
    _dispatchButtonEvent(isReveal) {
        const inputState = isReveal ? InputState.REVEAL : InputState.HIDE;
        this.dispatchEvent(new CustomEvent(inputState));
    }
    _dispatchCopyButtonEvent() {
        this.dispatchEvent(new CustomEvent('copy'));
    }
}
// Attributes
EPassword.observedAttributes = ['value', 'placeholder', 'disabled', 'readonly', 'name', 'reveal-hidden', 'copy-visible'];
