import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import State from './state';
import Template from './template';

class EVerticalnavItem extends HTMLCustomElement {
  init() {
    this._template = new Template();
    this._state = new State(this._render.bind(this));
  }

  connectedCallback() {
    this._render();
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this._dispatchDeleteEvent();
  }

  static get observedAttributes() {
    return ['value', 'icon', 'status', 'badge'];
  }

  set value(value) {
    this._state.setState({ value: value });
    this._dispatchUpdateEvent();
  }

  set icon(value) {
    this._state.setState({ icon: value });
    this._dispatchUpdateEvent();
  }

  set status(value) {
    this._state.setState({ status: value || '' });
  }

  set badge(value) {
    this._state.setState({ badge: value || '' });
  }

  get status() {
    return this._state.state.status;
  }

  set menuStatus(value) {
    this._state.setState({ menuStatus: value });
  }

  _isConnected() {
    return this.parentNode;
  }

  _render() {
    if (!this._state.state.value || !this._isConnected()) { return; }

    render(this, this._template.render(this._state.state));
  }

  _measureWidth() {
    if (!this.children[0]) { return 0; }

    const clone = this.children[0].cloneNode(true);
    clone.style.position = 'absolute';

    document.body.appendChild(clone);
    const width = clone.offsetWidth;
    document.body.removeChild(clone);

    return width;
  }

  _dispatchUpdateEvent() {
    if (!this._isConnected() || this.parentNode.nodeName === 'BODY') { return; }

    this.dispatchEvent(new CustomEvent('verticalnavitem.update', {
      bubbles: true,
      detail: {
        uuid: this._state.state.uuid,
        width: this._measureWidth()
      }
    }));
  }

  _dispatchDeleteEvent() {
    this.dispatchEvent(new CustomEvent('verticalnavitem.delete', {
      bubbles: true,
      detail: { uuid: this._state.state.uuid }
    }));
  }
}

export default EVerticalnavItem;
