import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { EColorPickerState } from './state';
import { EColorPickerEvents } from './events';
import { EColorPickerTemplate } from './template';

export class EColorPicker extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new EColorPickerState(this);
    this.events = new EColorPickerEvents(this);
    this.template = new EColorPickerTemplate(this);

    this.refs.wrapper = this.template.createWrapper();
  }

  connectedCallback() {
    super._cleanupContainer('.e-color-picker__wrapper');

    this.requestRender().then(() => {
      this.insertAdjacentElement('beforeend', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  // Attributes
  static get observedAttributes() {
    return ['value', 'key'];
  }

  get value() {
    return this.refs.colorinput.value;
  }

  set value(value) {
    this.requestRender().then(() => {
      this.refs.colorinput.value = value;
    });
  }

  set key(value) {
    this.requestRender().then(() => {
      this.refs.colorpicker.key = value;
    });
  }

  // Rendering
  render() {
    if (!this.isConnected) { return; }

    render(this.refs.wrapper, this.template.createElement());
  }
}

export default EColorPicker;
