import { HTMLCustomElement, dateUtils } from '@emartech/ui-framework-utils';
import State from './state';

class EDatetimePreset extends HTMLCustomElement {
  init() {
    this._state = new State();
    this._uuid = this._uuid();
  }

  connectedCallback() {
    this._dispatchEventOnValidData();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('datetimepreset.delete', {
      bubbles: true,
      detail: {
        uuid: this._uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['name', 'value', 'order'];
  }

  set value(value) {
    let parsedValue;
    try {
      parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
    } catch (error) {
      return;
    }

    if (!this._isValidValue(parsedValue.start) || !this._isValidValue(parsedValue.end)) {
      return;
    }

    if (this._state.state.value.start === parsedValue.start && this._state.state.value.end === parsedValue.end) {
      return;
    }

    this._state.setState({ value: parsedValue });

    this._dispatchEventOnValidData();
  }

  set name(value) {
    if (this._state.state.name === value) {
      return;
    }

    this._state.setState({ name: value });
    this._dispatchEventOnValidData();
  }

  set order(value) {
    if (this._state.state.order === value) {
      return;
    }

    this._state.setState({ order: value });
    this._dispatchEventOnValidData();
  }

  _isValidValue(value) {
    return dateUtils.format(value, dateUtils.formats.fullDateWithHoursAndMins) !== 'Invalid Date';
  }

  _dispatchEventOnValidData() {
    if (!this._dataIsValid()) {
      return;
    }

    this.dispatchEvent(new CustomEvent('datetimepreset.change', {
      bubbles: true,
      detail: {
        data: this._state.state,
        uuid: this._uuid
      }
    }));
  }

  _uuid(placeholder) {
    if (placeholder) {
      return (placeholder ^ Math.random() * 16 >> placeholder / 4).toString(16);
    } else {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this._uuid);
    }
  }

  _dataIsValid() {
    return this._state.state.name !== '' && this._state.state.value !== '';
  }

}

export default EDatetimePreset;
