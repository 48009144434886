import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';
import { EValidatorTemplate } from './template.js';
import { EValidatorState } from './state.js';
import { EValidatorEvents } from './events.js';

class EValidator extends EDSComponent {
  static componentName = 'validator';

  init() {
    this.refs = {};
    this.utils = {};
    this.state = new EValidatorState(this);
    this.events = new EValidatorEvents(this);
    this.template = new EValidatorTemplate(this);

    this.refs.wrapper = this.template.createWrapper();
    this.refs.contentElement = null;
    this.utils.mutationObserver = new MutationObserver(this.events.onContentChange);

    this.addEventListener('headerClick', this.events.onHeaderClick);
    this.addEventListener('actionClick', this.events.onActionClick);
  }

  connectedCallback() {
    this.events.onContentChange();
    this.utils.mutationObserver.observe(this, { childList: true, subtree: false });

    this.requestRender().then(() => {
      this.insertAdjacentElement('afterbegin', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.utils.mutationObserver.disconnect();
  }

  static observedAttributes = [
    'header',
    'subject',
    'subheader',
    'status',
    'action',
    'action-href',
    'action-type',
    'summary',
    'summary-value',
    'summary-type',
    'opened',
    'standalone'
  ];

  set header(value) {
    this.state.header = value;
  }

  set subject(value) {
    this.state.subject = value;
  }

  set subheader(value) {
    this.state.subheader = AttributeUtils.convertToString(value);
  }

  set action(value) {
    this.state.action = AttributeUtils.convertToString(value);
  }

  set actionHref(value) {
    this.state.actionHref = AttributeUtils.convertToString(value);
  }

  set actionType(value) {
    this.state.actionType = AttributeUtils.convertToString(value);
  }

  set status(value) {
    const stringValue = AttributeUtils.convertToString(value);

    this.state.status = stringValue;
    this.state.iconColor = stringValue;
    this.state.iconName = stringValue;
  }

  set summary(value) {
    this.state.summary = AttributeUtils.convertToString(value);
  }

  set summaryValue(value) {
    this.state.summaryValue = AttributeUtils.convertToString(value);
  }

  set summaryType(value) {
    this.state.summaryColor = AttributeUtils.convertToString(value);
    this.state.summaryType = AttributeUtils.convertToString(value);
  }

  set opened(value) {
    const isContentVisible = AttributeUtils.convertToBoolean(value);
    this.state.isContentVisible = isContentVisible;

    this.setVisibilityOfContentElement();
  }

  set standalone(value) {
    this.reflectBooleanAttribute('standalone', value);
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  setVisibilityOfContentElement() {
    if (!this.refs.contentElement) { return; }

    this.refs.contentElement.style.height = this.state.isContentVisible ? 'auto' : 0;
    this.refs.contentElement[this.state.isContentVisible ? 'setAttribute' : 'removeAttribute']('opened', '');
  }
}

export default EValidator;
