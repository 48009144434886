import autoBind from 'auto-bind';

export class EColorPickerEvents {
  constructor(component) {
    autoBind(this);

    this.component = component;
    this.refs = component.refs;
    this.state = component.state;
  }

  onColorinputClick() {
    if (this.refs.colorpicker.isOpened) {
      this.refs.colorpicker.close();
    } else {
      this._openColorpicker();
    }
  }

  onColorinputKeydown(event) {
    if (!['Space', 'Enter'].includes(event.code)) { return; }
    event.preventDefault();
    event.stopPropagation();

    this._openColorpicker();
  }

  onColorpickerApply(event) {
    if (!this.refs.colorinput) { return; }

    this.refs.colorinput.value = event.detail.value;
    this.state.temporaryValue = '';

    this.component.dispatchEvent(new CustomEvent('change', {
      detail: {
        value: event.detail.value
      }
    }));
  }

  onColorpickerChange(event) {
    this.state.temporaryValue = event.detail.value;
  }

  onColorpickerCancel() {
    this.state.temporaryValue = '';
  }

  _openColorpicker() {
    this.refs.colorpicker.open(this.refs.colorinput, this.state.temporaryValue || this.refs.colorinput.value);
  }
}

export default EColorPickerEvents;
