export class EValidationState {
  constructor() {
    this._name = '';
    this._validation = null;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get validation() {
    return this._validation;
  }

  set validation(value) {
    this._validation = value;
  }
}
