import autoBind from 'auto-bind';

export class ETimeEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
  }

  onConfigChange(config) {
    this.state.updateFormatsFromConfig(config);
  }
}

export default ETimeEvents;
