import { dateUtils } from '@emartech/ui-framework-utils';
import State from '../../../utils/state/index.js';

class DatetimeState extends State {
  get defaultState() {
    return {
      dateInputString: '',
      datetimeString: '',
      dateString: '',
      timeString: '',
      isDateValid: true,
      isTimeValid: true,
      min: undefined,
      max: undefined,
      rangeOtherEnd: undefined,
      isTime: false,
      todayHidden: false,
      resetTime: undefined,
      inputLabel: 'components.datetime.panel.inputLabel.date.start',
      disabled: false,

      get configFormats() {
        const { dateFormat, timeFormat } = window.e.utils.getCurrentConfig();
        return { dateFormat, timeFormat };
      },

      get minDate() {
        const minTime = dateUtils.format(this.min, dateUtils.formats.hoursAndMins);
        const isTimeBeforeMinTime = this.timeString < minTime;
        return addDays(this.min, isTimeBeforeMinTime ? 1 : 0);
      },

      get maxDate() {
        const maxTime = dateUtils.format(this.max, dateUtils.formats.hoursAndMins);
        const isTimeAfterMaxTime = this.timeString > maxTime;
        return addDays(this.max, isTimeAfterMaxTime ? -1 : 0);
      }

    };
  }
}

const addDays = (value, days) => {
  const newDate = dateUtils.addDays(dateUtils.parse(value), days);
  return dateUtils.format(newDate, dateUtils.formats.fullDate);
};

export default DatetimeState;
