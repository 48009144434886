import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import hexRgb from 'hex-rgb';
import uuid from '../../utils/uuid';
import Template from './template';

class ECircle extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      type: 'default',
      color: '#012851',
      stroke: '#fff',
      defaultBackground: false,
      background: false,
      width: 13,
      radius: 50,
      separated: false,
      startPercent: 0
    };

    this._template = new Template(this);
  }

  connectedCallback() {
    this._dispatchChangeEvent();
    this._render();
  }

  disconnectedCallback() {
    const event = new CustomEvent('circle.delete', {
      bubbles: true,
      detail: { uuid: this._state.uuid }
    });

    this.dispatchEvent(event);
  }

  static get observedAttributes() {
    return ['percent', 'color', 'stroke', 'default-background', 'background'];
  }

  set percent(value) {
    this._state.percent = parseFloat(value) || 0;
    this._dispatchChangeEvent();
    this._render();
  }

  set color(value) {
    this._state.color = value || '#012851';
    this._render();
  }

  set stroke(value) {
    this._state.stroke = value || '#fff';
    this._render();
  }

  set defaultBackground(value) {
    this._state.defaultBackground = value;
  }

  set background(value) {
    this._state.background = value;
  }

  setProperty(key, value) {
    this._state[key] = value;
    this._render();
  }

  setProperties(properties) {
    this._state = { ...this._state, ...properties };
    this._render();
  }

  _render() {
    const containerPadding = this._state.type === 'indicator' ? 6 : 14;
    const radius = this._state.radius ? this._state.radius + this._state.width : 56 + this._state.width;
    const size = this._state.size || radius * 2 + containerPadding;
    const center = size / 2;
    const embossRadius = radius + 6;
    const startAngle = (360 * this._state.startPercent / 100) - 90;
    const endAngle = startAngle + 360 * this._state.percent / 100;

    if (isNaN(startAngle) || isNaN(endAngle)) { return; }

    const renderProperties = {
      type: this._state.type,
      background: this._state.background,
      defaultBackground: this._state.defaultBackground,
      color: this._convertColorToRgb(this._state.color),
      stroke: this._state.stroke,
      embossed: true,
      embossPosition: { x1: 0, x2: 0, y1: 0, y2: 0 },
      embossRadius,
      percent: this._state.percent,
      position: { x1: 0, x2: 0, y1: 0, y2: 0 },
      separated: this._state.separated,
      maskColor: this._state.maskColor,
      radius,
      size,
      center,
      width: this._state.width
    };

    const startCos = Math.cos(Math.PI * startAngle / 180);
    const startSin = Math.sin(Math.PI * startAngle / 180);
    const endCos = Math.cos(Math.PI * endAngle / 180);
    const endSin = Math.sin(Math.PI * endAngle / 180);

    renderProperties.position.x1 = center + radius * startCos;
    renderProperties.position.y1 = center + radius * startSin;

    renderProperties.position.x2 = center + radius * endCos;
    renderProperties.position.y2 = center + radius * endSin;

    renderProperties.embossPosition.x1 = center + embossRadius * startCos;
    renderProperties.embossPosition.y1 = center + embossRadius * startSin;

    renderProperties.embossPosition.x2 = center + embossRadius * endCos;
    renderProperties.embossPosition.y2 = center + embossRadius * endSin;

    this._template.render(renderProperties);
  }

  _dispatchChangeEvent() {
    if (!this.parentNode || this.parentNode.nodeName !== 'E-RING') { return; }

    const event = new CustomEvent('circle.update', {
      bubbles: true,
      detail: { ...this._state }
    });

    this.dispatchEvent(event);
  }

  _convertColorToRgb(color) {
    try {
      return hexRgb(color, { format: 'array' }).slice(0, 3);
    } catch (error) {
      return [0, 0, 0];
    }
  }
}

export default ECircle;
