export class ERulelistGroupState {
  #requestRender;
  #reflectBooleanAttribute;

  #isClosed = false;
  #isReadOnly = false;
  #isToggling = false;
  #color = '';
  #styleSheet;
  #isSlotVisible = {};

  constructor(component) {
    this.#requestRender = component.requestRender.bind(component);
    this.#reflectBooleanAttribute = component.reflectBooleanAttribute.bind(component);
    this.#styleSheet = component.styleSheet;
  }

  get isClosed() {
    return this.#isClosed;
  }

  set isClosed(value) {
    this.#isClosed = value;
    this.#reflectBooleanAttribute('closed', value);
    this.#requestRender();
  }

  get isReadOnly() {
    return this.#isReadOnly;
  }

  set isReadOnly(value) {
    this.#isReadOnly = value;
    this.#reflectBooleanAttribute('readonly', value);
    this.#requestRender();
  }

  get color() {
    return this.#color;
  }

  set color(value) {
    this.#color = value;

    const cssRule = value ?
      // eslint-disable-next-line max-len
      `:host { --rulelist-header-background: var(--token-${value}-100); --rulelist-border: var(--token-${value}-300); }` :
      '';
    this.#styleSheet.replaceSync(cssRule);

    this.#requestRender();
  }

  get isToggling() {
    return this.#isToggling;
  }

  set isToggling(value) {
    this.#isToggling = value;
    this.#reflectBooleanAttribute('toggling', value);
  }

  get isSlotVisible() {
    return this.#isSlotVisible;
  }

  setSlotVisibility(slotName, isVisible) {
    this.#isSlotVisible[slotName] = isVisible;
    this.#requestRender();
  }

  isHeaderVisible() {
    const conditions = [
      this.#isSlotVisible.header,
      this.#isSlotVisible['header-action']
    ];

    return conditions.some(condition => condition);
  }

  isFeedbackVisible() {
    return this.#isSlotVisible.feedback;
  }

  isItemsVisible() {
    return this.#isSlotVisible.items;
  }

  isFooterVisible() {
    const conditions = [
      this.#isSlotVisible.footer,
      this.#isSlotVisible['footer-action']
    ];

    return conditions.some(condition => condition);
  }

  isRelationVisible() {
    return this.#isSlotVisible.relation;
  }
}

export default ERulelistGroupState;
