import { highlightKeywordsInItemContent, isKeywordInItemText } from './utils.js';

const validSpacings = ['cozy', 'none'];
const defaultLimit = 1000;

export class EActionListState {
  constructor(component) {
    this.requestRender = component.requestRender;
    this._updateScrollPosition = component._updateScrollPosition.bind(component);
    this._updateFocus = component._updateFocus.bind(component);
    this._refs = component.refs;

    this._items = [];
    this._itemLimit = defaultLimit;
    this._filteredItems = [];
    this._groups = [];
    this._selectedItems = [];
    this._activeItem = null;
    this._hoveredItemUuid = null;

    this._searchKeywords = [];
    this._emptyStateText = null;

    this._isLoading = false;
    this._isSearchAlwaysShown = false;
    this._isActiveAlwaysShown = false;
    this._isSearchHidden = false;
    this._isSelectionDisabled = false;
    this._isMultipleSelectionAllowed = false;
    this._isMinWidthDisabled = false;
    this._isInPopover = false;
    this._isAutoFocusEnabled = false;

    this._height = null;
    this._maxHeight = null;
    this._selectionMax = null;

    this._spacing = validSpacings[0];

    this._translations = {};
  }

  get items() {
    return this._items;
  }

  set items(value) {
    this._items = value;
    this.requestRender();
  }

  get itemLimit() {
    return this._itemLimit;
  }

  set itemLimit(value) {
    const limit = parseInt(value, 10);
    this._itemLimit = isNaN(limit) ? defaultLimit : limit;
    this.requestRender();
  }

  get isItemLimitReached() {
    return this.itemLimit > 0 && this.allItems.length > this.itemLimit;
  }

  get isFilteredItemLimitReached() {
    return this.itemLimit > 0 && this.filteredItems.length > this.itemLimit;
  }

  get groups() {
    return this._groups;
  }

  set groups(value) {
    this._groups = value;
    this.requestRender();
  }

  get groupedItems() {
    return this._groups.flatMap(group => group.items);
  }

  get allItems() {
    return [...this._items, ...this.groupedItems];
  }

  get hasAnyItems() {
    return this.allItems.length > 0;
  }

  get filteredItems() {
    return this._filteredItems;
  }

  set filteredItems(value) {
    this._filteredItems = value;
  }

  get limitedItems() {
    const sortedFilteredItems = this.isItemLimitReached ?
      this._filteredItems.sort((itemA, itemB) => itemB.selected - itemA.selected) : this._filteredItems;
    return this._itemLimit ? sortedFilteredItems.slice(0, this._itemLimit) : this._filteredItems;
  }

  updateFilteredItems() {
    const shouldFilterItems = this.isSearchVisible && this.searchKeywords.length > 0;
    const filteredItems = shouldFilterItems ?
      this.allItems.filter(item => this.searchKeywords.every(keyword => isKeywordInItemText(item, keyword))) :
      this.allItems;

    const shouldHighlightContent = shouldFilterItems && this.items.every(item => !item.hasHTMLContent);
    const highlightedItems = shouldHighlightContent ?
      filteredItems.map(item => highlightKeywordsInItemContent(item, this.searchKeywords)) :
      filteredItems.map(item => ({ ...item, highlightedContent: item.content }));

    this.filteredItems = highlightedItems;
  }

  updateItem(itemToUpdate) {
    const index = this._items.findIndex(item => itemToUpdate.uuid === item.uuid);
    this._items[index] = itemToUpdate;

    this.requestRender();
  }

  deleteItem(itemToDelete) {
    this._items = this._items.filter(item => itemToDelete.uuid !== item.uuid);
    this.deselectItem(itemToDelete);

    if (itemToDelete.uuid === this.activeItem?.uuid) {
      this.resetActiveItem();
    }

    this.requestRender();
  }

  upsertItem(item) {
    if (this._isItemExists(item)) {
      this.updateItem(item);
    } else {
      this.insertItem(item);
    }

    if (item.selected) {
      if (!this._isSelectionDisabled) {
        this.selectItem(item);
      }
    } else {
      this.deselectItem(item);
    }

    this.requestRender().then(() => {
      this._updateScrollPosition();
    });
  }

  getItemByUuid(uuid) {
    return this.allItems.find(item => item.uuid === uuid);
  }

  get activateableItems() {
    return this.filteredItems.filter(item => !item.disabled && !this.isNotSelectedAndMaximumReached(item));
  }

  get hasFilteredItems() {
    return this.filteredItems.length > 0;
  }

  get selectedItems() {
    return this._selectedItems;
  }

  set selectedItems(value) {
    this._selectedItems = value;
    this.requestRender();
  }

  get hasSelectedItems() {
    return this.selectedItems.length > 0;
  }

  get selectedItemValues() {
    if (!this.isMultipleSelectionAllowed) {
      return this._selectedItems.map(item => item.value)[0];
    }

    return this._selectedItems.map(item => item.value);
  }

  get activeItem() {
    if (this.hasActiveItem) {
      return this._activeItem;
    }

    const firstVisibleSelectedItem = this.selectedItems.find(item => !item.disabled && this.isItemVisible(item));

    if (!!firstVisibleSelectedItem) {
      return firstVisibleSelectedItem;
    }

    return this.filteredItems.find(item => !item.disabled) || this.allItems[0];
  }

  set activeItem(value) {
    this._activeItem = value;
    this.requestRender().then(() => {
      this._updateScrollPosition();
    });
  }

  set hoveredItem(value) {
    this._hoveredItemUuid = value;
    this.requestRender();
  }

  get isFirstItemActive() {
    return this.activeItem.uuid === this.activateableItems[0]?.uuid;
  }

  get isLastItemActive() {
    return this.activeItem.uuid === this.activateableItems[this.activateableItems.length - 1]?.uuid;
  }

  moveActiveItem(position) {
    const activeItemIndex = this.activateableItems.findIndex(item => this.activeItem?.uuid === item.uuid);
    const directionIndexMap = {
      'previous': activeItemIndex - 1,
      'next': activeItemIndex + 1,
      'first': 0,
      'last': this.activateableItems.length - 1
    };
    const nextIndex = directionIndexMap[position];

    if (nextIndex === undefined || nextIndex < 0 || nextIndex >= this.activateableItems.length) { return; }

    this.activeItem = this.activateableItems[nextIndex];
  }

  get hasActiveItem() {
    return this._activeItem !== null;
  }

  get searchKeywords() {
    return this._searchKeywords;
  }

  set searchKeywords(value) {
    this._searchKeywords = value;
    this.requestRender().then(() => {
      this._updateScrollPosition();
    });
  }

  get emptyStateText() {
    return this._emptyStateText;
  }

  set emptyStateText(value) {
    this._emptyStateText = value;
    this.requestRender();
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
    this.requestRender().then(() => {
      this._updateFocus();
    });
  }

  get isSearchAlwaysShown() {
    return this._isSearchAlwaysShown;
  }

  set isSearchAlwaysShown(value) {
    this._isSearchAlwaysShown = value;
    this.requestRender();
  }

  get isActiveAlwaysShown() {
    return this._isActiveAlwaysShown;
  }

  set isActiveAlwaysShown(value) {
    this._isActiveAlwaysShown = value;
    this.requestRender();
  }

  get isSearchHidden() {
    return this._isSearchHidden;
  }

  set isSearchHidden(value) {
    this._isSearchHidden = value;
    this.requestRender();
  }

  get isSearchVisible() {
    return this._isSearchAlwaysShown ||
      (this.allItems.length > 8 && !this._isSearchHidden) ||
      this.isItemLimitReached;
  }

  get isSelectionDisabled() {
    return this._isSelectionDisabled;
  }

  set isSelectionDisabled(value) {
    this._isSelectionDisabled = value;
    this.requestRender();
  }

  get isMultipleSelectionAllowed() {
    return this._isMultipleSelectionAllowed;
  }

  set isMultipleSelectionAllowed(value) {
    this._isMultipleSelectionAllowed = value;
    this.requestRender();
  }

  get isMinWidthDisabled() {
    return this._isMinWidthDisabled;
  }

  set isMinWidthDisabled(value) {
    this._isMinWidthDisabled = value;
    this.requestRender();
  }

  get isInPopover() {
    return this._isInPopover;
  }

  set isInPopover(value) {
    this._isInPopover = value;
    this.requestRender();
  }

  get isAutoFocusEnabled() {
    return this._isAutoFocusEnabled;
  }

  set isAutoFocusEnabled(value) {
    this._isAutoFocusEnabled = value;

    this.requestRender().then(() => {
      this._updateFocus();
    });
  }

  get height() {
    return this._height;
  }

  set height(value) {
    this._height = value;
    this.requestRender();
  }

  get maxHeight() {
    return this._maxHeight;
  }

  set maxHeight(value) {
    this._maxHeight = value;
    this.requestRender();
  }

  get spacing() {
    return this._spacing;
  }

  set spacing(value) {
    this._spacing = validSpacings.includes(value) ? value : validSpacings[0];
    this.requestRender();
  }

  get translations() {
    return this._translations;
  }

  set translations(value) {
    this._translations = value;
    this.requestRender();
  }

  get shouldShowEmptyState() {
    return !this.hasAnyItems || (this.isSearchVisible && !this.hasFilteredItems);
  }

  get selectionMax() {
    return this._selectionMax;
  }

  set selectionMax(value) {
    this._selectionMax = value;
    this.requestRender();
  }

  get isSelectionMaxReached() {
    return this._isMultipleSelectionAllowed && !!this._selectionMax && this._selectedItems.length >= this._selectionMax;
  }

  resetActiveItem() {
    this._activeItem = null;

    this.requestRender();
  }

  resetSearchKeywords() {
    if (this._refs.searchInput) {
      this._refs.searchInput.value = '';
    }
    this.searchKeywords = [];
  }

  updateItemSelections() {
    this._selectedItems = [];

    this.allItems.forEach(item => {
      if (item.selected && !this._isSelectionDisabled) {
        this.selectItem(item);
      }
    });
  }

  selectItem(item) {
    if (this.isItemSelected(item)) { return; }

    if (!this.isMultipleSelectionAllowed) {
      this.clearAllSelections();
    }
    this.selectedItems.push(item);

    this.requestRender();
  }

  deselectItem(itemToDeselect) {
    if (!this.isItemSelected(itemToDeselect)) { return; }

    this._selectedItems = this._selectedItems.filter(item => itemToDeselect.uuid !== item.uuid);

    this.requestRender();
  }

  toggleItemSelection(item) {
    if (this.isSelectionDisabled || item.disabled) { return; }
    if (this.isItemSelected(item) && !this.isMultipleSelectionAllowed) { return; }

    if (this.isItemSelected(item)) {
      this.deselectItem(item);
    } else {
      this.selectItem(item);
    }
  }

  isNotSelectedAndMaximumReached(item) {
    return !this.isItemSelected(item) && this.isSelectionMaxReached;
  }

  clearAllSelections() {
    this._selectedItems = [];
  }

  insertItem(item) {
    this._items.push(item);

    this.requestRender();
  }

  isItemSelected(itemToCheck) {
    return !!this.selectedItems.find(item => itemToCheck?.uuid === item.uuid);
  }

  isItemActive(itemToCheck) {
    return this.activeItem?.uuid === itemToCheck?.uuid;
  }

  isItemVisible(itemToCheck) {
    return this.filteredItems.some(item => itemToCheck.uuid === item.uuid);
  }

  isItemHovered(uuid) {
    return this._hoveredItemUuid === uuid;
  }

  insertGroup(group) {
    this._groups.push(group);

    this.requestRender();
  }

  updateGroup(groupToUpdate) {
    const index = this._groups.findIndex(group => groupToUpdate.uuid === group.uuid);
    this._groups[index] = groupToUpdate;

    this.requestRender();
  }

  deleteGroup(groupToDelete) {
    this._groups = this._groups.filter(group => groupToDelete.uuid !== group.uuid);

    groupToDelete.items.forEach(item => {
      this.deselectItem(item);
    });

    if (!this._isItemExists(this._activeItem)) {
      this.resetActiveItem();
    }

    this.requestRender();
  }

  upsertGroup(group) {
    if (this._isGroupExists(group)) {
      this.updateGroup(group);
    } else {
      this.insertGroup(group);
    }

    group.items.forEach(item => {
      if (item.selected) {
        if (!this._isSelectionDisabled) {
          this.selectItem(item);
        }
      } else {
        this.deselectItem(item);
      }
    });

    this.selectedItems = this.selectedItems.filter(selectedItem => {
      if (selectedItem.group !== group.uuid) {
        return true;
      }

      return group.items.some(item => item.uuid === selectedItem.uuid);
    });

    this.requestRender().then(() => {
      this._updateScrollPosition();
    });
  }

  getGroupByUuid(uuid) {
    return this._groups.find(group => group.uuid === uuid);
  }

  getGroupItems(group) {
    return this.limitedItems.filter(item => item.group === group.uuid);
  }

  _isItemExists(item) {
    return !!this.getItemByUuid(item?.uuid);
  }

  _isGroupExists(group) {
    return !!this.getGroupByUuid(group?.uuid);
  }
}

export default EActionListState;
