import manifest from '../../../../dist/app.js';
import * as Sentry from '@sentry/browser';

const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, (args) => {
  const event = args.getEvent();
  const routeTo = event?.extra?.routeTo || [];
  return Array.isArray(routeTo) ? routeTo : [];
});

const beforeSend = (event) => {
  if (event?.exception?.values?.[0].stacktrace.frames) {
    const frames = event.exception.values[0].stacktrace.frames;
    const routeTo = frames
      .filter((frame) => frame.module_metadata?.dsn)
      .map((frame) => frame.module_metadata)
      .slice(-1);

    if (routeTo.length) {
      event.extra = { ...event.extra, routeTo };
    }
  }

  return event;
};

export const uiMonitor = {
  init() {
    Sentry.init({
      dsn: manifest.sentryDSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.moduleMetadataIntegration()
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      transport,
      beforeSend
    });

    console.log('Sentry is initialized'); //eslint-disable-line no-console
  }
};
