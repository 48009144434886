import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import { ERadioState } from './state';
import { ERadioEvents } from './events';
import { ERadioTemplate } from './template';

export class ERadio extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.state = new ERadioState(this);
    this.events = new ERadioEvents(this);
    this.template = new ERadioTemplate(this);

    this.addEventListener('click', this.events.onClick);
    this.addEventListener('change', this.events.onChange);
  }

  connectedCallback() {
    this.render();
  }

  // Attributes
  static get observedAttributes() {
    return ['data-id', 'label', 'name', 'value', 'checked', 'disabled', 'type'];
  }

  get dataId() {
    return this.state.id;
  }

  set dataId(value) {
    this.state.id = value;
  }

  get type() {
    return this.state.type;
  }

  set type(value) {
    const allowedTypes = ['error', 'success', 'warning', 'info'];
    if (!allowedTypes.includes(value)) { return; }

    this.state.type = value;
  }

  get label() {
    return this.state.label;
  }

  set label(value) {
    this.state.label = value;
  }

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
  }

  get value() {
    return this.state.value;
  }

  set value(value) {
    this.state.value = value;
  }

  get checked() {
    return this.state.isChecked;
  }

  set checked(value) {
    this.state.isChecked = convertAttributeToBoolean(value);
    this.refs.input.checked = this.state.isChecked;
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
  }

  // Rendering
  render() {
    render(this, this.template.createElement());
  }

  // Private methods
  _updateRadiosWithSameName() {
    if (!this.state.name) { return; }

    const radios = document.querySelectorAll('e-radio');

    for (const radio of radios) {
      if (radio.name !== this.state.name || radio === this) { continue; }

      radio.checked = false;
    }
  }
}

export default ERadio;
