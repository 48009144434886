import { openDB } from 'idb';

const DATABASE_NAME = 'ui-framework';
const DATABASE_VERSION = 1;

class Database {
  constructor(objectStoreName, openingDb) {
    this._objectStoreName = objectStoreName;
    this._openingDb = openingDb;
  }

  async isAvailable() {
    try {
      await this._openingDb;
      return true;
    } catch (error) {
      return false;
    }
  }

  async get(key) {
    const db = await this._openingDb;
    return db.get(this._objectStoreName, key);
  }

  async set(key, value) {
    const db = await this._openingDb;
    return db.put(this._objectStoreName, value, key);
  }

  static create(objectStoreName) {
    const openingDb = openDB(DATABASE_NAME, DATABASE_VERSION, {
      upgrade(db) {
        db.createObjectStore(objectStoreName);
      }
    });
    return new Database(objectStoreName, openingDb);
  }
}

export default Database;
