import { html } from 'uhtml';
import classNames from 'clsx';

const createArrowElements = (state, events) => {
  if (state.disabled) { return; }

  const downArrowClass = classNames(
    'e-btn e-btn-borderless e-btn-onlyicon',
    'e-numberinput__arrow e-numberinput__arrow-down',
    { 'e-btn-disabled': state.isArrowDownDisabled }
  );

  const upArrowClass = classNames(
    'e-btn e-btn-borderless e-btn-onlyicon',
    'e-numberinput__arrow e-numberinput__arrow-up',
    { 'e-btn-disabled': state.isArrowUpDisabled }
  );

  return html`
    <div>
      <div class=${upArrowClass} @mousedown=${events.increase}>
        <e-icon icon="e-move-up"></e-icon>
      </div>
      <div class=${downArrowClass} @mousedown=${events.decrease}>
        <e-icon icon="e-move-down"></e-icon>
      </div>
    </div>
  `;
};

const createInputElement = (state, events, refs) => {
  const inputClass = classNames('e-input e-numberinput__input', {
    [`e-numberinput__input-${state.size}`]: state.size,
    'e-input-error': !state.isValid || state.isForceInvalid
  });

  return html`
    <input
      ref=${node => refs.input = node}
      class=${inputClass}
      type="text"
      .value=${state.isFocused || !state.isValid ? state.value : state.displayValue}
      placeholder=${state.placeholder}
      @keydown=${events.keyDown}
      @focus=${events.focus}
      @blur=${events.blur}
      @input=${events.input}
      @change=${events.change}
      ?disabled=${state.disabled}
    >
  `;
};

export default (state, events, refs) => {
  const style = state.size ? 'display: inline-block;' : '';

  return html`
    <div class="e-numberinput" style=${style}>
      ${createInputElement(state, events, refs)}
      ${createArrowElements(state, events)}
    </div>
    <input type="hidden" value=${state.value} name=${state.name} ?disabled=${state.disabled}>
  `;
};
