import translator from '../../js/utils/translator/index.js';

export class EPaginationState {
  constructor(component) {
    this._requestRender = component.requestRender.bind(component);
    this.maxPage = 0;
    this.currentPage = 1;
    this._hideThreshold = 3;
    this._hiddenPagesPlaceholder = '...';
    this._defaultLabel = translator.translate('components.pagination.defaultLabel');
    this._label = this._defaultLabel;
    this.loading = false;
  }


  get hiddenPagesPlaceholder() {
    return this._hiddenPagesPlaceholder;
  }

  get defaultLabel() {
    return this._defaultLabel;
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
    this._requestRender();
  }

  generateLabels() {
    const labels = this.maxPage < 8 ? this._range(1, this.maxPage) : this._generateLabelsWithMoreThan8Page();
    return labels.map(label => label.toString());
  }

  _generateLabelsWithMoreThan8Page() {
    if (this.currentPage <= 1 + this._hideThreshold) {
      return this._createRightHiddenLabels();
    }

    if (this.currentPage < this.maxPage - this._hideThreshold) {
      return this._createBothWayHiddenLabels(this.currentPage);
    }

    return this._createLeftHiddenLabels();
  }

  _createRightHiddenLabels() {
    return [...this._range(1, 5), this._hiddenPagesPlaceholder, this.maxPage];
  }

  _createLeftHiddenLabels() {
    return [1, this._hiddenPagesPlaceholder, ...this._range(this.maxPage - 4, this.maxPage)];
  }

  _createBothWayHiddenLabels(currentPage) {
    return [
      1,
      this._hiddenPagesPlaceholder,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      this._hiddenPagesPlaceholder,
      this.maxPage
    ];
  }

  _range(min, max) {
    const range = [];

    for (let i = min; i <= max; ++i) {
      range.push(i);
    }

    return range;
  }
}
