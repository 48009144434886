import { html } from 'uhtml';
import classnames from 'clsx';

class ETooltipTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createPopup() {
    const popupClasses = classnames('e-tooltip-popup', {
      'e-tooltip-popup-permission': this.state.isPermissionType,
      'e-tooltip-popup-has_action': this.state.hasAction
    });

    return html.node`
      <div
        class="${popupClasses}"
        @mouseenter=${this.events.popup.onMouseEnter}
        @mouseleave=${this.events.popup.onMouseLeave}
      ></div>
    `;
  }

  createHelperIcon() {
    return html.node`
      <e-icon icon="question-circle" type="helper"></e-icon>
    `;
  }

  createFocusInCatcher() {
    return html.node`
      <div
        class="e-tooltip-content__focus_in_catcher"
        tabindex="0"
        @focus=${this.events.tooltip.onFocusInCatcherFocus}
        @focusout=${this.events.tooltip.onFocusCatcherFocusOut}
      ></div>
    `;
  }

  createFocusOutCatcher() {
    return html.node`
      <div
        class="e-tooltip-content__focus_out_catcher"
        tabindex="0"
        @focus=${this.events.tooltip.onFocusOutCatcherFocus}
        @focusout=${this.events.tooltip.onFocusCatcherFocusOut}
      ></div>
    `;
  }

  createPopupContent() {
    return html`
      <div class="e-tooltip-popup__content" ref="${(node) => this.refs.popupContent = node}">
        ${this.createPopupContentPermissionIcon()}
        ${html([this.state.content])}
      </div>
    `;
  }

  createPopupContentPermissionIcon() {
    if (!this.state.isPermissionType) { return; }

    return html`<e-icon color="error" icon="lock" size="small"></e-icon>`;
  }
}

export default ETooltipTemplate;
