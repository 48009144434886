import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { ERulelistItemState } from './state.js';
import { ERulelistItemEvents } from './events.js';
import { ERulelistItemTemplate } from './template.js';

export class ERulelistItem extends EDSComponent {
  static componentName = 'rulelist-item';
  #shadowRoot = null;

  // Lifecycle methods
  constructor() {
    super();

    this.#shadowRoot = this.attachShadow({ mode: 'open' });

    this.state = new ERulelistItemState(this);
    this.events = new ERulelistItemEvents(this);
    this.template = new ERulelistItemTemplate(this);
    this.template.createCloseWrapper();
    this.template.createOpenWrapper();
  }

  connectedCallback() {
    super._cleanupContainer('e-rulelist-item-close-wrapper');
    super._cleanupContainer('e-rulelist-item-open-wrapper');
    this.appendChild(this.refs.closeWrapper);
    this.appendChild(this.refs.openWrapper);

    this.requestRender().then(() => {
      const slots = this.#shadowRoot.querySelectorAll('slot');
      [...slots].forEach(slot => this.events.onSlotChange(slot));
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  // Attributes
  static get observedAttributes() {
    return ['open', 'readonly'];
  }

  get open() {
    return this.state.isOpen;
  }

  set open(value) {
    this.state.isOpen = AttributeUtils.convertToBoolean(value);
  }

  get readonly() {
    return this.state.isReadOnly;
  }

  set readonly(value) {
    this.state.isReadOnly = AttributeUtils.convertToBoolean(value);
  }

  // Rendering
  render() {
    if (!this.isConnected) { return; }

    render(this.#shadowRoot, this.template.createElement());
    render(this.refs.closeWrapper, this.template.createCloseElement());
    render(this.refs.openWrapper, this.template.createOpenElement());
  }
}

export default ERulelistItem;
