import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import manifest from '../../../../../dist/app.js';
import template from './template';

const DEFAULT_DEVICE = 'ios_phone_default';

class EDevicePush extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();
    this._dom = {};
    this._dom.container = document.createElement('e-device-push-container');
    this._state = {};
    this._state.icon = `${manifest.uiAssets}/static/images/e-device/e-device-push-ios-icon-mail.svg`;
    this._state.appTitle = '';
    this._state.headline = '';
    this._state.subtitle = '';
    this._state.content = '';
    this._state.media = '';
    this._state.device = DEFAULT_DEVICE;
    this._state.type = 'lockscreen';
    this._state.expanded = false;
    this._state.editable = false;
  }

  connectedCallback() {
    const container = this.querySelector('e-device-push-container');
    if (container) {
      this._dom.container = container;
    } else {
      this.appendChild(this._dom.container);
    }
    this._render();
  }

  disconnectedCallback() {
    this.removeChild(this._dom.container);
  }

  static get observedAttributes() {
    return ['icon', 'app-title', 'headline', 'subtitle', 'content', 'device', 'type', 'expanded', 'editable', 'media'];
  }

  set icon(value) {
    this._state.icon = value;
    this._render();
  }

  set appTitle(value) {
    this._state.appTitle = value;
    this._render();
  }

  set headline(value) {
    this._state.headline = value;
    this._render();
  }

  set subtitle(value) {
    this._state.subtitle = value;
    this._render();
  }

  set content(value) {
    this._state.content = value;
    this._render();
  }

  set device(value) {
    this._state.device = value ? value.split('-').join('_') : DEFAULT_DEVICE;
    this._render();
  }

  set type(value) {
    this._state.type = value;
    this._render();
  }

  set expanded(value) {
    this._state.expanded = super._convertAttributeToBoolean(value);
    this._render();
  }

  set editable(value) {
    this._state.editable = super._convertAttributeToBoolean(value);
    this._render();
  }

  set media(value) {
    this._state.media = value;
    this._render();
  }

  _render() {
    render(this._dom.container, template(this._state));
  }
}

export default EDevicePush;
