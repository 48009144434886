const getPageContent = (content, pageSize, pageNumber) =>
  content.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

const getPageCount = (content, pageSize) => Math.ceil(content.length / pageSize) || 1;

const paginate = (content, [hidePagination, pageSize, pageNumber]) => {
  if (hidePagination) {
    return {
      content: content,
      filteredContent: content
    };
  } else {
    return {
      content: getPageContent(content, pageSize, pageNumber),
      filteredContent: content,
      pageCount: getPageCount(content, pageSize)
    };
  }
};

export default paginate;
