import { dateUtils } from '@emartech/ui-framework-utils';

const createArrayWithGivenLength = length => Array.from(new Array(length));
const numberOfDays = createArrayWithGivenLength(7);
const numberOfMonths = createArrayWithGivenLength(12);
const daysInSixWeeks = 42;

const getDaysInMonth = (date, weekStartsOn) => {
  const startOfMonth = dateUtils.startOfMonth(date);
  const firstDayOfStartOfMonth = dateUtils.startOfWeek(startOfMonth, { weekStartsOn });
  const lastDayOfEndOfMonth = dateUtils.addDays(firstDayOfStartOfMonth, daysInSixWeeks - 1);

  return dateUtils.eachDayOfInterval({ start: firstDayOfStartOfMonth, end: lastDayOfEndOfMonth });
};

const getDaysOfWeek = (date, currentMonth, selectedDate) => {
  return numberOfDays.map((_, index) => {
    const day = dateUtils.addDays(date, index);
    const dateFormat = dateUtils.formats.fullDate;

    return {
      number: dateUtils.getDate(day),
      isCurrentMonth: dateUtils.getMonth(day) === currentMonth,
      isToday: dateUtils.isToday(day),
      isSelected: dateUtils.format(day, dateFormat) === dateUtils.format(selectedDate, dateFormat),
      date: dateUtils.format(day, dateFormat)
    };
  });
};

const calculateDaysOfMonth = (date, selectedDate, weekStartsOn = 1) => {
  const currentMonth = dateUtils.getMonth(date);
  return getDaysInMonth(date, weekStartsOn)
    .filter(day => findFirstDayOfWeeks(day, weekStartsOn))
    .map(day => getDaysOfWeek(day, currentMonth, selectedDate));
};

const calculateDayNames = (date, locale = 'en', weekStartsOn = 1) => {
  const startOfMonth = dateUtils.startOfMonth(date);
  const startOfWeek = dateUtils.startOfWeek(startOfMonth, { weekStartsOn });
  return numberOfDays.map((value, index) => {
    const day = dateUtils.addDays(startOfWeek, index);
    const localeObject = dateUtils.getLocaleWithFallback(locale);
    return dateUtils.format(day, dateUtils.formats.dayShortName, {
      locale: localeObject.lang
    });
  });
};

const getMonthList = (date, locale = 'en') => {
  const currentMonth = dateUtils.getMonth(date);
  return numberOfMonths.map((_, index) => {
    const month = new Date(2000, index, 1);
    const localeObject = dateUtils.getLocaleWithFallback(locale);
    return {
      formattedName: dateUtils.format(month, dateUtils.formats.monthFullName, {
        locale: localeObject.lang
      }),
      isCurrent: index === currentMonth
    };
  });
};

const getPreviousMonth = date => {
  return dateUtils.subMonths(date, 1);
};

const getNextMonth = date => {
  return dateUtils.addMonths(date, 1);
};

const getYear = (date, year) => {
  return new Date(year, dateUtils.getMonth(date), dateUtils.getDate(date));
};

const getMonth = (date, month) => {
  return new Date(dateUtils.getYear(date), month, dateUtils.getDate(date));
};

const getToday = () => new Date();

const getDate = dateString => dateUtils.parseWithoutTime(dateString);

const getDateString = date => dateUtils.format(dateUtils.parseWithoutTime(date), dateUtils.formats.fullDate);

const getYearList = date => {
  const currentYear = dateUtils.getYear(date) || new Date().getFullYear();
  const startOfYearList = currentYear - 5;
  const endOfYearList = currentYear + 5;
  const numberOfYears = createArrayWithGivenLength(endOfYearList - startOfYearList + 1);

  return numberOfYears.map((_, index) => {
    const year = startOfYearList + index;
    return {
      value: year,
      isCurrent: dateUtils.getYear(date) === year
    };
  });
};

const findFirstDayOfWeeks = (date, weekStartsOn) =>
  dateUtils.isEqual(dateUtils.startOfWeek(date, { weekStartsOn }), date);

const startOfMonth = dateUtils.startOfMonth.bind(dateUtils);

const endOfMonth = dateUtils.endOfMonth.bind(dateUtils);

const addDays = dateUtils.addDays.bind(dateUtils);

const addMonths = dateUtils.addMonths.bind(dateUtils);

const isSameMonth = (givenDate, baseDate) => dateUtils.getMonth(givenDate) === dateUtils.getMonth(baseDate);

const isSameYear = (givenDate, baseDate) => dateUtils.getYear(givenDate) === dateUtils.getYear(baseDate);

const isSameDate = dateUtils.isSameDay.bind(dateUtils);

const isValidDate = dateUtils.isValid.bind(dateUtils);

const isBefore = dateUtils.isBefore.bind(dateUtils);

const isAfter = dateUtils.isAfter.bind(dateUtils);

const isEqual = dateUtils.isEqual.bind(dateUtils);

export default {
  calculateDaysOfMonth,
  calculateDayNames,
  getMonthList,
  getPreviousMonth,
  getNextMonth,
  getYear,
  getMonth,
  getYearList,
  getToday,
  getDate,
  getDateString,
  startOfMonth,
  endOfMonth,
  addDays,
  addMonths,
  isSameYear,
  isSameMonth,
  isSameDate,
  isValidDate,
  isBefore,
  isAfter,
  isEqual
};
