import { html } from 'uhtml';
import classNames from 'clsx';
export class ESkeletonTemplate {
    constructor(component) {
        this.state = component.state;
    }
    createWrapper() {
        return html.node `<div class="e-skeleton__wrapper"></div>`;
    }
    createElement() {
        return html `
      <div class="e-skeleton__content">
      ${this.createSkeletonBars()}
    </div>`;
    }
    createSkeletonBars() {
        return Array(this.state.barCount)
            .fill(0)
            .map((value, index) => this.createSkeletonBar(index));
    }
    createSkeletonBar(index) {
        const skeletonBarStyle = this.skeletonBarStyle();
        const classList = classNames('e-skeleton', {
            'e-margin-bottom-xs': index !== (this.state.barCount - 1)
        });
        return html `<div class="${classList}" style="${skeletonBarStyle}"></div>`;
    }
    skeletonBarStyle() {
        const styleObject = {
            height: this.state.barHeight,
            width: this.state.width
        };
        let styleString = '';
        for (const [property, value] of Object.entries(styleObject)) {
            if (!!value) {
                styleString += `${property}:${value};`;
            }
        }
        return styleString;
    }
}
