export class ETimeState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._formats = { dateFormat: '', timeFormat: '' };
    this._value = '';
    this._format = '';
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.render();
  }

  get format() {
    return this._format;
  }

  set format(value) {
    this._format = value;
    this.render();
  }

  get formats() {
    return this._formats;
  }

  updateFormatsFromConfig(config) {
    this._formats = {
      dateFormat: config.dateFormat,
      timeFormat: config.timeFormat
    };
    this.render();
  }
}

export default ETimeState;
