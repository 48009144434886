import flipper from '../flipper/index.js';
import Storage from '../storage.js';
import Database from '../database/index.js';
import loader from '../loader/index.js';

class AssetHandler {
  constructor(global, manifest, logger, flipper, database, storage) {
    this._global = global;
    this._manifest = manifest;
    this._manifestFetchPromises = {};
    this._logger = logger;
    this._database = database;
    this._storage = storage;
    this._flipper = flipper;
  }

  init() {
    this._global.e.storeAndInjectAsset = this._storeAndInject.bind(this);

    const validAssets = Object.keys(this._manifest.assets);

    validAssets.forEach(name => {
      const fetcher = async name => {
        const revision = this._manifest.assets[name];
        if (!revision) {
          return;
        }

        if (!await this._database?.isAvailable()) {
          this._logger.error('local load failed', 'database not available', {
            asset_name: name, asset_revision: revision
          });

          const asset = await this._request(name);
          this._inject(asset);
          return;
        }

        const storedRevision = await this._database.get(`${name}.revision`);
        if (storedRevision === revision) {
          try {
            const asset = await this._database.get(name);
            this._inject(asset);
          } catch (e) {
            this._logger.error('local load failed', e.message, { asset_name: name, asset_revision: revision });

            const asset = await this._request(name);
            this._storeAndInject(asset);
          }
        } else {
          const asset = await this._request(name);
          this._storeAndInject(asset);
        }

        this._storage.removeItem('e.asset.' + name + '.rev');
        this._storage.removeItem('e.asset.' + name);
      };

      this._manifestFetchPromises[name] = fetcher(name);
    });

    return Promise.all(Object.values(this._manifestFetchPromises));
  }

  async getAsset(name) {
    await this._manifestFetchPromises[name];
    return this._database.get(name);
  }

  async _request(name) {
    if (!this._manifest.target) {
      return;
    }

    try {
      return await this._requestWithFetch(name);
    } catch (e) {
      this._logger.warn('script fallback', {
        errorMessage: e.message,
        assetName: name,
        manifestTarget: this._manifest.target
      });
      try {
        await this._requestScript(name);
      } catch (error) {
        this._logger.warn('request script failed', {
          errorMessage: error.message,
          assetName: name,
          manifestTarget: this._manifest.target
        });
      }
    }
  }

  _requestWithAjax(name) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();

      request.open('GET', this._manifest.target + '/assets/' + name + '.json', true);
      request.timeout = 10000; // 10 sec

      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 0) { // fallback allow origin access error, etc
            this._logger.warn('CORS fallback');
            this._requestScript(name).catch(reject);
          } else if (request.status >= 200 && request.status < 400) {
            try {
              resolve(JSON.parse(request.responseText));
            } catch (e) {
              const shortData = request.responseText.substring(0, 100);
              this._logger.error('json parse error from ajax', e.message, { shortData });
              reject(e);
            }
          } else {
            this._logger.error('ajax error', request.statusText);
          }
        }
      };
      request.send();
    });
  }

  _requestWithFetch(name) {
    return this._global.fetch(`${this._manifest.target}/assets/${name}.json`, { mode: 'cors' })
      .then(response => {
        if (!response.ok) {
          this._logger.warn(
            'failed request',
            {
              asset_name: name,
              response_status: response.status,
              response_status_text: response.statusText
            }
          );
          throw new Error('failed request');
        }

        return response.json();
      });
  }

  _requestScript(name) {
    return loader.loadScript(this._manifest.target + '/assets/' + name + '.js');
  }

  _storeAndInject(asset) {
    if (!asset) {
      return;
    }

    if (!this._isValid(asset)) {
      this._logger.error('malformed asset from request');
      return;
    }

    this._store(asset);
    this._inject(asset);
  }

  _isValid(asset) {
    return asset && asset.name && asset.data && asset.type;
  }

  _inject(asset) {
    if (!this._isValid(asset)) {
      this._logger.error('malformed asset from local');
      return;
    }

    switch (asset.type) {
      case 'html':
        this._injectHtml(asset.data);
        break;
    }
  }

  async _store(asset) {
    const revision = this._manifest.assets[asset.name];

    await this._database.set(asset.name, asset);
    await this._database.set(`${asset.name}.revision`, revision);
  }

  _injectHtml(data) {
    if (!document.body) {
      document.addEventListener('DOMContentLoaded', () => {
        this._injectHtml(data);
      });
      return;
    }

    const assetContainer = document.createElement('div');
    assetContainer.style.display = 'none';
    assetContainer.innerHTML = data;
    document.body.insertBefore(assetContainer, document.body.childNodes[0]);
  }

  static create(manifest, logger) {
    let database;

    try {
      database = Database.create('assets');
    } catch {};

    const storage = new Storage();
    return new AssetHandler(global, manifest, logger, flipper, database, storage);
  }
}

export default AssetHandler;
