import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import configStore from '../../../utils/config-store';
import State from './state';
import Template from './template';
import calendarUtilities from './calendar-utilities';

class EDatetimeCalendar extends HTMLCustomElement {
  init() {
    this._wrapperElement = document.createElement('div');
    this._wrapperElement.classList.add('e-datetime-calendar__wrapper');
    this._emitter = new EventEmitter();
    this._state = new State(this.requestRender.bind(this));
    this._template = new Template(this._state.state, this._emitter);

    this._emitter.on('onPreviousButtonClick', this._onPreviousButtonClick.bind(this));
    this._emitter.on('onNextButtonClick', this._onNextButtonClick.bind(this));
    this._emitter.on('onMonthChange', this._onMonthChange.bind(this));
    this._emitter.on('onYearChange', this._onYearChange.bind(this));
    this._emitter.on('onShowTodayClick', this._onShowTodayClick.bind(this));
    this._emitter.on('onSelectDate', this._onSelectDate.bind(this));
    this._emitter.on('dayHover', this._onDayHover.bind(this));
    this._emitter.on('dayLeave', this._onDayLeave.bind(this));
    this._emitter.on('onCalendarKeypress', this._onCalendarKeypress.bind(this));
  }

  static get observedAttributes() {
    return ['selected-date', 'range-other-end', 'today-hidden', 'disabled'];
  }

  set disabled(value) {
    this._state.setState({ disabled: this._convertAttributeToBoolean(value) });
  }

  get disabled() {
    return this._state.state.disabled;
  }

  set selectedDate(value) {
    if (this._isValidDateString(value)) {
      const dateString = calendarUtilities.getDateString(value);
      const selectedDate = calendarUtilities.getDate(dateString);
      this._state.setState({
        selectedDate,
        activeDate: selectedDate
      }, false);
      this._updateComponentState(selectedDate);
    } else {
      this._state.setState({
        selectedDate: this._state.defaultState.selectedDate,
        activeDate: this._state.defaultState.selectedDate || this._state.state.currentDate
      }, false);
      this._updateComponentState(this._state.state.currentDate);
    }
  }

  set min(value) {
    this._state.setState({ minDate: calendarUtilities.getDateString(value) });
  }

  get min() {
    return this._state.state.minDate;
  }

  set max(value) {
    this._state.setState({ maxDate: calendarUtilities.getDateString(value) });
  }

  get max() {
    return this._state.state.maxDate;
  }

  set todayHidden(value) {
    this._state.setState({ todayHidden: super._convertAttributeToBoolean(value) });
  }

  get todayHidden() {
    return this._state.state.todayHidden;
  }

  set rangeOtherEnd(value) {
    const dateString = calendarUtilities.getDateString(value);
    this._state.setState({
      rangeOtherEnd: this._isValidDateString(dateString) ? dateString : this._state.defaultState.rangeOtherEnd
    });

    if (this._state.state.selectedDate) {
      this._state.setState({ activeDate: this._state.state.selectedDate }, false);
      this._updateComponentState(this._state.state.activeDate);
    }
  }

  get rangeOtherEnd() {
    return this._state.state.rangeOtherEnd;
  }

  _isValidDateString(value) {
    if (!value) { return false; }
    const date = calendarUtilities.getDate(value);
    return calendarUtilities.isValidDate(date);
  }

  connectedCallback() {
    configStore.subscribe(this._onConfigChange.bind(this));
    super._cleanupContainer(`.${this._wrapperElement.className}`);
    this.appendChild(this._wrapperElement);

    this._state.setState({ activeDate: this._state.state.selectedDate || this._state.state.currentDate }, false);
    this._updateComponentState(this._state.state.currentDate);
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  _render() {
    render(this._wrapperElement, this._template.createElement());
  }

  _onPreviousButtonClick() {
    const previousMonthDate = calendarUtilities.getPreviousMonth(this._state.state.currentDate);
    this._updateComponentState(previousMonthDate);
  }

  _onNextButtonClick() {
    const nextMonthDate = calendarUtilities.getNextMonth(this._state.state.currentDate);
    this._updateComponentState(nextMonthDate);
  }

  _onMonthChange(monthValue) {
    const dateWithSelectedMonth = calendarUtilities.getMonth(this._state.state.currentDate, monthValue);
    this._updateComponentState(dateWithSelectedMonth);
  }

  _onYearChange(yearValue) {
    const dateWithSelectedYear = calendarUtilities.getYear(this._state.state.currentDate, yearValue);
    this._updateComponentState(dateWithSelectedYear);
  }

  _onShowTodayClick() {
    const today = calendarUtilities.getToday();
    this._updateComponentState(today);
  }

  _onSelectDate(selectedDate) {
    if (this._state.state.disabled) { return; }
    if (!calendarUtilities.isSameMonth(selectedDate, this._state.state.currentDate) ||
      calendarUtilities.isSameDate(selectedDate, this._state.state.selectedDate)) {
      return;
    }

    if (calendarUtilities.isBefore(selectedDate, this._state.state.minDate)) { return; }

    if (calendarUtilities.isAfter(selectedDate, this._state.state.maxDate)) { return; }

    this._state.setState({
      selectedDate,
      activeDate: selectedDate,
      daysOfMonth: calendarUtilities.calculateDaysOfMonth(selectedDate, selectedDate)
    });

    this.dispatchEvent(new CustomEvent('calendar.change', {
      bubbles: true,
      detail: {
        value: calendarUtilities.getDateString(selectedDate)
      }
    }));
  }

  _onConfigChange() {
    this._updateComponentState(this._state.state.currentDate);
  }

  _onDayHover(hoveredDate) {
    if (this._state.state.disabled) { return; }

    this._state.setState({ hoveredDate });
  }

  _onDayLeave() {
    if (this._state.state.disabled) { return; }

    this._state.setState({ hoveredDate: null });
  }

  _onCalendarKeypress(event) {
    if (!['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', ' '].includes(event.key)) { return; }

    event.preventDefault();

    if (this._state.state.disabled) { return; }

    const activeDate = this._state.state.activeDate;
    const dayNumber = activeDate.getDate();

    if (event.key === 'ArrowUp' && dayNumber > 7) {
      this._setActiveDate(calendarUtilities.addDays(activeDate, -7));
    } else if (event.key === 'ArrowDown' && dayNumber + 6 < calendarUtilities.endOfMonth(activeDate).getDate()) {
      this._setActiveDate(calendarUtilities.addDays(activeDate, 7));
    } else if (event.key === 'ArrowLeft') {
      if (dayNumber > 1) {
        this._setActiveDate(calendarUtilities.addDays(activeDate, -1));
      } else {
        const previousMonthEnd = calendarUtilities.endOfMonth(calendarUtilities.addMonths(activeDate, -1));
        this._setActiveDate(previousMonthEnd);
        this._updateComponentState(this._state.state.activeDate);
      }
    } else if (event.key === 'ArrowRight') {
      if (dayNumber < calendarUtilities.endOfMonth(activeDate).getDate()) {
        this._setActiveDate(calendarUtilities.addDays(activeDate, 1));
      } else {
        const nextMonthStart = calendarUtilities.startOfMonth(calendarUtilities.addMonths(activeDate, 1));
        this._setActiveDate(nextMonthStart);
        this._updateComponentState(this._state.state.activeDate);
      }
    } else if (event.key === ' ') {
      this._onSelectDate(activeDate);
    }

    this._state.setState({ hoveredDate: calendarUtilities.getDateString(this._state.state.activeDate) });
  }

  _setActiveDate(date) {
    const dateString = calendarUtilities.getDateString(date);
    const isBefore = calendarUtilities.isBefore(dateString, this._state.state.minDate);
    const isAfter = calendarUtilities.isAfter(dateString, this._state.state.maxDate);

    if (isBefore || isAfter) { return; }

    this._state.setState({ activeDate: date });
  }

  _updateComponentState(newDate) {
    const isSameMonth = calendarUtilities.isSameMonth(this._state.state.activeDate, newDate);
    const isSameYear = calendarUtilities.isSameYear(this._state.state.activeDate, newDate);
    const activeDate = isSameMonth && isSameYear ?
      this._state.state.activeDate :
      calendarUtilities.startOfMonth(newDate);

    this._state.setState({
      currentDate: newDate,
      activeDate,
      monthList: calendarUtilities.getMonthList(newDate, configStore.config.language),
      yearList: calendarUtilities.getYearList(newDate),
      dayNames: calendarUtilities.calculateDayNames(newDate, configStore.config.language),
      daysOfMonth: calendarUtilities.calculateDaysOfMonth(newDate, this._state.state.selectedDate)
    });
  }
}

export default EDatetimeCalendar;
