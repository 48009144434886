import autoBind from 'auto-bind';

export class ECardEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
  }

  onSlotChange({ target: slot }) {
    const slotName = slot.name;
    const isSlotVisible = !!slot.assignedNodes().length;

    this.state.setSlotVisibility(slotName, isSlotVisible);
  }

}
