import { html, render } from 'uhtml';
import { dateUtils } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';
import translator from '../../../utils/translator/index.js';

class Template {
  constructor(emitter) {
    this._uuid = uuid();
    this._element = document.createElement('div');
    this._element.setAttribute('role', 'group');
    this._emitter = emitter;
  }

  get element() {
    return this._element;
  }

  get elementNodeName() {
    return this._element.nodeName.toLowerCase();
  }

  render(state) {
    const translatedLabel = translator.translate(state.inputLabel);
    this._element.setAttribute('aria-label', translatedLabel);
    render(this._element, html`
      ${this._dateInputLabelElement(state)}
      <div class="e-datetime-picker__inputs">
        <div class="e-datetime-picker__input">
          ${this._dateInputElement(state)}
        </div>
        ${this._timepickerElement(state)}
      </div>
      <div class="e-datetime-picker__hint">
        ${this._hintText(state)}
      </div>
      ${this._calendarElement(state)}
    `);
  }

  _hintText(state) {
    if (state.isDateValid) {
      return html`
        <e-translation
          key="components.datetime.pickerExample"
          .value=${[dateUtils.formatByName('2021-06-23', 'fullDate', state.configFormats)]}
          data-testid="datetimeHint"
        ></e-translation>
      `;
    } else {
      return html`
        <span class="text-color-danger">
          ${this._hintErrorFormat(state)}
          ${this._hintErrorRange(state)}
        </span>
      `;
    }
  }

  _hintErrorFormat(state) {
    if (state.isDateValidFormat) { return; }

    return html`
      <e-translation
        key="components.datetime.invalidDateFormatHint"
        data-testid="datetimeHint"
      ></e-translation>
    `;
  }

  _hintErrorRange(state) {
    if (state.isDateValidRange) { return; }

    return html`
      <e-translation
        key="components.datetime.invalidDateRangeHint"
        data-testid="datetimeHint"
      ></e-translation>
    `;
  }

  _calendarElement(state) {
    return html`
      <e-datetime-calendar
        .min=${state.minDate}
        .max=${state.maxDate}
        ?today-hidden=${state.todayHidden}
        range-other-end=${state.rangeOtherEnd}
        selected-date=${state.isTimeValid ? state.dateString : ''}
        ?disabled=${state.disabled}
        @calendar.change=${this._onCalendarChange.bind(this)}></e-datetime-calendar>`;
  }

  _dateInputLabelElement(state) {
    const id = `input-${this._uuid}`;

    return html`
      <label class="e-datetime-picker__label" for="${id}">
        <e-translation key=${state.inputLabel}></e-translation>
      </label>
    `;
  }

  _dateInputElement(state) {
    const id = `input-${this._uuid}`;
    const value = state.isDateValid ?
      dateUtils.formatByName(state.dateInputString, 'fullDate', state.configFormats) :
      state.dateInputString;

    return html`
      <input
        id="${id}"
        class="${state.isDateValid ? 'e-input' : 'e-input e-input-error'}"
        value=${value}
        ?disabled=${state.disabled}
        @change=${this._onDateInputChange.bind(this)}>`;
  }

  _timepickerElement(state) {
    return state.isTime ? html`
      <e-timepicker
        error="${!state.isTimeValid}"
        class="e-datetime-picker__input"
        value=${dateUtils.formatByName(state.timeString, 'hoursAndMins', state.configFormats)}
        ?disabled=${state.disabled}
        @update=${this._onTimepickerChange.bind(this)}></e-timepicker>
    ` : undefined;
  }

  _onCalendarChange(event) {
    this._emitter.emit('calendarChange', event.detail.value);
  }

  _onDateInputChange(event) {
    this._emitter.emit('dateinputChange', event.target.value);
  }

  _onTimepickerChange(event) {
    this._emitter.emit('timepickerChange', event.detail.time['24h']);
  }
}

export default Template;
