import edsThemes from '@emartech/eds-themes';
import manifest from '../../../../dist/app.js';
let styleSheet = null;
const iconName = 'horizon';

const createStyleSheet = () => {
  styleSheet = new CSSStyleSheet();
  document.adoptedStyleSheets = [...document.adoptedStyleSheets, styleSheet];
};

const generateVariables = (themeHandler) => {
  const version = themeHandler.manifestVersion;

  let content = `@font-face {
    font-family: 'EDS Horizon Icons';
    font-style: normal;
    font-weight: 400;
    src:
      url('${themeHandler.themeManifest.cdnURL}/eds-themes/${version}/fonts/${iconName}.woff2') format('woff2'),
      url('${manifest.clientAssetsFallbackUrl}/eds-themes/${version}/fonts/${iconName}.woff2') format('woff2');
  }`;

  content += '\n:root {';

  const variables = themeHandler.currentTheme?.variables || {};
  for (const [name, value] of Object.entries(variables)) {
    content += `--token-${name}: ${value};`;
  }

  const glyphs = edsThemes.icons[iconName].glyphs;
  for (const [name, value] of Object.entries(glyphs)) {
    content += `--token-glyphs-${name}: "\\${value.code}";`;
  }

  content += '}';
  styleSheet.replaceSync(content);
};

export const cssVariables = {
  inject(themeHandler) {
    createStyleSheet();
    generateVariables(themeHandler);
  },
  generateVariables
};
