import { html } from 'uhtml';
import classNames from 'clsx';

export class EEmojiPickerTemplate {
  constructor(component) {
    this.createReference = component.createReference.bind(component);
    this._state = component.state;
    this._events = component.events;
  }

  createWrapper() {
    return html.node`<div ref=${this.createReference(['wrapper'])}></div>`;
  }

  createElement() {
    return html`
      ${this._createHandler()}
      ${this._createPicker()}
    `;
  }

  _createHandler() {
    const classes = classNames('e-btn e-btn-onlyicon', {
      'e-btn-active': this._state.opened,
      'e-btn-disabled': this._state.isDisabled
    });

    const emojiIcon = this._state.opened ? 'close' : 'e-smile';

    return html`
      <button
        class="${classes}"
        @click="${this._events.onTogglePicker}"
        @keydown="${this._events.onOpenPickerByKeydown}"
        ref=${this.createReference(['handler'])}
        type="button"
        ?disabled=${this._state.isDisabled}
      >
        <e-icon icon="${emojiIcon}" ref=${this.createReference(['handler', 'icon'])}></e-icon>
      </button>
    `;
  }

  _createPicker() {
    return html`
      <div ref="${this.createReference(['picker'])}" class="e-emojipicker__container">
        <div
          class="e-emojipicker__tabs"
          @click="${this._events.onCategoryChange}"
        >
          ${this._categoryTabs()}
        </div>
        <div
          class="e-emojipicker__content"
          ref=${this.createReference(['content'])}
          @click="${this._events.onEmojiClick}"
        >
          ${this._categoryContents()}
        </div>
      </div>
    `;
  }

  _categoryTabs() {
    return this._state.emojis.map((category, index) => this._tabElement(category, index));
  }

  _tabElement(category, index) {
    const classes = classNames('e-emojipicker__tab', {
      'e-emojipicker__tab-active': this._state.activeTab === index
    });

    return html`
      <div
        class="${classes}"
        data-category="${index}"
        ref=${this.createReference(['tab', index])}
        tabindex="0"
        @keydown="${(event) => this._events.onCategoryKeydown(event, index)}"
      >${category[0]}
      </div>`;
  };

  _categoryContents() {
    return this._state.activeTabEmojis.map((emoji, index) => this._emojiElement(emoji, index));
  }

  _emojiElement(emoji, index) {
    const buttonClasses = classNames('e-btn e-btn-onlyicon e-btn-borderless e-emojipicker__emoji_button', {
      'e-emojipicker__emoji_button-active': this._state.activeEmoji === index
    });

    return html`
      <button
        tabindex="-1"
        type="button"
        class="${buttonClasses}"
        ref=${this.createReference(['emoji', index])}
        data-emoji="${emoji}"
      >${emoji}</button>
    `;
  }
}
