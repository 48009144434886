import autoBind from 'auto-bind';
export class EAccordionEvents {
    constructor(component) {
        autoBind(this);
        this.state = component.state;
    }
    onItemUpdate(event) {
        const itemElement = event.target;
        itemElement.removeEventListener('accordion-item.delete', this.onItemDelete);
        itemElement.addEventListener('accordion-item.delete', this.onItemDelete);
        this.state.upsertItem(event);
    }
    onItemDelete(event) {
        this.state.deleteItem(event.detail.uuid);
    }
    onItemLabelClick(item) {
        const eventName = this.state.activeItems.has(item.uuid) ? 'close' : 'open';
        this.state.toggleActiveItem(item);
        item.target.dispatchEvent(new CustomEvent(eventName));
    }
}
