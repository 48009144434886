const confettiParams = {
    size: [4, 14],
    initialSpeed: 30,
    gravity: .55,
    drag: .12,
    terminalVelocity: 12,
    flipSpeed: .03
};
const randomNumberBetween = (min, max) => Math.random() * (max - min) + min;
export class ConfettiElement {
    constructor(position, container, initSpeed = confettiParams.initialSpeed, availableColors) {
        this.randomModifier = randomNumberBetween(-1, 1);
        this.colorPair = availableColors[Math.floor(randomNumberBetween(0, availableColors.length))];
        this.size = randomNumberBetween(confettiParams.size[0], confettiParams.size[1]);
        this.position = { ...position };
        this.rotation = randomNumberBetween(0, 2 * Math.PI);
        this.scale = { x: 1, y: 1 };
        this.velocity = {
            x: randomNumberBetween(-initSpeed, initSpeed) * .5,
            y: randomNumberBetween(initSpeed / 2, initSpeed)
        };
        this.flipSpeed = (confettiParams.size[1] / 10 - this.size / 10 + .4) * confettiParams.flipSpeed;
        if (this.position.y <= container.height) {
            this.velocity.y = -Math.abs(this.velocity.y);
        }
        this.terminalVelocity = randomNumberBetween(1, 1.5) * confettiParams.terminalVelocity;
    }
    update() {
        this.velocity.x *= .98;
        this.velocity.y += (this.randomModifier * confettiParams.drag);
        this.velocity.y += confettiParams.gravity;
        this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity);
        this.position.x += this.velocity.x;
        this.position.y += this.velocity.y;
        this.scale.y = Math.cos((this.position.y + this.randomModifier) * this.flipSpeed);
        this.color = this.scale.y > 0 ? this.colorPair.front : this.colorPair.back;
    }
    isWithinDimension(dimensions) {
        const isOffscreenHorizontal = this.position.x < 0 || this.position.x > dimensions.width;
        const isOffscreenVertical = this.position.y > dimensions.height;
        return !(isOffscreenVertical || isOffscreenHorizontal);
    }
}
