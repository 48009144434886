import autoBind from 'auto-bind';
import { RuleState } from './types/Rule';
;
;
;
export class ERuleBuilderEvents {
    constructor(component) {
        autoBind(this);
        this.dispatchEvent = component.dispatchEvent.bind(component);
        this.state = component.state;
        this.refs = component.refs;
    }
    onBlueprintUpdate(event) {
        const blueprintElement = event.target;
        blueprintElement.removeEventListener('rule-blueprint.delete', this.onBlueprintDelete);
        blueprintElement.addEventListener('rule-blueprint.delete', this.onBlueprintDelete);
        this.state.upsertBlueprint(event.detail);
        this.state.updateBlueprintForRules(this.state.mainGroup);
        if (this.state.mainExcludedGroup) {
            this.state.updateBlueprintForRules(this.state.mainExcludedGroup);
        }
    }
    onBlueprintDelete(event) {
        this.state.deleteBlueprint(event.detail.uuid);
    }
    onRelationUpdate(event) {
        const relationElement = event.target;
        relationElement.removeEventListener('rule-relation.delete', this.onRelationDelete);
        relationElement.addEventListener('rule-relation.delete', this.onRelationDelete);
        this.state.upsertRelation(event.detail);
    }
    onRelationDelete(event) {
        this.state.deleteRelation(event.detail.uuid);
    }
    onChange() {
        this._dispatchChangeEvent();
    }
    onGlobalChange(event) {
        if (event.target.nodeName === 'E-FORMATTER') {
            this.onFormatterChange(event);
            return;
        }
        if (event.target.nodeName === 'E-VALIDATION') {
            this.onValidationChange(event);
            return;
        }
    }
    onFormatterChange(event) {
        this.state.upsertFormatter(event.detail.name, event.detail.formatter);
        event.target.removeEventListener('remove', this.onFormatterRemove);
        event.target.addEventListener('remove', this.onFormatterRemove);
    }
    onFormatterRemove(event) {
        this.state.deleteFormatter(event.detail.name);
    }
    onValidationChange(event) {
        this.state.upsertValidation(event.detail.name, event.detail.validation);
        event.target.removeEventListener('remove', this.onValidationRemove);
        event.target.addEventListener('remove', this.onValidationRemove);
    }
    onValidationRemove(event) {
        this.state.deleteValidation(event.detail.name);
    }
    onAddRuleButtonClick(event, group, blueprint) {
        const blueprintValidation = this.state.getValidationByName(blueprint.validation);
        blueprint.template?.querySelectorAll('[data-field]')?.forEach((fieldElement) => {
            const valueProperty = fieldElement.dataset.property ? fieldElement.dataset.property : 'value';
            if (blueprint.defaultValues) {
                fieldElement[valueProperty] = blueprint.defaultValues[fieldElement.dataset.field];
            }
        });
        const newRule = RuleState.fromBlueprint(blueprint, blueprintValidation);
        this.state.addRuleToGroup(group, newRule);
        this._dispatchRuleOpenEvent(newRule);
    }
    onRuleCancelClick(event, group, rule) {
        event.stopPropagation();
        if (!rule.isSaved) {
            this.state.removeRule(rule);
            this._dispatchRuleCloseEvent(rule);
            return;
        }
        const cancelButton = event.target;
        const ruleElement = cancelButton.closest('.e-rule-builder__rule');
        const fieldElements = [...ruleElement.querySelectorAll('[data-field]')];
        fieldElements.forEach((fieldElement) => {
            if (fieldElement.dataset.property) {
                fieldElement[fieldElement.dataset.property] = rule.fields[fieldElement.dataset.field];
            }
            else {
                fieldElement.value = rule.fields[fieldElement.dataset.field];
            }
        });
        this.state.stopRuleEditing(rule);
        this.state.removeGroupHoverState(group);
        this._dispatchRuleCloseEvent(rule);
    }
    onRuleConfirmButtonClick(event, group, rule) {
        const confirmButton = event.target;
        const ruleElement = confirmButton.closest('.e-rule-builder__rule');
        const fieldElements = [...ruleElement.querySelectorAll('[data-field]')];
        const values = this.collectFieldValues(fieldElements);
        const fieldSummaries = this.collectFieldPropertiesForSummary(fieldElements, rule);
        this.state.updateRuleFields(rule, values, fieldSummaries);
        rule.validate();
        this.state.stopRuleEditing(rule);
        this.state.removeGroupHoverState(group);
        this._dispatchRuleCloseEvent(rule);
        this._dispatchRuleChangeEvent(rule);
    }
    onRuleActionClick(event, action, rule) {
        event.stopPropagation();
        action.dispatchEvent(new CustomEvent('trigger', {
            detail: { rule: rule.toDTO() }
        }));
    }
    onRuleEditClick(event, rule) {
        if (this.state.hasEditedRule() || this.state.isReadonlyOrDisabled) {
            return;
        }
        event.stopPropagation();
        if (event.target.closest('button[disabled]')) {
            return;
        }
        this.state.startRuleEditing(rule);
        this._dispatchRuleOpenEvent(rule);
    }
    onRuleRemove(event, rule) {
        event.stopPropagation();
        this.state.removeRule(rule);
        this._dispatchChangeEvent();
    }
    onGroupLabelChange(event, group) {
        this.state.updateGroupLabel(event.detail.value, group);
        this._dispatchGroupChangeEvent(group);
    }
    onGroupRelationChange(event, group) {
        this.state.updateGroupRelation(event.detail.value, group);
        this._dispatchGroupChangeEvent(group);
    }
    onGroupVisibilityToggleClick(group) {
        this.state.toggleGroupIsContentVisible(group);
        if (group.isContentVisible) {
            this._dispatchGroupOpenEvent(group);
        }
        else {
            this._dispatchGroupCloseEvent(group);
        }
    }
    onAddSubgroup(group) {
        const newGroup = this.state.addGroup(group);
        this._dispatchGroupOpenEvent(newGroup);
        this._dispatchGroupChangeEvent(newGroup);
    }
    onGroupRemove(group) {
        window.e.utils.openDestructiveConfirmationDialog({
            headline: '<e-translation key="components.ruleBuilder.group.deleteDialog.headline"></e-translation>',
            content: '<e-translation key="components.ruleBuilder.group.deleteDialog.content"></e-translation>',
            confirm: {
                label: this.state.translations.groupDeleteDialogConfirm,
                callback: () => {
                    const removedGroup = this.state.removeGroup(group);
                    this._dispatchChangeEvent();
                    if (removedGroup.isContentVisible) {
                        this._dispatchGroupCloseEvent(removedGroup);
                    }
                }
            },
            cancel: {
                label: this.state.translations.groupDeleteDialogCancel
            }
        });
    }
    onGroupFocusEnter(event, group) {
        event.stopPropagation();
        this.state.updateGroupHasHoverState(group, true);
    }
    onGroupFocusLeave(event, group) {
        this.state.updateGroupHasHoverState(group, false);
    }
    onRuleFocusEnter(event, rule) {
        event.stopPropagation();
        this.state.updateRuleHasHoverState(rule, true);
    }
    onRuleFocusLeave(event, rule) {
        event.stopPropagation();
        this.state.updateRuleHasHoverState(rule, false);
    }
    onDropdownStatusChange(event, group) {
        if (!['opened', 'closed'].includes(event.detail.status)) {
            return;
        }
        group.isDropdownOpen = event.detail.status === 'opened';
    }
    onGroupEditLabelButtonClick(event) {
        const header = event.target.closest('.e-rule-builder__group_header');
        const headerLabel = header.querySelector('.e-rule-builder__group_header_label');
        if (!headerLabel) {
            return;
        }
        headerLabel.focus();
    }
    onConfigChange() {
        this.state.updateTranslations();
    }
    onAddExclusionButtonClick() {
        this.state.addMainExcludedGroup();
    }
    onMainExcludedGroupRemove() {
        this.state.removeMainExcludedGroup();
    }
    collectFieldValues(fieldElements) {
        const values = {};
        for (const fieldElement of fieldElements) {
            const fieldName = fieldElement.dataset.field;
            if (fieldElement.dataset.property) {
                values[fieldName] = fieldElement[fieldElement.dataset.property];
            }
            else {
                values[fieldName] = fieldElement.value;
            }
        }
        return values;
    }
    collectFieldPropertiesForSummary(fieldElements, rule) {
        const summaryElement = rule.blueprint.summaryClone;
        if (!summaryElement) {
            return {};
        }
        const fieldSummaries = {};
        for (const fieldElement of fieldElements) {
            const fieldName = fieldElement.dataset.field;
            const placeholderSelector = `e-rule-blueprint-summary-value[type="field"][field="${fieldName}"]`;
            const summaryPlaceholders = [...summaryElement.querySelectorAll(placeholderSelector)];
            const properties = summaryPlaceholders
                .map((placeholder) => placeholder.getAttribute('property'))
                .filter((property) => Boolean(property));
            fieldSummaries[fieldName] = {};
            for (const property of properties) {
                fieldSummaries[fieldName][property] = fieldElement[property];
            }
        }
        return fieldSummaries;
    }
    _dispatchRuleOpenEvent(rule) {
        this.dispatchEvent(new CustomEvent('rule.open', {
            detail: { rule: rule.toDTO() }
        }));
    }
    _dispatchRuleCloseEvent(rule) {
        this.dispatchEvent(new CustomEvent('rule.close', {
            detail: { rule: rule.toDTO() }
        }));
    }
    _dispatchRuleChangeEvent(rule) {
        this.dispatchEvent(new CustomEvent('rule.change', {
            detail: { rule: rule.toDTO() }
        }));
    }
    _dispatchGroupOpenEvent(group) {
        this.dispatchEvent(new CustomEvent('group.open', {
            detail: { group: group.toDTO() }
        }));
    }
    _dispatchGroupCloseEvent(group) {
        this.dispatchEvent(new CustomEvent('group.close', {
            detail: { group: group.toDTO() }
        }));
    }
    _dispatchGroupChangeEvent(group) {
        this.dispatchEvent(new CustomEvent('group.change', {
            detail: { group: group.toDTO() }
        }));
    }
    _dispatchChangeEvent() {
        this.dispatchEvent(new CustomEvent('change', {
            detail: { value: {
                    items: this.state.mainGroup.toDTO(),
                    excludedItems: this.state.mainExcludedGroup ? this.state.mainExcludedGroup.toDTO() : null
                } }
        }));
    }
}
