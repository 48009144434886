import { html } from 'uhtml';
import classNames from 'clsx';

export class ECardTemplate {

  constructor(component) {
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    const thumbnailWrapperParts = classNames('thumbnail-wrapper', {
      'hidden': !this.state.isThumbnailWrapperVisible()
    });

    const contentWrapperParts = classNames('content-wrapper', {
      'without-thumbnail-wrapper': !this.state.isThumbnailWrapperVisible()
    });
    const headerParts = classNames('header', { 'hidden': !this.state.isHeaderVisible() });

    return html`
      <style>
        .thumbnail-wrapper ::slotted(img) {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }

        .thumbnail-wrapper ::slotted(iframe) {
          height: 100%;
          width: 100%;
        }
      </style>
      <div class="thumbnail-wrapper" part="${thumbnailWrapperParts}">
        ${this.createThumbnailSlot()}
        <div part="${headerParts}">
          ${this.createHeaderStartSlot()}
          ${this.createHeaderEndSlot()}
        </div>
      </div>
      <div part="${contentWrapperParts}">
        ${this.createContentSlot()}
        ${this.createActionsSlot()}
      </div>
    `;
  }

  createContentSlot() {
    return html`
      <slot part='content' name='content' @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createActionsSlot() {
    return html`
      <slot part='actions' name='actions' @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createHeaderStartSlot() {
    return html`
      <slot part='header-start' name='header-start' @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createHeaderEndSlot() {
    return html`
      <slot part='header-end' name='header-end' @slotChange=${this.events.onSlotChange}></slot>
    `;
  }

  createThumbnailSlot() {
    const parts = classNames('thumbnail', { 'hidden': !this.state.isThumbnailVisible() });

    return html`
      <slot
        tabindex="-1"
        part=${parts}
        name='thumbnail'
        @slotChange=${this.events.onSlotChange}
      ></slot>
    `;
  }
};
