import { dateUtils } from '@emartech/ui-framework-utils';
import autoBind from 'auto-bind';

export class EDatetimeEvents {
  constructor(component) {
    autoBind(this);
    this.component = component;
    this.refs = component.refs;
    this.utils = component.utils;
    this.state = component.state;
    this.template = component.template;
  }

  onClear() {
    this.state.value = this.state.isRange ? { start: '', end: '' } : '';
    this.state.internalError = false;
    this.refs.panel.value = '';
    this.refs.input.focus({ preventScroll: true });
    this.#dispatchChangeEvent();
  }

  onApplyClose() {
    this.utils.popup.close();
  }

  onCancelClose() {
    this.refs.panel.value = this.state.value;
    this.utils.popup.close();
  }

  onPanelChange(event) {
    const { value } = event.detail;
    const isInvalidDate = !this.state.isRange && value === dateUtils.invalidDateString;
    const isInvalidRange = this.state.isRange &&
      (value.start === dateUtils.invalidDateString || value.end === dateUtils.invalidDateString);

    if (isInvalidDate || isInvalidRange) {
      this.state.internalError = true;
      this.state.inputValue = value;
      this.#dispatchErrorEvent();
      return;
    }

    this.state.value = value;
    this.state.internalError = false;
    this.#dispatchChangeEvent();
  }

  onClick(event) {
    const containsClear = event.target && event.target.classList.contains('e-datetime__clear');
    if (this.state.disabled || containsClear) { return; }

    this.utils.popup.toggle();
  }

  onKeydown(event) {
    const containsClear = (event.target && event.target.classList.contains('e-datetime__clear'));
    if (this.state.disabled || containsClear) { return; }

    const handledKeys = ['Enter', 'Space'];
    if (!handledKeys.includes(event.code)) { return; }

    event.preventDefault();

    this.utils.popup.toggle();

    this.component.dispatchEvent(new CustomEvent('open'));
  }

  handlePresetChange(event) {
    event.target.addEventListener('datetimepreset.delete', this.handlePresetDelete);

    this.state.upsertPreset(Object.assign({}, event.detail, { component: event.target }));
  }

  handlePresetDelete(event) {
    const newPresets = this.state.presets.filter(preset => preset.uuid !== event.detail.uuid);
    this.state.presets = newPresets;
  }

  onPopupOpen() {
    this.state.isPopupOpened = true;
    if (this.state.presets.length) {
      this.refs.panel.querySelector('e-actionlist').autofocus = true;
    } else {
      this.refs.panel.querySelector('.e-input').focus({ preventScroll: true });
    }

    this.component.dispatchEvent(new CustomEvent('open'));
  }

  onPopupClose() {
    this.state.isPopupOpened = false;
    this.component.dispatchEvent(new CustomEvent('close'));
  }

  #dispatchChangeEvent() {
    this.component.dispatchEvent(new CustomEvent('change', {
      'detail': {
        'value': this.state.value
      }
    }));
  }

  #dispatchErrorEvent() {
    this.component.dispatchEvent(new CustomEvent('error', {
      'detail': {
        'value': this.state.value,
        'inputValue': this.state.inputValue
      }
    }));
  }
}
