export class EFormatterState {
  constructor() {
    this._name = '';
    this._formatter = null;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get formatter() {
    return this._formatter;
  }

  set formatter(value) {
    this._formatter = value;
  }
}
