import { debounce } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

export class ESelectNativeHandler {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.utils = component.utils;

    this.refs.nativeSelect = null;

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        if (mutationRecord.type === 'childList') {
          this.handleAddedNodes(mutationRecord.addedNodes);
          this.handleRemovedNodes(mutationRecord.removedNodes);
        } else if (mutationRecord.type === 'attributes') {
          this.handleAttributeChange(mutationRecord.target);
        }
      });
    });

    this.requestUpdateOptionsFromNativeSelectElement = debounce(this.updateOptionsFromNativeSelectElement, this);
    observer.observe(this.refs.component, { childList: true, subtree: true, attributes: true });
  }

  init() {
    const nativeSelectElement = this.refs.component.querySelector('select');

    this.initNativeSelectElement(nativeSelectElement);
  }

  initNativeSelectElement(nativeSelectElement) {
    if (!nativeSelectElement) { return; }

    this.refs.nativeSelect = nativeSelectElement;
    this.refs.nativeSelect.addEventListener('change', this.requestUpdateOptionsFromNativeSelectElement);

    this.requestUpdateOptionsFromNativeSelectElement();
    this.updateAttributesFromFromNativeSelectElement();
  }

  removeNativeSelectElement() {
    this.refs.nativeSelect = null;
    this.state.items = [];
  }

  updateNativeSelectElementFromState() {
    if (!this.refs.nativeSelect) { return; }

    this.refs.nativeSelect.value = this.state.selectedOption?.value || '';
  }

  updateOptionsFromNativeSelectElement() {
    if (!this.refs.nativeSelect) { return; }

    const children = [...this.refs.nativeSelect.children];

    this.state.items = children.flatMap((node) => {
      if (node.nodeName === 'OPTION') {
        return this.createItemFromOptionElement(node);
      } else if (node.nodeName === 'OPTGROUP') {
        return this.createItemsFromOptgroupElement(node);
      }
    });
  }

  updateAttributesFromFromNativeSelectElement() {
    if (!this.refs.nativeSelect) { return; }

    const isInlineVariant = ['inline', 'small', 'medium', 'large'];
    const isInline = isInlineVariant.some(variant => this.refs.nativeSelect.classList.contains(`e-select-${variant}`));

    this.refs.component.inline = isInline;
    this.state.isInline = isInline;
    this.state.isDisabled = this.refs.nativeSelect.disabled;
    this.state.isInErrorState = this.refs.nativeSelect.classList.contains('e-select-error');
  }

  createItemFromOptionElement(node) {
    return {
      type: 'option',
      value: node.value,
      content: node.innerHTML,
      selected: node.selected,
      disabled: node.disabled
    };
  }

  createItemsFromOptgroupElement(node) {
    const children = [...node.children];

    const groupId = uuid();
    const groupItem = { uuid: groupId, type: 'group', label: node.label, disabled: node.disabled };
    const itemsInGroup = children.map(node => ({ ...this.createItemFromOptionElement(node), group: groupId }));

    return [groupItem, ...itemsInGroup];
  }

  handleAddedNodes(nodes) {
    [...nodes].forEach(node => {
      if (node.nodeName === 'SELECT') {
        this.initNativeSelectElement(node);
      } else if (node.nodeName === 'OPTION') {
        this.requestUpdateOptionsFromNativeSelectElement();
      } else if (node.nodeName === 'OPTGROUP') {
        this.requestUpdateOptionsFromNativeSelectElement();
      }
    });
  }

  handleRemovedNodes(nodes) {
    [...nodes].forEach(node => {
      if (node.nodeName === 'SELECT') {
        this.removeNativeSelectElement();
      } else if (node.nodeName === 'OPTION') {
        this.requestUpdateOptionsFromNativeSelectElement();
      } else if (node.nodeName === 'OPTGROUP') {
        this.requestUpdateOptionsFromNativeSelectElement();
      }
    });
  }

  handleAttributeChange(node) {
    if (node !== this.refs.nativeSelect) { return; }

    this.updateAttributesFromFromNativeSelectElement();
  }

  dispatchChangeEvent() {
    if (!this.refs.nativeSelect) { return; }

    this.refs.nativeSelect.dispatchEvent(new CustomEvent('change', { bubbles: true }));
  }
}

export default ESelectNativeHandler;
