export class ECardState {
  #isSlotVisible = {};

  constructor(component) {
    this.requestRender = component.requestRender.bind(component);
  }

  isThumbnailVisible() {
    return this.#isSlotVisible.thumbnail;
  }

  isHeaderVisible() {
    const conditions = [
      this.#isSlotVisible['header-start'],
      this.#isSlotVisible['header-end']
    ];

    return conditions.some(condition => condition);
  }

  isThumbnailWrapperVisible() {
    return this.isThumbnailVisible() || this.isHeaderVisible();
  }

  setSlotVisibility(slotName, isVisible) {
    this.#isSlotVisible[slotName] = isVisible;
    this.requestRender();
  }
};
