class EDatagridPriorityState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._priorityColumn = null;
    this._isPriorityModeOpen = false;
    this._selectedToPrioritize = {};
  }

  get priorityColumn() {
    return this._priorityColumn;
  }

  set priorityColumn(value) {
    this._priorityColumn = value;
    this.requestRender();
  }

  get isPriorityModeOpen() {
    return this._isPriorityModeOpen;
  }

  set isPriorityModeOpen(value) {
    this._isPriorityModeOpen = value;
    this.requestRender();
  }

  get selectedToPrioritize() {
    return this._selectedToPrioritize;
  }

  set selectedToPrioritize(value) {
    this._selectedToPrioritize = value;
    this.requestRender();
  }

  get selectedToPrioritizePosition() {
    if (!this._priorityColumn) { return null; }
    return this._currentPriorityPosition();
  }

  togglePriorityMode() {
    if (this._isPriorityModeOpen === false) {
      this._isPriorityModeOpen = true;
      this._coreState.filterState.sorting = { key: this._priorityColumn.contentKey, order: 'asc' };

      if (this._coreState.visibleContent.length) {
        this._selectedToPrioritize = this._firstNonDisabledRow();
      }
    } else {
      this._isPriorityModeOpen = false;
      this._selectedToPrioritize = {};
    }

    this.requestRender();
  }

  isSortableField(field) {
    return (field.sortable && !this._isPriorityModeOpen) || this.isPriorityField(field);
  }

  isPriorityField(field) {
    return this._isPriorityModeOpen && field.type === 'priority';
  }

  isPriorityModeDisabledOnRow(rowData) {
    const priorityDisabledKey = this._priorityColumn && this._priorityColumn.disabledKey;
    const isPriorityDisabledByKey = priorityDisabledKey && rowData.raw[this._priorityColumn.disabledKey];

    return this._isPriorityModeOpen && isPriorityDisabledByKey;
  }

  _firstNonDisabledRow() {
    const nonDisabledRows = this._coreState.visibleContent.filter(row => {
      if (row.raw[this._priorityColumn.disabledKey] === false) {
        return row;
      }
    });

    return nonDisabledRows.length !== 0 ? nonDisabledRows[0].raw : this._coreState.visibleContent[0].raw;
  }

  _currentPriorityPosition() {
    const columnName = this._priorityColumn.contentKey;
    return this._selectedToPrioritize[columnName];
  }
}

export default coreState => new EDatagridPriorityState(coreState);
