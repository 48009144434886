import uuid from '../../utils/uuid';

export class ERadioState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._id = uuid();
    this._label = null;
    this._name = null;
    this._value = null;
    this._isChecked = false;
    this._isDisabled = false;
    this._type = '';
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
    this.render();
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
    this.render();
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
    this.render();
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
    this.render();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.render();
  }

  get isChecked() {
    return this._isChecked;
  }

  set isChecked(value) {
    this._isChecked = value;
    this.render();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._isDisabled = value;
    this.render();
  }
}

export default ERadioState;
