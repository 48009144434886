import { HTMLCustomElement } from '@emartech/ui-framework-utils';

import ESuggestInputOptionState from './state';
import ESuggestInputOptionEvents from './events';

export class ESuggestInputOption extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.utils = {};
    this.state = new ESuggestInputOptionState(this);
    this.events = new ESuggestInputOptionEvents(this);

    this.utils.mutationObserver = new MutationObserver(this.events.onMutation);
  }

  connectedCallback() {
    this.utils.mutationObserver.observe(this, { childList: true, subtree: true });
    this.state.content = this._getContent();
    this._dispatchInsertEvent();
  }

  disconnectedCallback() {
    this.utils.mutationObserver.disconnect();
    this._dispatchDeleteEvent();
  }

  // Private methods
  _dispatchInsertEvent() {
    this.dispatchEvent(new CustomEvent('suggestinputoption.insert', {
      detail: this.state.content,
      bubbles: true
    }));
  }

  _dispatchDeleteEvent() {
    this.dispatchEvent(new CustomEvent('suggestinputoption.delete', {
      detail: this.state.content,
      bubbles: true
    }));
  }

  _dispatchUpdateEvent(oldContent) {
    this.dispatchEvent(new CustomEvent('suggestinputoption.update', {
      detail: { oldContent, newContent: this.state.content },
      bubbles: true
    }));
  }

  _getContent() {
    return this.textContent.trim();
  }
}

export default ESuggestInputOption;
