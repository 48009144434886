import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { createPopper } from '@popperjs/core';
import { render } from 'uhtml';

import { ESliderBubble } from './template';

class EBubble extends HTMLCustomElement {
  init() {
    this._dom = {};
    this._dom.popper = this._popperElement();
    this._dom.arrow = this._arrowElement();
    this._dom.content = this._contentElement();
    this._state = {};
    this._state.boundary = null;
    this._state.hideBubble = false;
    this._state.disabled = false;
    this.template = new ESliderBubble(this);
  }

  connectedCallback() {
    this.classList.add('e-slider-bubble');
    this._render();
  }

  disconnectedCallback() {
    this._destroyPopper();
  }

  static get observedAttributes() {
    return ['label', 'left', 'boundary', 'hide-bubble', 'disabled'];
  }

  set boundary(value) {
    this._state.boundary = value;
    this._popper = this._initPopper();
  }

  get boundary() {
    return this._state.boundary;
  }

  set hideBubble(value) {
    this._state.hideBubble = this._convertAttributeToBoolean(value);
  }

  get hideBubble() {
    return this._state.hideBubble;
  }

  set label(value) {
    this._label = value;
    this._dom.content.innerHTML = this.label;
    this.updatePopper();
  }

  get label() {
    return this._label;
  }

  set left(value) {
    this.style.left = value;
    this.updatePopper();
  }

  get disabled() {
    return this._state.disabled;
  }

  set disabled(value) {
    this._state.disabled = this._convertAttributeToBoolean(value);
    this.updatePopper();
  }

  updatePopper() {
    if (!this._popper) { return; }

    this._dom.popper.classList.toggle('e-slider-bubble__bubble-hide', this._state.hideBubble);
    this._popper.update();
  }

  _render() {
    render(this, this.template.createElement());

    this._popper = this._initPopper();
  }

  _popperElement() {
    const popperElement = document.createElement('div');
    popperElement.classList.add('e-slider-bubble__bubble');
    return popperElement;
  }

  _arrowElement() {
    const arrowElement = document.createElement('div');
    arrowElement.className = 'e-slider-bubble__arrow';
    return arrowElement;
  }

  _contentElement() {
    const popperContent = document.createElement('div');
    popperContent.className = 'e-slider-bubble__content';
    return popperContent;
  }

  _initPopper() {
    if (this._popper) {
      this._popper.destroy();
    }

    if (!this.parentNode) { return; }

    this._dom.popper.appendChild(this._dom.content);
    this._dom.popper.appendChild(this._dom.arrow);
    this._dom.popper.classList.toggle('e-slider-bubble__bubble-hide', this._state.hideBubble);
    this.appendChild(this._dom.popper);

    return createPopper(this, this._dom.popper, {
      placement: 'top',
      removeOnDestroy: true,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: this._dom.arrow,
            padding: {
              left: 8,
              right: 8
            }
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 16]
          }
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: this._state.boundary,
            mainAxis: 'x'
          }
        },
        {
          name: 'flip',
          enabled: false
        }
      ]
    });
  }

  _destroyPopper() {
    if (!this._popper) { return; }

    this._popper.destroy();
    this._popper = null;
  }
}

export default EBubble;
