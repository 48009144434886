import { normalizeColorWithHashmark } from '../../../utils/normalize-color';

export class EColorInputState {
  constructor(component) {
    this.requestRender = component.requestRender;

    this._inputValue = '';
    this._isReadOnly = false;
    this._name = '';
  }

  get inputValue() {
    return this._inputValue;
  }

  set inputValue(value) {
    this._inputValue = value;
    this.requestRender();
  }

  get inputValueWithHashmark() {
    return normalizeColorWithHashmark(this._inputValue);
  }

  get isReadOnly() {
    return this._isReadOnly;
  }

  set isReadOnly(value) {
    this._isReadOnly = value;
    this.requestRender();
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
    this.requestRender();
  }

}
