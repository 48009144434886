import classNames from 'clsx';
import { html } from 'uhtml';

export class ERadioTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      <div class="e-radio__wrapper">
        ${this.createInput()}
        ${this.createRadio()}
        ${this.createLabel()}
      </div>
    `;
  }

  createInput() {
    const className = classNames('e-radio e-radio-onlyradio', {
      [`e-radio-${this.state.type}`]: this.state.type
    });
    return html`
      <input
        ref=${node => this.refs.input = node}
        class=${className}
        type="radio"
        id=${this.state.id}
        name=${this.state.name}
        value=${this.state.value}
        ?disabled=${this.state.isDisabled}
        .checked=${this.state.isChecked}
      />
    `;
  }

  createRadio() {
    const className = classNames('e-radio__button', {
      'e-radio__button-checked': this.state.isChecked
    });

    return html`<div class=${className}></div>`;
  }

  createLabel() {
    if (!this.state.label) { return; }

    return html`
      <label class="e-radio__label" for=${this.state.id}>
        ${this.state.label}
      </label>
    `;
  }
}

export default ERadioTemplate;
