import { html } from 'uhtml';
import classNames from 'clsx';

export class EColorpickerTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createWrapperElement() {
    return html.node`
      <div class="e-colorpicker e-popover">
        <div ref="${node => this.refs.picker = node}"></div>
      </div>
    `;
  }

  createElement() {
    return html`
      <div class="e-colorpicker__top">
        <e-colorinput
          ref="${node => this.refs.colorinput = node}"
          value="${this.state.color}"
          inline
          @change="${this.events.onColorInputChange}"
        ></e-colorinput>
        <div class="e-colorpicker__separator"></div>
        ${this._createRecents()}
      </div>
      <div class="e-colorpicker__bottom">
        ${this._createBackButton()}
        ${this._createPredefinedColors()}
      </div>
      <div class="e-colorpicker__buttons e-buttongroup">
        ${this._createCancelButton()}
        ${this._createApplyButton()}
      </div>
    `;
  }

  _createCancelButton() {
    return html`
      <button
        type="button"
        class="e-btn"
        data-testid="colorpickerCancelButton"
        @click=${this.events.onCancelButtonClick}
      >
        <e-translation key="components.colorpicker.cancelButton"></e-translation>
      </button>
    `;
  }

  _createApplyButton() {
    const isDisabled = !this.state.color;

    return html`
      <button
        type="button"
        class="e-btn e-btn-highlight"
        data-testid="colorpickerApplyButton"
        ?disabled=${isDisabled}
        @click=${this.events.onApplyButtonClick}
      >
        <e-translation key="components.colorpicker.applyButton"></e-translation>
      </button>
    `;
  }

  _createBackButton() {
    const classes = classNames('e-btn e-btn-onlyicon e-btn-borderless', {
      'e-hidden': this.state.isShowingBaseColors
    });

    return html`
      <button
        type=button"
        data-testid="colorpickerBackButton"
        class="${classes}"
        ref="${node => this.refs.backButton = node}"
        @click=${this.events.onBackButtonClick}
      >
        <e-icon icon="e-move-left" size="small"></e-icon>
      </button>
    `;
  }

  _createPredefinedColors() {
    return html`
      <div
        class="e-colorpicker__predefined"
        tabindex="0"
        ref="${node => this.refs.predefinedColors = node}"
        @focus="${this.events.onFocusPredefined}"
        @blur="${this.events.onBlurPredefined}"
        @keydown="${this.events.onMoveActivePredefined}"
        @keydown="${this.events.onKeyboardClickPredefined}"
      >
        ${this.state.predefinedColors.map((definition, index) => this._createPredefinedColor(definition, index))}
      </div>
    `;
  }

  _createPredefinedColor({ color, shades }, index) {
    const isSelected = color === this.state.color && !shades;
    const isActive = this.state.activePredefinedColorIndex === index;

    const background = `background-color: ${color};`;
    const classList = classNames('e-colorpicker__color', {
      'e-colorpicker__color-active': isActive,
      'e-colorpicker__color-selected': isSelected
    });

    return html`
      <div
        class="${classList}"
        style="${background}"
        @click=${() => this.events.onSelectPredefined(color, isSelected)}
      >
        ${this._createSelectionIcon(isSelected)}
      </div>
    `;
  }

  _createSelectionIcon(isSelected) {
    if (!isSelected) { return; }

    return html`<e-icon icon="check" size="small" color="white"></e-icon>`;
  }

  _createRecents() {
    const hasRecentColors = this.state.recentColors.some(color => color !== '');
    const tabindex = hasRecentColors ? 0 : -1;

    return html`
      <div
        class="e-colorpicker__recent"
        tabindex="${tabindex}"
        @focusin=${this.events.onFocusRecents}
        @keydown=${this.events.onMoveActiveRecent}
        @keydown=${this.events.onKeyboardClickRecent}
      >
        ${this.state.recentColors.map((color, index) => this._createRecent(color, index))}
      </div>
    `;
  }

  _createRecent(color, index) {
    if (!color) {
      return html`<div class="e-colorpicker__color e-colorpicker__color-empty"></div>`;
    }

    const background = `background-color: ${color};`;
    const isSelected = color === this.state.color;
    const isActive = this.state.activeRecentColorIndex === index;
    const classList = classNames('e-colorpicker__color', {
      'e-colorpicker__color-active': isActive,
      'e-colorpicker__color-selected': isSelected
    });

    return html`
      <div
        class="${classList}"
        style="${background}"
        @click=${() => this.events.onRecentColorClick(color)}
      >
        ${this._createSelectionIcon(isSelected)}
      </div>
    `;
  }
}

export default EColorpickerTemplate;
