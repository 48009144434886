import autoBind from 'auto-bind';

export class ESeparatorEvents {
  #state;

  constructor(component) {
    autoBind(this);

    this.#state = component.state;
  }

  onSlotChange(event) {
    const slot = event.target;
    this.#state.hasContent = !!slot.assignedNodes().length;
  }
}
