import { HTMLCustomElement } from '@emartech/ui-framework-utils';

const keyCodeMap = {
  40: 'down',
  38: 'up',
  13: 'enter',
  27: 'esc',
  37: 'left',
  39: 'right',
  8: 'backspace',
  46: 'delete',
  9: 'tab'
};


class EInplaceEditor extends HTMLCustomElement {
  init() {
    this._savedContent = '';
    this._focus = false;
    this._disabled = false;
    this._error = false;
    this._maxlength = false;
    this._placeholder = '';

    this.addEventListener('mouseenter', () => this.style.backfaceVisibility = 'hidden');
    this.addEventListener('focus', () => this.style.backfaceVisibility = 'hidden');
  }

  connectedCallback() {
    this.disabled = this.getAttribute('disabled');
    this.classList.add('e-inplaceeditor');
    this._render();
    this.textContent = this.textContent.trim();
  }

  static get observedAttributes() {
    return ['disabled', 'error', 'value', 'maxlength', 'placeholder'];
  }

  set placeholder(value) {
    this._placeholder = value;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    const convertedValue = this._convertAttributeToBoolean(value);
    if (this._disabled === convertedValue) {
      return;
    }

    this._disabled = convertedValue;
    this._render();
  }

  set error(value) {
    const convertedValue = this._convertAttributeToBoolean(value);
    if (this._error === convertedValue) {
      return;
    }

    this._error = convertedValue;
    this._render();
  }

  set value(value) {
    const trimmedValue = value.trim();

    if (trimmedValue === '' && this._placeholder === '') {
      return;
    }

    if (!this._focus) {
      this.textContent = trimmedValue;
    }

    this._savedContent = trimmedValue;
  }

  set maxlength(value) {
    const maxlength = parseInt(value);
    this._maxlength = !isNaN(maxlength) ? maxlength : false;
  }

  get value() {
    return this.textContent;
  }

  get savedContent() {
    return this._savedContent;
  }

  selectAll() {
    const range = document.createRange();
    range.selectNodeContents(this);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  _render() {
    this.setAttribute('contenteditable', !this._disabled);
    this.classList.toggle('e-inplaceeditor-disabled', this._disabled);
    this.classList.toggle('e-inplaceeditor-error', this._error);
    this._handleEvents();
  }

  _handleEvents() {
    const eventHandler = this._disabled ? 'removeEventListener' : 'addEventListener';

    this[eventHandler]('focus', this._onFocus);
    this[eventHandler]('keydown', this._onKeyPress);
    this[eventHandler]('blur', this._blur);
    this[eventHandler]('paste', this._onPaste);
  }

  _storeContent() {
    this._savedContent = this.textContent;
  }

  _blur() {
    this._focus = false;
    this.textContent = this._normalizeContent();
    this._save();
    window.getSelection().removeAllRanges();
  }

  _onPaste(event) {
    if (!this._maxlength) {
      return;
    }

    let pastedText = '';
    if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData('Text');
    } else if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData('text/plain');
    }

    const currentText = this.textContent;
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    const remainingCharacterLength = selection.toString().length + (this._maxlength - currentText.length);

    if (pastedText.length <= remainingCharacterLength) {
      return;
    }

    event.preventDefault();
    const cutPastedText = pastedText.slice(0, remainingCharacterLength);

    range.deleteContents();
    range.insertNode(document.createTextNode(cutPastedText));
    range.collapse();
  }

  _normalizeContent() {
    if (this.textContent === '' && this._placeholder === '') {
      return this._savedContent;
    }

    return this.textContent.trim();
  }

  _save() {
    if (this._savedContent !== this.textContent) {
      this._savedContent = this._normalizeContent();
      this._sendUpdateEvent();
    }

    this.blur();
  }

  _sendUpdateEvent() {
    const event = new CustomEvent('update', {
      detail: { value: this.textContent }
    });
    this.dispatchEvent(event);
  }

  _onKeyPress(event) {
    const keyCode = event.keyCode || event.charCode;
    const selectAllKeyboardShortcut = keyCode === 65 && (event.metaKey || event.ctrlKey);

    if (this._isContentLongerThanMaxLength() && selectAllKeyboardShortcut) {
      return;
    }

    if (this._isContentLongerThanMaxLength() && this._isKeyCodeEnterText(keyCode) && this._hasNoSelection()) {
      event.preventDefault();
    }

    if (keyCodeMap[keyCode] === 'enter') {
      event.preventDefault();
      this._save();
    }

    if (keyCodeMap[keyCode] === 'esc') {
      event.preventDefault();
      this._cancel();
    }
  }

  _isContentLongerThanMaxLength() {
    return this._maxlength && this.textContent.length > this._maxlength - 1;
  }

  _isKeyCodeEnterText(keyCode) {
    return Object.keys(keyCodeMap).indexOf(keyCode.toString()) === -1;
  }

  _hasNoSelection() {
    const selection = window.getSelection();
    return selection.toString().length === 0;
  }

  _cancel() {
    this.textContent = this._savedContent;

    this._blur();
  }

  _onFocus() {
    this._focus = true;
    this._storeContent();
  }

}

export default EInplaceEditor;
