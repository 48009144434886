import State from '../../../utils/state';

class TimepickerState extends State {
  get defaultState() {
    return {
      defaultHours: '00',
      hours: '',
      maxHours: 23,
      minHours: 0,
      defaultMinutes: '00',
      minutes: '',
      isAmpm: false,
      partOfDay: 'am',
      error: false,
      emptyEnabled: false,
      translations: {},
      disabled: false
    };
  }
}

export default TimepickerState;
