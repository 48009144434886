import { queryKeyboardFocusable } from '@emartech/ui-framework-utils';

class ETooltipState {
  constructor(component) {
    this.requestRender = component.requestRender;
    this.updateFocusClasses = component.updateFocusClasses.bind(component);

    this.component = component;
    this.refs = component.refs;

    this._content = '';
    this._type = '';
    this._placement = 'top';
    this._hasAction = false;
    this._isDisabled = false;
    this._isTooltipContentFocusable = undefined;
    this._isPopupContentFocusable = false;
    this._isFocusOnFocusCatcher = false;
  }

  get content() {
    return this._content;
  }

  set content(value) {
    this._content = value;
    this.requestRender().then(() => {
      if (!this.refs.popup) { return; }

      this._isPopupContentFocusable = !!queryKeyboardFocusable(this.refs.popupContent, { includeHidden: true });
      this.component.createFocusCatchers();
      this.component.updateComponentAriaDescription();
    });
  }

  get placement() {
    return this._placement;
  }

  set placement(value) {
    this._placement = value;
  }

  get hasAction() {
    return this._hasAction;
  }

  set hasAction(value) {
    this._hasAction = value;
    this.requestRender();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._isDisabled = value;
  }

  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
    this.requestRender();
  }

  get isPermissionType() {
    return this.type === 'permission';
  }

  get isTooltipContentFocusable() {
    return this._isTooltipContentFocusable;
  }

  set isTooltipContentFocusable(value) {
    this._isTooltipContentFocusable = value;
  }

  get isPopupContentFocusable() {
    return this._isPopupContentFocusable;
  }

  get isFocusOnFocusCatcher() {
    return this._isFocusOnFocusCatcher;
  }

  set isFocusOnFocusCatcher(value) {
    this._isFocusOnFocusCatcher = value;
    this.updateFocusClasses();
  }
}

export default ETooltipState;
