import {
  AuthenticationTokenService,
  BACKEND_URL_SUITE,
  BACKEND_URL_EMS_ADMIN
} from './authentication-token';

const authenticationTokenService = new AuthenticationTokenService(window);
authenticationTokenService.initialize();

export default {
  suite: integration => authenticationTokenService.getToken(BACKEND_URL_SUITE, integration),
  emsAdmin: integration => authenticationTokenService.getToken(BACKEND_URL_EMS_ADMIN, integration)
};
