import { html } from 'uhtml';
import templateVariables from './template-variables';

export default (state, events, refs) => {
  const variables = templateVariables(state, events, refs);

  if (state.disabled) {
    return html`
      <div class="e-upload">
        <div class="clearfix">
          <div class="e-grid e-grid-xsmall">
            <div class="e-cell e-cell-auto e-cell-xsmall">
              <input
                value="${state.inputValue}"
                class="${variables.inputClass}"
                type="text"
                readonly
                placeholder="${state.placeholder || ''}"
                ?disabled="${state.disabled}">
            </div>
            <div class="e-cell e-cell-xsmall">
              <button type="button" class="${variables.buttonClass}" ?disabled="${state.disabled}">
                <e-icon icon="folder-open"></e-icon>
                ${state.button || 'Choose File'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="e-dropdown__content">
        <span class="e-dropdown__item e-dropdown__mediadb_callback">
          <e-icon icon="e-mediadb" type="table"></e-icon>
          ${state.mediadb || 'From your Media Database'}
        </span>
        <span class="e-dropdown__item" onclick="${variables.openFileDialog}">
          <e-icon icon="monitor" type="table"></e-icon>
          ${state.browse || 'From Computer'}
        </span>
      </div>${variables.fileElement}`;
  }

  return html`
    <e-dropdown data-autoclose style="display: block;">
      <e-dropdown-handler>
        <div class="e-upload">
          <div class="clearfix">
            <div class="e-grid e-grid-xsmall">
              <div class="e-cell e-cell-auto e-cell-xsmall">
                <input
                  value="${state.inputValue}"
                  class="${variables.inputClass}"
                  type="text"
                  readonly
                  placeholder="${state.placeholder || ''}"
                  ?disabled="${state.disabled}">
              </div>
              <div class="e-cell e-cell-xsmall">
                <button type="button" class="${variables.buttonClass}" ?disabled="${state.disabled}">
                  <e-icon icon="folder-open"></e-icon>
                  ${state.button || 'Choose File'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </e-dropdown-handler>
      <div class="e-dropdown__content">
        <span class="e-dropdown__item e-dropdown__mediadb_callback">
          <e-icon icon="e-mediadb" type="table"></e-icon>
          ${state.mediadb || 'From your Media Database'}
        </span>
        <span class="e-dropdown__item" onclick="${variables.openFileDialog}">
          <e-icon icon="monitor" type="table"></e-icon>
          ${state.browse || 'From Computer'}
        </span>
      </div>
    </e-dropdown>${variables.fileElement}`;
};
