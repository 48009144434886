
export class EThemeSwitcherState {
  #colorTheme = null;

  constructor(component) {
    this.requestRender = component.requestRender;
    this.utils = component.utils;
  }

  get defaultTheme() {
    return this.utils.themeHandler.defaultTheme;
  }

  get availableThemes() {
    return this.utils.themeHandler.availableThemes;
  }

  get colorTheme() {
    return this.#colorTheme || this.utils.themeHandler.currentTheme;
  }

  set colorTheme(theme) {
    this.#colorTheme = theme;
    this.requestRender();
  }
}

export default EThemeSwitcherState;
