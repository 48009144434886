import { render } from 'uhtml';
import { convertAttributeToBoolean, HTMLCustomElement } from '@emartech/ui-framework-utils';
import ETimepickerAmpmTemplate from './template';
import ETimepickerAmpmState from './state';
import ETimepickerAmpmEvents from './events';

class ETimepickerAmpm extends HTMLCustomElement {
  init() {
    this._state = new ETimepickerAmpmState(this);
    this._events = new ETimepickerAmpmEvents(this);
    this._template = new ETimepickerAmpmTemplate(this);

    this.addEventListener('keydown', this._events.onKeyDown);
  }

  connectedCallback() {
    this._template.init();
    this._render();
  }

  static get observedAttributes() {
    return ['disabled', 'error'];
  }

  set isAm(value) {
    this._state.isAm = value;
  }

  get isAm() {
    return this._state.isAm;
  }

  get disabled() {
    return this._state.disabled;
  }

  set disabled(value) {
    this._state.disabled = convertAttributeToBoolean(value);
    this.classList.toggle('e-input-disabled', this._state.disabled);
    this.setAttribute('tabindex', this._state.disabled ? '-1' : '0');
  }

  get error() {
    return this._state.error;
  }

  set error(value) {
    this._state.error = convertAttributeToBoolean(value);
    this.classList.toggle('e-input-error', this._state.error);
  }

  _render() {
    render(this, this._template.createElement());
  }
}

export default ETimepickerAmpm;
