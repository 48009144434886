import { html } from 'uhtml';

class Template {
  render(state) {
    const badge = html`
      <div class="e-verticalnavitem__badge">${state.badge}</div>
    `;

    const icon = html`
      <e-tooltip content="${state.value}" placement="right">
        <div class="e-verticalnavitem__icon e-svgclickfix">
          <e-icon icon="${state.icon}"></e-icon>
          ${state.badge ? badge : '' }
        </div>
      </e-tooltip>
    `;

    return html`
      <div class="${this._calculateClassName(state)}">
        ${state.icon ? icon : ''}

        <div class="e-verticalnavitem__value">${state.value}</div>
      </div>
    `;
  }

  _calculateClassName(state) {
    const className = ['e-verticalnavitem'];

    const status = state.menuStatus || state.status;
    if (status) {
      status.split(' ').forEach(status => {
        if (['active', 'faded', 'disabled'].indexOf(status) !== -1) {
          className.push(`e-verticalnavitem-${status}`);
        }
      });
    }

    return className.join(' ');
  }
}

export default Template;
