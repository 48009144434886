import { dateUtils } from '@emartech/ui-framework-utils';
import { getTextContent } from './helpers.js';

const asNumber = value => parseFloat(value);
const asDate = value => {
  const parsedValue = dateUtils.parse(value);
  return dateUtils.isValid(parsedValue) ? parsedValue : null;
};
const asBoolean = value => value ? 1 : 0;

const converters = {
  number: asNumber,
  currency: asNumber,
  percent: asNumber,
  duration: asNumber,
  time: asDate,
  priority: asNumber,
  switch: asBoolean,
  html: value => value ? getTextContent(value).toLowerCase() : '',
  default: value => value ? value.toString().toLowerCase() : ''
};

const getValueConverter = (sortBy, columnDefinitions) => {
  const sortColumn = columnDefinitions.filter(definition => definition.contentKey === sortBy)[0];
  const sortType = sortColumn?.type;
  const isHTMLContent = sortColumn?.renderHtml;

  return converters[sortType] || (isHTMLContent ? converters.html : converters.default);
};

const extendWithSortValue = (sortBy, converter) => row => {
  row._sortValue = converter(row.raw[sortBy]);
  return row;
};

const isEmpty = value => !value && value !== 0;

const getComparator = (sortBy, order) => (actualRow, previousRow) => {
  const actual = actualRow._sortValue;
  const previous = previousRow._sortValue;
  if (actual === previous) { return 0; };
  if (isEmpty(actual)) { return 1; }
  if (isEmpty(previous)) { return -1; }
  return (actual > previous ? 1 : -1) * (order === 'asc' ? 1 : -1);
};

const deleteSortValue = row => {
  delete row._sortValue;
  return row;
};

const sort = (content, [sortBy, order, columnDefinitions]) => {
  if (!sortBy) { return content; }

  const converter = getValueConverter(sortBy, columnDefinitions);
  return content
    .map(extendWithSortValue(sortBy, converter))
    .sort(getComparator(sortBy, order))
    .map(deleteSortValue);
};

export default sort;
