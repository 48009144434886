export const normalizeColor = (value) => {
  if (typeof value !== 'string') { return ''; }
  const trimmedValue = trimHashmark(value);
  const lowerCasedValue = trimmedValue.toString().toLowerCase();
  return isValid(lowerCasedValue) ? expandValue(lowerCasedValue) : '';
};

export const normalizeColorWithHashmark = (value) => {
  const normalizedValue = normalizeColor(value);
  return normalizedValue ? `#${normalizedValue}` : '';
};

export const trimHashmark = (value) => {
  return value[0] === '#' ? value.slice(1) : value;
};

const isValid = (value) => {
  return value.match(/^[0-9a-f]+$/) && (value.length === 3 || value.length === 6);
};

const expandValue = (value) => {
  return value.length === 6 ? value : value[0] + value[0] + value[1] + value[1] + value[2] + value[2];
};
