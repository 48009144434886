import translator from '../../../js/utils/translator';
export class EStepBarState {
    constructor(component) {
        this.defaultLabel = translator.translate('components.stepBar.defaultLabel');
        this.requestRender = component.requestRender;
        this._steps = [];
        this._isFloating = false;
        this._label = this.defaultLabel;
    }
    get steps() {
        return this._steps;
    }
    get selectedStepIndex() {
        return this._steps.findIndex(step => step.selected);
    }
    get stepsWithCount() {
        return this._steps.filter(step => !step.countExcluded);
    }
    get isFloating() {
        return this._isFloating;
    }
    set isFloating(value) {
        this._isFloating = value;
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
    }
    get previousAvailableStep() {
        const selectedStepIndex = this.selectedStepIndex;
        const previousSteps = this._steps.slice(0, selectedStepIndex);
        return previousSteps.reverse().find(step => !step.disabled);
    }
    get isPreviousStepAvailable() {
        return !!this.previousAvailableStep;
    }
    get nextAvailableStep() {
        const selectedStepIndex = this.selectedStepIndex;
        const nextSteps = this._steps.slice(selectedStepIndex + 1, this._steps.length);
        return nextSteps.find(step => !step.disabled);
    }
    get isNextStepAvailable() {
        return !!this.nextAvailableStep;
    }
    get hasAvailableSelected() {
        return !!this._steps.find(step => step.selected);
    }
    upsertStep(detail) {
        const stepIndex = this._steps.findIndex(storedStep => storedStep.uuid === detail.uuid);
        if (detail.selected) {
            this._resetSelectedSteps();
        }
        if (stepIndex === -1) {
            this._steps = [...this._steps, detail];
        }
        else {
            this._steps[stepIndex] = detail;
        }
        this.requestRender();
    }
    _resetSelectedSteps() {
        this._steps.forEach((step) => step.selected = false);
    }
    deleteStep(detail) {
        const stepIndex = this._steps.findIndex(storedStep => storedStep.uuid === detail.uuid);
        if (stepIndex === -1) {
            return;
        }
        this._steps.splice(stepIndex, 1);
        this.requestRender();
    }
}
