import EDatagridColumn from '../datagrid-column';

class EDatagridColumnTime extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      disabledKey: '',
      disabledTooltip: '',
      type: 'priority'
    });
  }

  static get observedAttributes() {
    return ['disabled-key', 'disabled-tooltip'].concat(super.observedAttributes);
  }

  set disabledKey(value) {
    this._state.disabledKey = value;
    this._dispatchEvent();
  }

  set disabledTooltip(value) {
    this._state.disabledTooltip = value;
    this._dispatchEvent();
  }
}

export default EDatagridColumnTime;
