import { html } from 'uhtml';
import classNames from 'clsx';
import purifier from '../../../utils/dom-purify';
export class ETabBarTemplate {
    constructor(component) {
        this.state = component.state;
        this.events = component.events;
    }
    createWrapper() {
        return html.node `<div class="e-tab-bar__wrapper"></div>`;
    }
    createElement() {
        const tab = this.state.selectedTab;
        const spacingClass = (tab?.spacing && tab?.spacing !== 'none') ?
            ` e-tab-bar__content-spacing_${tab.spacing}` :
            '';
        const itemClasses = `e-tab-bar__content${spacingClass}`;
        const headerClasses = classNames('e-tab-bar__header', {
            'e-tab-bar__header-fluid': this.state.isVertical && this.state.isLabelVisible
        });
        const tabOwnIds = this.state.tabs.map(tab => tab.uuid).join(' ');
        return html `
      <div class="${headerClasses}" role="tablist" aria-owns="${tabOwnIds}">
        ${this.createTabs()}
      </div>
      <div class="${itemClasses}">
        ${this.state.selectedTabContent}
      </div>
    `;
    }
    createTabs() {
        return html `
      ${this.state.tabs.map(tab => this.createTab(tab))}
    `;
    }
    createTab(tab) {
        const itemClasses = classNames('e-tab-bar__tab', {
            'e-tab-bar__tab-disabled': tab.disabled,
            'e-tab-bar__tab-selected': this.state.isSelectedTab(tab)
        });
        const isDisabledTabWithDisabledTooltip = tab.disabled && !!tab.disabledTooltip;
        const isVerticalNotDisabledTabWithLabel = this.state.isVertical &&
            !tab.disabled &&
            !!tab.label &&
            !this.state.isLabelVisible;
        const disabledTooltip = !isDisabledTabWithDisabledTooltip && !isVerticalNotDisabledTabWithLabel;
        const tooltipContent = isDisabledTabWithDisabledTooltip ? tab.disabledTooltip : tab.label;
        const tooltipPosition = this.state.isVertical ? 'right' : 'top';
        return html `
      <div class="${itemClasses}" @click="${() => this.events.onTabClick(tab)}">
        <e-tooltip
          class="e-tab-bar__tab_tooltip"
          content="${tooltipContent}"
          placement="${tooltipPosition}"
          ?disabled="${disabledTooltip}"
          ?permission="${tab.disabledType === 'permission'}"
        >
          ${this.createTabButton(tab)}
        </e-tooltip>
      </div>
    `;
    }
    createTabButton(tab) {
        const isButtonDisabled = tab.disabled;
        const isTabSelected = this.state.isSelectedTab(tab);
        const buttonClasses = classNames('e-tab-bar__tab_content', {
            'e-tab-bar__tab_content-disabled': tab.disabled
        });
        return html `
      <button
        type="button"
        role="tab"
        id="${tab.uuid}"
        aria-selected="${isTabSelected}"
        class="${buttonClasses}"
        ?disabled="${isButtonDisabled}"
      >
        ${this.createTabIcon(tab.icon)}
        ${this.createTabLabel(tab.label)}
        ${this.createTabBadge(tab.label, tab.badge)}
      </button>
    `;
    }
    createTabLabel(label) {
        if (!label || !this.state.isLabelVisible) {
            return;
        }
        return html `
      <div class="e-tab-bar__tab_label">
        ${purifier.sanitize(label)}
      </div>
    `;
    }
    createTabIcon(icon) {
        if (!icon || this.state.isIconHidden) {
            return;
        }
        return html `
      <e-icon class="e-tab-bar__tab_icon" icon="${icon}" color="inherit"></e-icon>
    `;
    }
    createTabBadge(label, badge) {
        if (!badge) {
            return;
        }
        const classList = classNames('e-tab-bar__badge', {
            'e-tab-bar__badge-icon': !label || this.state.isVertical
        });
        return html `
      <div class="${classList}">
        ${badge}
      </div>
    `;
    }
}
