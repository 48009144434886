import { html } from 'uhtml';
import EDatagridSearchTemplate from './search';
import EDatagridFilterTemplate from './filter';
import classNames from 'clsx';
import EDatagridActionTemplate from './action';
import EDatagridBulkActionBarTemplate from './bulk-actions-bar';
import flipper from '../../../../utils/flipper';

export default class EDatagridHeaderTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;

    this._search = new EDatagridSearchTemplate(this._state, this._events, this._refs);
    this._filter = new EDatagridFilterTemplate(this._state, this._events);
    this._action = new EDatagridActionTemplate(this._state, this._events);
    this._bulkActionBar = new EDatagridBulkActionBarTemplate(this._state, this._events);
  }

  createPriorityButton() {
    if (!this._state.columnDefinitions.some(definition => definition.type === 'priority')) { return; }

    const buttonClass = classNames('e-btn e-btn-borderless', {
      'e-btn-active': this._state.priorityState.isPriorityModeOpen
    });

    return html`
      <div class="e-datagrid__priority_button">
        <button class="${buttonClass}" @click=${this._events.onPriorityModeButtonClick}>
          <e-icon icon="e-move-vertical"></e-icon>
          <e-translation key="components.datagrid.priority.enterPriorityModeButton"></e-translation>
        </button>
      </div>
    `;
  };

  createBulkToggleButton() {
    if (!this._state.bulkState.hasBulkToggle) { return; }

    const { icon, label } = this._state.bulkState.bulkToggle;
    const classList = classNames('e-datagrid__bulk_toggle e-btn e-btn-borderless', {
      'e-btn-active': this._state.bulkState.isBulkToggleActive
    });

    return html`
      <button class=${classList} @click=${this._events.onBulkToggleClick}>
        ${icon ? html`<e-icon icon=${icon}></e-icon>` : null}${label}
      </button>
    `;
  }

  createAdvancedFilter() {
    if (!this._state.filterState.hasAdvancedFilters) { return; }

    const filtersClass = classNames('e-datagrid__advanced_filters', {
      'e-hidden': !this._state.filterState.isFiltersOpen
    });

    return html`
      <div class="${filtersClass}">
        ${this._filter.createAdvanced()}
      </div>
    `;
  }

  create() {
    const hasColumnDefinitions = this._state.columnDefinitions.length;

    if (!hasColumnDefinitions || !this._state.isHeaderVisible) {
      return;
    }

    return html`
      <div class="e-datagrid__header">
        <div class="e-datagrid__header_left">
          ${this._search.create()}
          ${this._filter.createSimple()}

          ${this._filter.createAdvancedButton()}
          ${this._createViewSettingsButton()}
          ${this.createPriorityButton()}
          ${this.createBulkToggleButton()}
        </div>
        <div class="e-datagrid__header_right">
          ${this._action.create()}
        </div>
        ${this.createAdvancedFilter()}
        ${this._bulkActionBar.create()}
      </div>
    `;
  }

  createViewSettingsPopupElement() {
    return html`
      <div class="e-datagrid__view_settings_popup">
        <e-separator>
          <e-translation key="components.datagrid.viewSettingShowColumn"></e-translation>
        </e-separator>
        <div class="e-margin-top-s">
          ${this._state.columnDefinitions.map(field => this._viewSettingsSwitch(field))}
        </div>
        <e-separator>
          <e-translation key="components.datagrid.viewSettingsReset"></e-translation>
        </e-separator>
        <div class="e-margin-top-s">
          <button
            type="button"
            class="e-btn e-btn-borderless"
            @click="${this._events.onViewSettingsResetButtonClick}"
          >
            <e-icon icon="reset"></e-icon>
            <e-translation key="components.datagrid.viewSettingsResetButton"></e-translation>
          </button>
        </div>
      </div>
    `;
  }

  _createViewSettingsButton() {
    let buttonClass;

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      buttonClass = classNames('e-btn e-btn-borderless', {
        'e-btn-active': this._state.viewSettingsVisible || this._state.columnSettingsState.hasHiddenColumn
      });
    } else {
      buttonClass = classNames('e-btn e-btn-borderless', {
        'e-btn-active': this._state.viewSettingsVisible || this._state.hasHiddenColumn
      });
    }

    return html`
      <div class="e-datagrid__view_settings_button">
        <button
          type="button"
          class="${buttonClass}"
          @click="${this._events.onViewSettingsButtonClick}"
          ref="${node => this._refs.viewSettingsButton = node}"
        >
          <e-icon icon="eye"></e-icon>
          <e-translation key="components.datagrid.viewSettingsButton"></e-translation>
        </button>
      </div>
    `;
  }

  _viewSettingsSwitch(field) {
    let columnSettings;

    if (flipper.isOn('ui_datagrid_column_hidden')) {
      columnSettings = this._state.columnSettingsState.activeSettings[field.contentKey];
    } else {
      columnSettings = this._state.columnSettings[field.contentKey];
    }

    return html`
      <div class="e-margin-bottom-xs">
        <e-switch
          value="${field.contentKey}"
          label="${field.head}"
          ?checked=${!columnSettings?.hidden}
          @change=${event => this._events.onViewSettingsSwitchChange(event, field.contentKey)}
        ></e-switch>
      </div>
    `;
  }
}
