import autoBind from 'auto-bind';

export class ESwitchEvents {
  constructor(component) {
    autoBind(this);

    this.state = component.state;
    this.refs = component.refs;
  }

  onSwitchClick(event) {
    if (this.state.isDisabled) {
      event.stopImmediatePropagation();
      return;
    }

    if (event.target !== this.refs.input) {
      event.stopImmediatePropagation();
      event.preventDefault();

      this.refs.input.click();

      this.state.isChecked = this.refs.input.checked;
    }
  }

  onSecondaryLabelClick(event) {
    event.stopImmediatePropagation();

    if (this.state.isDisabled) { return; }
    if (this.refs.input.checked === false) { return; }

    this.onSwitchClick(event);
  }

  onPrimaryLabelClick(event) {
    event.stopImmediatePropagation();

    if (this.state.isDisabled) { return; }
    if (this.refs.input.checked === true) { return; }

    this.onSwitchClick(event);
  }
}

export default ESwitchEvents;
