import NavigationClient from './client.js';
import NavigationService from './service.js';
import JSLogger from '../jslogger/index.js';

const client = new NavigationClient();
const service = new NavigationService();

export default {
  navigate(options, top) {
    const logger = new JSLogger('decommission');
    try {
      const urlFromRequest = new URL(options.url);
      logger.log('navigate', urlFromRequest.origin);
    } catch {
      logger.log('navigate', 'relative path');
    }

    return client.navigate(options, top);
  },

  getTopUrl(options) {
    return client.getTopUrl(options);
  },

  getTopOrigin(options) {
    return client.getTopOrigin(options);
  },

  subscribe() {
    client.subscribe();
    service.subscribe();
  }
};
