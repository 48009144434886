import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import State from './state';
import Template from './template';

class EDatetimePanel extends HTMLCustomElement {
  init() {
    this._emitter = new EventEmitter();
    this._state = new State(this.requestRender.bind(this));
    this._template = new Template(this._emitter);

    this._emitter.on('pickerChange', this._onPickerChange.bind(this));
    this._emitter.on('presetsChange', this._onPresetsChange.bind(this));
    this._emitter.on('onCancel', this._onCancel.bind(this));
    this._emitter.on('onApply', this._onApply.bind(this));
  }

  connectedCallback() {
    this.requestRender();
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  static get observedAttributes() {
    return ['start-disabled', 'end-disabled', 'presets-disabled'];
  }

  get value() {
    return this._isRange() ? this._state.state.value : this._state.state.value.start;
  }

  set value(value) {
    const newValue = typeof value === 'object' ? value : { start: value, end: value };
    this._state.setState({ value: newValue });
    this._template.setValueOnPickerElements(newValue);
  }

  get todayHidden() {
    return this._state.state.todayHidden;
  }

  set todayHidden(value) {
    this._state.setState({ todayHidden: value });
  }

  get type() {
    return this._state.state.type;
  }

  set type(value) {
    this._state.setState({ type: value });
  }

  get min() {
    return this._state.state.min;
  }

  set min(value) {
    this._state.setState({ min: value });
  }

  get max() {
    return this._state.state.max;
  }

  set max(value) {
    this._state.setState({ max: value });
  }

  set presets(value) {
    this._state.setState({ presets: value });
  }

  get presets() {
    return this._state.state.presets;
  }

  get resetTime() {
    return this._state.state.resetTime;
  }

  set resetTime(value) {
    const newValue = typeof value === 'object' ? value : { start: value, end: value };
    this._state.setState({ resetTime: newValue });
  }

  get manualApply() {
    return this._state.state.manualApply;
  }

  set manualApply(value) {
    this._state.setState({ manualApply: value });
  }

  get startDisabled() {
    return this._state.state.startDisabled;
  }

  set startDisabled(value) {
    this._state.setState({ startDisabled: this._convertAttributeToBoolean(value) });
  }

  get endDisabled() {
    return this._state.state.endDisabled;
  }

  set endDisabled(value) {
    this._state.setState({ endDisabled: this._convertAttributeToBoolean(value) });
  }

  get presetsDisabled() {
    return this._state.state.isPresetsDisabled;
  }

  set presetsDisabled(value) {
    this._state.setState({ isPresetsDisabled: this._convertAttributeToBoolean(value) });
  }

  _render() {
    render(this, this._template.createElement(this._state));
  }

  _onPickerChange({ type, value }) {
    const newValue = this._isRange() ?
      { ...this._state.state.value, [type]: value } :
      { start: value, end: value };
    this._state.setState({ value: newValue });
    this._handleValueChange();
  }

  _onPresetsChange(value) {
    this._state.setState({ value });
    this._template.setValueOnPickerElements(value);
    this._handleValueChange();
  }

  _onCancel() {
    this.dispatchEvent(new CustomEvent('panel.cancel'));
  }

  _onApply() {
    this.dispatchEvent(new CustomEvent('panel.apply'));
    this._dispatchPanelChange();
  }

  _handleValueChange() {
    if (this._state.state.manualApply) { return; }

    this._dispatchPanelChange();
  }

  _dispatchPanelChange() {
    this.dispatchEvent(new CustomEvent('panel.change', { detail: { value: this.value } }));
  }

  _isRange() {
    return ['daterange', 'datetimerange'].indexOf(this._state.state.type) > -1;
  }
}

export default EDatetimePanel;
