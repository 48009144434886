import { html } from 'uhtml';
import classNames from 'clsx';

export default (state, utils) => {
  const needToForceHelperIconColor = !['helper', 'label'].includes(state.type) &&
    state.icon === 'question-circle' && !state.color;

  const className = classNames('e-icon', {
    [`e-icon-${state.type}`]: state.type,
    [`e-icon-${state.size}`]: state.size,
    'e-icon-disabled': state.disabled,
    [`text-color-${state.color}`]: state.color,
    [`e-icon-rotate-${state.rotation}`]: state.rotation,
    'text-color-faint': needToForceHelperIconColor
  });

  let iconCode = utils.themeHandler.getCurrentIconTheme().glyphs[state.icon].code;

  if (['helper', 'label'].includes(state.type) && state.icon === 'question' && !state.color) {
    iconCode = utils.themeHandler.getCurrentIconTheme().glyphs['question-circle'].code;
  }

  const iconCharacter = String.fromCharCode(Number.parseInt(iconCode, 16));

  return html`<div class="${className}">${iconCharacter}</div>`;
};
