import { html } from 'uhtml';

export default class EDatagridSearchTemplate {
  constructor(state, events, refs) {
    this._state = state;
    this._events = events;
    this._refs = refs;
  }

  create() {
    if (this._state.filterState.isSearchHidden) { return; }

    const tooltipContent = this._state.filterState.searchTooltip || this._state.translations.searchTooltip;

    return html`
      <div class="e-datagrid__search">
        <e-tooltip placement="right" content="${tooltipContent}" width="200">
          <input
            id="${this._state.uuid}"
            value="${this._state.filterState.searchKeyword.keyword}"
            type="search"
            placeholder="${this._state.translations.searchPlaceholder}"
            class="e-input e-input-large e-input-search"
            ref="${node => this._refs.searchInput = node}"
            ?disabled="${this._isDisabled()}"
            @input="${this._events.onSearchInputChange}"
          >
        </e-tooltip>
      </div>
    `;
  }

  _isDisabled() {
    if (this._state.serverState.isServerSide) { return false; }

    return this._state.serverState.isLoading || !this._state.content || !this._state.content.length;
  }
}

