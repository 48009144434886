export class EAccordionState {
    constructor(component) {
        this._items = [];
        this._isBorderless = false;
        this._isSingle = false;
        this._activeItems = new Set();
        this.requestRender = component.requestRender;
    }
    get items() {
        return this._items;
    }
    get isBorderless() {
        return this._isBorderless;
    }
    set isBorderless(value) {
        this._isBorderless = value;
        this.requestRender();
    }
    get isSingle() {
        return this._isSingle;
    }
    set isSingle(value) {
        this._isSingle = value;
        this.requestRender();
    }
    get activeItems() {
        return this._activeItems;
    }
    upsertItem({ target, detail }) {
        const itemIndex = this._items.findIndex(storedItem => storedItem.uuid === detail.uuid);
        if (detail.active) {
            this.removeActiveFromAllItems();
            this._activeItems.add(detail.uuid);
        }
        if (itemIndex === -1) {
            this._items = [...this._items, { ...detail, target }];
        }
        else {
            this._items[itemIndex] = { ...detail, target };
        }
        this.requestRender();
    }
    deleteItem(uuid) {
        const itemIndex = this._items.findIndex(storedItem => storedItem.uuid === uuid);
        if (itemIndex === -1) {
            return;
        }
        this._items.splice(itemIndex, 1);
        this._activeItems.delete(uuid);
        this.requestRender();
    }
    toggleActiveItem(item) {
        if (this._activeItems.has(item.uuid)) {
            this._activeItems.delete(item.uuid);
        }
        else {
            this.removeActiveFromAllItems();
            this._activeItems.add(item.uuid);
        }
        this.requestRender();
    }
    removeActiveFromAllItems() {
        if (!this._isSingle) {
            return;
        }
        this._activeItems.clear();
    }
}
