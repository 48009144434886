class ScriptInjector {
  constructor(configStore, documentObject) {
    this._configStore = configStore;
    this._document = documentObject;
  }

  inject() {
    this._configStore.getLoadedConfig().then(({ includeScripts }) => {
      if (!includeScripts) { return; }

      Object.keys(includeScripts).forEach(includeScript => {
        const scriptUrls = includeScripts[includeScript];
        this._awaitEach(scriptUrls, scriptUrl => this._appendScript(scriptUrl));
      });
    });
  }

  _createScriptElement(scriptUrl) {
    const scriptElement = this._document.createElement('script');
    scriptElement.src = scriptUrl;
    return scriptElement;
  }

  _appendElement(element) {
    return new Promise(resolve => {
      this._document.head.appendChild(element);
      element.addEventListener('load', resolve);
    });
  }

  _appendScript(scriptUrl) {
    const element = this._createScriptElement(scriptUrl);
    return this._appendElement(element);
  }

  _awaitEach(list, callback) {
    let previousPromise = null;

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      previousPromise = previousPromise ?
        previousPromise.then(() => callback(item)) :
        callback(item);
    }
  }
}

export default ScriptInjector;
