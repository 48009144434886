
export const validatorIcons = {
  'info': 'e-info-circle',
  'success': 'e-check-circle',
  'danger': 'e-error',
  'error': 'e-error',
  'warning': 'warning'
};

export const validatorMeasureHeight = element => {
  const originalHeight = window.getComputedStyle(element, null).getPropertyValue('height');
  element.style.height = 'auto';
  const measuredHeight = element.offsetHeight;
  element.style.height = originalHeight;
  return measuredHeight;
};
