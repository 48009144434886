class EmojiConvert {
  encodeEntities(txt) {
    if (!txt) {
      return '';
    }

    let ch = '';
    let chCode = '';
    let outptSingle = '';

    for (let i = 0; i < txt.length; ++i) {
      ch = txt.charAt(i);
      chCode = txt.charCodeAt(i);

      if (chCode >= 0xD800 && chCode <= 0xDBFF) {
        chCode = ((chCode - 0xD800) * 0x400) + (txt.charCodeAt(++i) - 0xDC00) + 0x10000;
        outptSingle += this._entitify(chCode);
      } else if (chCode >= 0x10000) {
        outptSingle += this._entitify(chCode);
      } else {
        outptSingle += ch;
      }
    }

    return outptSingle;
  }

  decodeEntities(value) {
    let str;
    let temp = document.createElement('textarea');

    temp.innerHTML = value;
    str = temp.value;
    temp = null;

    return str || '';
  }

  _entitify(str) {
    return '&#x' + this._padString(str.toString(16).toUpperCase(), 4) + ';';
  }

  _padString(str, len) {
    let n = len - str.length;

    for (let i = 0; i < n; ++i) {
      str = '0' + str;
    }

    return str;
  }
}

export default new EmojiConvert();
