import { html } from 'uhtml';
const ORDER_OFFSET = 1;

class AddConditionTemplate {
  constructor({ refs, popover, emitter }) {
    this._refs = refs;
    this._emitter = emitter;
    this._popover = popover;
    this._popoverHandler = null;
  }

  handleEvent(event) {
    if (event.type !== 'open') { return; }

    this._emitter.emit('addConditionButton');
    this._popoverHandler.update();
  }

  close() {
    this._popoverHandler.opened = false;
  }

  render(state) {
    const isEmptyState = state.conditions.length === 0 && state.editMode === false;
    this._refs.addCondition.style.order = state.conditions.length + ORDER_OFFSET;

    if (isEmptyState) {
      return this._emptyState(state);
    }

    return this._footer(state);
  }

  _footer(state) {
    return html`
      <e-criteria-addcondition-footer>${this._addConditionButton(state)}</e-criteria-addcondition-footer>
    `;
  }

  _emptyState(state) {
    return html`
      <e-criteria-addcondition-emptystate>${this._addConditionButton(state)}</e-criteria-addcondition-footer>
    `;
  }

  _addConditionButton(state) {
    return html`
      <e-criteria-addcondition-button>${this._renderPopoverHandler(state)}</e-criteria-footer-button>
    `;
  }

  _renderPopoverHandler(state) {
    this._popoverHandler = html.node`
      <e-popover-handler ?disabled=${state.addNewDisabled} @open=${this} placement="top" remove-on-destroy inline>
        ${state.hasTooltip ? this._renderTooltipButton(state) : this._renderButton(state)}
      </e-popover-handler>
    `;
    this._popoverHandler.popover = this._popover;
    return this._popoverHandler;
  }

  _renderTooltipButton(state) {
    return html`
      <e-tooltip content=${state.addNewTooltip} placement="bottom">
        ${this._renderButton(state)}
      </e-tooltip>
    `;
  }

  _renderButton(state) {
    return html`
      <button
        class="e-btn e-btn-borderless text-color-secondary"
        type="button"
        ?disabled=${state.editMode || state.addNewDisabled}
      >
        <e-translation key="components.criteria.addConditionButton"></e-translation>
      </button>
    `;
  }
}

export default AddConditionTemplate;
