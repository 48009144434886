import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import Template from './template';
import State from './state';
import Storage from '../../../utils/storage';
const storage = new Storage();

class EVerticalnavMenu extends HTMLCustomElement {
  init() {
    this._state = new State(this._render.bind(this));
    this._storage = storage;
    this._events = {
      onToggle: this._onToggle.bind(this),
      onOpenTransitionEnd: this._onOpenTransitionEnd.bind(this),
      onCloseTransitionEnd: this._onCloseTransitionEnd.bind(this),
      onItemUpdate: this._onItemUpdate.bind(this),
      onItemDelete: this._onItemDelete.bind(this)
    };
    this._template = new Template(document.createElement('div'), this._events.onToggle);

    this.addEventListener('verticalnavitem.update', this._events.onItemUpdate);
  }

  connectedCallback() {
    super._cleanupContainer('.e-verticalnav__toggle');

    if (this._state.state.storageKey) {
      this._state.setState({ extended: !!this._storage.getItem(this._state.state.storageKey) });
    }

    if (this._state.state.extended) {
      this.classList.add('e-notransition');
    }

    this.appendChild(this._template.element);
  }

  static get observedAttributes() {
    return ['id', 'status', 'no-toggle'];
  }

  set id(value) {
    this._state.setState({ storageKey: `e-verticalnav-extended-${value}` });
  }

  set status(value) {
    this._state.setState({ status: value });
    this._disabledChildToggle();
  }

  set noToggle(value) {
    this._state.setState({ noToggle: super._convertAttributeToBoolean(value) });
  }

  get storage() {
    return this._storage;
  }

  _onToggle() {
    if (this._state.state.extending) { return; }

    if (!this._state.state.extended) {
      this._open();
      this._template.element.classList.add('e-verticalnav__toggle-active');
    } else {
      this._close();
      this._template.element.classList.remove('e-verticalnav__toggle-active');
    }

    const event = new CustomEvent('toggle', {
      detail: {
        extended: this._state.state.extended
      }
    });
    this.dispatchEvent(event);
  }

  _onItemUpdate(event) {
    event.stopPropagation();

    event.target.removeEventListener('verticalnavitem.delete', this._events.onItemDelete);
    event.target.addEventListener('verticalnavitem.delete', this._events.onItemDelete);

    const data = {
      ...event.detail,
      element: event.target
    };

    this._state.upsertCollection('items', data, 'uuid');

    this._disabledChildToggle();
    this.appendChild(this._template.element);
  }

  _onItemDelete(event) {
    this._state.removeFromCollection('items', event.detail.uuid, 'uuid');
  }

  _open() {
    this.addEventListener('transitionend', this._events.onOpenTransitionEnd);
    this._state.setState({ extended: true, extending: true });

    if (this._state.state.storageKey) {
      this._storage.setItem(this._state.state.storageKey, true);
    }
  }

  _onOpenTransitionEnd(event) {
    if (event.target !== this) { return; }

    this.removeEventListener('transitionend', this._events.onOpenTransitionEnd);

    this._state.setState({ extending: false });
    this._fireExtendedEvent();
  }

  _close() {
    this.classList.remove('e-notransition');

    this.addEventListener('transitionend', this._events.onCloseTransitionEnd);
    this._state.setState({ extending: true });

    this.style.removeProperty('width');

    if (this._state.state.storageKey) {
      this._storage.removeItem(this._state.state.storageKey);
    }
  }

  _onCloseTransitionEnd(event) {
    if (event.target !== this) { return; }

    this.removeEventListener('transitionend', this._events.onCloseTransitionEnd);

    this._state.setState({ extended: false, extending: false });
    this._fireExtendedEvent();
  }

  _fireExtendedEvent() {
    const verticalnavExtendedEvent = new CustomEvent('verticalnavextended', {
      detail: {
        extended: this._state.state.extended
      }
    });

    const resizeEvent = document.createEvent('Event');
    resizeEvent.initEvent('resize', true, true);

    setTimeout(() => this.dispatchEvent(verticalnavExtendedEvent));
    setTimeout(() => window.dispatchEvent(resizeEvent));
  }

  _disabledChildToggle() {
    this._state.state.items.forEach(item => {
      if (this._state.state.status === 'disabled') {
        const originalStatus = item.element.status || '';

        if (originalStatus.indexOf('disabled') !== -1) { return; }

        item.element.menuStatus = `disabled ${originalStatus}`;
      } else {
        item.element.menuStatus = '';
      }
    });
  }

  getExtendedWidth() {
    return this._state.state.maxWidth;
  }

  _render() {
    this.classList.toggle('e-verticalnav__extended', this._state.state.extended);

    if (this._state.state.extended) {
      this.style.width = this._state.state.maxWidth + 'px';
    }

    render(this._template.element, this._template.render(this._state.state));
  }
}

export default EVerticalnavMenu;
