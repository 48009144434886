import { HTMLCustomElement, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { EAccordionItemState } from './state';
export class EAccordionItem extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.isComponentConnected = false;
        this.state = new EAccordionItemState();
        this.utils = { mutationObserver: null };
        this.refs = {
            content: null,
            label: null
        };
        this.utils.mutationObserver = new MutationObserver(this.updateRefs.bind(this));
    }
    connectedCallback() {
        this.isComponentConnected = true;
        this.classList.add('e-accordion-item');
        this.utils.mutationObserver.observe(this, {
            childList: true, subtree: true, attributes: true, characterData: true
        });
        this.updateRefs();
    }
    disconnectedCallback() {
        this.dispatchDeleteEvent();
    }
    get disabled() {
        return this.state.isDisabled;
    }
    set disabled(value) {
        this.state.isDisabled = convertAttributeToBoolean(value);
        this.dispatchUpdateEvent();
    }
    get active() {
        return this.state.isActive;
    }
    set active(value) {
        this.state.isActive = convertAttributeToBoolean(value);
        this.dispatchUpdateEvent();
    }
    // Private methods
    getEventDetail() {
        return {
            uuid: this.state.uuid,
            label: this.refs.label,
            content: this.refs.content,
            disabled: this.state.isDisabled,
            active: this.state.isActive
        };
    }
    dispatchUpdateEvent() {
        if (!this.isComponentConnected) {
            return;
        }
        this.dispatchEvent(new CustomEvent('accordion-item.update', {
            bubbles: true,
            detail: this.getEventDetail()
        }));
    }
    dispatchDeleteEvent() {
        this.dispatchEvent(new CustomEvent('accordion-item.delete', {
            detail: this.getEventDetail()
        }));
    }
    updateRefs() {
        this.updateLabel();
        this.updateContent();
        this.dispatchUpdateEvent();
    }
    updateLabel() {
        const labelElement = this.querySelector('e-accordion-item-label');
        if (!labelElement) {
            return;
        }
        this.refs.label = labelElement;
    }
    updateContent() {
        const contentElement = this.querySelector('e-accordion-item-content');
        if (!contentElement) {
            return;
        }
        this.refs.content = contentElement;
    }
}
// Attributes
EAccordionItem.observedAttributes = ['disabled', 'active'];
export default EAccordionItem;
