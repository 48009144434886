class Storage {
  constructor(global = window) {
    this._global = global;
    this._isLocalStorage = this._isLocalStorageSupported();
  }

  getItem(item, type = 'local') {
    this._s = this._getStorage(type);
    return this._getStorage(type).getItem(item);
  }

  setItem(item, value, type = 'local') {
    this._getStorage(type).setItem(item, value);
  }

  removeItem(item, type = 'local') {
    this._getStorage(type).removeItem(item);
  }

  clear(type = 'local') {
    this._getStorage(type).clear();
  }

  _isLocalStorageSupported() {
    const testString = '__storage_test__';
    try {
      this._global.localStorage.setItem(testString, testString);
      this._global.localStorage.removeItem(testString);
      return true;
    } catch (e) {
      return false;
    }
  }

  _getStorage(type) {
    if ((type !== 'local' && type !== 'session') || !this._isLocalStorage) {
      return this._memoryStorage(type);
    } else if (type === 'local') {
      return this._global.localStorage;
    } else if (type === 'session') {
      return this._global.sessionStorage;
    }
  }

  _memoryStorage(type) {
    if (!this._global.e.storageDB) {
      this._global.e.storageDB = { memory: {} };

      if (!this._isLocalStorage) {
        this._global.e.storageDB.local = {};
        this._global.e.storageDB.session = {};
      }
    }

    return {
      getItem: item => this._global.e.storageDB[type][item] || null,
      setItem: (item, value) => this._global.e.storageDB[type][item] = String(value),
      removeItem: item => delete this._global.e.storageDB[type][item],
      clear: () => this._global.e.storageDB[type] = {}
    };
  }
}

export default Storage;
