import { html } from 'uhtml';
import classNames from 'clsx';

export class ERulelistItemTemplate {
  #createReference;
  #state;
  #events;

  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.#state = component.state;
    this.#events = component.events;
  }

  // Wrapper Element
  createCloseWrapper() {
    return html.node`
      <e-rulelist-item-close-wrapper slot="_close" ref="${this.#createReference(['closeWrapper'])}" />
    `;
  }
  createOpenWrapper() {
    return html.node`
      <e-rulelist-item-open-wrapper slot="_summary-actions-default" ref="${this.#createReference(['openWrapper'])}" />
    `;
  }

  // Component Content
  createElement() {
    const onSlotChange = (event) => this.#events.onSlotChange(event.target);

    const summaryParts = classNames('item-summary', { 'hidden': !this.#state.isSummaryVisible() });
    const editParts = classNames('item-edit', { 'hidden': !this.#state.isEditVisible() });
    const relationParts = classNames('relation', { 'hidden': !this.#state.isRelationVisible() });

    return html`
      <slot name="relation" part="${relationParts}" @slotchange="${onSlotChange}" />

      <div part="item">
        <div part="${summaryParts}">
          <slot name="summary" part="summary" @slotchange="${onSlotChange}" />
          ${this.#createSummaryActions(onSlotChange)}
        </div>
        <div part="${editParts}">
          <div part="header-wrapper">
            <slot name="header" part="header" @slotchange="${onSlotChange}" />
            <slot name="close">
              <slot name="_close" />
            </slot>
          </div>
          <slot name="content" part="content" @slotchange="${onSlotChange}" />
          <div part="footer-wrapper">
            <slot name="footer-action" part="footer-actions" @slotchange="${onSlotChange}" />
            <slot name="footer" part="header" @slotchange="${onSlotChange}" />
          </div>
        </div>
      </div>
    `;
  }

  createCloseElement() {
    if (this.#state.isSlotVisible.close) { return html``; }

    return html`
      <button
        type="button"
        class="e-btn e-btn-borderless e-btn-onlyicon"
        @click="${this.#events.onCloseClick}"
        ref="${this.#createReference(['closeButton'], { testOnly: true })}"
      >
        <e-icon icon="close"></e-icon>
      </button>
    `;
  }

  createOpenElement() {
    return html`
      <button
        type="button"
        class="e-btn e-btn-borderless e-btn-onlyicon"
        @click="${this.#events.onOpenClick}"
        ref="${this.#createReference(['openButton'], { testOnly: true })}"
      >
        <e-icon icon="edit"></e-icon>
      </button>
    `;
  }

  #createSummaryActions(onSlotChange) {
    if (this.#state.isReadOnly) { return; }

    return html`
      <div part="summary-actions">
        <slot name="summary-action" @slotchange="${onSlotChange}" />
        <slot name="summary-action-default">
          <slot name="_summary-actions-default" />
        </slot>
      </div>
    `;
  }
}

export default ERulelistItemTemplate;
