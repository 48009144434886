export default {
  'components': {
    'chart': {
      'tooltip': {
        'missingData': 'Missing Data'
      }
    },
    'dialog': {
      'backButtonTooltip': 'Back',
      'closeButtonTooltip': 'Close Dialog'
    },
    'confirmDialog': {
      'confirmLabel': 'OK',
      'cancelLabel': 'Cancel'
    },
    'datagrid': {
      'emptyState': 'No Items Found',
      'emptyStateNoColumn': 'All Columns Hidden',
      'server': {
        'emptyStateTitle': 'No Data to Show',
        'emptyStateLead': 'We could not connect to the server. Please try again later.'
      },
      'viewSettingsButton': 'View',
      'viewSettingShowColumn': 'Show Columns',
      'advancedFiltersButton': 'More Filters',
      'viewSettingsReset': 'Reset',
      'viewSettingsResetButton': 'Reset View Settings',
      'search': {
        'label': 'Search',
        'placeholder': 'Search',
        'tooltip': 'Search in every column of this table',
        'emptyStateTitle': 'No Results',
        'emptyStateLead': 'No items have matched your search. Try refining your search query.'
      },
      'filters': {
        'showAll': 'Show All'
      },
      'columnSettings': {
        'tooltip': 'Column Settings',
        'visibility': {
          'label': 'Visibility',
          'option': 'Hide Column'
        },
        'width': {
          'label': 'Width',
          'auto': 'Default',
          'narrow': 'Narrow',
          'wide': 'Wide'
        },
        'reset': {
          'label': 'Reset',
          'option': 'Reset View Settings'
        }
      },
      'columnSortTooltip': 'Sort',
      'bulkCheckboxLabel': 'Select item: %s',
      'pageSizeSelector': 'Show %s rows',
      'paginationLabel': 'Table pagination',
      'itemCounter': {
        'nonFiltered': '%s-%s of %s',
        'filtered': '%s-%s of %s (filtered from %s)'
      },
      'selection': {
        'oneItemSelected': '1 item selected',
        'multipleItemsSelected': '%s items selected',
        'dropdown': {
          'selectVisible': 'Select Visible Items',
          'deselectVisible': 'Deselect Visible Items',
          'selectAll': 'Select All',
          'deselectAll': 'Clear Selection'
        }
      },
      'priority': {
        'enterPriorityModeButton': 'Set Priority',
        'exitPriorityModeButton': 'Finish Prioritizing'
      }
    },
    'datagridColumnSwitch': {
      'activeLabel': 'Active',
      'inactiveLabel': 'Inactive',
      'activateTooltip': 'Activate',
      'deactivateTooltip': 'Deactivate',
      'deactivatingLabel': 'Deactivating',
      'activatingLabel': 'Activating'
    },
    'password': {
      'showPassword': 'Show Password',
      'hidePassword': 'Hide Password',
      'copyPassword': 'Copy to Clipboard',
      'copyPopup': 'Copied'
    },
    'colorpicker': {
      'applyButton': 'Apply',
      'cancelButton': 'Cancel'
    },
    'multiselect': {
      'clearLabel': 'Clear',
      'selection': {
        'oneItemSelected': '1 item selected',
        'multipleItemsSelected': '%s items selected'
      },
      'popup': {
        'emptyState': 'No Options to Show',
        'selectAll': 'Select All',
        'deselectAll': 'Clear Selection',
        'selectionMax': 'Selected items: %s',
        'selectionMaxError': 'Selected items: %s. Too many items selected.'
      },
      'search': {
        'emptyState': 'No Match Found'
      },
      'operators': {
        'all': 'All',
        'any': 'Any'
      }
    },
    'criteria': {
      'addConditionButton': 'Add Condition',
      'applyConditionButton': 'Apply',
      'cancelConditionButton': 'Cancel',
      'relation': {
        'and': 'All conditions are true',
        'or': 'Any condition is true'
      },
      'addConditionDialog': {
        'headline': 'Add Condition'
      }
    },
    'criteriaCondition': {
      'editActionTooltip': 'Edit',
      'deleteActionTooltip': 'Delete'
    },
    'ruleBuilder': {
      'rule': {
        'header': {
          'editTooltip': 'Edit',
          'removeTooltip': 'Remove',
          'summaryError': 'Error'
        },
        'editor': {
          'addTitle': 'Add Rule:',
          'editTitle': 'Edit Rule:',
          'cancel': 'Cancel',
          'add': 'Add',
          'apply': 'Apply'
        },
        'error': {
          'blueprintMissing': 'The blueprint is missing for this rule',
          'blueprintMissingValues': 'Values'
        }
      },
      'group': {
        'header': {
          'editTooltip': 'Edit',
          'removeTooltip': 'Remove',
          'expandTooltip': 'Expand',
          'collapseTooltip': 'Collapse'
        },
        'actions': {
          'addRule': 'Add Rule',
          'addGroup': 'Add Group',
          'addSubgroup': 'Add Subgroup',
          'addExclusion': 'Add Exclusion',
          'maxRuleCountReachedTooltip': 'Maximum number of rules reached'
        },
        'deleteDialog': {
          'headline': 'Delete Group?',
          'content': 'Deleted groups cannot be restored',
          'confirm': 'Delete',
          'cancel': 'Cancel'
        },
        'subgroupAutoLabel': 'In group: %s',
        'untitled': 'untitled group',
        'emptyState': {
          'title': 'No Rule to Show',
          'text': 'Add a rule or a subgroup to continue'
        },
        'emptyStateWithoutSubgroup': {
          'title': 'No Rule to Show',
          'text': 'Add a rule to continue'
        }
      },
      'relation': {
        'and': 'AND',
        'or': 'OR',
        'matchAll': 'Match all (AND)',
        'matchAny': 'Match any (OR)'
      }
    },
    'actionlist': {
      'emptyState': 'No Items to Display',
      'loadingState': 'Loading',
      'noResults': 'No items have matched your search. Try refining your search query.',
      'limitReached': 'Items over the limit are not displayed. Try refining your search query.',
      'search': {
        'displayResults': 'Search to display results',
        'filterResults': 'Search to filter results'
      }
    },
    'numeric': {
      'humanize': {
        'thousand': '[K-thousand]',
        'million': '[M-million]',
        'billion': '[B-billion]',
        'trillion': '[T-trillion]',
        'quadrillion': '[Q-quadrillion]'
      }
    },
    'duration': {
      'days': {
        'short': '%dd',
        'long': '%d days'
      },
      'hours': {
        'short': '%dh',
        'long': '%d hours'
      },
      'minutes': {
        'short': '%dm',
        'long': '%d minutes'
      },
      'seconds': {
        'short': '%ds',
        'long': '%d seconds'
      },
      'milliseconds': {
        'short': '%dms',
        'long': '%d milliseconds'
      }
    },
    'navigation': {
      'title': 'Main Menu'
    },
    'navigationMenu': {
      'showMoreLabel': 'More'
    },
    'navigationSuiteHelp': {
      'label': 'Help'
    },
    'navigationSuiteNotifications': {
      'label': 'Notifications'
    },
    'navigationSuiteProfile': {
      'notifications': 'Notifications',
      'serverTimeLabel': 'Server Time',
      'accountIDLabel': 'Account ID',
      'shareAccountDialogDone': 'Done',
      'shareAccountDialogSuccess': 'Your settings were successfully saved.',
      'themeSwitcher': 'Appearance',
      'betaBadge': 'Beta'
    },
    'navigationSearchButton': {
      'tooltip': 'Search'
    },
    'navigationProfileButton': {
      'tooltip': 'Profile Menu'
    },
    'navigationSuiteSearch': {
      'header': 'Search',
      'actionButton': 'Search',
      'searchScopeLabel': 'Search scope',
      'searchKeywordLabel': 'Search keyword',
      'labels': {
        'contact': 'Contacts',
        'contactByFirstName': 'Contacts by First Name',
        'contactByLastName': 'Contacts by Last Name',
        'contactByEmail': 'Contacts by Email',
        'emailCampaigns': 'Email Campaigns',
        'registrationForms': 'Registration Forms',
        'segments': 'Segments',
        'lists': 'Lists',
        'bounces': 'Bounces',
        'analyses': 'Email Analyses'
      },
      'placeholders': {
        'contact': 'Name or email starts with...',
        'contactByFirstName': 'First name starts with...',
        'contactByLastName': 'Last name starts with...',
        'contactByEmail': 'Email starts with...',
        'emailCampaigns': 'Campaign name contains...',
        'registrationForms': 'Form name contains...',
        'segments': 'Segment name contains...',
        'lists': 'List name contains...',
        'bounces': 'Email starts with...',
        'analyses': 'Campaign name contains...'
      }
    },
    'datetime': {
      'invalidDate': 'Invalid Date',
      'invalidDateFormatHint': 'The date format is invalid.',
      'invalidDateRangeHint': 'The selected date is not allowed.',
      'showToday': 'Show Today',
      'pickerExample': 'For example, %s',
      'applyButton': 'Apply',
      'cancelButton': 'Cancel',
      'clearButton': 'Clear',
      'panel': {
        'presets': 'Presets',
        'inputLabel': {
          'date': {
            'start': 'Date'
          },
          'datetime': {
            'start': 'Date and Time'
          },
          'daterange': {
            'start': 'Start Date',
            'end': 'End Date'
          },
          'datetimerange': {
            'start': 'Start Time',
            'end': 'End Time'
          }
        }
      },
      'calendar': {
        'prevButton': 'Previous Month',
        'nextButton': 'Next Month',
        'nav': 'Calendar'
      }
    },
    'timepicker': {
      'hours': 'hh',
      'minutes': 'mm'
    },
    'themepicker': {
      'dialog': {
        'headline': 'Appearance',
        'description': 'Change the visual appearance of Emarsys.',
        'betaBadge': 'Beta',
        // eslint-disable-next-line max-len
        'betaNotification': 'Some themes are currently in the beta phase. There might be some minor visual issues on certain pages. We are actively addressing these to ensure a seamless experience.',
        'themingButton': {
          'morningHorizon': 'Light',
          'eveningHorizon': 'Dark',
          'autoHorizon': 'Auto'
        },
        'defaultThemeOption': 'Default',
        'closeButtonLabel': 'Close'
      }
    },
    'stepBar': {
      'defaultLabel': 'Steps Menu',
      'stepLabel': 'Step %s.',
      'prevButton': 'Previous Step',
      'nextButton': 'Next Step',
      'badgeLabel': 'New Items'
    },
    'pagination': {
      'defaultLabel': 'Pagination Menu',
      'prevButton': 'Previous Page',
      'nextButton': 'Next Page'
    },
    'select': {
      'clearLabel': 'Clear'
    }
  }
};
