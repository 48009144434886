import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../utils/uuid';

class ESelectGroup extends HTMLCustomElement {
  init() {
    this.addEventListener('option.update', this);
    this._state = {
      uuid: uuid(),
      type: 'group',
      label: ''
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('group.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  static get observedAttributes() {
    return ['label'];
  }

  set label(value) {
    this._state.label = value;
    this._dispatchEvent();
  }

  handleEvent(event) {
    if (event.type === 'option.update') {
      this._onOptionUpdate(event);
    }
  }

  _dispatchEvent() {
    if (!this.parentNode) {
      return;
    }

    this.dispatchEvent(new CustomEvent('group.update', {
      bubbles: true,
      detail: this._state
    }));
  }

  _onOptionUpdate(event) {
    if (event.detail.group) { return; }

    event.stopPropagation();
    event.target.dispatchEvent(new CustomEvent('option.update', {
      bubbles: true,
      detail: Object.assign({}, event.detail, { group: this._state.uuid })
    }));
  }
}

export default ESelectGroup;
