import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { CONFETTI_TRIGGER_MESSAGE } from '../../utils/confetti/ConfettiHandler';
import { ConfettiSize } from '../../utils/confetti/types/ConfettiSize';
export class EConfetti extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this._size = ConfettiSize.MEDIUM;
    }
    // Attributes
    static get observedAttributes() {
        return ['size'];
    }
    set size(value) {
        if (value === 'large') {
            this._size = ConfettiSize.LARGE;
        }
        else if (value === 'small') {
            this._size = ConfettiSize.SMALL;
        }
        else {
            this._size = ConfettiSize.MEDIUM;
        }
    }
    // Actions
    fire() {
        this._dispatchPostMessage();
    }
    // Private methods
    _dispatchPostMessage() {
        if (!this.firstElementChild) {
            return;
        }
        const position = this.firstElementChild.getBoundingClientRect();
        window.postMessage({
            message: CONFETTI_TRIGGER_MESSAGE,
            position,
            size: this._size
        }, '*');
    }
}
