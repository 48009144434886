import uuid from '../../../js/utils/uuid';
export class EAccordionItemState {
    constructor() {
        this._uuid = uuid();
        this._isDisabled = false;
        this._isActive = false;
    }
    get uuid() {
        return this._uuid;
    }
    get isDisabled() {
        return this._isDisabled;
    }
    set isDisabled(value) {
        this._isDisabled = value;
    }
    get isActive() {
        return this._isActive;
    }
    set isActive(value) {
        this._isActive = value;
    }
}
