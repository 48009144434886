import EDatagridColumn from '../datagrid-column';
import translator from '../../../utils/translator';
import configStore from '../../../utils/config-store';

class EDatagridColumnSwitch extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      disabled: false,
      disabledKey: null,
      disabledTooltip: null,
      disabledTooltipKey: null,
      disabledType: '',
      type: 'switch'
    });
    this._updateTranslations();

    configStore.subscribe(this._configChangedCallback.bind(this));
  }

  static get observedAttributes() {
    return [
      'disabled',
      'disabled-key',
      'disabled-tooltip',
      'disabled-tooltip-key',
      'disabled-type',
      'active-label',
      'inactive-label',
      'deactivate-tooltip',
      'activate-tooltip',
      'deactivating-label',
      'activating-label'
    ].concat(super.observedAttributes);
  }

  set activeLabel(value) {
    this._state.activeLabel = value;
    this._dispatchEvent();
  }

  set inactiveLabel(value) {
    this._state.inactiveLabel = value;
    this._dispatchEvent();
  }

  set deactivateTooltip(value) {
    this._state.deactivateTooltip = value;
    this._dispatchEvent();
  }

  set activateTooltip(value) {
    this._state.activateTooltip = value;
    this._dispatchEvent();
  }

  set deactivatingLabel(value) {
    this._state.deactivatingLabel = value;
    this._dispatchEvent();
  }

  set activatingLabel(value) {
    this._state.activatingLabel = value;
    this._dispatchEvent();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set disabledKey(value) {
    this._state.disabledKey = value;
    this._dispatchEvent();
  }

  set disabledTooltip(value) {
    this._state.disabledTooltip = value;
    this._dispatchEvent();
  }

  set disabledTooltipKey(value) {
    this._state.disabledTooltipKey = value;
    this._dispatchEvent();
  }

  set disabledType(value) {
    this._state.disabledType = value;
    this._dispatchEvent();
  }

  _updateTranslations() {
    Object.assign(this._state, {
      activeLabel: translator.translate('components.datagridColumnSwitch.activeLabel'),
      inactiveLabel: translator.translate('components.datagridColumnSwitch.inactiveLabel'),
      deactivateTooltip: translator.translate('components.datagridColumnSwitch.deactivateTooltip'),
      activateTooltip: translator.translate('components.datagridColumnSwitch.activateTooltip'),
      deactivatingLabel: translator.translate('components.datagridColumnSwitch.deactivatingLabel'),
      activatingLabel: translator.translate('components.datagridColumnSwitch.activatingLabel')
    });
  }

  _configChangedCallback() {
    this._updateTranslations();
    this._dispatchEvent();
  }
}

export default EDatagridColumnSwitch;
