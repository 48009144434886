import EDatagridFilter from '../datagrid-filter';

class EDatagridFilterCheckbox extends EDatagridFilter {
  init() {
    super.init();

    Object.assign(this._state, {
      defaultChecked: false,
      checked: null,
      tooltip: null,
      type: 'checkbox'
    });
  }

  static get observedAttributes() {
    return ['default-checked', 'checked', 'tooltip'].concat(super.observedAttributes);
  }

  set defaultChecked(value) {
    this._state.defaultChecked = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set checked(value) {
    this._state.checked = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set tooltip(value) {
    this._state.tooltip = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (super._dispatchEvent()) {
      this._state.checked = null;
    }
  }

}

export default EDatagridFilterCheckbox;
