import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import configStore from '../../utils/config-store';
import translator from '../../utils/translator';

class ETranslation extends HTMLCustomElement {
  init() {
    this._key = '';
    this._value = undefined;
    this._part = undefined;
    this._configChangeCallback = this._configChangeCallback.bind(this);
  }

  connectedCallback() {
    configStore.subscribe(this._configChangeCallback);
    this._render();
  }

  disconnectedCallback() {
    configStore.unsubscribe(this._configChangeCallback);
  }

  static get observedAttributes() {
    return ['key', 'value', 'part'];
  }

  get key() {
    return this._key;
  }

  set key(value) {
    this._key = value;
    this._render();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = typeof value === 'string' ? JSON.parse(value) : value;
    this._render();
  }

  set part(value) {
    this._part = parseInt(value);
    this._render();
  }

  _configChangeCallback() {
    this._render();
  }

  _render() {
    if (this._part !== undefined) {
      this.innerHTML = translator.translatePart(this._key, this._part);
    } else {
      this.innerHTML = translator.translate(this._key, this._value);
    }
  }
}

export default ETranslation;
