import { EventEmitter } from '@emartech/ui-framework-utils';
import promiseTimeout from '../promiseTimeout.js';
import defaultConfig from './default-config.js';

class ConfigStore {
  constructor(windowObject) {
    this._emitter = new EventEmitter();
    this._config = { ...defaultConfig, ...(windowObject.e && windowObject.e.config) };
    this._hasLoadedConfig = !!(windowObject.e && windowObject.e.config);

    if (windowObject !== windowObject.top) {
      windowObject.top.postMessage({
        message: 'e:requestConfig',
        data: {}
      }, '*');
    }

    windowObject.addEventListener('message', this._handleMessage.bind(this), false);
  }

  get config() {
    return this._config;
  }

  set config(value) {
    this._config = { ...defaultConfig, ...value };
    this._emitter.emit('configstore.update', this._config);
  }

  get defaultConfig() {
    return defaultConfig;
  }

  getLoadedConfig() {
    const getConfig = new Promise((resolve) => {
      const unsubscribe = this.unsubscribe.bind(this);
      this.subscribe(function callback(config) {
        resolve(config);
        unsubscribe(callback);
      });
    });

    return this._hasLoadedConfig ? Promise.resolve(this.config) : promiseTimeout(getConfig, this.config);
  }

  _handleMessage(event) {
    const message = this._getMessageFromEvent(event);
    if (message === 'e:receiveConfig') {
      this.config = event.data.data;
      this._hasLoadedConfig = true;
    } else if (message === 'e:requestConfig') {
      event.source.postMessage({
        message: 'e:receiveConfig',
        data: this._config
      }, '*');
    }
  }

  _getMessageFromEvent(event) {
    return event.data && event.data.message;
  }

  subscribe(callback) {
    this._emitter.on('configstore.update', callback);
  }

  unsubscribe(callback) {
    this._emitter.off('configstore.update', callback);
  }
}

export default ConfigStore;
