import EDatagridColumn from '../datagrid-column';

class EDatagridColumnTime extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      type: 'time',
      format: 'datetime',
      noWrap: true,
      precision: 'seconds'
    });
  }

  set type(value) {
    this._state.type = value;
    this._dispatchEvent();
  }

  set format(value) {
    this._state.format = value;
    this._dispatchEvent();
  }

  set precision(value) {
    this._state.precision = value;

    if (this._state.type !== 'duration') { return; }

    this._dispatchEvent();
  }

  static get observedAttributes() {
    return super.observedAttributes.concat('format', 'precision');
  }
}

export default EDatagridColumnTime;
