import State from '../../../utils/state';

class DatetimePresetState extends State {
  get defaultState() {
    return {
      value: '',
      name: '',
      order: undefined
    };
  }
}

export default DatetimePresetState;
