import {
  HTMLCustomElement,
  ResizeObserver,
  convertAttributeToBoolean,
  convertAttributeToArray
} from '@emartech/ui-framework-utils';

import { render } from 'uhtml';

import translator from '../../../js/utils/translator/index.js';
import configStore from '../../../js/utils/config-store/index.js';
import JSLogger from '../../../js/utils/jslogger/index.js';

import { EActionListState } from './state.js';
import { EActionListEvents } from './events.js';
import { EActionListTemplate } from './template.js';
import { createGroupFromAttributeData, createItemFromAttributeData } from './utils.js';

const log = new JSLogger('actionlist');

export class EActionList extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = { items: [] };
    this.utils = {};
    this.state = new EActionListState(this);
    this.events = new EActionListEvents(this);
    this.template = new EActionListTemplate(this);

    this.refs.wrapper = this.template.createWrapper();

    this._updateTranslations();

    this.utils.resizeObserver = new ResizeObserver(this.events.onResize);
    this.addEventListener('actionlistitem.update', this.events.onItemUpdate);
    this.addEventListener('actionlistgroup.update', this.events.onGroupUpdate);
  }

  connectedCallback() {
    this.utils.resizeObserver.observe(this);
    configStore.subscribe(this.events.onConfigChange);

    super._cleanupContainer('e-actionlist-wrapper');

    this.requestRender().then(() => {
      this.insertAdjacentElement('beforeend', this.refs.wrapper);
      this._updateFocus();
      this._updateScrollPosition();
    });
  }

  disconnectedCallback() {
    this.state.resetSearchKeywords();
    this.requestRender.clear();
    this.utils.resizeObserver.disconnect();
    configStore.unsubscribe(this.events.onConfigChange);
  }

  // Attributes
  static get observedAttributes() {
    return [
      'items',
      'item-limit',
      'options',
      'loading',
      'always-show-search',
      'always-show-active',
      'search-hidden',
      'empty-state-text',
      'selection-disabled',
      'height',
      'max-height',
      'disable-min-width',
      'in-popover',
      'autofocus',
      'auto-focus',
      'multiple',
      'spacing',
      'selection-max'
    ];
  }

  get items() {
    return this.state.items;
  }

  set items(value) {
    this.state.selectedItems = [];
    this.state.activeItem = null;

    const itemData = convertAttributeToArray(value);

    const hasInvalidItem = itemData.find(item => item.type === undefined);
    if (hasInvalidItem) {
      log.log('items setter called with wrong format');
    }

    this.state.items = itemData
      .filter(item => item.type !== 'group')
      .map(createItemFromAttributeData);

    this.state.groups = itemData
      .filter(item => item.type === 'group')
      .map(createGroupFromAttributeData);

    this.state.updateItemSelections();
  }

  set options(value) {
    this.items = value;

    log.log('options setter called');
  }

  get itemLimit() {
    return this.state.itemLimit = this.state.itemLimit;
  }

  set itemLimit(value) {
    this.state.itemLimit = value;
  }

  get loading() {
    return this.state.isLoading;
  }

  set loading(value) {
    this.state.isLoading = convertAttributeToBoolean(value);
  }

  set alwaysShowSearch(value) {
    this.state.isSearchAlwaysShown = convertAttributeToBoolean(value);
  }

  set alwaysShowActive(value) {
    this.state.isActiveAlwaysShown = convertAttributeToBoolean(value);
  }

  set emptyStateText(value) {
    this.state.emptyStateText = value;
  }

  set searchHidden(value) {
    this.state.isSearchHidden = convertAttributeToBoolean(value);
  }

  set selectionDisabled(value) {
    this.state.isSelectionDisabled = convertAttributeToBoolean(value);
    this.state.selectedItems = [];
  }

  set height(value) {
    this.state.height = value;
  }

  set maxHeight(value) {
    this.state.maxHeight = value;
  }

  set disableMinWidth(value) {
    this.state.isMinWidthDisabled = convertAttributeToBoolean(value);
  }

  set inPopover(value) {
    this.state.isInPopover = convertAttributeToBoolean(value);
  }

  set autofocus(value) {
    this.autoFocus = value;
  }

  get autoFocus() {
    return this.state.isAutoFocusEnabled;
  }
  set autoFocus(value) {
    this.state.isAutoFocusEnabled = convertAttributeToBoolean(value);
  }

  get multiple() {
    return this.state.isMultipleSelectionAllowed;
  }

  set multiple(value) {
    this.state.isMultipleSelectionAllowed = convertAttributeToBoolean(value);
  }

  get spacing() {
    return this.state.spacing;
  }

  set spacing(value) {
    this.state.spacing = value;
  }

  get isFirstItemActive() {
    return this.state.isFirstItemActive;
  }

  get isLastItemActive() {
    return this.state.isLastItemActive;
  }

  get selectedItems() {
    return this.state.selectedItems;
  }

  get selectionMax() {
    return this.state.selectionMax;
  }

  set selectionMax(value) {
    const intValue = parseInt(value);
    if (intValue <= 0) { return; }

    this.state.selectionMax = intValue;
  }
  // Actions
  moveActiveItem(position) {
    this.state.moveActiveItem(position);
  }

  toggleActiveItem() {
    if (!this.state.activeItem) { return; }

    this.state.toggleItemSelection(this.state.activeItem);
    this._dispatchChangeEvent(this.state.activeItem);
  }

  // Rendering
  render() {
    this.state.updateFilteredItems();

    render(this.refs.wrapper, this.template.createElement());
  }

  // Private methods
  _updateTranslations() {
    this.state.translations = {
      loadingState: translator.translate('components.actionlist.loadingState'),
      search: {
        displayResults: translator.translate('components.actionlist.search.displayResults'),
        filterResults: translator.translate('components.actionlist.search.filterResults')
      }
    };
  }

  _updateScrollPosition() {
    if (!this.refs.itemsContainer || this.state.isLoading || !this.state.activeItem) { return; }

    const containerElement = this.refs.itemsContainer;
    const targetItem = this.state.activeItem;
    const targetItemElement = this.refs.items[targetItem.uuid];

    if (!targetItemElement) { return; }

    const containerScrollPosition = containerElement.scrollTop;
    const containerHeight = containerElement.offsetHeight;
    const itemOffset = targetItemElement.offsetTop;
    const itemHeight = targetItemElement.offsetHeight;

    if (containerScrollPosition + containerHeight < itemOffset + itemHeight * 2) {
      containerElement.scrollTop = itemOffset - containerHeight + itemHeight * 2;
    } else if (containerScrollPosition > itemOffset - itemHeight) {
      containerElement.scrollTop = itemOffset - itemHeight;
    }
  }

  _updateFocus() {
    if (!this.refs.wrapper.isConnected || this.state.isLoading || !this.state.isAutoFocusEnabled) { return; }

    const elementToFocus = this.refs.searchInput || this.refs.itemsContainer;
    elementToFocus?.focus();
  }

  _dispatchChangeEvent(item) {
    if (item.disabled) { return; }

    const changeEventDetail = this.state.isSelectionDisabled ? item.value : this.state.selectedItemValues;

    this.dispatchEvent(new CustomEvent('change', {
      detail: { value: changeEventDetail }
    }));
  }

  _dispatchHoverChangeEvent(item = null) {
    this.dispatchEvent(new CustomEvent('hoverchange', {
      detail: { item }
    }));
  }

  _dispatchActiveChangeEvent(item = null) {
    this.dispatchEvent(new CustomEvent('activechange', {
      detail: { item }
    }));
  }
}

export default EActionList;
