import uuid from '../../../js/utils/uuid';
export class EStepState {
    constructor() {
        this._uuid = uuid();
        this._label = '';
        this._icon = '';
        this._badge = '';
        this._tooltip = '';
        this._isDisabled = false;
        this._isSelected = false;
        this._isCountExcluded = false;
    }
    get uuid() {
        return this._uuid;
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
    }
    get icon() {
        return this._icon;
    }
    set icon(value) {
        this._icon = value;
    }
    get badge() {
        return this._badge;
    }
    set badge(value) {
        this._badge = value;
    }
    get tooltip() {
        return this._tooltip;
    }
    set tooltip(value) {
        this._tooltip = value;
    }
    get isDisabled() {
        return this._isDisabled;
    }
    set isDisabled(value) {
        this._isDisabled = value;
    }
    get isSelected() {
        return !this._isDisabled && this._isSelected;
    }
    set isSelected(value) {
        this._isSelected = value;
    }
    get isCountExcluded() {
        return this._isCountExcluded;
    }
    set isCountExcluded(value) {
        this._isCountExcluded = value;
    }
}
