import {
  HTMLCustomElement, convertAttributeToString, convertAttributeToBoolean, queryKeyboardFocusableAll
} from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import autoBind from 'auto-bind';

import { popupUtility } from '../../../utils/popup';

import ETooltipEvents from './events';
import ETooltipState from './state';
import ETooltipTemplate from './template';

class ETooltip extends HTMLCustomElement {
  // Lifecycle events
  init() {
    autoBind(this);
    this.attachInternals();

    this.refs = {
      popup: null,
      popupContent: null,
      focusInCatcher: null,
      focusOutCatcher: null
    };

    this.utils = {
      popup: null,
      mutationObserver: null
    };

    this.state = new ETooltipState(this);
    this.events = new ETooltipEvents(this);
    this.template = new ETooltipTemplate(this);

    this.refs.focusInCatcher = this.template.createFocusInCatcher();
    this.refs.focusOutCatcher = this.template.createFocusOutCatcher();
    this.utils.mutationObserver = new MutationObserver(this.events.tooltip.onContentChange);

    this._createPopup();

    this.addEventListener('mouseenter', this.events.tooltip.onMouseEnter);
    this.addEventListener('mouseleave', this.events.tooltip.onMouseLeave);
    this.addEventListener('focusin', this.events.tooltip.onFocusIn);
    this.addEventListener('focusout', this.events.tooltip.onFocusOut);

    this.attachShadow({ mode: 'open' }).innerHTML = '<slot></slot>';
    this.refs.slot = this.shadowRoot.querySelector('slot');
  }

  connectedCallback() {
    this._createPopup();
    this.events.tooltip.onContentChange();
    this.utils.mutationObserver.observe(this, { childList: true, subtree: true, attributes: true });
    this.requestRender();
  }

  disconnectedCallback() {
    this.utils.mutationObserver.disconnect();
    this._destroyPopup();
  }

  // Attributes
  static observedAttributes = ['content', 'placement', 'type', 'has-action', 'disabled', 'permission', 'block'];

  get content() {
    return this.state.content;
  }

  set content(value) {
    this.state.content = convertAttributeToString(value);
  }

  get placement() {
    return this.state.placement;
  }

  set placement(value) {
    const validPlacements = ['top', 'bottom', 'left', 'right'];

    if (!validPlacements.includes(value)) { return; }

    this.state.placement = convertAttributeToString(value);
    this._createPopup();
  }

  get type() {
    return this.state.type;
  }

  set type(value) {
    this.state.type = convertAttributeToString(value);
    this._createPopup();
  }

  get hasAction() {
    return this.state.hasAction;
  }

  set hasAction(value) {
    this.state.hasAction = convertAttributeToBoolean(value);
    this._createPopup();
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
    this.updateFocusCatcherTabIndexes();
    this.updateComponentAriaDescription();
    this.utils.popup?.close();
  }

  get permission() {
    return this.state.isPermissionType;
  }

  set permission(value) {
    this.state.type = convertAttributeToBoolean(value) ? 'permission' : '';
    this._createPopup();
  }

  // Rendering
  render() {
    if (!this.refs.popup) { return; }

    if (this.state.type === 'helper') {
      render(this, this.template.createHelperIcon());
    }

    render(this.refs.popup, this.template.createPopupContent());
    this.updateContentAriaDescription();
    this.updateComponentAriaDescription();
  }

  updateFocusClasses() {
    this.classList.toggle('e-tooltip-content-focus', this.state.isFocusOnFocusCatcher);
  }

  createFocusCatchers() {
    const hasFocusInCatcher = this.refs.focusInCatcher.isConnected;
    const hasFocusOutCatcher = this.refs.focusOutCatcher.isConnected;
    const isFocusInCatcherNeeded = !this.state.isTooltipContentFocusable || this.state.isPopupContentFocusable;
    const isFocusOutCatcherNeeded = this.state.isPopupContentFocusable;

    if (!hasFocusInCatcher && isFocusInCatcherNeeded) {
      this.refs.slot.insertAdjacentElement('beforebegin', this.refs.focusInCatcher);
    } else if (!isFocusInCatcherNeeded) {
      this.refs.focusInCatcher.remove();
    }

    if (!hasFocusOutCatcher && isFocusOutCatcherNeeded) {
      this.refs.slot.insertAdjacentElement('afterend', this.refs.focusOutCatcher);
    } else if (!isFocusOutCatcherNeeded) {
      this.refs.focusOutCatcher.remove();
    }
  }

  updateFocusCatcherTabIndexes() {
    this.refs.focusInCatcher.tabIndex = this.state.isDisabled ? -1 : 0;
    this.refs.focusOutCatcher.tabIndex = this.state.isDisabled ? -1 : 0;
  }

  updatePopupAriaHidden() {
    if (!this.refs.popup) { return; }

    this.refs.popup.ariaHidden = 'true';
  }

  updateContentAriaDescription() {
    const focusableElements = queryKeyboardFocusableAll(this, { includeHidden: true });
    focusableElements.forEach(focusableElement => {
      const content = this.state.isDisabled ? null : this.refs.popupContent?.textContent.trim();
      if (focusableElement.textContent.trim().length === 1) {
        focusableElement.ariaLabel = content;
        focusableElement.ariaDescription = '';
      } else {
        focusableElement.ariaDescription = content;
        focusableElement.ariaLabel = '';
      }
    });
  }

  updateComponentAriaDescription() {
    this.ariaDescription = null;
    this.role = null;

    if (this.state.isTooltipContentFocusable === undefined ||
      this.state.isTooltipContentFocusable ||
      this.state.isPopupContentFocusable ||
      this.state.isDisabled) { return; }

    this.role = 'tooltip';
    this.ariaDescription = this.refs.popupContent?.textContent.trim();
  }

  // Private methods
  _createPopup() {
    if (this.refs.popup) {
      this._destroyPopup();
    }

    this.refs.popup = this.template.createPopup();
    this.utils.popup = popupUtility.createPopup(this, this.refs.popup, {
      placement: this.state.placement,
      onFocusOutForward: this.events.popup.onFocusOutForward,
      onFocusOutBackward: this.events.popup.onFocusOutBackward
    });
  }

  _destroyPopup() {
    this.refs.popup = null;
    this.utils.popup?.destroy({ preventAutoFocus: true });
  }
}

export default ETooltip;
