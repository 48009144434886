import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import themeHandler from '../../utils/theme-handler';
import EThemeSwitcherState from './state';
import EThemeSwitcherEvents from './events';
import EThemeSwitcherTemplate from './template';

export class EThemeSwitcher extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.utils = { themeHandler };
    this.state = new EThemeSwitcherState(this);
    this.events = new EThemeSwitcherEvents(this);
    this.template = new EThemeSwitcherTemplate(this);

    this.refs.wrapper = this.template.createWrapper();
  }

  connectedCallback() {
    super._cleanupContainer('e-theme-switcher-wrapper');

    this.addEventListener('click', this.events.onOpenerClick);

    this.requestRender().then(() => {
      this.insertAdjacentElement('beforeend', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  // Rendering
  render() {
    render(this.refs.wrapper, this.template.createElement());
  }
}

export default EThemeSwitcher;
