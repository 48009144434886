import { html, render } from 'uhtml';

const template = (containerElement, onClickOutSide) => {
  return html`
    <div
      class="e-dialog e-dialog-visible e-dialog-active"
      tabindex="-1"
      onclick="${onClickOutSide}">${containerElement}</div>
  `;
};

const createContainerElement = content => {
  const containerElement = html.node`<div class="e-dialog__container"></div>`;

  if (typeof content === 'string') {
    containerElement.innerHTML = content;
  } else {
    containerElement.appendChild(content);
  }

  return containerElement;
};

export default ({
  content = ''
} = {}, global = window) => {
  const htmlElement = global.document.createElement('e-floatingdialog');

  const onClickOutSide = event => {
    if (event.target === event.currentTarget) {
      close();
    }
  };

  const onCloseOnEsc = event => {
    if (event.key === 'Escape') {
      close();
    }
  };

  const containerElement = createContainerElement(content);
  const dialog = render(htmlElement, template(containerElement, onClickOutSide));

  const open = () => {
    global.document.body.appendChild(dialog);
    global.document.addEventListener('keydown', onCloseOnEsc);
  };

  const close = () => {
    global.document.body.removeChild(dialog);
    global.document.removeEventListener('keydown', onCloseOnEsc);
  };

  return {
    open,
    close
  };
};
