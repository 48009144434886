export class ESuggestInputState {
  constructor(component) {
    this.requestRender = component.requestRender;

    this._value = '';
    this._options = [];
    this._placeholder = '';
    this._isDisabled = false;
    this._isLoading = false;
    this._isUseValueButtonActive = false;
    this._isInErrorState = false;
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.requestRender();
  }

  get isValueEmpty() {
    return this.value === '';
  }

  get isValueInOptions() {
    return this.options.includes(this.value);
  }

  get options() {
    return this._options;
  }

  set options(value) {
    this._options = value.map(item => item.toString());
    this.requestRender();
  }

  insertOption(value) {
    if (this._options.includes(value)) { return; }

    this._options.push(value);
    this.requestRender();
  }

  deleteOption(value) {
    if (!this._options.includes(value)) { return; }

    const index = this._options.indexOf(value);
    this._options.splice(index, 1);
    this.requestRender();
  }

  updateOption({ oldContent, newContent }) {
    if (!this._options.includes(oldContent)) { return; }

    const index = this._options.indexOf(oldContent);
    this._options[index] = newContent;
    this.requestRender();
  }

  get isOptionsEmpty() {
    return this._options.length === 0;
  }

  get filteredOptions() {
    if (this.isValueEmpty) { return this.options; }

    const query = this.value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(query));
  }

  get isFilteredOptionsEmpty() {
    return this.filteredOptions.length === 0;
  }

  get lastFilteredOption() {
    return this.filteredOptions[this.filteredOptions.length - 1];
  }

  get itemsForActionlist() {
    return this.filteredOptions.map(option => ({
      type: 'option',
      value: option,
      content: option
    }));
  }

  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value) {
    this._placeholder = value;
    this.requestRender();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._isDisabled = value;
    this.requestRender();
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
    this.requestRender();
  }

  get isUseValueButtonActive() {
    return this._isUseValueButtonActive || this.isFilteredOptionsEmpty;
  }

  set isUseValueButtonActive(value) {
    this._isUseValueButtonActive = value;
    this.requestRender();
  }

  get isUseValueButtonVisible() {
    return !this.isValueEmpty && !this.isValueInOptions;
  }

  get isInErrorState() {
    return this._isInErrorState;
  }

  set isInErrorState(value) {
    this._isInErrorState = value;
    this.requestRender();
  }
}

export default ESuggestInputState;
