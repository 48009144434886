import { Blueprint } from './types/Blueprint';
import { GroupState } from './types/Group';
import { defaultRelations } from './types/Relation';
import translator from '../../../utils/translator';
export class ERuleBuilderState {
    constructor(component) {
        this._label = '';
        this._autoLabel = false;
        this._isGroupHeaderLabelHidden = false;
        this._isGroupHeaderToggleHidden = false;
        this._groupHeaderRelationLabel = '';
        this._addRuleButtonText = '';
        this._addRuleButtonTooltip = '';
        this._ruleEditorAddTitle = '';
        this._ruleEditorEditTitle = '';
        this._mainExcludedGroup = null;
        this._maxDepth = 3;
        this._maxRuleCount = Infinity;
        this._isExcludeEnabled = false;
        this._blueprints = [];
        this._relations = [];
        this._isDisabled = false;
        this._isReadonly = false;
        this._formatters = {};
        this._validations = {};
        this.requestRender = component.requestRender;
        this._refs = component.refs;
        this._mainGroup = GroupState.fromDTO({ items: [], relation: this.defaultRelation.value });
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
        this.requestRender();
    }
    get autoLabel() {
        return this._autoLabel;
    }
    set autoLabel(value) {
        this._autoLabel = value;
        this.requestRender();
    }
    get mainGroup() {
        return this._mainGroup;
    }
    get relations() {
        return this._relations.length > 0 ? this._relations : defaultRelations;
    }
    get defaultRelation() {
        return this.relations[0];
    }
    set items(values) {
        this._mainGroup = GroupState.fromDTO(values);
        this.updateBlueprintForRules(this._mainGroup);
        this.requestRender();
    }
    get mainExcludedGroup() {
        return this._mainExcludedGroup;
    }
    set excludedItems(value) {
        if (value === null) {
            this.removeMainExcludedGroup();
            return;
        }
        this._mainExcludedGroup = GroupState.fromDTO(value);
        this.updateBlueprintForRules(this._mainExcludedGroup);
        this.requestRender();
    }
    get hasExcludedGroup() {
        return this.mainExcludedGroup !== null;
    }
    get maxDepth() {
        return this._maxDepth;
    }
    set maxDepth(value) {
        this._maxDepth = value;
        this.requestRender();
    }
    get maxRuleCount() {
        return this._maxRuleCount;
    }
    set maxRuleCount(value) {
        this._maxRuleCount = value;
        this.requestRender();
    }
    get isSingleBlueprint() {
        return this._blueprints.length === 1;
    }
    get isExcludeEnabled() {
        return this._isExcludeEnabled;
    }
    set isExcludeEnabled(value) {
        this._isExcludeEnabled = value;
        this.requestRender();
    }
    get isGroupHeaderLabelHidden() {
        return this._isGroupHeaderLabelHidden;
    }
    set isGroupHeaderLabelHidden(value) {
        this._isGroupHeaderLabelHidden = value;
        this.requestRender();
    }
    get isGroupHeaderToggleHidden() {
        return this._isGroupHeaderToggleHidden;
    }
    set isGroupHeaderToggleHidden(value) {
        this._isGroupHeaderToggleHidden = value;
        this.requestRender();
    }
    get groupHeaderRelationLabel() {
        return this._groupHeaderRelationLabel;
    }
    set groupHeaderRelationLabel(value) {
        this._groupHeaderRelationLabel = value;
        this.requestRender();
    }
    get addRuleButtonText() {
        return this._addRuleButtonText;
    }
    set addRuleButtonText(value) {
        this._addRuleButtonText = value;
        this.requestRender();
    }
    get addRuleButtonTooltip() {
        return this._addRuleButtonTooltip;
    }
    set addRuleButtonTooltip(value) {
        this._addRuleButtonTooltip = value;
        this.requestRender();
    }
    get ruleEditorAddTitle() {
        return this._ruleEditorAddTitle;
    }
    set ruleEditorAddTitle(value) {
        this._ruleEditorAddTitle = value;
        this.requestRender();
    }
    get ruleEditorEditTitle() {
        return this._ruleEditorEditTitle;
    }
    set ruleEditorEditTitle(value) {
        this._ruleEditorEditTitle = value;
        this.requestRender();
    }
    get emptyStateHandler() {
        return this._emptyStateHandler;
    }
    set emptyStateHandler(callback) {
        this._emptyStateHandler = callback;
        this.requestRender();
    }
    get blueprints() {
        return this._blueprints;
    }
    set isDisabled(value) {
        this._isDisabled = value;
        this.requestRender();
    }
    get isDisabled() {
        return this._isDisabled;
    }
    set isReadonly(value) {
        this._isReadonly = value;
        this.requestRender();
    }
    get isReadonly() {
        return this._isReadonly;
    }
    get isReadonlyOrDisabled() {
        return this._isReadonly || this._isDisabled;
    }
    upsertFormatter(name, formatter) {
        this._formatters[name] = formatter;
        this.requestRender().then(() => {
            this.updateFieldValues(this.mainGroup);
        });
    }
    deleteFormatter(name) {
        delete this._formatters[name];
        this.requestRender().then(() => {
            this.updateFieldValues(this.mainGroup);
        });
    }
    upsertValidation(name, validation) {
        this._validations[name] = validation;
        this.updateBlueprintForRules(this._mainGroup);
    }
    deleteValidation(name) {
        delete this._validations[name];
    }
    getValidationByName(name) {
        return !!name ? this._validations[name] : undefined;
    }
    upsertBlueprint(blueprintEventDetail) {
        const blueprintIndex = this._blueprints.findIndex(storedBlueprint => storedBlueprint.uuid === blueprintEventDetail.uuid);
        const defaultValues = {};
        blueprintEventDetail.template?.querySelectorAll('[data-field]')?.forEach((fieldElement) => {
            const customPropertyName = fieldElement.dataset.property;
            defaultValues[fieldElement.dataset.field] = customPropertyName ?
                fieldElement[customPropertyName] :
                fieldElement.value;
        });
        blueprintEventDetail.defaultValues = defaultValues;
        if (blueprintIndex === -1) {
            this._blueprints = [...this._blueprints, blueprintEventDetail];
        }
        else {
            this._blueprints[blueprintIndex] = blueprintEventDetail;
        }
        this.requestRender();
    }
    updateBlueprintForRules(group) {
        group.items.forEach(item => {
            if ('items' in item) {
                return this.updateBlueprintForRules(item);
            }
            const blueprint = this.getBlueprintByName(item.blueprintName);
            if (!blueprint) {
                return;
            }
            item.blueprint = new Blueprint(blueprint);
            item.blueprint.validation = this.getValidationByName(item.blueprint.validationName);
            item.validate();
        });
        this.requestRender().then(() => {
            this.updateFieldValues(this.mainGroup);
        });
    }
    deleteBlueprint(uuid) {
        this._blueprints = this._blueprints.filter(blueprint => blueprint.uuid !== uuid);
        this.requestRender();
    }
    upsertRelation(relationEventDetail) {
        const relationIndex = this._relations.findIndex(storedRelation => storedRelation.uuid === relationEventDetail.uuid);
        const relation = relationEventDetail;
        if (relationIndex === -1) {
            this._relations = [...this._relations, relation];
        }
        else {
            this._relations[relationIndex] = relation;
        }
        this.updateRelationForDefault();
        this.requestRender();
    }
    updateRelationForDefault() {
        if (this.mainGroup.items.length > 0) {
            return;
        }
        this.mainGroup.relation = this.defaultRelation.value;
    }
    ;
    deleteRelation(uuid) {
        this._relations = this._relations.filter(relation => relation.uuid !== uuid);
        this.updateRelationForDefault();
        this.requestRender();
    }
    getBlueprintByName(name) {
        return this._blueprints.find(blueprint => blueprint.name === name);
    }
    addRuleToGroup(group, rule) {
        group.addItem(rule);
        this.requestRender().then(() => {
            this.updateFieldValues(this.mainGroup);
        });
    }
    removeRule(rule) {
        rule.remove();
        this.requestRender().then(() => {
            this.updateFieldValues(this.mainGroup);
        });
    }
    async startRuleEditing(rule) {
        rule.isEditing = true;
        await this.requestRender();
        this.updateFieldValues(this.mainGroup);
    }
    stopRuleEditing(rule) {
        rule.isEditing = false;
        this.requestRender();
    }
    removeGroupHoverState(group) {
        group.hasHoverState = false;
        this.requestRender();
    }
    updateRuleFields(rule, fieldChanges, fieldPropertyChanges) {
        rule.isSaved = true;
        rule.fields = { ...rule.fields, ...fieldChanges };
        rule.fieldProperties = { ...rule.fieldProperties, ...fieldPropertyChanges };
        this.updateFieldValues(this.mainGroup);
        this.requestRender();
    }
    hasEditedRule() {
        return this._mainGroup.isEditing || (!!this._mainExcludedGroup && this._mainExcludedGroup.isEditing);
    }
    isMaxRuleCountReached() {
        return this._mainGroup.ruleCount >= this._maxRuleCount;
    }
    isExclusionItem(item) {
        if (item === this.mainExcludedGroup) {
            return true;
        }
        if (item === this.mainGroup) {
            return false;
        }
        return this.isExclusionItem(item.parentGroup);
    }
    updateGroupLabel(value, group) {
        group.label = value;
        this.requestRender();
    }
    updateGroupRelation(value, group) {
        const relation = this.relations.find((relation) => relation.value === value);
        if (!relation) {
            return;
        }
        group.relation = relation.value;
        this.requestRender();
    }
    toggleGroupIsContentVisible(group) {
        group.isContentVisible = !group.isContentVisible;
        this.requestRender();
    }
    addGroup(parentGroup) {
        const newGroup = GroupState.fromDTO({ items: [], relation: this.defaultRelation.value });
        parentGroup.addItem(newGroup);
        this.requestRender();
        return newGroup;
    }
    removeGroup(group) {
        const removedGroup = group;
        group.remove();
        this.requestRender();
        return removedGroup;
    }
    addMainExcludedGroup() {
        this._mainExcludedGroup = new GroupState({ items: [], relation: this.defaultRelation.value });
        this.requestRender();
    }
    removeMainExcludedGroup() {
        this._mainExcludedGroup = null;
        this.requestRender();
    }
    getGroupLabel(group) {
        if (this.isExclusionItem(group)) {
            return group.getAutoLabel(1);
        }
        else {
            return group.getAutoLabel(0);
        }
    }
    get translations() {
        return this._translations;
    }
    updateTranslations() {
        this._translations = {
            untitledGroup: translator.translate('components.ruleBuilder.group.untitled'),
            editRuleTooltip: translator.translate('components.ruleBuilder.rule.header.editTooltip'),
            removeRuleTooltip: translator.translate('components.ruleBuilder.rule.header.removeTooltip'),
            editGroupTooltip: translator.translate('components.ruleBuilder.group.header.editTooltip'),
            removeGroupTooltip: translator.translate('components.ruleBuilder.group.header.removeTooltip'),
            collapseGroupTooltip: translator.translate('components.ruleBuilder.group.header.collapseTooltip'),
            expandGroupTooltip: translator.translate('components.ruleBuilder.group.header.expandTooltip'),
            groupDeleteDialogConfirm: translator.translate('components.ruleBuilder.group.deleteDialog.confirm'),
            groupDeleteDialogCancel: translator.translate('components.ruleBuilder.group.deleteDialog.cancel'),
            maxRuleCountReachedTooltip: translator.translate('components.ruleBuilder.group.actions.maxRuleCountReachedTooltip')
        };
        this.requestRender();
    }
    updateGroupHasHoverState(group, value) {
        if (group.hasHoverState === value) {
            return;
        }
        group.hasHoverState = value;
        this.requestRender();
    }
    updateRuleHasHoverState(rule, value) {
        if (rule.hasHoverState === value) {
            return;
        }
        rule.hasHoverState = value;
        this.requestRender();
    }
    getRelationByValue(relationValue) {
        return this.relations.find(relation => relation.value === relationValue);
    }
    updateFieldValues(group) {
        group.items.forEach(item => {
            if ('items' in item) {
                return this.updateFieldValues(item);
            }
            this.updateRuleValues(item);
            this.updateSummaryValues(item);
        });
    }
    updateRuleValues(rule) {
        if (!rule.isEditing || !rule.blueprint || !rule.blueprint.template) {
            return;
        }
        Object.keys(rule.fields).forEach((key) => {
            const field = rule.blueprint.template.querySelector(`[data-field="${key}"]`);
            if (!field) {
                return;
            }
            if (field.dataset.property) {
                field[field.dataset.property] = rule.fields[key];
            }
            else {
                field.value = rule.fields[key];
            }
        });
    }
    updateSummaryValues(rule) {
        if (!rule.blueprint || !rule.blueprint.summaryClone) {
            return;
        }
        const templateClone = rule.blueprint.templateClone;
        const summaryClone = rule.blueprint.summaryClone;
        if (templateClone) {
            this._refs.wrapper.appendChild(templateClone);
        }
        const blueprintPropertyPlaceholders = [
            ...summaryClone.querySelectorAll('e-rule-blueprint-summary-value[type="blueprint"]'),
            ...summaryClone.querySelectorAll('[data-blueprint]')
        ];
        blueprintPropertyPlaceholders.forEach((placeholder) => {
            const property = placeholder.getAttribute('property') || 'label';
            const value = rule.blueprint[property];
            placeholder.textContent = value;
        });
        const fieldsPropertyPlaceholders = [
            ...summaryClone.querySelectorAll('e-rule-blueprint-summary-value[type="field"]'),
            ...summaryClone.querySelectorAll('[data-field]')
        ];
        Object.keys(rule.fields).forEach((key) => {
            const field = templateClone.querySelector(`[data-field="${key}"]`);
            if (!field) {
                return;
            }
            if (field.dataset.property) {
                field[field.dataset.property] = rule.fields[key];
            }
            else {
                field.value = rule.fields[key];
            }
        });
        fieldsPropertyPlaceholders.forEach((placeholder) => {
            const fieldName = placeholder.getAttribute('field') || placeholder.getAttribute('data-field');
            const fieldElement = templateClone.querySelector(`[data-field="${fieldName}"]`);
            const property = placeholder.getAttribute('property') || 'value';
            const value = property === 'value' ? rule.fields[fieldName] : fieldElement[property] ?? '';
            const formatter = placeholder.getAttribute('formatter');
            placeholder.textContent = formatter && this._formatters[formatter] ? this._formatters[formatter](value) : value;
        });
        const indexPlaceholders = [
            ...summaryClone.querySelectorAll('e-rule-blueprint-summary-value[type="index"]'),
            ...summaryClone.querySelectorAll('[data-index]')
        ];
        indexPlaceholders.forEach((placeholder) => {
            placeholder.textContent = (rule.parentGroup.rules.indexOf(rule) + 1).toString();
        });
        if (templateClone) {
            templateClone.remove();
        }
    }
}
