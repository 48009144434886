import { render, html } from 'uhtml';

class Template {
  constructor(node) {
    this._node = node;
  }

  render(state) {
    const coordinates = state.coordinates;
    const points = `${coordinates[0]},0 ${coordinates[1]},0 ${coordinates[3]},35 ${coordinates[2]},35`;

    render(this._node, html`
      <svg width="240" height="35" fill="${state.color}">
        <polygon points="${points}"></polygon>
      </svg>
    `);
  }
}

export default Template;
