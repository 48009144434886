import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import State from './state';

class ERing extends HTMLCustomElement {
  init() {
    this._state = new State(this._render.bind(this));

    this._events = {
      update: this._onCircleUpdate.bind(this),
      delete: this._onCircleDelete.bind(this)
    };

    this.addEventListener('circle.update', this._events.update);
  }

  static get observedAttributes() {
    return ['type', 'mask-color', 'separated', 'radius', 'width'];
  }

  set type(value) {
    this._state.setState({ type: value });
  }

  set maskColor(value) {
    this._state.setState({ maskColor: value });
  }

  set separated(value) {
    this._state.setState({ separated: value });
  }

  set radius(value) {
    this._state.setState({ radius: parseInt(value, 10) });
  }

  set width(value) {
    this._state.setState({ width: parseInt(value, 10) });
  }

  _onCircleUpdate(event) {
    event.stopPropagation();

    event.target.removeEventListener('circle.delete', this._events.delete);
    event.target.addEventListener('circle.delete', this._events.delete);

    const data = {
      ...event.detail,
      element: event.target
    };

    this._state.upsertCollection('circles', data, 'uuid');
  }

  _onCircleDelete(event) {
    this._state.removeFromCollection('circles', event.detail.uuid, 'uuid');
  }

  _render() {
    let startPercent = 0;

    this._state.state.circles.forEach((circle, index) => {
      const properties = {
        defaultBackground: this._state.state.type === 'doughnut' && index === 0,
        background: this._state.state.circles.length === 1 || this._state.state.type === 'concentric',
        type: this._state.state.type,
        maskColor: this._state.state.maskColor,
        separated: this._state.state.separated,
        radius: this._state.state.radius,
        width: this._state.state.width,
        startPercent: this._state.state.type === 'concentric' ? 0 : startPercent
      };

      properties.width += this._state.state.type === 'doughnut' ? 12 : 0;
      properties.width += this._state.state.separated ? 1.5 : 0;
      properties.radius -= this._state.state.type === 'doughnut' ? 12 : 0;
      properties.radius -= this._state.state.type === 'concentric' ? properties.width * index : 0;

      const concentricSize = (this._state.state.radius + properties.width) * 2 + 14;
      properties.size = this._state.state.type === 'concentric' ? concentricSize : false;

      circle.element.setProperties(properties);
      startPercent += this._bumpStartPercent(circle.percent);
    });
  }

  _bumpStartPercent(percent) {
    return this._state.state.type !== 'concentric' ? parseFloat(percent) : 0;
  }
}

export default ERing;
