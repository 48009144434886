import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import autoBind from 'auto-bind';

import { normalizeColor } from '../../../utils/normalize-color';
import color from '../../../utils/color';

import { EColorInputState } from './state';
import { EColorInputEvents } from './events';
import { EColorInputTemplate } from './template';

class EColorInput extends HTMLCustomElement {
  //#region Lifecycle hooks
  init() {
    autoBind(this);

    this.refs = {};
    this.state = new EColorInputState(this);
    this.events = new EColorInputEvents(this);
    this.template = new EColorInputTemplate(this);
  }

  connectedCallback() {
    this.requestRender();
  }
  //#endregion

  //#region Attributes
  static get observedAttributes() {
    return ['value', 'readonly', 'name'];
  }

  set value(value) {
    this.state.inputValue = normalizeColor(value);
  }

  get value() {
    return this.state.inputValueWithHashmark;
  }

  set readonly(value) {
    this.state.isReadOnly = super._convertAttributeToBoolean(value);
  }

  set name(value) {
    this.state.name = value || '';
  }
  //#endregion

  //#region Actions
  selectAll() {
    if (!this.refs.input) { return; }

    this.requestRender().then(() => {
      const valueLength = this.refs.input.value.length;

      this.refs.input.focus();
      this.refs.input.setSelectionRange(0, valueLength);
    });
  }

  focus() {
    if (!this.refs.input) { return; }

    this.requestRender().then(() => {
      this.refs.input.focus();
    });
  }
  //#endregion

  //#region Rendering
  render() {
    render(this, this.template.createElement());
  }
  //#endregion

  //#region Private methods
  replaceSelectedText(text) {
    const { value, selectionStart, selectionEnd } = this.refs.input;
    const newValue = value.slice(0, selectionStart) + text + value.slice(selectionEnd);
    const cursorPosition = selectionStart + text.length;

    this.refs.input.value = newValue;
    this.refs.input.setSelectionRange(cursorPosition, cursorPosition);
    this.state.inputValue = newValue;
  }

  dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('change', {
      detail: Object.assign({ value: this.value }, color(this.value))
    }));
  }
  //#endregion
}

export default EColorInput;
