import autoBind from 'auto-bind';

export class ESuggestInputOptionEvents {
  constructor(component) {
    autoBind(this);

    this._dispatchUpdateEvent = component._dispatchUpdateEvent.bind(component);
    this._getContent = component._getContent.bind(component);

    this.state = component.state;
  }

  onMutation() {
    const oldContent = this.state.content;

    this.state.content = this._getContent();
    this._dispatchUpdateEvent(oldContent);
  }
}

export default ESuggestInputOptionEvents;
