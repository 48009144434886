import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import template from './template';

class ESpinner extends HTMLCustomElement {
  init() {
    this._state = {};
    this._state.text = '';
    this._state.size = 'large';
    this._state.fullscreen = '';
  }

  connectedCallback() {
    this._render();
  }

  static get observedAttributes() {
    return ['data-text', 'data-size', 'data-fullscreen'];
  }

  set dataText(value) {
    this._state.text = value;
    this._render();
  }

  set dataSize(value) {
    this._state.size = value;
    this._render();
  }

  set dataFullscreen(value) {
    this._state.fullscreen = super._convertAttributeToBoolean(value);
    this._render();
  }

  _getState() {
    return this._state;
  }

  _render() {
    render(this, template(this._getState()));
  }
}

export default ESpinner;
