import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { ERuleBlueprintState } from './state';
import { ERuleBlueprintEvents } from './events';
export class ERuleBlueprint extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = { component: this, template: null, summary: null, actions: null };
        this.utils = { mutationObserver: null };
        this.state = new ERuleBlueprintState();
        this.events = new ERuleBlueprintEvents(this);
        this.utils.mutationObserver = new MutationObserver(this.events.onMutation);
    }
    connectedCallback() {
        this.utils.mutationObserver.observe(this, { childList: true, subtree: true, attributes: true });
        this.updateRefs();
    }
    disconnectedCallback() {
        this._dispatchDeleteEvent();
    }
    // Attributes
    static get observedAttributes() {
        return ['name', 'label', 'validation'];
    }
    set name(value) {
        this.state.name = value;
        this._dispatchUpdateEvent();
    }
    set label(value) {
        this.state.label = value;
        this._dispatchUpdateEvent();
    }
    set validation(value) {
        this.state.validation = value;
        this._dispatchUpdateEvent();
    }
    // Actions
    updateRefs() {
        const blueprintTemplate = this.querySelector('e-rule-blueprint-template');
        if (!this.refs.template || blueprintTemplate) {
            this.refs.template = blueprintTemplate;
        }
        else if (!this.refs.template.isConnected) {
            this.refs.template = null;
        }
        this.refs.summary = this.querySelector('e-rule-blueprint-summary');
        this.refs.actions = Array.from(this.querySelectorAll('e-rule-blueprint-action'));
        this._dispatchUpdateEvent();
    }
    // Private methods
    _toBlueprint() {
        return {
            uuid: this.state.uuid,
            name: this.state.name,
            label: this.state.label,
            validation: this.state.validation,
            template: this.refs.template || null,
            summary: this.refs.summary || null,
            actions: this.refs.actions || []
        };
    }
    _dispatchUpdateEvent() {
        if (!this.state.name || !this.state.label) {
            return;
        }
        this.dispatchEvent(new CustomEvent('rule-blueprint.update', {
            bubbles: true,
            detail: this._toBlueprint()
        }));
    }
    _dispatchDeleteEvent() {
        this.dispatchEvent(new CustomEvent('rule-blueprint.delete', {
            bubbles: true,
            detail: this._toBlueprint()
        }));
    }
}
