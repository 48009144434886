import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import uuid from '../../../js/utils/uuid/index.js';

class EActionlistGroup extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: uuid(),
      label: '',
      items: []
    };

    this._events = {
      deleteItem: this._deleteItem.bind(this)
    };

    this.addEventListener('actionlistitem.update', this._updateItem.bind(this));
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('actionlistgroup.delete', {
      detail: {
        uuid: this._state.uuid
      }
    }));
  }

  _updateItem(event) {
    event.stopPropagation();
    event.target.removeEventListener('actionlistitem.delete', this._events.deleteItem);
    event.target.addEventListener('actionlistitem.delete', this._events.deleteItem);

    this._upsertItem(Object.assign({}, event.detail, {
      component: event.target,
      group: this._state.uuid
    }));
    this._dispatchEvent();
  }

  _upsertItem(itemData) {
    const item = this._getItemByUuid(itemData.uuid);
    const index = this._state.items.indexOf(item);

    if (index > -1) {
      this._state.items = this._state.items.slice();
      this._state.items[index] = itemData;
    } else {
      this._state.items = this._state.items.concat(itemData);
    }
  }

  static get observedAttributes() {
    return ['label'];
  }

  set label(value) {
    this._state.label = value;
    this._dispatchEvent();
  }

  _getItemByUuid(uuid) {
    return this._state.items.filter(item => item.uuid === uuid)[0];
  }

  _deleteItem(event) {
    this._state.items = this._state.items.filter(item => item.uuid !== event.detail.uuid);
    this._dispatchEvent(true);
  }

  _dispatchEvent(force = false) {
    if (!this.parentNode && !force) {
      return;
    }

    this.dispatchEvent(new CustomEvent('actionlistgroup.update', {
      bubbles: true,
      detail: this._state
    }));
  }
}

export default EActionlistGroup;
