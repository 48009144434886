export class EColorPickerState {
  constructor(component) {
    this.requestRender = component.requestRender;

    this._temporaryValue = '';
  }

  get temporaryValue() {
    return this._temporaryValue;
  }

  set temporaryValue(value) {
    this._temporaryValue = value;
    this.requestRender();
  }
}

export default EColorPickerState;
