import autoBind from 'auto-bind';

export class ESuggestInputEvents {
  constructor(component) {
    autoBind(this);
    this.open = component.open.bind(component);
    this.close = component.close.bind(component);

    this._dispatchChangeEvent = component._dispatchChangeEvent.bind(component);
    this._updateActionlistItems = component._updateActionlistItems.bind(component);

    this.refs = component.refs;
    this.utils = component.utils;
    this.state = component.state;
  }

  onOptionInsert(event) {
    event.target.removeEventListener('suggestinputoption.delete', this.onOptionDelete);
    event.target.addEventListener('suggestinputoption.delete', this.onOptionDelete);

    this.state.insertOption(event.detail);
    this._updateActionlistItems();
  }

  onOptionDelete(event) {
    this.state.deleteOption(event.detail);
    this._updateActionlistItems();
  }

  onOptionUpdate(event) {
    this.state.updateOption(event.detail);
    this._updateActionlistItems();
  }

  onClick() {
    this.open();
  }

  onUseValueClick() {
    this._dispatchChangeEvent();
    this.close();
  }

  onInput(event) {
    this.state.value = event.target.value;
    this._updateActionlistItems();
    this._dispatchChangeEvent();
    this.open();
  }

  onActionlistChange(event) {
    event.stopPropagation();

    this.state.value = event.detail.value;
    this._updateActionlistItems();
    this._dispatchChangeEvent();
    this.close();
  }

  onKeydown(event) {
    if (event.code === 'Escape' || event.code === 'Tab') {
      this.close();
      return;
    }

    if (event.code === 'ArrowDown') {
      event.preventDefault();
      this.open();

      if (this.state.isUseValueButtonActive) {
        this.state.isUseValueButtonActive = false;
        this.refs.actionlist.moveActiveItem('first');
        return;
      }

      if (!this.refs.actionlist.isLastItemActive) {
        this.refs.actionlist.moveActiveItem('next');
        return;
      }

      if (this.state.isUseValueButtonVisible) {
        this.state.isUseValueButtonActive = true;
      } else {
        this.refs.actionlist.moveActiveItem('first');
      }
    }

    if (event.code === 'ArrowUp') {
      event.preventDefault();
      this.open();

      if (this.state.isUseValueButtonActive) {
        this.state.isUseValueButtonActive = false;
        this.refs.actionlist.moveActiveItem('last');
        return;
      }

      if (!this.refs.actionlist.isFirstItemActive) {
        this.refs.actionlist.moveActiveItem('previous');
        return;
      }

      if (this.state.isUseValueButtonVisible) {
        this.state.isUseValueButtonActive = true;
      } else {
        this.refs.actionlist.moveActiveItem('last');
      }
    }

    if (event.code === 'Enter' && this.utils.popup.isOpened) {
      if (!this.state.isUseValueButtonActive) {
        this.refs.actionlist.toggleActiveItem();
      } else {
        this._dispatchChangeEvent();
      }

      this.close();
      return;
    }
  }
}

export default ESuggestInputEvents;
