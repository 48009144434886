const typeMap = {
  default: 'e-info-circle',
  info: 'e-info-circle',
  warning: 'warning',
  success: 'e-check-circle',
  error: 'e-error',
  danger: 'e-error',
  neutral: 'e-info-circle',
  loading: ''
};

export const getIconForType = (type) => {
  return typeMap[type] || typeMap.default;
};
