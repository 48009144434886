import generateUuid from '../uuid/index.js';
import {
  GET_TOP_ORIGIN_MESSAGE,
  GET_TOP_URL_MESSAGE,
  NAVIGATE_MESSAGE,
  RECEIVE_TOP_ORIGIN_MESSAGE,
  RECEIVE_TOP_URL_MESSAGE
} from './constants.js';

class NavigationClient {

  constructor(global = window) {
    this._global = global;
    this._deferredRegistry = {
      getTopUrl: {},
      getTopOrigin: {}
    };
  }

  navigate(options = {}, top) {
    const targetWindow = options.targetWindow || top || window.top;

    const optionsForMessage = {
      ...options,
      targetWindow: undefined
    };

    targetWindow.postMessage({ message: NAVIGATE_MESSAGE, data: optionsForMessage }, '*');
  };

  getTopUrl({ targetWindow = window.top } = {}) {
    return new Promise(resolve => {
      const uuid = generateUuid();
      this._deferredRegistry.getTopUrl[uuid] = { resolve };
      targetWindow.postMessage({ message: GET_TOP_URL_MESSAGE, data: { uuid } }, '*');
    });
  }

  getTopOrigin({ targetWindow = window.top } = {}) {
    return new Promise(resolve => {
      const uuid = generateUuid();
      this._deferredRegistry.getTopOrigin[uuid] = { resolve };
      targetWindow.postMessage({ message: GET_TOP_ORIGIN_MESSAGE, data: { uuid } }, '*');
    });
  }

  subscribe() {
    this._global.addEventListener('message', event => {
      if (!event.data) { return; }

      if (event.data.message === RECEIVE_TOP_URL_MESSAGE) {
        this._handleGetTopUrlMessage(event.data.data);
      } else if (event.data.message === RECEIVE_TOP_ORIGIN_MESSAGE) {
        this._handleGetTopOriginMessage(event.data.data);
      }
    });
  }

  _handleGetTopUrlMessage(data) {
    if (!this._deferredRegistry.getTopUrl[data.uuid]) {
      return;
    }

    const { resolve } = this._deferredRegistry.getTopUrl[data.uuid];
    resolve(data.url);
    delete this._deferredRegistry[data.uuid];
  }

  _handleGetTopOriginMessage(data) {
    if (!this._deferredRegistry.getTopOrigin[data.uuid]) {
      return;
    }

    const { resolve } = this._deferredRegistry.getTopOrigin[data.uuid];
    resolve(data.origin);
    delete this._deferredRegistry[data.uuid];
  }
}

export default NavigationClient;
