import purifier from '../../dom-purify.js';

export class AlertHandler {

  static eventName = 'alert';

  static getType(className = '') {
    return className.replace('e-alert-', '').replace('danger', 'error');
  };

  static handleMessage(data, global = window) {
    global.e.utils.v2.ui.openNotification({
      content: purifier.sanitize(data.text, { USE_PROFILES: { html: false } }),
      type: AlertHandler.getType(data.className),
      autoClose: true
    });
  }
}
