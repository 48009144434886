import { HTMLCustomElement, dateUtils } from '@emartech/ui-framework-utils';

class EDateLocale extends HTMLCustomElement {
  init() {
    this._date = this._now();
    this._short = false;
    this._offset = 0;
    this._locale = 'en';
    this._distanceInWords = false;
  }

  connectedCallback() {
    this._displayDate();
  }

  static get observedAttributes() {
    return ['locale', 'date', 'offset', 'distance-in-words', 'short'];
  }

  set short(value) {
    this._short = super._convertAttributeToBoolean(value);
    this._displayDate();
  }

  set locale(value) {
    this._locale = value;
    this._displayDate();
  }

  set date(value) {
    this._date = (value !== null && dateUtils.isValid(value)) ? value : this._now();
    this._displayDate();
  }

  set offset(value) {
    const offset = parseInt(value, 10);
    this._offset = isNaN(offset) ? 0 : offset;
    this._displayDate();
  }

  set distanceInWords(value) {
    this._distanceInWords = super._convertAttributeToBoolean(value);
    this._displayDate();
  }

  _now() {
    return new Date();
  }

  _displayDate() {
    const date = dateUtils.addDays(dateUtils.parse(this._date), this._offset);
    if (this._distanceInWords) {
      const localeObject = dateUtils.getLocaleWithFallback(this._locale);
      const options = {
        locale: localeObject.lang
      };
      this.textContent = dateUtils.formatDistance(this._now(), date, Object.assign({ addSuffix: true }, options));
    } else {
      const options = {
        locale: this._locale,
        short: this._short
      };
      this.textContent = dateUtils.formatByLocale(date, options);
    }
  }
}

export default EDateLocale;
