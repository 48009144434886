import manifest from '../../../../dist/app.js';
import JSLogger from '../jslogger/index.js';
const logger = new JSLogger('Matomo');

export class Matomo {
  #siteId;
  #trackerUrl;

  constructor(siteId, trackerUrl) {
    if (!siteId || typeof siteId !== 'string' && typeof siteId !== 'number') {
      throw new Error('siteId is required');
    }
    if (!trackerUrl) { throw new Error('trackerUrl is required'); }
    if (!trackerUrl.endsWith('matomo.php') && !trackerUrl.endsWith('piwik.php')) {
      throw new Error('url should be valid');
    }

    this.#siteId = siteId;
    this.#trackerUrl = trackerUrl;

  }

  track(options = {}) {
    if (typeof options === 'string') {
      options = {
        url: options
      };
    }

    options.idsite = this.#siteId;
    options.rec = 1;

    if (!options.url) { throw new Error('URL to be tracked must be specified.'); }

    const queryParams = new URLSearchParams(options).toString();

    try {
      fetch(`${this.#trackerUrl}?${queryParams}`);
    } catch (error) {
      logger.warn('fetch failed', { errorMessage: error.message });
    }
  }
}

let matomoTrackerInstance;

try {
  matomoTrackerInstance = new Matomo(manifest.matomoConnectionInfo.siteId, manifest.matomoConnectionInfo.url);
} catch (error) {
  matomoTrackerInstance = undefined;
}

export default matomoTrackerInstance;
