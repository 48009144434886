import { html } from 'uhtml';
import classNames from 'clsx';
import { convertAttributeToBoolean } from '@emartech/ui-framework-utils';
export class ERuleBuilderTemplate {
    constructor(component) {
        this.refs = component.refs;
        this.state = component.state;
        this.events = component.events;
    }
    // Wrapper Element
    createWrapper() {
        return html.node `<div class="e-rule-builder__wrapper"></div>`;
    }
    // Component Content
    createElement() {
        return html `
      <div class="e-rule-builder">
        ${this.createMainGroups()}
        ${this.createAddExclusionButton()}
        ${this.createExclusionLabel()}
        ${this.createMainExclusionGroups()}
      </div>
    `;
    }
    createMainGroups() {
        return html `
      <div class="e-rule-builder__content">
        ${this.createGroup(this.state.mainGroup)}
      </div>
    `;
    }
    createMainExclusionGroups() {
        if (!this.state.hasExcludedGroup) {
            return;
        }
        return html `
      <div class="e-rule-builder__content e-rule-builder__content-exclusion">
        ${this.createGroup(this.state.mainExcludedGroup)}
      </div>
    `;
    }
    createGroup(group) {
        const variant = (group.depth - 1) % 3 + 1;
        const groupClassName = classNames('e-rule-builder__group', {
            'e-rule-builder__group-main': group.isMainGroup
        });
        const wrapperClassName = classNames('e-rule-builder__group_wrapper', {
            [`e-rule-builder__group_wrapper-variant-${variant}`]: true,
            'e-rule-builder__group_wrapper-hover': group.hasHoverState && !this.state.isReadonlyOrDisabled
        });
        return html `
      <div class="${groupClassName}">
        ${this.createGroupRelation(group)}
        <div
          class="${wrapperClassName}"
          @mouseover="${event => this.events.onGroupFocusEnter(event, group)}"
          @mouseleave="${event => this.events.onGroupFocusLeave(event, group)}"
        >
          ${this.createGroupHeader(group)}
          ${this.createGroupContent(group)}
        </div>
      </div>
    `;
    }
    createGroupRelation(group) {
        if (group.isMainGroup || group.parentGroup.items.length <= 1) {
            return;
        }
        const relation = this.state.getRelationByValue(group.parentGroup.relation);
        if (relation === undefined || !relation.label) {
            return;
        }
        const relationClassName = classNames('e-rule-builder__group_relation', {
            'e-rule-builder__group_relation-hidden': group.parentGroup.items.indexOf(group) === 0
        });
        return html `
      <div class="${relationClassName}">${relation.label}</div>
    `;
    }
    createGroupHeader(group) {
        const isClosed = !group.isContentVisible;
        const className = classNames('e-rule-builder__group_header', {
            'e-rule-builder__group_header-closed': isClosed
        });
        return html `
      <div class=${className}>
        <div class="e-rule-builder__group_header_left">
          ${this.createAutoLabel(group)}
          ${this.createGroupHeaderRelation(group)}
          ${this.createInplaceeditor(group)}
        </div>

        <div class="e-rule-builder__group_header_actions">
          ${this.createGroupHeaderEditButton(group)}
          ${this.createGroupHeaderRemoveButton(group)}
          ${this.createGroupHeaderToggleVisibilityButton(group)}
        </div>
      </div>
    `;
    }
    createInplaceeditor(group) {
        if (this.state.isGroupHeaderLabelHidden) {
            return;
        }
        return html `
      <e-inplaceeditor
        class="e-rule-builder__group_header_label"
        placeholder="${`(${this.state.translations.untitledGroup})`}"
        .value=${group.label}
        ?disabled=${this.state.isReadonlyOrDisabled}
        @update=${event => this.events.onGroupLabelChange(event, group)}
      ></e-inplaceeditor>`;
    }
    createGroupHeaderRelation(group) {
        const selectedRelation = this.state.getRelationByValue(group.relation);
        if (!selectedRelation) {
            return;
        }
        const relationOptions = this.state.relations.map(relation => this.createGroupHeaderRelationItem(relation, relation.value === selectedRelation.value));
        return html `
      ${this.createGroupHeaderRelationLabel()}
      ${this.createGroupHeaderRelationIcon(selectedRelation.icon)}

      <e-select
        inline
        ?disabled=${this.state.isDisabled}
        ?readonly=${this.state.isReadonly}
        @change=${event => this.events.onGroupRelationChange(event, group)}
      >
        ${relationOptions}
      </e-select>
    `;
    }
    createGroupHeaderRelationLabel() {
        if (!this.state.groupHeaderRelationLabel) {
            return;
        }
        return html `<span>${this.state.groupHeaderRelationLabel}</span>`;
    }
    createGroupHeaderRelationIcon(icon) {
        if (!icon) {
            return;
        }
        return html `
      <e-icon
        class="e-rule-builder__group_header_relation_icon"
        icon=${icon}
        color="inherit"
      ></e-icon>
    `;
    }
    createGroupHeaderRelationItem(relation, isSelected) {
        return html `
        <e-select-option value="${relation.value}" ?selected="${isSelected}">
          ${relation.description}
        </e-select-option>
    `;
    }
    createGroupHeaderEditButton(group) {
        if (this.state.isGroupHeaderLabelHidden || this.state.isReadonlyOrDisabled) {
            return;
        }
        return html `
      <e-tooltip content="${`${this.state.translations.editGroupTooltip}`}">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__group_header_edit_button"
          type="button"
          @click="${this.events.onGroupEditLabelButtonClick}"
          @blur="${event => this.events.onGroupFocusLeave(event, group)}"
          @focus="${event => this.events.onGroupFocusEnter(event, group)}"
        >
          <e-icon icon="pencil" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createGroupHeaderRemoveButton(group) {
        if (this.state.isReadonlyOrDisabled) {
            return;
        }
        if (group.isMainGroup && group !== this.state.mainExcludedGroup) {
            return;
        }
        const onClick = () => group.isMainGroup ?
            this.events.onMainExcludedGroupRemove() :
            this.events.onGroupRemove(group);
        return html `
      <e-tooltip content="${`${this.state.translations.removeGroupTooltip}`}">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__group_header_remove_button"
          type="button"
          @click="${onClick}"
          @blur="${event => this.events.onGroupFocusLeave(event, group)}"
          @focus="${event => this.events.onGroupFocusEnter(event, group)}"
        >
          <e-icon icon="trash-o" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createGroupHeaderToggleVisibilityButton(group) {
        if (this.state.isGroupHeaderToggleHidden) {
            return;
        }
        const groupToggleIcon = group.isContentVisible ? 'e-angle-up' : 'e-angle-down';
        const groupToggleTooltip = group.isContentVisible ? 'collapseGroupTooltip' : 'expandGroupTooltip';
        return html `
      <e-tooltip content="${`${this.state.translations[groupToggleTooltip]}`}">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__group_header_toggle_button"
          type="button"
          @click="${() => this.events.onGroupVisibilityToggleClick(group)}"
          @blur="${event => this.events.onGroupFocusLeave(event, group)}"
          @focus="${event => this.events.onGroupFocusEnter(event, group)}"
        >
          <e-icon icon="${groupToggleIcon}" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createAutoLabel(group) {
        if (!this.state.autoLabel) {
            return;
        }
        return html `<span class="e-rule-builder__group_header_auto_label">${this.state.getGroupLabel(group)}</span>`;
    }
    createGroupContent(group) {
        const className = classNames('e-rule-builder__group_content', {
            'e-rule-builder__group_content-hidden': !group.isContentVisible
        });
        return html `
      <div class="${className}">
        <div class="e-rule-builder__group_list">
          ${this.createGroupItems(group)}
        </div>
        ${this.createGroupEmptyState(group)}
        ${this.createGroupActions(group)}
      </div>
    `;
    }
    createGroupEmptyState(group) {
        if (!group.isEmpty) {
            return;
        }
        const isMaxDepthReached = group.depth >= this.state.maxDepth;
        const { title, text } = this.getEmptyStateLabels(isMaxDepthReached);
        return html `
      <div class="e-rule-builder__group_empty_state e-emptystate">
        <div class="e-emptystate__content">
          <div class="e-emptystate__title e-rule-builder__group_empty_state_label">
            ${title}
          </div>
          <div class="e-emptystate__lead">
            ${text}
          </div>
          ${this.createEmptyStateGroupActions(group)}
        </div>
      </div>
    `;
    }
    createEmptyStateGroupActions(group) {
        if (this.state.isReadonlyOrDisabled) {
            return;
        }
        return html `
      <div class="e-rule-builder__group_empty_state_actions">
        ${this.createGroupActionButtons(group)}
      </div>`;
    }
    createGroupActions(group) {
        if (group.isEmpty || this.state.isReadonlyOrDisabled) {
            return;
        }
        return html `
      <div class="e-rule-builder__group_actions">
        ${this.createGroupActionButtons(group)}
      </div>
    `;
    }
    createGroupActionButtons(group) {
        if (!group.hasHoverState && !group.isEmpty && !group.isDropdownOpen) {
            return html `
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__add_rule_placeholder"
          @blur="${event => this.events.onGroupFocusLeave(event, group)}"
          @focus="${event => this.events.onGroupFocusEnter(event, group)}"
        >
          <e-icon icon="plus" size="small" color="gray-500"></e-icon>
        </button>
      `;
        }
        return html `
      <div class="e-rule-builder__add_rule_dropdown">
        ${this.createAddRuleSection(group)}
      </div>
      ${this.createAddSubgroupButton(group)}
    `;
    }
    createAddSubgroupButton(group) {
        if (group.depth >= this.state.maxDepth) {
            return;
        }
        const className = classNames('e-btn e-rule-builder__add_subgroup_button', {
            'e-btn-disabled': this.state.hasEditedRule()
        });
        return html `
      <button
        class="${className}"
        ?disabled=${this.state.hasEditedRule()}
        @click=${() => this.events.onAddSubgroup(group)}
      >
        <e-icon icon="e-file-folder"></e-icon>
        <e-translation key="components.ruleBuilder.group.actions.addSubgroup"></e-translation>
      </button>
      ${this.createSubgroupAutoLabel(group)}
    `;
    }
    createSubgroupAutoLabel(group) {
        if (!this.state.autoLabel || group.isEmpty) {
            return;
        }
        return html `
      <span class="e-rule-builder__add_subgroup_label">
        <e-translation
          key="components.ruleBuilder.group.subgroupAutoLabel"
          value=${JSON.stringify([group.getAutoLabel()])}
        ></e-translation>
      </span>
    `;
    }
    createGroupItems(group) {
        return group.items.map(item => html.for(item) `${this.createItem(group, item)}`);
    }
    createAddRuleSection(group) {
        const isCustomTooltip = !!this.state.addRuleButtonTooltip;
        const isSingleBlueprint = this.state.isSingleBlueprint;
        const tooltipText = isCustomTooltip ?
            this.state.addRuleButtonTooltip :
            this.state.translations.maxRuleCountReachedTooltip;
        if (isSingleBlueprint) {
            return this.createAddSingleRuleButton(group, tooltipText);
        }
        return this.createAddRuleDropdown(group, tooltipText);
    }
    createAddRuleDropdown(group, tooltipText) {
        if (this.state.hasEditedRule()) {
            return html `
        <button disabled class="e-btn e-btn-dropdown e-btn-disabled">
          <e-icon icon="plus"></e-icon>
          ${this.createAddRuleButtonText()}
        </button>
      `;
        }
        else if (this.state.isMaxRuleCountReached()) {
            return html `
        <e-tooltip content="${`${tooltipText}`}">
          <button disabled class="e-btn e-btn-dropdown e-btn-disabled">
            <e-icon icon="plus"></e-icon>
            ${this.createAddRuleButtonText()}
          </button>
        </e-tooltip>
      `;
        }
        return html `
      <e-dropdown
        data-markup-class="e-btn e-btn-dropdown"
        data-autoclose
        data-dropdown-align='auto'
        @status="${event => this.events.onDropdownStatusChange(event, group)}"
      >
        <e-dropdown-handler tabindex="0">
          <e-icon icon="plus"></e-icon>
          ${this.createAddRuleButtonText()}
        </e-dropdown-handler>
        <div class="e-dropdown__content e-rule-builder__add_rule_content">
          ${this.state.blueprints.map(blueprint => this.createAddRuleButton(group, blueprint))}
        </div>
      </e-dropdown>
    `;
    }
    createAddSingleRuleButton(group, tooltipText) {
        const singleBlueprint = this.state.blueprints[0];
        if (this.state.hasEditedRule()) {
            return html `
        <button disabled class="e-btn e-btn-disabled">
          <e-icon icon="plus"></e-icon>
          ${this.createAddRuleButtonText()}
        </button>
      `;
        }
        else if (this.state.isMaxRuleCountReached()) {
            return html `
        <e-tooltip content="${`${tooltipText}`}">
          <button disabled class="e-btn e-btn-disabled">
            <e-icon icon="plus"></e-icon>
            ${this.createAddRuleButtonText()}
          </button>
        </e-tooltip>
      `;
        }
        return html `
      <button
        class="e-btn"
        @click=${event => this.events.onAddRuleButtonClick(event, group, singleBlueprint)}
        @blur="${event => this.events.onGroupFocusLeave(event, group)}"
        @focus="${event => this.events.onGroupFocusEnter(event, group)}"
      >
        <e-icon icon="plus"></e-icon>
        ${this.createAddRuleButtonText()}
      </button>
    `;
    }
    createAddRuleButtonText() {
        return !!this.state.addRuleButtonText ?
            html `<span>${this.state.addRuleButtonText}</span>` :
            html `<e-translation key="components.ruleBuilder.group.actions.addRule"></e-translation>`;
    }
    createAddRuleButton(group, blueprint) {
        return html `
      <button
        class="e-dropdown__item e-rule-builder__add_rule_button"
        data-blueprint=${blueprint.name}
        @click=${event => this.events.onAddRuleButtonClick(event, group, blueprint)}
        @blur="${event => this.events.onGroupFocusLeave(event, group)}"
        @focus="${event => this.events.onGroupFocusEnter(event, group)}"
      >
        ${blueprint.label}
      </button>
    `;
    }
    createItem(group, item) {
        const isGroup = 'items' in item;
        return isGroup ? this.createGroup(item) : this.createRule(group, item);
    }
    createRule(group, rule) {
        const hasBlueprint = !!rule.blueprint;
        const ruleIndex = group.items.indexOf(rule);
        const previousItem = ruleIndex > 0 ? group.items[ruleIndex - 1] : null;
        const isFirstRule = !previousItem || 'items' in previousItem;
        const nextItem = ruleIndex < (group.items.length - 1) ? group.items[ruleIndex + 1] : null;
        const isLastRule = !nextItem || 'items' in nextItem;
        const className = classNames('e-rule-builder__rule', {
            'e-rule-builder__rule-first': isFirstRule,
            'e-rule-builder__rule-last': isLastRule,
            'e-rule-builder__rule-error': !hasBlueprint
        });
        return html `
      <div class=${className}>
        ${this.createRuleRelation(group, rule)}
        <div class="e-rule-builder__rule_wrapper">
          ${hasBlueprint ? this.createRuleContent(group, rule) : this.createRuleErrorState(group, rule)}
        </div>
      </div>
    `;
    }
    createRuleContent(group, rule) {
        const hasOtherEditedRule = this.state.hasEditedRule() && !rule.isEditing;
        const className = classNames('e-rule-builder__rule_header', {
            'e-rule-builder__rule_header-disabled': hasOtherEditedRule || this.state.isReadonlyOrDisabled
        });
        return html `
      <div
        class=${className}
        @click=${event => this.events.onRuleEditClick(event, rule)}
      >
        ${this.createRuleHeader(rule)}
        ${this.createEditRuleHeader(group, rule)}
      </div>
      ${this.createRuleEditor(group, rule)}
    `;
    }
    createRuleErrorState(group, rule) {
        return html `
      <div class="e-rule-builder__rule_header">
        <div>
          ${this.createRuleSummaryAutoLabel(rule)}
          <strong>
            <e-translation key="components.ruleBuilder.rule.error.blueprintMissing"></e-translation>
          </strong>
          ${rule.fields ? html `
            <e-translation key="components.ruleBuilder.rule.error.blueprintMissingValues"></e-translation>:
            ${Object.values(rule.fields).join(', ')}` : ''}
        </div>
      </div>
    `;
    }
    createRuleRelation(group, rule) {
        const relation = this.state.getRelationByValue(group.relation);
        if (group.items.length <= 1 || relation === undefined || !relation.label) {
            return;
        }
        const relationClassName = classNames('e-rule-builder__rule_relation', {
            'e-rule-builder__rule_relation-hidden': group.items.indexOf(rule) === 0
        });
        return html `
      <div class="${relationClassName}">${relation.label}</div>
    `;
    }
    createRuleHeader(rule) {
        if (rule.isEditing) {
            return;
        }
        const hasEditedRule = this.state.hasEditedRule();
        const className = classNames('e-rule-builder__rule_header_summary', {
            'e-rule-builder__rule_header_summary-disabled': hasEditedRule || this.state.isDisabled
        });
        return html `
      <div class=${className}>
        ${this.createRuleSummaryAutoLabel(rule)}
        ${this.createRuleSummaryDisplay(rule)}
        ${this.createRuleErrorStateBadge(rule)}
      </div>

      ${this.createRuleHeaderActions(rule, hasEditedRule)}
    `;
    }
    createRuleHeaderActions(rule, hasEditedRule) {
        if (this.state.isReadonlyOrDisabled) {
            return;
        }
        const ruleActionClassList = classNames('e-rule-builder__rule_header_actions', {
            'e-rule-builder__rule_header_actions-show': rule.hasHoverState
        });
        return html `
      <div class="${ruleActionClassList}">
        ${this.createRuleActionButtons(rule, hasEditedRule)}
        ${this.createRuleEditButton(rule, hasEditedRule)}
        ${this.createRuleRemoveButton(rule, hasEditedRule)}
      </div>
    `;
    }
    createRuleSummaryDisplay(rule) {
        if (!rule.blueprint) {
            return;
        }
        if (rule.blueprint.summary) {
            return rule.blueprint.summaryClone;
        }
        return html `
      <strong>${rule.blueprint.label}</strong>
      ${rule.fields ? Object.values(rule.fields).map((field) => html `
        <span class="e-rule-builder__rule_header_summary_value">${field}</span>
    `) : ''}`;
    }
    createRuleErrorStateBadge(rule) {
        if (!rule.errorState) {
            return;
        }
        return html `
      <div class="e-label e-label-error e-margin-left-xs">
        <e-icon class="e-label__icon" icon="e-error" color="inherit" type="small"></e-icon>
        <e-translation key="components.ruleBuilder.rule.header.summaryError"></e-translation>
      </div>
    `;
    }
    createRuleSummaryAutoLabel(rule) {
        if (!this.state.autoLabel) {
            return;
        }
        const label = `${rule.parentGroup.getAutoLabel()}${(rule.parentGroup.rules.indexOf(rule) + 1)}`;
        return html `<span class="e-rule-builder__rule_header_auto_label">${label}</span>`;
    }
    createRuleActionButtons(rule, disabled) {
        if (!rule.blueprint || !rule.blueprint.actions.length) {
            return;
        }
        return rule.blueprint.actions.map(action => html `
      <e-tooltip content="${action.getAttribute('tooltip')}" class="e-rule-builder__rule_header_actions_blueprint">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless"
          type="button"
          ?disabled=${disabled || convertAttributeToBoolean(action.getAttribute('disabled'))}
          @click=${event => this.events.onRuleActionClick(event, action, rule)}
          @blur="${event => this.events.onRuleFocusLeave(event, rule)}"
          @focus="${event => this.events.onRuleFocusEnter(event, rule)}"
        >
          <e-icon icon="${action.getAttribute('icon')}"></e-icon>
        </button>
      </e-tooltip>
    `);
    }
    createRuleEditButton(rule, disabled) {
        if (!rule.hasTemplate) {
            return;
        }
        return html `
      <e-tooltip content="${`${this.state.translations.editRuleTooltip}`}">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__rule_header_actions_edit"
          type="button"
          ?disabled=${disabled}
          @click=${event => this.events.onRuleEditClick(event, rule)}
          @blur="${event => this.events.onRuleFocusLeave(event, rule)}"
          @focus="${event => this.events.onRuleFocusEnter(event, rule)}"
        >
          <e-icon icon="pencil"></e-icon>
        </button>
      </e-tooltip>
    `;
    }
    createRuleRemoveButton(rule, disabled) {
        return html `
      <e-tooltip content="${`${this.state.translations.removeRuleTooltip}`}">
      <button
        class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__rule_header_actions_remove"
        type="button"
        ?disabled=${disabled}
        @click="${(event) => this.events.onRuleRemove(event, rule)}"
        @blur="${event => this.events.onRuleFocusLeave(event, rule)}"
        @focus="${event => this.events.onRuleFocusEnter(event, rule)}"
      >
        <e-icon icon="trash-o"></e-icon>
      </button>
    `;
    }
    createEditRuleHeader(group, rule) {
        if (!rule.isEditing) {
            return;
        }
        const ruleHeaderTitle = Object.keys(rule.fields).length ?
            this.createRuleEditorEditTitle() :
            this.createRuleEditorAddTitle();
        const blueprintLabel = Object.keys(rule.fields).length ?
            (!this.state.ruleEditorEditTitle ? rule.blueprint.label : '') :
            (!this.state.ruleEditorAddTitle ? rule.blueprint.label : '');
        return html `
      <div class="e-rule-builder__rule_header_summary">
        <strong>${ruleHeaderTitle}</strong>&nbsp;${blueprintLabel}
      </div>

      <div class="e-rule-builder__rule_header_actions">
        <button
          class="e-btn e-btn-onlyicon e-btn-borderless e-rule-builder__rule_header_actions_close"
          type="button"
          @click=${event => this.events.onRuleCancelClick(event, group, rule)}
          @focus="${event => this.events.onGroupFocusEnter(event, group)}"
          @blur="${event => this.events.onGroupFocusLeave(event, group)}"
        >
          <e-icon icon="close"></e-icon>
        </button>
      </div>
    `;
    }
    createRuleEditorAddTitle() {
        return !!this.state.ruleEditorAddTitle ? html `${this.state.ruleEditorAddTitle}` :
            html `<e-translation key="components.ruleBuilder.rule.editor.addTitle"></e-translation>`;
    }
    createRuleEditorEditTitle() {
        return !!this.state.ruleEditorEditTitle ? html `${this.state.ruleEditorEditTitle}` :
            html `<e-translation key="components.ruleBuilder.rule.editor.editTitle"></e-translation>`;
    }
    createRuleEditor(group, rule) {
        if (!rule.blueprint.template) {
            return;
        }
        const editorClasses = classNames('e-rule-builder__rule_editor', {
            'e-rule-builder__rule_editor-hidden': !rule.isEditing
        });
        const blueprintTemplate = rule.isEditing ? rule.blueprint.template : null;
        return html `
      <div class="${editorClasses}">
        <div class="e-rule-builder__rule_editor_content">
          ${blueprintTemplate}
        </div>
        ${this.createRuleEditorActions(rule, group)}
      </div>
    `;
    }
    createRuleEditorActions(rule, group) {
        if (!rule.isEditing) {
            return;
        }
        const applyButtonText = rule.isSaved ? 'components.ruleBuilder.rule.editor.apply' :
            'components.ruleBuilder.rule.editor.add';
        return html `
      <div class="e-rule-builder__rule_editor_actions">
        <button
          class="e-btn e-rule-builder__rule_editor_actions_cancel_button"
          @click=${event => this.events.onRuleCancelClick(event, group, rule)}
          @blur="${event => this.events.onRuleFocusLeave(event, rule)}"
          @focus="${event => this.events.onRuleFocusEnter(event, rule)}"
        >
          <e-translation key="components.ruleBuilder.rule.editor.cancel"></e-translation>
        </button>

        <button
          class="e-btn e-btn-primary e-rule-builder__rule_editor_actions_confirm_button"
          @click=${event => this.events.onRuleConfirmButtonClick(event, group, rule)}
          @blur="${event => this.events.onRuleFocusLeave(event, rule)}"
          @focus="${event => this.events.onRuleFocusEnter(event, rule)}"
        >
          <e-translation key="${applyButtonText}"></e-translation>
        </button>
      </div>
    `;
    }
    createAddExclusionButton() {
        if (!this.state.isExcludeEnabled || this.state.hasExcludedGroup) {
            return;
        }
        return html `
      <button
        class="e-btn e-rule-builder__add_exclusion_button"
        @click="${this.events.onAddExclusionButtonClick}"
      >
        <e-translation key="components.ruleBuilder.group.actions.addExclusion"></e-translation>
      </button>
    `;
    }
    createExclusionLabel() {
        if (!this.state.hasExcludedGroup) {
            return;
        }
        return html `
      <div class="e-rule-builder__exclusion_label">
        AND <span class="text-color-red">NOT</span>
      </div>
    `;
    }
    getEmptyStateLabels(isMaxDepthReached) {
        if (!!this.state.emptyStateHandler) {
            const { title, text } = this.state.emptyStateHandler({ isMaxDepthReached });
            return { title, text };
        }
        const emptyStateLabelKey = isMaxDepthReached ?
            'emptyStateWithoutSubgroup' :
            'emptyState';
        return {
            title: html `<e-translation key=${`components.ruleBuilder.group.${emptyStateLabelKey}.title`}></e-translation>`,
            text: html `<e-translation key=${`components.ruleBuilder.group.${emptyStateLabelKey}.text`}></e-translation>`
        };
    }
}
