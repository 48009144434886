import { RuleState } from './Rule';
import { getABCLabelFromIndex } from '../utils';
export class GroupState {
    constructor(group) {
        this.items = [];
        this.label = '';
        this._isContentVisible = true;
        this._parentGroup = null;
        this._hasHoverState = false;
        this._isDropdownOpen = false;
        this.label = group.label || '';
        this.relation = group.relation;
    }
    get isEditing() {
        return this.items.some(item => item.isEditing);
    }
    get isMainGroup() {
        return this._parentGroup === null;
    }
    get rules() {
        return this.items.filter(item => !('items' in item));
    }
    get ruleCount() {
        const items = [];
        const groups = [];
        this.items.forEach(item => {
            if ('items' in item) {
                groups.push(item);
            }
            else {
                items.push(item);
            }
        });
        if (groups.length === 0) {
            return items.length;
        }
        const numberOfItmes = groups.reduce((acc, group) => {
            return acc + group.ruleCount;
        }, items.length);
        return numberOfItmes;
    }
    getAutoLabel(defaultIndex = 0) {
        if (this.isMainGroup) {
            return getABCLabelFromIndex(defaultIndex);
        }
        const index = this._parentGroup.getGroupIndex(this);
        return getABCLabelFromIndex(index, this._parentGroup.getAutoLabel(defaultIndex));
    }
    addItem(item) {
        if (!item) {
            return;
        }
        item.parentGroup = this;
        this.items.push(item);
    }
    removeItem(item) {
        this.items = this.items.filter(currentItem => currentItem !== item);
    }
    remove() {
        this._parentGroup.removeItem(this);
    }
    getGroupIndex(group) {
        return this.items.filter((item) => 'items' in item).indexOf(group);
    }
    toDTO() {
        return {
            items: this.items.map(item => item.toDTO()),
            label: this.label,
            relation: this.relation
        };
    }
    static fromDTO(dto) {
        const group = new GroupState(dto);
        dto.items.forEach(item => {
            group.addItem(('items' in item) ?
                GroupState.fromDTO(item) :
                RuleState.fromDTO(item));
        });
        return group;
    }
    get depth() {
        if (!this._parentGroup) {
            return 1;
        }
        return this._parentGroup.depth + 1;
    }
    get isContentVisible() {
        return this._isContentVisible;
    }
    set isContentVisible(value) {
        this._isContentVisible = value;
    }
    get parentGroup() {
        return this._parentGroup;
    }
    set parentGroup(value) {
        this._parentGroup = value;
    }
    get hasHoverState() {
        return this._hasHoverState;
    }
    set hasHoverState(value) {
        if (this._parentGroup && value) {
            this._parentGroup.removeHoverState();
        }
        this._hasHoverState = value;
    }
    get isDropdownOpen() {
        return this._isDropdownOpen;
    }
    set isDropdownOpen(value) {
        this._isDropdownOpen = value;
    }
    removeHoverState() {
        this.hasHoverState = false;
        if (!this._parentGroup) {
            return;
        }
        this._parentGroup.removeHoverState();
    }
    get isEmpty() {
        return this.items.length === 0;
    }
}
