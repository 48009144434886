import { render } from 'uhtml';
import { HTMLCustomElement } from '@emartech/ui-framework-utils';

import { EColorpickerState } from './state';
import { EColorpickerEvents } from './events';
import { EColorpickerTemplate } from './template';
import { predefinedColors } from './colors';

import popupUtility from '../../../utils/popup';
import { normalizeColorWithHashmark } from '../../../utils/normalize-color';

class EColorpicker extends HTMLCustomElement {
  init() {
    this.refs = {
      wrapper: null,
      picker: null,
      popper: null,
      elementToAttach: null
    };

    this.utils = {};
    this.state = new EColorpickerState(this);
    this.events = new EColorpickerEvents(this);
    this.template = new EColorpickerTemplate(this);

    this.refs.wrapper = this.template.createWrapperElement();
  }

  connectedCallback() {
    this.render();
  }

  disconnectedCallback() {
    this.close();
  }

  static get observedAttributes() {
    return ['key'];
  }

  get key() {
    return this.state.key;
  }

  set key(value) {
    this.state.key = value;
  }

  get isOpened() {
    return this.state.isOpened;
  }

  close() {
    this.state.isOpened = false;
    document.body.removeEventListener('keydown', this.onKeyDown);

    if (!this.utils.popup?.isOpened) { return; }

    this.utils.popup.close();
    this.utils.popup = null;
  }

  open(element, color) {
    this.refs.elementToAttach = element;

    this.utils.popup = popupUtility.createPopup(this.refs.elementToAttach, this.refs.wrapper, {
      elementToFocusOnOpen: this.refs.colorinput,
      onAfterOpen: () => this.refs.colorinput.selectAll(),
      onEscPressClose: () => this.close(),
      onOutsideClickClose: () => this.close()
    });

    this.utils.popup.open();

    this.state.predefinedColors = predefinedColors.slice();
    this.state.color = normalizeColorWithHashmark(color);
    this.state.isShowingBaseColors = true;
    this.state.isOpened = true;

    document.body.addEventListener('keydown', this.events.onKeyDown);
  }

  render() {
    if (!this.isConnected) { return; }

    render(this.refs.picker, this.template.createElement());
  }
}

export default EColorpicker;
