class EDatagridColumnSettingsState {
  constructor(coreState) {
    this._coreState = coreState;
    this.requestRender = this._coreState.requestRender;

    this._settings = {};
    this._visibleSettingsPanels = {};
  }

  get userSettings() {
    const userSettings = {};

    Object.keys(this._settings).forEach(columnKey => {
      userSettings[columnKey] = {};

      Object.keys(this._settings[columnKey]).forEach(field => {
        if (this._settings[columnKey][field].user !== undefined) {
          userSettings[columnKey][field] = this._settings[columnKey][field].user;
        }
      });
    });

    return userSettings;
  }

  get activeSettings() {
    const activeSettings = {};

    Object.keys(this._settings).forEach(columnKey => {
      activeSettings[columnKey] = {};

      Object.keys(this._settings[columnKey]).forEach(field => {
        if (this._settings[columnKey][field].user !== undefined) {
          activeSettings[columnKey][field] = this._settings[columnKey][field].user;
        } else {
          activeSettings[columnKey][field] = this._settings[columnKey][field].default;
        }
      });
    });

    return activeSettings;
  }

  setDefaultSetting(contentKey, field, value, type = 'default') {
    this._settings = { ...this._settings };

    if (!this._settings[contentKey]) {
      this._settings[contentKey] = {};
    }

    this._settings[contentKey][field] = {
      ...this._settings[contentKey][field],
      [type]: value
    };

    this.requestRender();
  }

  setUserSetting(contentKey, field, value) {
    this.setDefaultSetting(contentKey, field, value, 'user');
    this.requestRender();
  }

  hasUserSettings() {
    return Object.values(this.userSettings).some(settings => Object.keys(settings).length);
  }

  clearUserSetting(contentKey) {
    Object.keys(this._settings[contentKey]).forEach(field => {
      this._settings[contentKey][field] = { default: this._settings[contentKey][field].default };
    });

    this.requestRender();
  }

  clearAllUserSettings() {
    Object.keys(this._settings).forEach(contentKey => {
      this.clearUserSetting(contentKey);
    });
    this.requestRender();
  }

  setFromLocalStorageData(localstorageData) {
    Object.keys(localstorageData).forEach(contentKey => {
      Object.keys(localstorageData[contentKey]).forEach(field => {
        this.setUserSetting(contentKey, field, localstorageData[contentKey][field]);
      });
    });
    this.requestRender();
  }

  setSettingsPanelVisibility(contentKey, value) {
    this._visibleSettingsPanels = {
      ...this._visibleSettingsPanels,
      [contentKey]: value
    };
  }

  isSettingsPanelVisible(contentKey) {
    return this._visibleSettingsPanels[contentKey];
  }

  get hasHiddenColumn() {
    return Object.values(this.activeSettings).some(settings => settings?.hidden);
  }

  get hasVisibleColumn() {
    if (!this._coreState.isHeaderVisible || !this._coreState.columnDefinitions.length) { return true; }

    return Object.values(this.activeSettings).some(settings => !settings?.hidden);
  }
}

export default coreState => new EDatagridColumnSettingsState(coreState);
