import classnames from 'clsx';
import { html } from 'uhtml';
import translator from '../../../js/utils/translator';
export class EStepBarTemplate {
    constructor(component) {
        this.state = component.state;
        this.events = component.events;
    }
    // Wrapper Element
    createWrapper() {
        return html.node `<nav
      class="e-step-bar__wrapper"
      aria-label="${this.state.label}"
    ></nav>`;
    }
    // Component Content
    createElement() {
        return html `
      <ol class="e-step-bar__steps">
        ${this.createPrevArrowStep()}
        ${this.state.steps.map((step, index) => this.createStepItem(step, index !== 0))}
        ${this.createNextArrowStep()}
      </ol>
    `;
    }
    createPrevArrowStep() {
        if (this.state.steps.length === 0) {
            return;
        }
        const isButtonDisabled = !this.state.isPreviousStepAvailable || !this.state.hasAvailableSelected;
        const classList = classnames('e-btn e-btn-onlyicon e-step-bar__button', {
            'e-step-bar__button-disabled': isButtonDisabled
        });
        return html `<li class="e-step-bar__step">
      <e-tooltip content="${translator.translate('components.stepBar.prevButton')}">
        <button
          class="${classList}"
          type="button"
          data-testid="step-prev-button"
          ?disabled="${isButtonDisabled}"
          @click="${this.events.onPrevButtonClick}"
        >
          <e-icon icon="caret-left" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    </li>`;
    }
    createNextArrowStep() {
        if (this.state.steps.length === 0) {
            return;
        }
        const isButtonDisabled = !this.state.isNextStepAvailable || !this.state.hasAvailableSelected;
        const classList = classnames('e-btn e-btn-onlyicon e-step-bar__button', {
            'e-step-bar__button-disabled': isButtonDisabled
        });
        return html `<li class="e-step-bar__step">
      <e-tooltip content="${translator.translate('components.stepBar.nextButton')}">
        <button
          class="${classList}"
          type="button"
          data-testid="step-next-button"
          ?disabled="${isButtonDisabled}"
          @click="${this.events.onNextButtonClick}"
        >
          <e-icon icon="caret-right" color="inherit"></e-icon>
        </button>
      </e-tooltip>
    </li>`;
    }
    createStepItem(step, hasSeparator) {
        return html `
      ${this.createStepSeparator(hasSeparator)}
      <li class="e-step-bar__step">
        ${this.createStepTooltipWrapper(step)}
      </li>
    `;
    }
    createStepSeparator(hasSeparator) {
        if (!hasSeparator) {
            return;
        }
        return html `<li class="e-step-bar__separator" role="presentation"></li>`;
    }
    createStepTooltipWrapper(step) {
        if (step.tooltip) {
            return html `
        <e-tooltip content="${step.tooltip}">
          ${this.createStepContent(step)}
        </e-tooltip>
      `;
        }
        return html `${this.createStepContent(step)}`;
    }
    createStepContent(step) {
        const classList = classnames('e-step-bar__button', {
            'e-step-bar__button-disabled': step.disabled,
            'e-step-bar__button-selected': step.selected
        });
        return html `
      <button
        class="${classList}"
        type="button"
        data-testid="step-button"
        aria-current="${step.selected ? 'step' : null}"
        ?disabled="${step.disabled}"
        @click=${() => this.events.onStepClick(step)}
      >
        ${this.createStepBullet(step)}
        <div class="e-step-bar__label">${step.label}</div>
        ${this.createStepBadge(step)}
      </button>
    `;
    }
    createStepBullet(step) {
        if (step.countExcluded && !step.icon) {
            return;
        }
        const bulletClasses = classnames('e-step-bar__bullet', {
            'e-step-bar__bullet-number': !step.icon,
            'e-step-bar__bullet-icon': step.icon
        });
        const index = this.state.stepsWithCount.indexOf(step);
        const hasLabel = index !== -1;
        const ariaLabel = hasLabel ? translator.translate('components.stepBar.stepLabel', [index + 1]) : null;
        return html `
      <div aria-label="${ariaLabel}" class="${bulletClasses}">
        ${this.createStepBulletIcon(step)}
        ${this.createStepBulletNumber(step)}
      </div>
    `;
    }
    createStepBulletNumber(step) {
        if (step.icon) {
            return;
        }
        const index = this.state.stepsWithCount.indexOf(step);
        return html `${index + 1}`;
    }
    createStepBulletIcon(step) {
        if (!step.icon) {
            return;
        }
        return html `<e-icon icon="${step.icon}"></e-icon>`;
    }
    createStepBadge(step) {
        if (!step.badge) {
            return;
        }
        return html `<div
      class="e-step-bar__badge"
      aria-label="${translator.translate('components.stepBar.badgeLabel')}"
    >${step.badge}</div>`;
    }
}
