import { HTMLCustomElement } from '@emartech/ui-framework-utils';

class EDatagridBulkToggle extends HTMLCustomElement {
  init() {
    this.state = {
      label: '',
      icon: ''
    };
  }

  connectedCallback() {
    this._dispatchUpdateEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('bulk-toggle.delete'));
  }

  static observedAttributes = ['label', 'icon'];

  set label(value) {
    this.state.label = value;
    this._dispatchUpdateEvent();
  }

  set icon(value) {
    this.state.icon = value;
    this._dispatchUpdateEvent();
  }

  _dispatchUpdateEvent() {
    this.dispatchEvent(new CustomEvent('bulk-toggle.update', {
      bubbles: true,
      detail: {
        label: this.state.label,
        icon: this.state.icon
      }
    }));
  }
}

export default EDatagridBulkToggle;
