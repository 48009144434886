import { html, svg } from 'uhtml';
import classNames from 'clsx';

const getProperSvgSize = (data) => {
  const size = data.size === 'table' ? 'small' : data.size;

  const radius = { small: 40, medium: 42, large: 45 };
  const circleBase = `e-spinner-circle e-spinner-circle-${size} e-spinner-svg__circle_base`;
  const circle1 = `e-spinner-circle e-spinner-circle-${size} e-spinner-svg__circle_01 e-spinner-svg__circle_01-${size}`;
  const circle2 = `e-spinner-circle e-spinner-circle-${size} e-spinner-svg__circle_02 e-spinner-svg__circle_02-${size}`;
  const circle3 = `e-spinner-circle e-spinner-circle-${size} e-spinner-svg__circle_03 e-spinner-svg__circle_03-${size}`;

  const svgClass = `e-spinner-svg e-spinner-svg-${size}`;

  return svg`
    <svg class="${svgClass}" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle class=${circleBase} cx="50" cy="50" r=${radius[size]}/>
      <circle class=${circle1} cx="50" cy="50" r=${radius[size]}/>
      <circle class=${circle2} cx="50" cy="50" r=${radius[size]}/>
      <circle class=${circle3} cx="50" cy="50" r=${radius[size]}/>
    </svg>
  `;
};

const getSpinnerText = (data) => {
  if (!data.text) { return; }

  return html`<span class="e-spinner__text">${data.text}</span>`;
};

export default (data) => {
  const classnames = classNames('e-spinner', {
    [`e-spinner-${data.size}`]: data.size === 'table',
    'e-spinner-fullscreen': data.fullscreen
  });

  return html`
    <div class="${classnames}">
      ${getProperSvgSize(data)}
      ${getSpinnerText(data)}
    </div>
  `;
};
