import { html } from 'uhtml';

class ETimepickerAmpmTemplate {
  constructor(component) {
    this._element = component;
    this._events = component._events;
    this._state = component._state;
  }

  init() {
    this._element.classList.add('e-timepicker-input', 'e-input', 'e-timepicker__input');
    this._element.setAttribute('tabindex', '0');
  }

  createElement() {
    return html`
      <span class="e-timepicker__input_value">${this._state.isAm ? 'AM' : 'PM'}</span>
      <div class="e-timepicker-input__actions">
        <button
          @click=${this._events.onArrowClick}
          class="e-btn e-btn-borderless e-btn-onlyicon e-timepicker__arrow e-timepicker__arrow-up"
          type="button"
          ?disabled=${this._state.disabled}
        >
          <e-icon icon="e-move-up"></e-icon>
        </button>
        <button
          @click=${this._events.onArrowClick}
          class="e-btn e-btn-borderless e-btn-onlyicon e-timepicker__arrow e-timepicker__arrow-down"
          type="button"
          ?disabled=${this._state.disabled}
        >
          <e-icon icon="e-move-down"></e-icon>
        </button>
      </div>
    `;
  }
}

export default ETimepickerAmpmTemplate;
