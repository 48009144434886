import { TRACK_EVENT_MESSAGE } from './constants.js';
import matomoTracker from './matomo.js';
import getFeatureServiceUtil from '../feature-service/index.js';
import manifest from '../../../../dist/app.js';
import matomoConfig from './matomo.config.js';
import JSLogger from '../jslogger/index.js';
const logger = new JSLogger('Matomo');

class TrackerService {
  _isMatomoTrackingEnabled = true;
  _PerformanceObserverClass = null;
  _performanceObserver = null;

  constructor(getFeatureService = getFeatureServiceUtil, PerformanceObserverClass = PerformanceObserver) {
    this._getFeatureService = getFeatureService;
    this._PerformanceObserverClass = PerformanceObserverClass;
  }

  init(matomoTrackerInstance = matomoTracker, suiteUrl = manifest.suiteUrl, pubSubUrl = manifest.pubSubUrl) {
    return new Promise(resolve => {
      this._getFeatureService().then(featureService => {
        this._isMatomoTrackingEnabled = featureService.isOff('disable_matomo_tracking');

        if (this._isMatomoTrackingEnabled) {
          this._matomo = matomoTrackerInstance;
        }

        this._suiteUrl = suiteUrl;
        this._pubSubUrl = pubSubUrl;

        this._subscribe();

        resolve();
      });
    });
  }

  _subscribe() {
    const performanceObserverCallback = (list) => {
      list.getEntries().forEach((entry) => {
        if (entry.entryType !== 'navigation') { return; }

        this._trackLoad(entry);
        this._performanceObserver.disconnect();
      });
    };

    this._performanceObserver = new this._PerformanceObserverClass(performanceObserverCallback);
    this._performanceObserver.observe({ entryTypes: ['navigation'] });

    window.addEventListener('message', event => {
      if (!event.data) { return; }

      if (event.data.message === TRACK_EVENT_MESSAGE) {
        this._trackEvent(event.data.data.details);
      }
    });
  }

  _trackLoad(perfData) {
    if (window !== window.top) { return; }

    if (!this._isLogAllowed(window.location.hostname, window.navigator.userAgent)) {
      logger.warn('logging is not allowed for this domain');
      return;
    }

    if (this._isMatomoTrackingEnabled) {
      const screenProps = {
        width: window.screen.width,
        height: window.screen.height,
        realWidth: window.screen.width * window.devicePixelRatio,
        realHeight: window.screen.height * window.devicePixelRatio
      };

      const matomoDetails = {
        res: `${screenProps.width}x${screenProps.height} (${screenProps.realWidth}x${screenProps.realHeight})`,
        pf_net: perfData.connectEnd - perfData.fetchStart,
        pf_srv: perfData.responseStart - perfData.requestStart,
        pf_tfr: perfData.responseEnd - perfData.responseStart,
        pf_dm1: perfData.domContentLoadedEventEnd - perfData.domContentLoadedEventStart,
        pf_dm2: perfData.domComplete - perfData.domInteractive,
        pf_onl: perfData.loadEventEnd - perfData.loadEventStart
      };

      this._sendDataToMatomo(matomoDetails, window, 'Page Loaded');
    }
  }

  _trackEvent(details) {
    if (!window.e.config) { return; }

    if (!this._isLogAllowed(window.location.hostname, window.navigator.userAgent)) {
      logger.warn('logging is not allowed for this domain');
      return;
    }

    this._sendDataToPubSub(details, window);

    if (this._isMatomoTrackingEnabled) {
      const matomoDetails = {
        e_c: details.category,
        e_a: details.action,
        e_n: details.label
      };

      this._sendDataToMatomo(matomoDetails, window);
    }
  }

  _sendDataToMatomo(details, window, eventType = 'User Interaction') {
    if (!this._matomo) {
      logger.warn('could not find matomo instance', { eventType });
      return;
    }

    const sanitizedUrl = this._sanitizeUrl(window.location.href);

    this._matomo.track({
      uid: window.e.config ? `${window.e.config.customerId}` : undefined,
      action_name: `${eventType}/${document.title}`,
      url: sanitizedUrl,
      ...details
    });
  }

  _sendDataToPubSub(details, window) {
    var detailsJSON = JSON.stringify({
      ...details,
      customer_id: window.e.config.customerId,
      session_id: window.e.config.sessionId
    });

    try {
      if (window.navigator.sendBeacon) {
        window.navigator.sendBeacon(this._pubSubUrl, detailsJSON);
      } else {
        const client = new XMLHttpRequest();
        client.open('POST', this._pubSubUrl, false);
        client.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
        client.send(detailsJSON);
      }
    } catch (error) {} // eslint-disable-line no-empty
  };

  _sanitizeUrl(url) {
    const allowList = matomoConfig.allowList;
    const urlObject = new URL(url);
    const paramsObject = urlObject.searchParams;

    const filteredParams = Array.from(paramsObject).reduce((parameters, parameter) => {
      if (!allowList.includes(parameter[0])) { return parameters; }

      parameters.push(`${parameter[0]}=${parameter[1]}`);
      return parameters;
    }, []).join('&');

    const path = this._suiteUrl + urlObject.pathname;

    return [path, filteredParams].filter(part => part).join('?');
  }

  _isLogAllowed(hostname, useragent) {
    const allowedDomains = ['emarsys.com', 'emarsys.net'];
    const isPingdom = useragent.includes('PingdomTMS');
    const isLoggingAllowed = allowedDomains.some(domain => hostname.includes(domain));

    return isLoggingAllowed && !isPingdom;
  }
}

export default TrackerService;
