import {
  HTMLCustomElement, convertAttributeToBoolean, queryKeyboardFocusableAll
} from '@emartech/ui-framework-utils';

const devices = {
  iphone: {
    name: 'iphone_6',
    image: {
      width: 439,
      height: 892
    },
    screen: {
      offsetX: 32,
      offsetY: 111
    }
  },
  computer: {
    name: 'computer',
    image: {
      width: 1400,
      height: 1066
    },
    screen: {
      offsetX: 61,
      offsetY: 61
    }
  },
  android: {
    name: 'android',
    image: {
      width: 414,
      height: 802
    },
    screen: {
      offsetX: 28,
      offsetY: 88
    }
  }
};

class EDevice extends HTMLCustomElement {
  init() {
    super.watchForAddedChildNodes();
    this._DEFAULT_TYPE = 'iphone';
    this._type = devices[this._DEFAULT_TYPE];
    this._scaleType = 'width';
    this._size = false;
    this._isFocusDisabled = false;

    this._dom = {};
    this._dom.deviceContainer = document.createElement('div');
    this._dom.deviceContainer.className = 'e-device__frame';
    this._dom.content = null;

    this._render();
  }

  connectedCallback() {
    const container = this.querySelector('.e-device__frame');
    if (container) {
      this._dom.deviceContainer = container;
    } else {
      this.appendChild(this._dom.deviceContainer);
    }
    this._setClassName();
    this.appendChild(this._dom.deviceContainer);
    this.childrenChangedCallback([].map.call(this.children, child => [child]));
  }

  disconnectedCallback() {
    this.removeChild(this._dom.deviceContainer);
  }

  childrenChangedCallback(nodes) {
    const excludedNode = this._dom.deviceContainer;
    const foundElementList = nodes.filter(node => {
      return node.length && node[0].nodeType !== 3 && excludedNode !== node[0];
    });

    const foundElement = foundElementList.length ? foundElementList[0][0] : null;

    if (!foundElement) {
      return;
    }

    this._dom.content = foundElement;
    this._dom.content.classList.add('e-device__content');
    this._render();
  }

  static get observedAttributes() {
    return ['width', 'height', 'type', 'focus-disabled'];
  }

  set width(value) {
    this._scaleType = 'width';
    this._size = value;
    this._render();
  }

  set height(value) {
    this._scaleType = 'height';
    this._size = value;
    this._render();
  }

  set type(value) {
    const type = devices.hasOwnProperty(value) ? value : this._DEFAULT_TYPE;
    this._type = devices[type];
    this._setClassName();
    this._render();
  }

  set focusDisabled(value) {
    this._isFocusDisabled = convertAttributeToBoolean(value);
    this._render();
  }

  _calculateSize(value) {
    return value || this._type.image[this._scaleType];
  }

  _render() {
    const scaleRatio = this._calculateSize(this._size) / this._type.image[this._scaleType];

    this._dom.deviceContainer.style.width = Math.floor(scaleRatio * this._type.image.width) + 'px';
    this._dom.deviceContainer.style.height = Math.floor(scaleRatio * this._type.image.height) + 'px';

    if (!this._dom.content) {
      return;
    }

    const scale = 'scale(' + scaleRatio + ')';
    const translateX = 'translateX(' + this._type.screen.offsetX + 'px)';
    const translateY = 'translateY(' + this._type.screen.offsetY + 'px)';

    this._dom.content.style.transform = [scale, translateX, translateY].join(' ');
    this._updateTabIndexes();
  }

  _setClassName() {
    this.className = 'e-device e-device-' + this._type.name;
  }

  _updateTabIndexes() {
    if (this._isFocusDisabled) {
      const focusableElements = queryKeyboardFocusableAll(this._dom.content);

      for (const element of focusableElements) {
        element.setAttribute('data-focus-disabled', '');
        element.setAttribute('tabindex', '-1');
      }
    } else {
      const focusDisabledElements = this._dom.content.querySelectorAll('[data-focus-disabled]');

      for (const element of focusDisabledElements) {
        element.removeAttribute('data-focus-disabled');
        element.removeAttribute('tabindex');
      }
    }
  }
}

export default EDevice;
