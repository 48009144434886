import { HTMLCustomElement, formatNumber } from '@emartech/ui-framework-utils';
import configStore from '../../utils/config-store';

class ENumeric extends HTMLCustomElement {
  init() {
    this._state = {
      style: 'decimal',
      precision: 0,
      trimFractionZeros: false,
      currency: 'EUR',
      humanize: '',
      language: configStore.config.language,
      value: null
    };

    configStore.subscribe(this._configChangeCallback.bind(this));
  }

  connectedCallback() {
    this._render();
  }

  static get observedAttributes() {
    return ['value', 'precision', 'trim-fraction-zeros', 'type', 'currency', 'humanize'];
  }

  set value(value) {
    this._state.value = value === undefined ? null : value;
    this._render();
  }

  set precision(value) {
    this._state.precision = value;
    this._render();
  }

  set trimFractionZeros(value) {
    this._state.trimFractionZeros = super._convertAttributeToBoolean(value);
    this._render();
  }

  set type(value) {
    this._state.style = value === 'number' ? 'decimal' : value;
    this._render();
  }

  set currency(value) {
    this._state.currency = value;
    this._render();
  }

  set humanize(value) {
    this._state.humanize = value;
    this._render();
  }

  _configChangeCallback() {
    this._state.language = configStore.config.language;
    this._render();
  }

  _render() {
    if (!this.isConnected) { return; }

    this.textContent = formatNumber(this._state, this._state.value);
  }
}

export default ENumeric;
