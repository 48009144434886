import { TopHashChangeClient } from './client';
import { TopHashChangeService } from './service';

const client = new TopHashChangeClient(window);
const service = new TopHashChangeService(window);

export const topHashChange = {

  addEventListener(eventListener) {
    return client.addEventListener(eventListener);
  },

  removeEventListener(eventListener) {
    return client.removeEventListener(eventListener);
  },

  subscribe() {
    service.subscribe();
  }

};
