import uuid from '../../../utils/uuid';
export class ERuleRelationState {
    constructor() {
        this._uuid = uuid();
    }
    get description() {
        return this._description;
    }
    set description(value) {
        this._description = value;
    }
    get label() {
        return this._label;
    }
    set label(value) {
        this._label = value;
    }
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
    }
    get icon() {
        return this._icon;
    }
    set icon(value) {
        this._icon = value;
    }
    get uuid() {
        return this._uuid;
    }
}
