import { html } from 'uhtml';
import translator from '../../../../utils/translator';

const options = [10, 25, 50, 100];

export default class EDatagridFooterTemplate {
  constructor(state, events) {
    this._state = state;
    this._events = events;
  }

  create() {
    if (!this._state.paginationState.isPaginationVisible) { return; }

    return html`
      <div class="e-datagrid__footer">
        <div class="e-datagrid__page_size">
          ${this._selectElement()}
        </div>
        ${this._paginationElement()}
      </div>
    `;
  }

  _selectElement() {
    if (this._state.paginationState.pageSizeHidden) { return; }

    return html`
      <e-translation key="components.datagrid.pageSizeSelector" part="0"></e-translation>
      <span>&nbsp;</span>
      <e-select
        @change="${this._events.onPageSizeSelectChange}"
        disable-min-width="true"
      >
        ${options.map(option => this._optionElement(option))}
      </e-select>
      <span>&nbsp;</span>
      <e-translation key="components.datagrid.pageSizeSelector" part="1"></e-translation>
    `;
  };

  _optionElement(option) {
    return html`
      <e-select-option
        .value="${option}"
        ?selected=${option === this._state.paginationState.pageSize}
      >${option}</e-select-option>
    `;
  };

  _paginationElement() {
    return html`
      <div class="e-datagrid__pagination">
        ${this._itemCounterElement()}
        <e-pagination
          loading=${!this._state.isSetUp && this._state.serverState.isLoading}
          max-page="${this._state.paginationState.pageCount}"
          current-page="${this._state.paginationState.pageNumber}"
          label="${translator.translate('components.datagrid.paginationLabel')}"
          @change="${this._events.onPaginationChange}">
        </e-pagination>
      </div>
    `;
  };

  _itemCounterElement() {
    if (!this._state.isSetUp) { return; }

    const itemCount = this._state.paginationState.itemCount;
    const filteredItemCount = this._state.filterState.filteredItemCount;
    const lastVisibleItem = this._state.paginationState.pageNumber * this._state.paginationState.pageSize;
    const firstVisibleItem = filteredItemCount ? lastVisibleItem - this._state.paginationState.pageSize + 1 : 0;
    const isFiltered = itemCount && filteredItemCount !== itemCount;
    const translationKey = `components.datagrid.itemCounter.${isFiltered ? 'filtered' : 'nonFiltered'}`;

    const totalCountPartial = isFiltered ? [
      html`<e-translation key=${translationKey} part="3"></e-translation>`,
      html`<e-numeric class="js-totalContentCount" value="${itemCount}"></e-numeric>`,
      html`<e-translation key=${translationKey} part="4"></e-translation>`
    ] : null;

    return html`
      <div class="e-datagrid__counter"><e-numeric class="js-firstVisibleItem" value=${firstVisibleItem}>
        </e-numeric><e-translation key=${translationKey} part="1">
        </e-translation><e-numeric class="js-lastVisibleItem" value=${Math.min(lastVisibleItem, filteredItemCount)}>
        </e-numeric><e-translation key=${translationKey} part="2">
        </e-translation><e-numeric class="js-contentCount" value=${filteredItemCount}>
        </e-numeric>${totalCountPartial}
      </div>
    `;
  }
}
