import uuid from '../../js/utils/uuid/index.js';

export class ECheckboxState {
  constructor(component) {
    this.render = component.render.bind(component);

    this._id = uuid();
    this._label = null;
    this._srLabel = null;
    this._helper = null;
    this._name = null;
    this._value = null;
    this._isChecked = false;
    this._isIndeterminate = false;
    this._isDisabled = false;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
    this.render();
  }

  get label() {
    return this._label;
  }

  set label(value) {
    this._label = value;
    this.render();
  }

  get helper() {
    return this._helper;
  }

  set helper(value) {
    this._helper = value;
    this.render();
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
    this.render();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.render();
  }

  get isChecked() {
    return this._isChecked;
  }

  set isChecked(value) {
    this._isChecked = value;

    if (this._isChecked) {
      this._isIndeterminate = false;
    }

    this.render();
  }

  get isIndeterminate() {
    return this._isIndeterminate;
  }

  set isIndeterminate(value) {
    this._isIndeterminate = value;

    if (this._isIndeterminate) {
      this._isChecked = false;
    }

    this.render();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  set isDisabled(value) {
    this._isDisabled = value;
    this.render();
  }

  get srLabel() {
    return this._srLabel;
  }

  set srLabel(value) {
    this._srLabel = value;
    this.render();
  }
}

export default ECheckboxState;
