export class ERulelistItemState {
  #requestRender;
  #reflectBooleanAttribute;

  #isOpen = false;
  #isReadOnly = false;
  #isToggling = false;
  #isSlotVisible = {};

  constructor(component) {
    this.#requestRender = component.requestRender.bind(component);
    this.#reflectBooleanAttribute = component.reflectBooleanAttribute.bind(component);
  }

  get isOpen() {
    return this.#isOpen;
  }

  set isOpen(value) {
    this.#isOpen = value;
    this.#reflectBooleanAttribute('open', value);
    this.#requestRender();
  }

  get isReadOnly() {
    return this.#isReadOnly;
  }

  set isReadOnly(value) {
    this.#isReadOnly = value;
    this.#reflectBooleanAttribute('readonly', value);
    this.#requestRender();
  }

  get isToggling() {
    return this.#isToggling;
  }

  set isToggling(value) {
    this.#isToggling = value;
    this.#reflectBooleanAttribute('toggling', value);
  }

  get isSlotVisible() {
    return this.#isSlotVisible;
  }

  setSlotVisibility(slotName, isVisible) {
    this.#isSlotVisible[slotName] = isVisible;
    this.#requestRender();
  }

  isSummaryVisible() {
    const conditions = [
      this.#isSlotVisible.summary,
      this.#isSlotVisible['summary-actions']
    ];

    return !this.isOpen && conditions.some(condition => condition);
  }

  isEditVisible() {
    const conditions = [
      this.#isSlotVisible.header,
      this.#isSlotVisible.content,
      this.#isSlotVisible.footer,
      this.#isSlotVisible['footer-actions']
    ];

    return this.isOpen && conditions.some(condition => condition);
  }

  isRelationVisible() {
    return this.#isSlotVisible.relation;
  }
}

export default ERulelistItemState;
