import { html } from 'uhtml';

export default (state, customElements) => {
  const className = `e-device-content e-device-content-${state.type}`;

  const content = state.editable ? undefined : html`
    <div class="e-device-content__fromname">${state.fromName}</div>
    <div class="e-device-content__subject">${state.subject}</div>
    <div class="e-device-content__preheader"><e-ellipsis content="${state.preheader}" row="2"></e-ellipsis></div>
  `;

  const notification = html.node`<div class="e-device-content__notification">${content}</div>`;

  customElements.forEach(customElement => {
    notification.appendChild(customElement[0]);
  });

  return html`
    <div class="${className}">
      ${notification}
      <div class="e-device-content__time">${state.time}</div>
      <div class="e-device-content__date">${state.date}</div>
      <div class="e-device-content__timeago">${state.timeago}</div>
    </div>
  `;
};
