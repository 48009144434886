import { html } from 'uhtml';
import classNames from 'clsx';

export default (state, events, refs) => {
  const buttonClass = classNames('e-btn', {
    'e-button-disabled': state.disabled
  });
  const inputClass = classNames('e-input', {
    'e-input-error': state.invalid,
    'e-input-disabled': state.disabled
  });
  const fileElement = html`<input
    ref=${(node) => refs.fileElement = node}
    onchange="${events.fileInputChange}"
    type="file"
    class="e-hidden"
    maxlength="${state.maxlength}"
    accept="${state.accept}"
    name="${state.name}"
    value="${state.inputValue}"
    ?disabled="${state.disabled}">`;

  const openFileDialog = () => refs.fileElement.click();
  return { fileElement, inputClass, buttonClass, openFileDialog };
};
