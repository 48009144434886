import { messages } from './constants.js';

export default (options, type, global, top) => {
  if (!options.headline || !options.content) {
    console.error('Required parameters: headline, content'); // eslint-disable-line no-console
    return;
  }

  global.addEventListener('message', function messageHandler(event) {
    if (event.data.message === messages.RESPONSE) {
      if (options[event.data.data.response] && options[event.data.data.response].callback) {
        options[event.data.data.response].callback();
      }
      global.removeEventListener('message', messageHandler);
    }
  });

  const optionsWithoutCallbacks = JSON.parse(JSON.stringify(options));
  top.postMessage({
    message: messages.OPEN,
    data: {
      type,
      options: optionsWithoutCallbacks
    }
  }, '*');
};
