import { html } from 'uhtml';
import classNames from 'clsx';
import calendarUtilities from './calendar-utilities';
import translator from '../../../utils/translator/index.js';

const { isBefore, isAfter, isEqual, startOfMonth, endOfMonth } = calendarUtilities;

class Template {
  constructor(state, emitter) {
    this._state = state;
    this._emitter = emitter;
    this._handleDayHover = this._handleDayHover.bind(this);
    this._handleDayLeave = this._handleDayLeave.bind(this);
  }

  createElement() {
    const tableWrapperClasses = classNames('e-datetime-calendar__table_wrapper', {
      'e-datetime-calendar__table_wrapper-has-footer': !this._state.todayHidden
    });

    const tableAriaLabel = `${this._getCurrentMonth().formattedName} ${this._getCurrentYear().value}`;
    return html`
      <nav
        class="e-datetime-calendar__navigation"
        aria-label="${translator.translate('components.datetime.calendar.nav')}"
      >
        <e-tooltip content="${translator.translate('components.datetime.calendar.prevButton')}">
          <button
            data-testid="datetime-calendar.prev-button"
            type="button"
            class="e-btn e-btn-onlyicon e-btn-borderless e-datetime-calendar__prev_month"
            ?disabled=${this._state.disabled}
            @click=${this._handlePreviousButtonClick.bind(this)}
          >
            <e-icon icon="e-move-left"></e-icon>
          </button>
        </e-tooltip>
        <div class="e-datetime-calendar__selects">
          <e-select
            class="e-datetime-calendar__select"
            borderless
            search-hidden
            ?disabled=${this._state.disabled}
            @change=${this._handleOnMonthChange.bind(this)}
            .items=${this._calculateMonthOptions()}
          ></e-select>
          <e-select
            class="e-datetime-calendar__select"
            borderless
            search-hidden
            ?disabled=${this._state.disabled}
            @change=${this._handleOnYearChange.bind(this)}
            .items=${this._calculateYearOptions()}
          ></e-select>
        </div>
        <e-tooltip content="${translator.translate('components.datetime.calendar.nextButton')}">
          <button
            data-testid="datetime-calendar.next-button"
            type="button"
            class="e-btn e-btn-onlyicon e-btn-borderless e-datetime-calendar__next_month"
            ?disabled=${this._state.disabled}
            @click=${this._handleNextButtonClick.bind(this)}
          >
            <e-icon icon="e-move-right"></e-icon>
          </button>
        </e-tooltip>
      </nav>

      <div
      class="${tableWrapperClasses}"
      tabindex="0"
      @keydown=${this._handleCalendarKeypress.bind(this)}
      >
        <table role="grid" aria-label="${tableAriaLabel}">
          <thead>
            <tr class="e-datetime-calendar__weekdays">
              ${this._state.dayNames.map(day => html`
              <th class="e-datetime-calendar__weekday">${day}</th>`)}
            </tr>
          </thead>
          <tbody @click=${this._handleCalendarClick.bind(this)}>
            ${this._buildWeeks()}
          </tbody>
        </table>
      </div>
      ${this._calendarFooter()}
    `;
  }

  _buildWeeks() {
    return this._state.daysOfMonth.map((week) => this._buildWeek(week));
  }

  _buildWeek(week) {
    return html`
      <tr>${week.map(day => this._buildDay(day))}</tr>`;
  }

  _buildDay(day) {
    const dayCellClasses = this._getDayCellClasses(day);

    return html`
      <td
        data-date=${day.date}
        class="${dayCellClasses}"
        @mouseenter="${this._handleDayHover}"
        @mouseleave="${this._handleDayLeave}"
        aria-selected="${this._isDaySelected(day)}"
        aria-disabled="${this._isDayDisabled(day)}"
      >
        ${this._buildDayLabel(day)}
      </td>
    `;
  }

  _buildDayLabel(day) {
    if (!day.isCurrentMonth) { return; }

    const dayClasses = this._getDayClasses(day);

    return html`
      <div class="${dayClasses}">
        <div class="e-datetime-calendar__day_label">${day.number}</div>
      </div>
    `;
  }

  _getDayCellClasses(day) {
    const isDisabled = this._isDayDisabled(day);
    const isEmpty = !day.isCurrentMonth;

    const classes = ['e-datetime-calendar__day_cell'];
    if (isDisabled) {
      classes.push('e-datetime-calendar__day_cell-disabled');
    }
    if (isEmpty) {
      classes.push('e-datetime-calendar__day_cell-empty');
    }

    if (this._state.disabled) {
      classes.push('e-datetime-calendar__day_cell-inactive');
    }
    return classes.join(' ');
  }

  _isDayDisabled(day) {
    return isBefore(day.date, this._state.minDate) || isAfter(day.date, this._state.maxDate);
  }

  _getDayClasses(day) {
    const isToday = day.isCurrentMonth && day.isToday && !this._state.todayHidden;
    const { start, end } = this._getRange();
    const isActive = isEqual(day.date, calendarUtilities.getDateString(this._state.activeDate));
    const isSelected = this._isDaySelected(day);
    const isInsideRange = isAfter(day.date, start) && isBefore(day.date, end);
    const isRangeStart = isEqual(day.date, start);
    const isRangeEnd = isEqual(day.date, end);
    const isHoveredRange = this._state.hoveredDate && (isInsideRange || isRangeStart || isRangeEnd);
    const isStartOfMonth = isEqual(day.date, calendarUtilities.getDateString(startOfMonth(day.date)));
    const isEndOfMonth = isEqual(day.date, calendarUtilities.getDateString(endOfMonth(day.date)));

    const classes = classNames('e-datetime-calendar__day', {
      'e-datetime-calendar__day-today': isToday,
      'e-datetime-calendar__day-active': isActive,
      'e-datetime-calendar__day-inactive': this._state.disabled,
      'e-datetime-calendar__day-selected': isSelected,
      'e-datetime-calendar__day-inside_range': isInsideRange,
      'e-datetime-calendar__day-range_start': isRangeStart,
      'e-datetime-calendar__day-range_end': isRangeEnd,
      'e-datetime-calendar__day-hovered_range': isHoveredRange,
      'e-datetime-calendar__day-start_of_month': isStartOfMonth && day.isCurrentMonth,
      'e-datetime-calendar__day-end_of_month': isEndOfMonth && day.isCurrentMonth
    });

    return classes;
  }

  _isDaySelected(day) {
    return !this._isDayDisabled(day) && day.isCurrentMonth && day.isSelected;
  }

  _getRange() {
    const rangeOneEnd = this._state.hoveredDate || this._state.selectedDate;
    if (!rangeOneEnd || !this._state.rangeOtherEnd) {
      return { start: null, end: null };
    }
    const isBeginningOfRange = isBefore(rangeOneEnd, this._state.rangeOtherEnd);
    return isBeginningOfRange ?
      { start: rangeOneEnd, end: this._state.rangeOtherEnd } :
      { start: this._state.rangeOtherEnd, end: rangeOneEnd };
  }

  _calculateMonthOptions() {
    return this._state.monthList.map((month, index) => ({
      type: 'option',
      value: index,
      selected: month.isCurrent,
      content: month.formattedName
    }));
  }

  _getCurrentMonth() {
    return this._state.monthList.find(month => month.isCurrent);
  }

  _calculateYearOptions() {
    return this._state.yearList.map(year => ({
      type: 'option',
      value: year.value,
      selected: year.isCurrent,
      content: year.value
    }));
  }

  _getCurrentYear() {
    return this._state.yearList.find(year => year.isCurrent);
  }

  _handlePreviousButtonClick() {
    this._emitter.emit('onPreviousButtonClick');
  }

  _handleNextButtonClick() {
    this._emitter.emit('onNextButtonClick');
  }

  _handleOnMonthChange(event) {
    this._emitter.emit('onMonthChange', event.detail.value);
  }

  _handleOnYearChange(event) {
    this._emitter.emit('onYearChange', event.detail.value);
  }

  _handleShowTodayButtonClick() {
    this._emitter.emit('onShowTodayClick');
  }

  _handleCalendarClick(event) {
    this._emitter.emit('onSelectDate', calendarUtilities.getDate(event.target.dataset.date));
  }

  _handleDayHover(event) {
    if (event.target.classList.contains('e-datetime-calendar__day-disabled')) {
      return;
    }
    this._emitter.emit('dayHover', event.target.dataset.date);
  }

  _handleDayLeave() {
    this._emitter.emit('dayLeave');
  }

  _handleCalendarKeypress(event) {
    this._emitter.emit('onCalendarKeypress', event);
  }

  _calendarFooter() {
    if (this._state.todayHidden) { return; }

    return html`
      <div class="e-datetime-calendar__footer">
        <button
        type="button"
        class="e-btn e-btn-borderless"
        ?disabled=${this._state.disabled}
        @click=${this._handleShowTodayButtonClick.bind(this)}>
          <e-icon icon="e-reset"></e-icon>
          <e-translation key="components.datetime.showToday"></e-translation>
        </button>
      </div>`;
  }
}

export default Template;
