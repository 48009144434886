import { Blueprint } from './Blueprint';
export class RuleState {
    constructor() {
        this.fields = null;
        this.fieldProperties = null;
        this._errorState = false;
        this._isEditing = false;
        this._parentGroup = null;
        this._isSaved = true;
        this._hasHoverState = false;
    }
    remove() {
        this._parentGroup.removeItem(this);
    }
    toDTO() {
        return {
            blueprint: this.blueprint ? this.blueprint.toDTO() : { name: this.blueprintName },
            fields: this.fields
        };
    }
    validate() {
        if (this.blueprint.validation === undefined || !this.blueprint.validation) {
            return;
        }
        if (!this.fields || (this.fields && Object.keys(this.fields).length === 0)) {
            return;
        }
        this._errorState = !this.blueprint.validation(this);
    }
    static fromDTO(dto) {
        const newRule = new RuleState();
        newRule.blueprintName = dto.blueprint.name;
        newRule.fields = dto.fields;
        newRule.fieldProperties = {};
        return newRule;
    }
    static fromBlueprint(blueprint, blueprintValidation) {
        const newRule = new RuleState();
        newRule.blueprintName = blueprint.name;
        newRule.blueprint = new Blueprint(blueprint);
        newRule.blueprint.validation = blueprintValidation;
        newRule.fields = {};
        newRule.fieldProperties = {};
        newRule.isEditing = !!blueprint.template;
        newRule.isSaved = false;
        return newRule;
    }
    get hasTemplate() {
        return this.blueprint && this.blueprint.template !== null;
    }
    get isEditing() {
        return this._isEditing;
    }
    set isEditing(value) {
        this._isEditing = value && this.hasTemplate;
    }
    get isSaved() {
        return this._isSaved;
    }
    set isSaved(value) {
        this._isSaved = value;
    }
    get parentGroup() {
        return this._parentGroup;
    }
    set parentGroup(value) {
        this._parentGroup = value;
    }
    get hasHoverState() {
        return this._hasHoverState;
    }
    set hasHoverState(value) {
        this._hasHoverState = value;
    }
    get errorState() {
        return this._errorState;
    }
}
