import EDatagridColumn from '../datagrid-column';

class EDatagridColumnList extends EDatagridColumn {
  init() {
    super.init();
    Object.assign(this._state, {
      type: 'list',
      renderHtml: true
    });
  }
}

export default EDatagridColumnList;
