import { html } from 'uhtml';
import classNames from 'clsx';
import translator from '../../js/utils/translator/index.js';

export class EPaginationTemplate {
  #createReference;
  constructor(component) {
    this.#createReference = component.createReference.bind(component);
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    const isPrevDisabled = this.state.currentPage === 1 || this.state.loading;
    const prevButtonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
      'e-btn-disabled': isPrevDisabled
    });
    const prevClassName = classNames('e-pagination__item', 'e-pagination__item-prev', {
      'e-pagination__item-disabled': isPrevDisabled
    }, prevButtonClassList);

    const isNextDisabled = this.state.currentPage === this.state.maxPage || this.state.loading;
    const nextButtonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
      'e-btn-disabled': isNextDisabled
    });
    const nextClassName = classNames('e-pagination__item', 'e-pagination__item-next', {
      'e-pagination__item-disabled': isNextDisabled
    }, nextButtonClassList);

    const defaultFunction = event => event.preventDefault();
    const prevEvent = isPrevDisabled ?
      defaultFunction :
      (event) => this.events.clickHandler(this.state.currentPage - 1, 'prev', event);
    const nextEvent = isNextDisabled ?
      defaultFunction :
      (event) => this.events.clickHandler(this.state.currentPage + 1, 'next', event);

    const iconLeft = 'e-move-left';
    const iconRight = 'e-move-right';

    return html`
      <nav
        aria-label="${this.state.label}"
        class="${this.state.loading ? 'e-pagination e-pagination-loading' : 'e-pagination'}"
      >
        <e-tooltip content="${translator.translate('components.pagination.prevButton')}">
          <button
            type="button"
            ?disabled="${isPrevDisabled}"
            class="${prevClassName}"
            onclick="${prevEvent}"
            ref="${this.#createReference(['prevButton'], { testOnly: true })}"
          >
            <e-icon icon=${iconLeft}></e-icon>
          </button>
        </e-tooltip>
        ${this.state.loading ? this.#generateLoadingState() : this.#generatePageGroup()}
        <e-tooltip content="${translator.translate('components.pagination.nextButton')}">
          <button
            type="button"
            ?disabled="${isNextDisabled}"
            class="${nextClassName}"
            onclick="${nextEvent}"
            ref="${this.#createReference(['nextButton'], { testOnly: true })}"
          >
            <e-icon icon=${iconRight}></e-icon>
          </button>
        </e-tooltip>
      </nav>
    `;
  };

  #generatePageGroup() {
    const labels = this.state.generateLabels();
    return labels.map((label, index) => this.#renderPageNumber(label, index));
  };

  #generateLoadingState() {
    const buttonClassList = 'e-btn e-btn-onlyicon e-btn-borderless e-btn-disabled';
    const skeletonClassNames = classNames('e-pagination__item', buttonClassList);

    const skeletonTextElements = html`
      <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
      <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
      <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
      <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
      <div class="${skeletonClassNames}"><div class="e-skeleton e-skeleton-s"></div></div>
    `;

    return skeletonTextElements;
  };

  #renderPageNumber(label, index) {
    const isCurrentPage = this.state.currentPage === parseInt(label);
    const isDisabled = label === this.state.hiddenPagesPlaceholder;

    const buttonClassList = classNames('e-btn', 'e-btn-onlyicon', 'e-btn-borderless', {
      'e-btn-disabled': isDisabled,
      'e-btn-active': isCurrentPage
    });

    const className = classNames('e-pagination__item', {
      'e-pagination__item-disabled': isDisabled,
      'e-pagination__item-active': isCurrentPage
    }, buttonClassList);

    const defaultFunction = event => event.preventDefault();
    const clickEvent = isCurrentPage ||
      isDisabled ? defaultFunction : (event) => this.events.clickHandler(label, 'page', event);

    return html`
      <button
        type="button"
        ?disabled="${isDisabled}"
        aria-hidden="${isDisabled}"
        aria-current=${isCurrentPage}
        class="${className}"
        onclick="${clickEvent}"
        ref="${this.#createReference(['pageButton', index], { testOnly: true })}"
      >
        ${label}
      </button>
    `;
  };
}
