import State from '../../../utils/state';

class VerticalnavMenuState extends State {
  get defaultState() {
    return {
      items: [],
      storageKey: '',
      status: '',
      get maxWidth() { return this.items.length ? Math.max.apply(null, this.items.map(item => item.width)) : 0; },
      extended: false,
      extending: false
    };
  }
}

export default VerticalnavMenuState;
