
import manifest from '../../../../dist/app.js';
import uuid from '../uuid/index.js';
import configStore from '../config-store/index.js';
import navigation from '../navigation-service/index.js';

const pageId = uuid();

class Logger {
  #namespace = '';
  #configStore;
  #allowList = ['action', 'r', 'service', 'service_product', 'show', 'step'];

  constructor(namespace, config = configStore) {
    this.#namespace = namespace;
    this.#configStore = config;
  }

  async logData(message, { level, error, additional } = { level: 'log' }) {
    const allowedParams = await this.#getAllowedParams();
    const config = await this.#configStore.getLoadedConfig();
    const logData = {
      error,
      message,
      additional: JSON.stringify(additional),
      ...allowedParams,
      customerId: config.customerId,
      domain: JSON.stringify(window.location.hostname),
      level: this.#isValidLogLevel(level) ? level.toUpperCase() : 'LOG',
      flippers: config.flippers?.join(' '),
      features: config.features?.join(' '),
      type: this.#namespace,
      useragent: JSON.stringify(window.navigator.userAgent),
      version: manifest.revision,
      pageId
    };

    const filteredData = this.#removeEmptyObjects(logData);
    return filteredData;
  }

  getServiceURL(data) {
    const stringifiedData = new URLSearchParams(Object.entries(data)).toString();
    return `${manifest.uiLoggerServiceUrl}/?${stringifiedData}`;
  }

  isDomainAllowed(hostname) {
    const allowedInternalDomains = ['emarsys.com', 'emarsys.net'];
    const allowedExternalDomains = ['scarabresearch.com', 'ems-ci.com', 'loyalsys.io'];
    const allowedDomains = [...allowedExternalDomains, ...allowedInternalDomains];
    return allowedDomains.some(domain => hostname.includes(domain));
  }

  isPingdom(useragent) {
    return useragent.includes('PingdomTMS');
  }

  #isValidLogLevel(level) {
    return ['log', 'success', 'warn', 'error'].includes(level);
  }

  #removeEmptyObjects(data) {
    const copyOfData = { ...data };
    Object.keys(copyOfData).forEach(key => {
      if ((typeof copyOfData[key] === 'object' && !Object.keys(copyOfData[key]).length) ||
          (copyOfData[key] === undefined)) {
        delete copyOfData[key];
      }
    });

    return copyOfData;
  }

  async #getAllowedParams() {
    const topFrameUrl = new URL(await navigation.getTopUrl());
    const searchParams = new URLSearchParams(topFrameUrl.search);
    let list = {};

    this.#allowList.forEach(key => {
      const value = searchParams.get(key);

      if (value) {
        list[key] = JSON.stringify(value);
      }
    });

    return list;
  }
}

export default Logger;
