import { html, render } from 'uhtml';
import { HTMLCustomElement, convertAttributeToArray, convertAttributeToBoolean } from '@emartech/ui-framework-utils';
import { EMultiselectState } from './state';
import { EMultiselectEvents } from './events';
import { EMultiselectInputTemplate } from './templates/input';
import { EMultiselectActionlistTemplate } from './templates/actionlist';
import { EMultiselectFooterTemplate } from './templates/footer';
import configStore from '../../utils/config-store';
import { popupUtility } from '../../utils/popup';

class EMultiselect extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.utils = {};
    this.state = new EMultiselectState(this);
    this.events = new EMultiselectEvents(this);
    this.templates = {
      input: new EMultiselectInputTemplate(this),
      actionlist: new EMultiselectActionlistTemplate(this),
      footer: new EMultiselectFooterTemplate(this)
    };

    this.refs.wrapper = html.node`<e-multiselect-wrapper></e-multiselect-wrapper>`;
    this.refs.popup = html.node`
      <e-multiselect-popup>
        <e-multiselect-popup-actionlist
          class="e-popup-panel__scrollarea"
          ref=${node => this.refs.popupActionlist = node}
        ></e-multiselect-popup-actionlist>
        <e-multiselect-popup-toggle-button
          class="e-popup-panel__footer"
          ref=${node => this.refs.footer = node}
        ></e-multiselect-popup-toggle-button>
      </e-multiselect-popup>
    `;

    this.utils.popup = null;

    this.state.setTranslations();

    this.addEventListener('option.update', this.events.onUpdateOption);
    this.addEventListener('group.update', this.events.onUpdateOption);
  }

  connectedCallback() {
    configStore.subscribe(this.events.onConfigChange);
    super._cleanupContainer('e-multiselect-wrapper');
    this.appendChild(this.refs.wrapper);

    this.requestRender().then(() => {
      this._createPopup();
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();

    this._destroyPopup();

    configStore.unsubscribe(this.events.onConfigChange);
  }

  // Attributes
  static get observedAttributes() {
    return [
      'value',
      'disabled',
      'readonly',
      'loading',
      'invalid',
      'placeholder',
      'items',
      'always-show-search',
      'label-merge-limit',
      'selection-max'
    ];
  }

  get value() {
    return this.state.selectedOptions;
  }

  set value(value) {
    const selectedOptionValues = convertAttributeToArray(value).map(option => option.value);
    this.state.selectOptionsByValue(selectedOptionValues);
  }

  get content() {
    return this.state.selectedOptions.map(option => option.content);
  }

  set disabled(value) {
    this.state.disabled = convertAttributeToBoolean(value);
    this.closePopup();
    this.requestRender();
  }

  get disabled() {
    return this.state.disabled;
  }

  set readonly(value) {
    this.state.readonly = convertAttributeToBoolean(value);
    this.requestRender();
  }

  get readonly() {
    return this.state.readonly;
  }

  set loading(value) {
    this.state.loading = convertAttributeToBoolean(value);
    this.requestRender();
  }

  get loading() {
    return this.state.loading;
  }

  set invalid(value) {
    this.state.invalid = convertAttributeToBoolean(value);
    this.requestRender();
  }

  set placeholder(value) {
    this.state.placeholder = value;
    this.requestRender();
  }

  get placeholder() {
    return this.state.placeholder;
  }

  set items(value) {
    const items = typeof value === 'string' ? JSON.parse(value) : value;
    this.state.items = items || [];
    this.requestRender();
    this.actionlistRender();
  }

  set alwaysShowSearch(value) {
    this.state.alwaysShowSearch = convertAttributeToBoolean(value);
  }

  get labelMergeLimit() {
    return this.state.labelMergeLimit;
  }

  set labelMergeLimit(value) {
    const parsedValue = parseInt(value);
    this.state.labelMergeLimit = isNaN(parsedValue) ? Infinity : Math.abs(parsedValue);
    this.requestRender();
  }

  set selectionMax(value) {
    this.state.selectionMax = value;
    this.requestRender();
    this.actionlistRender();
  }

  _setActive(value) {
    this.state.activeOption = value;
  }

  // Actions
  clear() {
    this.state.clearAllOptions();
  }

  togglePopup() {
    this.utils.popup.toggle();

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  closePopup() {
    if (!this.utils.popup) { return; }

    this.utils.popup.close();

    this.requestRender();
  }

  // Rendering
  render() {
    if (!this.isConnected) { return; }

    render(this.refs.wrapper, this.templates.input.createElement());
  }

  actionlistRender() {
    if (!this.isConnected || !this.refs.popup.isConnected) { return; }

    render(this.refs.popupActionlist, this.templates.actionlist.createElement());
  }

  footerRender() {
    if (!this.isConnected || !this.refs.popup.isConnected) { return; }

    render(this.refs.footer, this.templates.footer.createElement());
  }

  _createPopup() {
    if (this.utils.popup !== null) {
      this._destroyPopup();
    }

    this.utils.popup = popupUtility.createPopup(this.refs.input, this.refs.popup, {
      matchOpenerWidth: true,
      onAfterClose: this.events.onClose
    });
  }

  _destroyPopup() {
    this.utils.popup?.destroy({ preventAutoFocus: true });
    this.utils.popup = null;
  }
}

export default EMultiselect;
