import autoBind from 'auto-bind';
export class EStepBarEvents {
    constructor(component) {
        autoBind(this);
        this.refs = component.refs;
        this.state = component.state;
    }
    onStepUpdate(event) {
        const stepElement = event.target;
        stepElement.removeEventListener('step.delete', this.onStepDelete);
        stepElement.addEventListener('step.delete', this.onStepDelete);
        this.refs.steps[event.detail.uuid] = event.target;
        this.state.upsertStep(event.detail);
    }
    onStepDelete(event) {
        delete this.refs.steps[event.detail.uuid];
        this.state.deleteStep(event.detail);
    }
    onStepClick(step) {
        if (!this.refs.steps[step.uuid]) {
            return;
        }
        this.refs.steps[step.uuid].dispatchEvent(new CustomEvent('trigger', {
            bubbles: true
        }));
    }
    onPrevButtonClick() {
        const previousAvailableStep = this.state.previousAvailableStep;
        if (!previousAvailableStep) {
            return;
        }
        this.onStepClick(previousAvailableStep);
    }
    onNextButtonClick() {
        const nextAvailableStep = this.state.nextAvailableStep;
        if (!nextAvailableStep) {
            return;
        }
        this.onStepClick(nextAvailableStep);
    }
}
