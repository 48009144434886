import { html } from 'uhtml';
import classNames from 'clsx';

export class EColorInputTemplate {
  constructor(component) {
    this.refs = component.refs;
    this.state = component.state;
    this.events = component.events;
  }

  createElement() {
    return html`
      ${this.createInput()}
      ${this.createColorDisplay()}
    `;
  }

  createInput() {
    return html`
      <input
        ref=${node => this.refs.input = node}
        type="text"
        name=${this.state.name}
        spellcheck="false"
        class="e-input e-colorinput__input"
        .value=${this.state.inputValue}
        ?readonly=${this.state.isReadOnly}
        @input=${this.events.onInput}
        @change=${this.events.onChange}
        @paste=${this.events.onPaste}
        @keydown=${this.events.onKeyDown}
      >
    `;
  }

  createColorDisplay() {
    const className = classNames('e-colorinput__display', {
      'e-colorinput__display-invalid': !this.state.inputValue
    });

    const inputClassName = classNames('e-colorinput__native', {
      'e-colorinput__native-invalid': !this.state.inputValue
    });

    return html`
        <div
          class="${className}"
        >
          <input
            ref=${node => this.refs.nativeInput = node}
            type="color"
            class="${inputClassName}"
            value=${this.state.inputValueWithHashmark || null}
            ?disabled=${this.state.isReadOnly}
            @input=${this.events.onNativeColorInput}
            @change=${this.events.onNativeColorChange}
          >
        </div>
      `;
  }
}
