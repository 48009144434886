import { render } from 'uhtml';
import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import Template from './templates';
import configStore from '../../../utils/config-store';
import translator from '../../../utils/translator';

class ECriteriaCondition extends HTMLCustomElement {
  init() {
    this._emitter = new EventEmitter();
    this._configChangeCallback = this._updateTranslations.bind(this);
    this._state = {
      header: '',
      disabled: false,
      translations: {
        editActionTooltip: translator.translate('components.criteriaCondition.editActionTooltip'),
        deleteActionTooltip: translator.translate('components.criteriaCondition.deleteActionTooltip')
      },
      values: [],
      order: 0,
      typeKey: '',
      valueLabelFormatter: null
    };
    this._template = new Template(this._emitter);
    this._emitter.on('edit', this._onEdit.bind(this));
    this._emitter.on('delete', this._onDelete.bind(this));
    this.addEventListener('click', this._onEdit.bind(this));
  }

  connectedCallback() {
    configStore.subscribe(this._configChangeCallback);
  }

  disconnectedCallback() {
    configStore.unsubscribe(this._configChangeCallback);
  }

  set values(value) {
    this._state.values = value;
    this.render();
  }

  set header(value) {
    this._state.header = value;
    this.render();
  }

  set typeKey(value) {
    this._state.typeKey = value;
    this.render();
  }

  get typeKey() {
    return this._state.typeKey;
  }

  get header() {
    return this._state.header;
  }

  set disabled(value) {
    this._state.disabled = value;
    this.render();
  }

  set order(value) {
    this._state.order = value;
    this.style.order = this._state.order;
  }

  get order() {
    return this._state.order;
  }

  set valueLabelFormatter(value) {
    this._state.valueLabelFormatter = value;
    this.render();
  }

  get valueLabelFormatter() {
    return this._state.valueLabelFormatter;
  }

  render() {
    render(this, this._template.render(this._state));
  }

  hide() {
    this.style.display = 'none';
  }

  show() {
    this.style.removeProperty('display');
  }

  _updateTranslations() {
    this._state.translations = {
      editActionTooltip: translator.translate('components.criteriaCondition.editActionTooltip'),
      deleteActionTooltip: translator.translate('components.criteriaCondition.deleteActionTooltip')
    };
    this.render();
  }

  _onEdit() {
    if (this._state.disabled) { return; }
    this.dispatchEvent(new CustomEvent('criteriacondition.edit', { bubbles: true }));
  }

  _onDelete() {
    this.dispatchEvent(new CustomEvent('criteriacondition.delete', { bubbles: true }));
  }
}

export default ECriteriaCondition;
