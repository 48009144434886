export const getOpenedPopups = (global = window) => {
  if (!global.e) {
    global.e = {};
  }

  if (!global.e.openedPopups) {
    global.e.openedPopups = [];
  }

  return global.e.openedPopups;
};

export const getLastOpenedPopup = (global = window) => {
  const openedPopups = getOpenedPopups(global).filter(popup => popup.opener?.nodeName !== 'E-TOOLTIP');

  return openedPopups[openedPopups.length - 1];
};

export const isPopupOpened = (global = window, opener) => {
  const openedPopups = getOpenedPopups(global);

  return openedPopups.some(popup => popup.opener === opener);
};

export const addOpenedPopup = (global = window, popup) => {
  const openedPopups = getOpenedPopups(global);

  openedPopups.push(popup);
};

export const removeOpenedPopup = (global = window, opener) => {
  const openedPopups = getOpenedPopups(global);

  const popupIndex = openedPopups.findIndex(popup => popup.opener === opener);
  if (popupIndex === -1) { return; }

  openedPopups.splice(popupIndex, 1);
};

export const getBoundaryElement = (global = window) => {
  const boundaryElementClassName = 'e-popover_boundary';
  const boundaryElement = global.document.querySelector(`.${boundaryElementClassName}`);

  if (boundaryElement === null) {
    const newBoundaryElement = global.document.createElement('div');
    newBoundaryElement.classList.add(boundaryElementClassName);

    if (global.document.querySelector('e-shellbar')) {
      newBoundaryElement.classList.add('e-popover_boundary-shellbar');
    }

    if (global.document.querySelector('e-navigation')) {
      newBoundaryElement.classList.add('e-popover_boundary-navigation');
    }

    if (global.document.querySelector('.e-steps') || global.document.querySelector('e-step-bar')?.floating) {
      newBoundaryElement.classList.add('e-popover_boundary-footer');
    }

    global.document.body.appendChild(newBoundaryElement);
    return newBoundaryElement;
  }

  return boundaryElement;
};

export default {
  getOpenedPopups,
  getBoundaryElement
};
