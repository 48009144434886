import { html } from 'uhtml';

class HeaderTemplate {
  constructor(emitter) {
    this._emitter = emitter;
  }

  handleEvent(event) {
    event.stopPropagation();
    this._emitter.emit('changeRelation', event.target.value);
  }

  render(state) {
    return html`
      <e-criteria-label>${state.label}</e-criteria-label>
      <e-criteria-relation>
        <e-icon
          class="e-margin-right-xs"
          icon=${state.relation === 'and' ? 'e-logic-and' : 'e-logic-or'}
          color="info"
        ></e-icon>
        <e-select>
          <select class="e-select e-select-inline" .value=${state.relation} @change=${this}>
            <option value="and">${state.translations.relation.and}</option>
            <option value="or">${state.translations.relation.or}</option>
          </select>
        </e-select>
      </e-criteria-relation>
    `;
  }
}

export default HeaderTemplate;
