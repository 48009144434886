import { html } from 'uhtml';
import classNames from 'clsx';

export class ESeparatorTemplate {
  #state;
  #events;

  constructor(component) {
    this.#state = component.state;
    this.#events = component.events;
  }

  createElement() {
    const wrapperPart = classNames('wrapper', {
      'wrapper-horizontal': !this.#state.isVertical,
      'wrapper-vertical': this.#state.isVertical,
      'wrapper-fullheight': this.#state.isFullheight && this.#state.isVertical
    });

    const slotPart = classNames('content', {
      'content-hidden': !this.#state.hasContent
    });

    const lineBeforePart = classNames('line', 'line-before', {
      'line-horizontal': !this.#state.isVertical,
      'line-vertical': this.#state.isVertical,
      'line-color-faint': this.#state.type === 'faint'
    });

    const lineAfterPart = classNames('line', 'line-after', {
      'line-horizontal': !this.#state.isVertical,
      'line-vertical': this.#state.isVertical,
      'line-color-faint': this.#state.type === 'faint'
    });

    return html`
      <div part="${wrapperPart}">
        ${this.#state.isVertical ? html`<div part="${lineBeforePart}"></div>` : null}
        <slot part="${slotPart}" @slotchange=${this.#events.onSlotChange} />
        <div part="${lineAfterPart}"></div>
      </div>
    `;
  }
}

export default ESeparatorTemplate;
