import { HTMLCustomElement, convertAttributeToBoolean, convertAttributeToArray } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';

import configStore from '../../../utils/config-store';
import { popupUtility } from '../../../utils/popup';
import { flattenItems } from './utils';

import { ESelectEvents } from './events';
import { ESelectState } from './state';
import { ESelectTemplate } from './template';
import { ESelectNativeHandler } from './native-select-handler';
import JSLogger from '../../../utils/jslogger';

const log = new JSLogger('select');

class ESelect extends HTMLCustomElement {
  // Lifecycle methods
  init() {
    this.refs = {};
    this.utils = {};
    this.state = new ESelectState(this);
    this.events = new ESelectEvents(this);
    this.template = new ESelectTemplate(this);

    this.refs.component = this;
    this.refs.wrapper = this.template.createWrapper();

    this.utils.popup = null;
    this.utils.nativeHandler = new ESelectNativeHandler(this);

    this.addEventListener('keydown', this.events.onKeyboardNavigation);
    this.addEventListener('group.update', this.events.onGroupUpdate);
    this.addEventListener('option.update', this.events.onOptionUpdate);
  }

  connectedCallback() {
    super._cleanupContainer('.e-selectnew__wrapper');
    this.state.updateTranslations();

    configStore.subscribe(this.events.onConfigChange);
    this.requestRender().then(() => {
      this._createPopup();
      this.utils.nativeHandler.init();
      this.insertAdjacentElement('beforeend', this.refs.wrapper);
    });
  }

  disconnectedCallback() {
    this.requestRender.clear();
    configStore.unsubscribe(this.events.onConfigChange);
    this._destroyPopup();
  }

  // Attributes
  static get observedAttributes() {
    return [
      'items', 'options', 'name', 'value', 'tabindex', 'placeholder', 'disabled', 'readonly', 'size', 'inline',
      'borderless', 'error', 'loading', 'opened', 'disable-min-width', 'always-show-search', 'search-hidden',
      'clear-visible', 'item-limit'
    ];
  }

  get items() {
    return this.state.items;
  }

  set items(value) {
    const items = convertAttributeToArray(value);
    this.state.items = flattenItems(items);
  }

  set options(value) {
    this.items = value;

    log.log('options setter called');
  }

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
  }

  get value() {
    return this.state.selectedOption?.value || '';
  }

  set value(value) {
    this.state.selectOptionByValue(value);
  }

  get content() {
    return this.state.selectedOption?.content || '';
  }

  get tabindex() {
    return this.state.tabindex;
  }

  set tabindex(value) {
    this.state.tabindex = value || 0;
  }

  get placeholder() {
    return this.state.placeholder;
  }

  set placeholder(value) {
    this.state.placeholder = value;
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = convertAttributeToBoolean(value);
  }

  get readonly() {
    return this.state.isReadonly;
  }

  set readonly(value) {
    this.state.isReadonly = convertAttributeToBoolean(value);
  }

  get size() {
    return this.state.size;
  }

  set size(value) {
    this.state.size = value;
  }

  get inline() {
    return this.state.isInline;
  }

  set inline(value) {
    const isInline = convertAttributeToBoolean(value);

    this.state.isInline = isInline;
    this.toggleAttribute('inline', isInline);
  }

  set borderless(value) {
    const isBorderless = convertAttributeToBoolean(value);

    this.state.isBorderless = isBorderless;
    this.toggleAttribute('borderless', isBorderless);
  }

  set focused(value) {
    const isFocused = convertAttributeToBoolean(value);

    this.state.isFocused = isFocused;
    this.toggleAttribute('focused', isFocused);
  }

  set error(value) {
    this.state.isInErrorState = convertAttributeToBoolean(value);
  }

  set loading(value) {
    const isLoading = convertAttributeToBoolean(value);

    this.state.isLoading = isLoading;
  }

  set opened(value) {
    const shouldOpen = convertAttributeToBoolean(value);

    if (shouldOpen) {
      this.utils.popup?.open();
    } else {
      this.utils.popup?.close();
    }
  }

  set disableMinWidth(value) {
    this.state.isMinWidthDisabled = convertAttributeToBoolean(value);
  }

  set alwaysShowSearch(value) {
    this.state.alwaysShowSearch = convertAttributeToBoolean(value);
  }

  set searchHidden(value) {
    this.state.isSearchHidden = convertAttributeToBoolean(value);
  }

  set clearVisible(value) {
    this.state.isClearVisible = convertAttributeToBoolean(value);
  }

  get itemLimit() {
    return this.state.itemLimit;
  }

  set itemLimit(value) {
    this.state.itemLimit = value;
  }

  // Actions
  open() {
    this.utils.popup.open();
  }

  close() {
    this.utils.popup.close();
  }

  // Rendering
  render() {
    render(this.refs.wrapper, this.template.createElement());
  }

  // Private methods
  _createPopup() {
    if (this.utils.popup !== null) {
      this._destroyPopup();
    }

    this.utils.popup = popupUtility.createPopup(this.refs.input, this.refs.actionlist, {
      matchOpenerWidth: true,
      onAfterOpen: this.events.onOpen,
      onAfterClose: this.events.onClose,
      elementToFocusOnClose: this.refs.input
    });
  }

  _destroyPopup() {
    this.utils.popup?.destroy({ preventAutoFocus: true });
    this.utils.popup = null;
  }

  _dispatchChangeEvent(event) {
    if (this.refs.nativeSelect) { return; }

    this.dispatchEvent(new CustomEvent('change', event));
  }
}

export default ESelect;
