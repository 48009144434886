import { html } from 'uhtml';
import classNames from 'clsx';

const calculateCalendarClass = (data) => {
  return classNames('e-calendar', {
    'e-calendar-sticky': data.isSticky
  });
};

const calculateDayClass = (day) => {
  return classNames('e-calendar__day', {
    'e-calendar__day-current': day.isSelected || day.isInRange,
    'e-calendar__day-disabled': day.isDisabled,
    'e-calendar__day-today': day.isToday,
    'e-calendar__day-alternate': !day.isCurrentMonth
  });
};

const days = (day) => html`
  <td>
    <div class="${calculateDayClass(day)}" date="${day.date}">
      ${day.number}
    </div>
  </td>
`;

export default (data, events) => {
  return html`
    <div class="${calculateCalendarClass(data)}">
      <div class="e-calendar__head">
        <div class="e-calendar__action" onclick="${events.prev}">
          <e-icon icon="e-move-left" size="button"></e-icon>
        </div>
        <div class="e-calendar__title">${data.title}</div>
        <div class="e-calendar__action" onclick="${events.next}">
          <e-icon icon="e-move-right" size="button"></e-icon>
        </div>
        <div class="e-calendar__now" onclick="${events.now}">
          <div class="e-calendar__now_day">${data.today}</div>
          <e-icon icon="calendar-o"></e-icon>
        </div>
      </div>
      <table class="e-calendar__table" onclick="${events.select}">
        <thead><tr>${data.daysOfWeek.map(day => html`<th>${day}</th>`)}</tr></thead>
        <tbody>${data.daysOfMonth.map(week => html`<tr>${week.map(days)}</tr>`)}</tbody>
      </table>
    </div>
  `;
};
