import { HTMLCustomElement, ResizeObserver } from '@emartech/ui-framework-utils';
import { svgTemplate, animationTemplate } from './template';

class EChartLoader extends HTMLCustomElement {
  init() {
    this._resizeObserver = new ResizeObserver(this._render.bind(this));
  }

  static get observedAttributes() {
    return ['height'];
  }

  set height(value) {
    this.style.height = `${value}px`;
  }

  connectedCallback() {
    this._resizeObserver.observe(this);
    this._render();
  }

  disconnectedCallback() {
    this._resizeObserver.unobserve(this);
  }

  _render() {
    this.innerHTML = '';

    let boundings = this.getBoundingClientRect();

    const svg = new DOMParser().parseFromString(svgTemplate(boundings), 'application/xml');
    this.appendChild(this.ownerDocument.importNode(svg.documentElement, true));

    boundings = this.getBoundingClientRect();

    const animation = new DOMParser().parseFromString(animationTemplate(boundings), 'text/html');
    this.appendChild(this.ownerDocument.importNode(animation.body.firstChild, true));
  }
}

export default EChartLoader;
