import autoBind from 'auto-bind';
import { createItemFromEventData } from './utils.js';
import { splitSearchQuery } from '../../../js/utils/split-search-query/index.js';

export class EActionListEvents {
  constructor(component) {
    autoBind(this);
    this.moveActiveItem = component.moveActiveItem.bind(component);
    this.toggleActiveItem = component.toggleActiveItem.bind(component);
    this.dispatchEvent = component.dispatchEvent.bind(component);

    this._dispatchChangeEvent = component._dispatchChangeEvent.bind(component);
    this._dispatchHoverChangeEvent = component._dispatchHoverChangeEvent.bind(component);
    this._dispatchActiveChangeEvent = component._dispatchActiveChangeEvent.bind(component);
    this._updateScrollPosition = component._updateScrollPosition.bind(component);
    this._updateTranslations = component._updateTranslations.bind(component);

    this.refs = component.refs;
    this.state = component.state;
  }

  stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  onResize() {
    this._updateScrollPosition();
  }

  onConfigChange() {
    this._updateTranslations();
  }

  onSearchInput(event) {
    event.stopImmediatePropagation();

    const searchInputValue = event.target.value;

    this.state.searchKeywords = splitSearchQuery(searchInputValue);
    this.dispatchEvent(new CustomEvent('search', { detail: { value: searchInputValue } }));
  }

  onSearchKeyDown(event) {
    const keysToPrevent = ['ArrowDown', 'ArrowUp', 'Enter'];
    if (keysToPrevent.includes(event.code) || keysToPrevent.includes(event.key)) {
      event.preventDefault();
    }

    if (event.code === 'Escape') {
      this.state.resetSearchKeywords();
      return;
    }

    if (event.code === 'ArrowDown') {
      this.moveActiveItem('next');
      this.refs?.itemsContainer.focus({ preventScroll: true });
      return;
    }

    if (event.code === 'ArrowUp') {
      this.moveActiveItem('previous');
      this.refs?.itemsContainer.focus({ preventScroll: true });
      return;
    }

    if (event.code === 'Enter') {
      this.toggleActiveItem();
      return;
    }
  }

  onItemsContainerKeyDown(event) {
    const keysToPrevent = ['ArrowDown', 'ArrowUp', 'Enter', 'Space'];
    if (keysToPrevent.includes(event.code) || keysToPrevent.includes(event.key)) {
      event.preventDefault();
    }

    const controlKeys = ['ArrowDown', 'ArrowUp', 'Enter', 'Space', 'Shift', 'Alt', 'Control', 'Meta', 'Tab', 'Escape'];
    if (!controlKeys.includes(event.code) && !controlKeys.includes(event.key)) {
      this.refs?.searchInput.focus();
      this._dispatchActiveChangeEvent();
      return;
    }

    if (event.code === 'ArrowDown') {
      this.moveActiveItem('next');
      this._dispatchActiveChangeEvent(this.state.activeItem);
      return;
    }

    if (event.code === 'ArrowUp') {
      this.moveActiveItem('previous');
      this._dispatchActiveChangeEvent(this.state.activeItem);
      return;
    }

    if (event.code === 'Escape') {
      this._dispatchActiveChangeEvent();
      return;
    }

    if (event.code === 'Enter' || event.code === 'Space') {
      this.toggleActiveItem();
      return;
    }
  }

  onItemClick(event) {
    const uuid = event.currentTarget.dataset.uuid;
    const item = this.state.getItemByUuid(uuid);

    if (!this.state.isItemSelected(item) && this.state.isSelectionMaxReached) { return; }

    this.state.toggleItemSelection(item);

    this._dispatchChangeEvent(item);
  }

  onItemMouseEnter(event, item) {
    if (item.color.text) {
      this.state.hoveredItem = event.currentTarget.dataset.uuid;
    }

    this._dispatchHoverChangeEvent(item);
  };

  onItemMouseLeave(item) {
    if (item.color.text) {
      this.state.hoveredItem = null;
    }

    this._dispatchHoverChangeEvent();
  };

  onItemUpdate(event) {
    event.stopPropagation();

    event.target.removeEventListener('actionlistitem.delete', this.onItemDelete);
    event.target.addEventListener('actionlistitem.delete', this.onItemDelete);

    const item = createItemFromEventData({ ...event.detail, component: event.target });
    this.state.upsertItem(item);
  }

  onItemDelete(event) {
    const uuid = event.detail.uuid;
    const item = this.state.getItemByUuid(uuid);

    this.state.deleteItem(item);
  }

  onGroupUpdate(event) {
    event.stopPropagation();

    event.target.removeEventListener('actionlistgroup.delete', this.onGroupDelete);
    event.target.addEventListener('actionlistgroup.delete', this.onGroupDelete);

    const group = {
      ...event.detail,
      items: event.detail.items.map(createItemFromEventData),
      component: event.target
    };
    this.state.upsertGroup(group);
  }

  onGroupDelete(event) {
    const uuid = event.detail.uuid;
    const group = this.state.getGroupByUuid(uuid);

    this.state.deleteGroup(group);
  }
}

export default EActionListEvents;
