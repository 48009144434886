import { theming } from '@emartech/ui-framework-utils';
import uuid from '../../../js/utils/uuid/index.js';
import { escapeRegex } from '../../../js/utils/escape-regex/index.js';
import themeHandler from '../../../js/utils/theme-handler/index.js';

const stripHtmlTagsHelper = document.createElement('span');

export const stripHtmlTags = htmlString => {
  stripHtmlTagsHelper.innerHTML = htmlString;

  return stripHtmlTagsHelper.textContent;
};

export const isKeywordInItemText = (item, keyword) => {
  if (!keyword) { return true; }

  return item.textContent.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
};

export const highlightKeywordsInItemContent = (item, keywords = []) => {
  const regex = new RegExp(`(${keywords.map(escapeRegex).join('|')})`, 'gi');

  return { ...item, highlightedContent: item.content.replace(regex, '<u>$1</u>') };
};

const setItemContentData = itemData => {
  const textContent = stripHtmlTags(itemData.content);
  theming.useTheme(themeHandler.currentTheme);

  return {
    ...itemData,
    textContent,
    color: {
      text: theming.getToken(itemData.color)?.name,
      background: theming.getTokenVariant(itemData.color, '100')?.name,
      backgroundHover: theming.getTokenVariant(itemData.color, '200')?.name
    },
    hasHTMLContent: itemData.content !== textContent
  };
};

export const createItemFromEventData = eventData => {
  return {
    ...setItemContentData(eventData)
  };
};

export const createItemFromAttributeData = attributeData => {
  return {
    uuid: uuid(),
    ...setItemContentData(attributeData)
  };
};

export const createGroupFromAttributeData = attributeData => {
  const groupUuid = attributeData.uuid || uuid();
  const items = attributeData.items
    .map(createItemFromAttributeData)
    .map(item => ({
      ...item,
      group: groupUuid
    }));

  return {
    ...attributeData,
    uuid: groupUuid,
    items
  };
};
