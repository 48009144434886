const BACKEND_URL = '/client_log.php';

class ClientLoggerService {
  constructor(configStore, global) {
    this._configStore = configStore;
    this._global = global;
  }

  initialize() {
    this._global.addEventListener('message', this._handleMessage.bind(this), false);
  }

  sendLog(logPayload) {
    if (this._global === this._global.top) {
      this._sendLog(logPayload);
    } else {
      this._emitLogMessage(logPayload);
    }
  }

  _sendLog(logPayload) {
    return this._configStore.getLoadedConfig().then(({ environmentHost, sessionId }) => {
      return this._sendRequestToBackend(environmentHost, sessionId, logPayload);
    });
  }

  _sendRequestToBackend(environmentHost, sessionId, logPayload) {
    return this._global.fetch(
      this._getBackendUrl(environmentHost, sessionId),
      { method: 'put', credentials: 'same-origin', body: JSON.stringify(logPayload) }
    ).then(response => {
      if (response.status !== 200) {
        this._global.console.error('failed to log');
      }
    });
  }

  _getBackendUrl(environmentHost, sessionId) {
    return `//${environmentHost}${BACKEND_URL}?session_id=${sessionId}`;
  };

  _handleMessage(event) {
    const { message, data } = this._getPayloadFromEvent(event);
    if (message === 'e:sendLog') {
      this._sendLog(data.logPayload);
    }
  }

  _emitLogMessage(logPayload) {
    this._global.top.postMessage({
      message: 'e:sendLog',
      data: { logPayload }
    }, '*');
  }

  _getPayloadFromEvent(event) {
    return event.data || {};
  }
}

export default ClientLoggerService;
