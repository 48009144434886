import translator from '../../../utils/translator';

export class EDialogState {
  constructor() {
    this._cached = false;
    this._visible = false;
    this._hasLegacyCustomFooter = false;
    this._hasCustomFooter = false;
    this._hasHeadlineSlot = false;
    this._closing = false;
    this._preventClose = false;
    this._lastClickTarget = null;
    this._translations = {};
    this._coverImage = '';
    this._coverImageHeight = '';
    this._isLightCoverTone = false;

    this._headline = '';
    this._content = '';
    this._noClose = false;
    this._noKeys = false;
    this._width = '650px';
    this._height = 'auto';
    this._disableScroll = false;
    this._className = '';
    this._cache = false;
    this._opened = false;
    this._opener = null;
    this._local = false;
    this._buttons = {};
  }

  get cached() {
    return this._cached;
  }

  set cached(value) {
    this._cached = value;
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    this._visible = value;
  }

  get hasLegacyCustomFooter() {
    return this._hasLegacyCustomFooter;
  }

  set hasLegacyCustomFooter(value) {
    this._hasLegacyCustomFooter = value;
  }

  get hasCustomFooter() {
    return this._hasCustomFooter;
  }

  set hasCustomFooter(value) {
    this._hasCustomFooter = value;
  }

  get hasHeadlineSlot() {
    return this._hasHeadlineSlot;
  }

  set hasHeadlineSlot(value) {
    this._hasHeadlineSlot = value;
  }

  get closing() {
    return this._closing;
  }

  set closing(value) {
    this._closing = value;
  }

  get preventClose() {
    return this._preventClose;
  }

  set preventClose(value) {
    this._preventClose = value;
  }

  get lastClickTarget() {
    return this._lastClickTarget;
  }

  set lastClickTarget(value) {
    this._lastClickTarget = value;
  }

  get translations() {
    return this._translations;
  }

  get coverImage() {
    return this._coverImage;
  }

  set coverImage(value) {
    this._coverImage = value;
  }

  get coverImageHeight() {
    return this._coverImageHeight;
  }

  set coverImageHeight(value) {
    this._coverImageHeight = value;
  }

  set isLightCoverTone(value) {
    this._isLightCoverTone = value;
  }

  get isLightCoverTone() {
    return this._isLightCoverTone;
  }

  get headline() {
    return this._headline;
  }

  set headline(value) {
    this._headline = value;
  }

  get content() {
    return this._content;
  }

  set content(value) {
    this._content = value;
  }

  get noClose() {
    return this._noClose;
  }

  set noClose(value) {
    this._noClose = value;
  }

  get noKeys() {
    return this._noKeys;
  }

  set noKeys(value) {
    this._noKeys = value;
  }

  get width() {
    return this._width;
  }

  set width(value) {
    this._width = value;
  }

  get height() {
    return this._height;
  }

  set height(value) {
    this._height = value;
  }

  get disableScroll() {
    return this._disableScroll;
  }

  set disableScroll(value) {
    this._disableScroll = value;
  }

  get className() {
    return this._className;
  }

  set className(value) {
    this._className = value;
  }

  get cache() {
    return this._cache;
  }

  set cache(value) {
    this._cache = value;
  }

  get opened() {
    return this._opened;
  }

  set opened(value) {
    this._opened = value;
  }

  get opener() {
    return this._opener;
  }

  set opener(value) {
    this._opener = value;
  }

  get local() {
    return this._local;
  }

  set local(value) {
    this._local = value;
  }

  get buttons() {
    return this._buttons;
  }

  set buttons(value) {
    this._buttons = value;
  }

  get hasButtons() {
    return Object.keys(this._buttons).length > 0;
  }

  setOptions(options) {
    if (!options) { return; }

    const validOptions = [
      'headline', 'content', 'noClose', 'noKeys', 'width', 'height', 'disableScroll', 'className', 'cache', 'opened',
      'opener', 'local', 'buttons'
    ];

    Object.keys(options).forEach(key => {
      if (!(key in this) && validOptions.includes(key)) { return; }

      this[key] = options[key];
    });
  }

  updateTranslations() {
    this._translations.closeButtonTooltip = translator.translate('components.dialog.closeButtonTooltip');
    this._translations.backButtonTooltip = translator.translate('components.dialog.backButtonTooltip');
  }
}
