import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';
import { popupUtility } from '../../utils/popup';
import assetHandler from '../../utils/asset-handler';
import { EEmojiPickerState } from './state';
import { EEmojiPickerEvents } from './events';
import { EEmojiPickerTemplate } from './template';

class EEmojiPicker extends EDSComponent {
  static componentName = 'emoji-picker';

  init() {
    this.utils = {
      popup: null,
      mutationObserver: null
    };

    this.state = new EEmojiPickerState(this);
    this.events = new EEmojiPickerEvents(this);
    this.template = new EEmojiPickerTemplate(this);

    this.utils.emojiCache = assetHandler.getAsset('emoji')
      .then(asset => this.state.collectEmojis(asset))
      .then(emojis => this.state.emojis = emojis);

    this.template.createWrapper();
  }

  connectedCallback() {
    this.requestRender().then(() => {
      this._createPopup();
      this.insertAdjacentElement('beforeEnd', this.refs.wrapper);
    });
  }

  static get observedAttributes() {
    return ['disabled'];
  }

  get disabled() {
    return this.state.isDisabled;
  }

  set disabled(value) {
    this.state.isDisabled = AttributeUtils.convertToBoolean(value);
  }

  get activeTabIndex() {
    return this.state.activeTab;
  }

  set activeTabIndex(categoryIndex) {
    this.state.changeCategory(categoryIndex);
  }

  disconnectedCallback() {
    this.close();
  }

  open() {
    this.events.open();
  }

  close() {
    this.events.close();
  }

  _createPopup() {
    const handler = this.refs.handler;
    if (!handler || !this.refs.picker) { return; }

    this.utils.popup = popupUtility.createPopup(handler, this.refs.picker, {
      onAfterOpen: this.events.onOpen,
      onAfterClose: this.events.onClose,
      elementToFocusOnClose: handler
    });
  }

  render() {
    render(this.refs.wrapper, this.template.createElement());
  }
}

export default EEmojiPicker;
