export class Blueprint {
    constructor(value) {
        this._templateClone = null;
        this._summaryClone = null;
        this.name = value.name;
        this.label = value.label;
        this.validationName = value.validation;
        this.template = value.template;
        this.summary = value.summary;
        this.actions = value.actions;
        if (value.summary) {
            this._summaryClone = value.summary.cloneNode(true);
        }
    }
    get templateClone() {
        if (!this.template) {
            return null;
        }
        return this.template.cloneNode(true);
    }
    get summaryClone() {
        if (!this.summary) {
            return null;
        }
        if (this._summaryClone) {
            return this._summaryClone;
        }
        this._summaryClone = this.summary.cloneNode(true);
        return this._summaryClone;
    }
    toDTO() {
        return {
            name: this.name
        };
    }
}
