import { HTMLCustomElement, ResizeObserver } from '@emartech/ui-framework-utils';

class EEllipsis extends HTMLCustomElement {
  init() {
    this._content = '';
    this._rowNumber = 2;
    this._oneLineHeight = 0;
    this._resizeObserver = new ResizeObserver(() => {
      setTimeout(this._render.bind(this));
    });
  }

  connectedCallback() {
    this.style.display = 'block';
    this._oneLineHeight = this._getOneLineHeight();
    this._resizeObserver.observe(this);
    this._render();
  }

  disconnectedCallback() {
    this._resizeObserver.unobserve(this);
  }

  static get observedAttributes() {
    return ['content', 'row'];
  }

  set content(value) {
    this._content = value || '';
    this._render();
  }

  set row(value) {
    this._rowNumber = parseInt(value);
    this._render();
  }

  _render() {
    this.textContent = this._formatContent();

    const isTruncated = this.textContent !== this._content;
    if (isTruncated) {
      this.setAttribute('title', this._content);
      this.setAttribute('aria-label', this._content);
    } else {
      this.removeAttribute('title');
      this.removeAttribute('aria-label');
    }
  }

  _formatContent() {
    if (!this.parentNode) {
      return this._content;
    }

    const textLength = this._content.length;
    const maxLineHeight = this._oneLineHeight * this._rowNumber;

    this.textContent = this._content;
    const fullHeight = Math.floor(window.getComputedStyle(this).height.replace('px', ''));

    const ratio = maxLineHeight / fullHeight;

    if (ratio >= 1) {
      return this._content;
    }

    const startCharacter = Math.round(textLength * ratio);
    this.textContent = this._content.slice(0, startCharacter);
    const currentHeight = Math.floor(window.getComputedStyle(this).height.replace('px', ''));
    const isTextTooLong = currentHeight > Math.floor(maxLineHeight);

    if (isTextTooLong) {
      for (let character = startCharacter; character >= 0; --character) {
        this.textContent = this._content.slice(0, character);
        const contentHeight = Math.floor(window.getComputedStyle(this).height.replace('px', ''));
        if (contentHeight === maxLineHeight) {
          return this._content.slice(0, character - 3) + '...';
        }
      }
    } else {
      for (let character = startCharacter; character <= textLength; ++character) {
        this.textContent = this._content.slice(0, character);
        const contentHeight = Math.floor(window.getComputedStyle(this).height.replace('px', ''));
        if (contentHeight > maxLineHeight) {
          return this._content.slice(0, character - 4) + '...';
        }
      }
    }
  }

  _getOneLineHeight() {
    const originalMinHeight = this.style.minHeight;
    this.innerHTML = '&nbsp;';
    this.style.minHeight = '0';
    const oneLineHeight = parseFloat(window.getComputedStyle(this).height.replace('px', ''));
    this.style.minHeight = originalMinHeight;
    return oneLineHeight;
  }
}

export default EEllipsis;
