import { convertAttributeToBoolean, convertAttributeToObject, HTMLCustomElement } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import configStore from '../../../utils/config-store';
import { ERuleBuilderState } from './state';
import { ERuleBuilderEvents } from './events';
import { ERuleBuilderTemplate } from './template';
import JSLogger from '../../../../js/utils/jslogger/index.js';
const logger = new JSLogger('decommission');
export class ERuleBuilder extends HTMLCustomElement {
    // Lifecycle methods
    init() {
        this.refs = {
            wrapper: null
        };
        this.state = new ERuleBuilderState(this);
        this.events = new ERuleBuilderEvents(this);
        this.template = new ERuleBuilderTemplate(this);
        this.refs.wrapper = this.template.createWrapper();
        this.state.updateTranslations();
        this.addEventListener('rule-blueprint.update', this.events.onBlueprintUpdate);
        this.addEventListener('rule-relation.update', this.events.onRelationUpdate);
        this.addEventListener('rule.change', this.events.onChange);
        this.addEventListener('group.change', this.events.onChange);
        this.addEventListener('change', this.events.onGlobalChange);
        configStore.subscribe(this.events.onConfigChange);
    }
    connectedCallback() {
        super._cleanupContainer('.e-rule-builder-component__wrapper');
        this.requestRender().then(() => {
            this.insertAdjacentElement('beforeend', this.refs.wrapper);
        });
        logger.log('ERuleBuilder');
    }
    disconnectedCallback() {
        configStore.unsubscribe(this.events.onConfigChange);
    }
    // Attributes
    static get observedAttributes() {
        return [
            'items',
            'excluded-items',
            'disabled',
            'readonly',
            'label',
            'auto-label',
            'max-depth',
            'exclude-enabled',
            'max-rule-count',
            'group-header-label-hidden',
            'group-header-toggle-hidden',
            'group-header-relation-label',
            'add-rule-button-text',
            'add-rule-button-tooltip',
            'rule-editor-add-title',
            'rule-editor-edit-title'
        ];
    }
    get emptyStateHandler() {
        return this.state.emptyStateHandler;
    }
    set emptyStateHandler(callback) {
        this.state.emptyStateHandler = callback;
    }
    get items() {
        return this.state.mainGroup.toDTO();
    }
    set items(value) {
        this.state.items = convertAttributeToObject(value);
    }
    get excludedItems() {
        return this.state.mainExcludedGroup ? this.state.mainExcludedGroup.toDTO() : null;
    }
    set excludedItems(value) {
        this.state.excludedItems = convertAttributeToObject(value);
    }
    get value() {
        return {
            items: this.items,
            excludedItems: this.excludedItems
        };
    }
    set value(value) {
        const { items, excludedItems } = value;
        if (items !== undefined) {
            this.items = items;
        }
        if (excludedItems !== undefined) {
            this.excludedItems = excludedItems;
        }
    }
    get disabled() {
        return this.state.isDisabled;
    }
    set disabled(value) {
        this.state.isDisabled = convertAttributeToBoolean(value);
    }
    get readonly() {
        return this.state.isReadonly;
    }
    set readonly(value) {
        this.state.isReadonly = convertAttributeToBoolean(value);
    }
    get label() {
        return this.state.label;
    }
    set label(value) {
        this.state.label = value;
    }
    get groupHeaderRelationLabel() {
        return this.state.groupHeaderRelationLabel;
    }
    set groupHeaderRelationLabel(value) {
        this.state.groupHeaderRelationLabel = value;
    }
    get addRuleButtonText() {
        return this.state.addRuleButtonText;
    }
    set addRuleButtonText(value) {
        this.state.addRuleButtonText = value;
    }
    get addRuleButtonTooltip() {
        return this.state.addRuleButtonTooltip;
    }
    set addRuleButtonTooltip(value) {
        this.state.addRuleButtonTooltip = value;
    }
    get ruleEditorAddTitle() {
        return this.state.ruleEditorAddTitle;
    }
    set ruleEditorAddTitle(value) {
        this.state.ruleEditorAddTitle = value;
    }
    get ruleEditorEditTitle() {
        return this.state.ruleEditorEditTitle;
    }
    set ruleEditorEditTitle(value) {
        this.state.ruleEditorEditTitle = value;
    }
    get autoLabel() {
        return this.state.autoLabel;
    }
    set autoLabel(value) {
        this.state.autoLabel = convertAttributeToBoolean(value);
    }
    get maxRuleCount() {
        return this.state.maxRuleCount;
    }
    set maxRuleCount(value) {
        const parsedValue = parseInt(value);
        this.state.maxRuleCount = isNaN(parsedValue) ? Infinity : Math.abs(parsedValue);
    }
    get maxDepth() {
        return this.state.maxDepth;
    }
    set maxDepth(value) {
        this.state.maxDepth = parseInt(value);
    }
    get excludeEnabled() {
        return this.state.isExcludeEnabled;
    }
    set excludeEnabled(value) {
        this.state.isExcludeEnabled = convertAttributeToBoolean(value);
    }
    get groupHeaderLabelHidden() {
        return this.state.isGroupHeaderLabelHidden;
    }
    set groupHeaderLabelHidden(value) {
        this.state.isGroupHeaderLabelHidden = convertAttributeToBoolean(value);
    }
    get groupHeaderToggleHidden() {
        return this.state.isGroupHeaderToggleHidden;
    }
    set groupHeaderToggleHidden(value) {
        this.state.isGroupHeaderToggleHidden = convertAttributeToBoolean(value);
    }
    // Rendering
    render() {
        if (!this.isConnected) {
            return;
        }
        render(this.refs.wrapper, this.template.createElement());
    }
}
