import configStore from '../config-store/index.js';

const setDocumentLanugage = async () => {
  const { language } = await configStore.getLoadedConfig();

  const documentElement = document.documentElement;

  if (![null, 'en'].includes(documentElement.getAttribute('lang'))) { return; }

  documentElement.setAttribute('lang', language);
};

export default setDocumentLanugage;
