import { DurationFormatter } from '@emartech/eds-utils-formatter';
import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render, html } from 'uhtml';
import configStore from '../../utils/config-store/index.js';
import translator from '../../utils/translator/index.js';
import { EDurationState } from './state.js';

class EDuration extends EDSComponent {
  static componentName = 'duration';

  #shadowRoot = null;
  #state = null;
  #durationFormatter = null;

  // Lifecycle methods
  constructor() {
    super();

    this.#shadowRoot = this.attachShadow({ mode: 'open' });

    this.#state = new EDurationState(this);
    this.#state.language = configStore.config.language;

    this.#durationFormatter = new DurationFormatter();

    configStore.subscribe(this.#configChangeCallback.bind(this));
    this.#configChangeCallback();
  }

  disconnectedCallback() {
    this.requestRender.clear();
  }

  static get observedAttributes() {
    return ['value', 'precision', 'max-precision', 'pad', 'show-zero-parts'];
  }

  set value(value) {
    this.#state.value = value;
  }

  set precision(value) {
    this.#state.precision = value === null ? 'seconds' : value;
  }

  set maxPrecision(value) {
    this.#state.maxPrecision = value;
  }

  set pad(value) {
    this.#state.pad = AttributeUtils.convertToBoolean(value);
  }

  set showZeroParts(value) {
    this.#state.showZeroParts = AttributeUtils.convertToBoolean(value);
  }

  #configChangeCallback() {
    this.#state.translations = {
      days: {
        short: translator.translate('components.duration.days.short'),
        long: translator.translate('components.duration.days.long')
      },
      hours: {
        short: translator.translate('components.duration.hours.short'),
        long: translator.translate('components.duration.hours.long')
      },
      minutes: {
        short: translator.translate('components.duration.minutes.short'),
        long: translator.translate('components.duration.minutes.long')
      },
      seconds: {
        short: translator.translate('components.duration.seconds.short'),
        long: translator.translate('components.duration.seconds.long')
      },
      milliseconds: {
        short: translator.translate('components.duration.milliseconds.short'),
        long: translator.translate('components.duration.milliseconds.long')
      }
    };

    this.requestRender();
  }

  _render() {
    this.#durationFormatter.setOptions({
      precision: this.#state.precision,
      maxPrecision: this.#state.maxPrecision,
      pad: this.#state.pad,
      showZeroParts: this.#state.showZeroParts,
      translations: this.#state.translations
    });

    const durationText = this.#durationFormatter.format(this.#state.value);

    render(this.#shadowRoot, html`${durationText}`);

    this.#durationFormatter.setOptions({
      precision: this.#state.precision,
      maxPrecision: this.#state.maxPrecision,
      translations: this.#state.translations,
      format: 'long'
    });
    const longDurationText = this.#durationFormatter.format(this.#state.value);

    this.setAttribute('aria-label', longDurationText);
  }
}

export default EDuration;
