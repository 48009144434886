import NotificationClient from './client';
import NotificationService from './service';

const client = new NotificationClient();
const service = new NotificationService();

export default {

  open(options, top) {
    return client.open(options, top);
  },

  subscribe() {
    client.subscribe();
    service.subscribe();
  }
};
