export class ETabBarState {
    constructor(component) {
        this._tabs = [];
        this._isFullheight = false;
        this._isVertical = false;
        this._isIconVisiable = false;
        this._isLabelVisible = null;
        this.requestRender = component.requestRender.bind(component);
    }
    get isFullheight() {
        return this._isFullheight;
    }
    set isFullheight(value) {
        this._isFullheight = value;
        this.requestRender();
    }
    get isVertical() {
        return this._isVertical;
    }
    set isVertical(value) {
        this._isVertical = value;
        this.requestRender();
    }
    get tabs() {
        return this._tabs;
    }
    get selectedTab() {
        if (!this._selectedTab) {
            this.selectedTab = this._tabs.find(tab => !tab.disabled);
        }
        return this._selectedTab;
    }
    set selectedTab(tab) {
        this._selectedTab = tab;
        this.requestRender();
    }
    get selectedTabContent() {
        return this.selectedTab?.content;
    }
    get selectableTabs() {
        return this._tabs.filter(tab => !tab.disabled);
    }
    get selectedTabIndex() {
        if (!this.selectedTab) {
            return NaN;
        }
        return this._tabs.findIndex(tab => tab.uuid === this.selectedTab.uuid);
    }
    get isFirstTabSelected() {
        if (!this.selectedTab) {
            return false;
        }
        return this.selectableTabs.findIndex(tab => tab.uuid === this._selectedTab.uuid) === 0;
    }
    get isLastTabSelected() {
        if (!this.selectedTab) {
            return false;
        }
        const tabs = this.selectableTabs;
        return tabs.findIndex(tab => tab.uuid === this.selectedTab.uuid) === tabs.length - 1;
    }
    get isIconHidden() {
        return this._isIconVisiable;
    }
    set isIconHidden(value) {
        this._isIconVisiable = value;
        this.requestRender();
    }
    get isLabelVisible() {
        if (this._isLabelVisible === null) {
            return !this.isVertical;
        }
        return this._isLabelVisible;
    }
    set isLabelVisible(value) {
        this._isLabelVisible = value;
        this.requestRender();
    }
    isSelectedTab(tab) {
        return this.selectedTab?.uuid === tab.uuid;
    }
    upsertTab(tabEventDetail) {
        const tabIndex = this._tabs.findIndex(tab => tab.uuid === tabEventDetail.uuid);
        if (tabIndex === -1) {
            this._tabs = [...this._tabs, tabEventDetail];
        }
        else {
            this._tabs[tabIndex] = tabEventDetail;
        }
        if (tabEventDetail.selected) {
            this._selectedTab = tabEventDetail;
        }
        this.requestRender();
    }
    deleteTab(uuid) {
        const tabIndex = this._tabs.findIndex(tab => tab.uuid === uuid);
        if (tabIndex === -1) {
            return;
        }
        this._tabs.splice(tabIndex, 1);
        this.requestRender();
    }
    setSelectedTabById(id) {
        const tabById = this.selectableTabs.find(tab => tab.id === id);
        if (!tabById) {
            return;
        }
        this.selectedTab = tabById;
    }
    setSelectedTabByIndex(index) {
        const tabByIndex = this._tabs[index];
        if (tabByIndex === undefined || tabByIndex.disabled) {
            return;
        }
        this.selectedTab = tabByIndex;
    }
    moveSelectedTab(position) {
        const activeIndex = this.selectableTabs.findIndex(tab => tab.uuid === this.selectedTab.uuid);
        const directionIndexMap = {
            'previous': activeIndex - 1,
            'next': activeIndex + 1,
            'first': 0,
            'last': this.selectableTabs.length - 1
        };
        const nextIndex = directionIndexMap[position];
        if (nextIndex === undefined || nextIndex < 0 || nextIndex >= this.selectableTabs.length) {
            return;
        }
        this.selectedTab = this.selectableTabs[nextIndex];
    }
}
