class TimeInput {
  convertTo12(hours, isAmpm, partOfDay) {
    hours = parseInt(hours, 10);
    if (isAmpm && hours <= 12) {
      return this.#replaceValue(hours, partOfDay);
    }

    if (hours > 12) {
      hours -= 12;
      partOfDay = 'pm';
    } else if (hours === 12) {
      hours = 12;
      partOfDay = 'pm';
    } else if (hours === 0) {
      hours = 12;
      partOfDay = 'am';
    } else {
      partOfDay = 'am';
    }

    return this.#replaceValue(hours, partOfDay);
  }

  convertTo24(hours, partOfDay) {
    hours = parseInt(hours, 10);

    if (hours === 12 && partOfDay === 'am') {
      hours = 0;
    } else if (hours < 12 && hours >= 0 && partOfDay === 'pm') {
      hours += 12;
    }

    return this.#zeroFill(hours);
  }

  validateValue(value, max = 23) {
    if (isNaN(value, 10)) {
      return '00';
    }

    value = this.#boundary(value, max);
    value = this.#zeroFill(value);

    return value + '';
  }

  #boundary(value, max) {
    return Math.min(Math.max(value, 0), max);
  }

  #zeroFill(number) {
    return number.toString().padStart(2, 0);
  }

  #replaceValue(hours, partOfDay) {
    return {
      value: this.#zeroFill(hours),
      partOfDay: partOfDay
    };
  }
}

export default TimeInput;
