const isEqualArray = (array1, array2) => array1.length === array2.length &&
  array1.every((value, index) => array2[index] === value);

const doesCacheMatch = (cached, previousOutput, deps) => {
  return cached && cached.previousOutput === previousOutput && isEqualArray(cached.deps, deps);
};

const cachedPipe = fnsWithDeps => {
  const cache = [];
  return dependencyPool => fnsWithDeps.reduce((previousOutput, fnWithDeps, index) => {
    const fn = fnWithDeps[fnWithDeps.length - 1];
    const deps = fnWithDeps.slice(0, -1).map(dep => dependencyPool[dep]);
    const result = doesCacheMatch(cache[index], previousOutput, deps) ?
      cache[index].result :
      fn(previousOutput, deps);
    cache[index] = { result, previousOutput, deps };
    return result;
  }, undefined);
};

export default cachedPipe;
