import autoBind from 'auto-bind';
import uuid from '../../utils/uuid/index.js';
import translator from '../../utils/translator/index.js';

export class EMultiselectState {
  constructor(component) {
    autoBind(this);
    this.requestRender = component.requestRender;
    this.actionlistRender = component.actionlistRender.bind(component);
    this.footerRender = component.footerRender.bind(component);

    this._items = [];
    this._popupOpened = false;
    this._disabled = false;
    this._readonly = false;
    this._loading = false;
    this._invalid = false;
    this._placeholder = '';
    this._alwaysShowSearch = false;
    this._isSearching = false;
    this._labelMergeLimit = Infinity;
    this._translations = {};
    this._selectionMax = null;
  }

  get items() {
    return this._items;
  }

  set items(value) {
    const items = value.map(this._extendItemWithDefaults);
    const flattenedItems = this._flattenItems(items);

    this._items = this._cloneItems(flattenedItems);
  }

  get options() {
    return this._items.filter(item => item.type !== 'group');
  }

  get selectedOptions() {
    return this.options.filter(option => option.selected);
  }

  set selectedOptions(selectedOptions) {
    this.options.forEach((option) => {
      option.selected = selectedOptions.includes(option.uuid);
    });

    this.requestRender();
    this.footerRender();
  }

  selectOptionsByValue(selectedOptionValues) {
    this.options.forEach((option) => {
      option.selected = selectedOptionValues.includes(option.value);
    });

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  get itemsForActionlist() {
    const options = this._items
      .filter(item => item.type !== 'group' && !item.group)
      .map(item => ({ ...item, value: item.uuid, disabled: item.disabled || !this.isEditable }));

    const groups = this._items
      .filter(item => item.type === 'group')
      .map(group => ({ ...group, items: [] }));

    const optionsWithGroup = this._items
      .filter(item => item.group)
      .map(item => ({ ...item, value: item.uuid, disabled: item.disabled || !this.isEditable }));

    const nestedItems = [...options, ...groups];

    optionsWithGroup.forEach(optionWithGroup => {
      const foundGroup = groups.find(group => group.uuid === optionWithGroup.group);
      foundGroup.items.push(optionWithGroup);
    });

    return nestedItems;
  }

  get isAllOptionsSelected() {
    return this.options.filter(option => !option.disabled).every(option => option.selected);
  }

  get popupOpened() {
    return this._popupOpened;
  }

  set popupOpened(value) {
    this._popupOpened = value;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
  }

  get readonly() {
    return this._readonly;
  }

  set readonly(value) {
    this._readonly = value;
  }

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
  }

  get invalid() {
    return this._invalid;
  }

  set invalid(value) {
    this._invalid = value;
  }

  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value) {
    this._placeholder = value;
  }

  get alwaysShowSearch() {
    return this._alwaysShowSearch;
  }

  set alwaysShowSearch(value) {
    this._alwaysShowSearch = value;
  }

  get labelMergeLimit() {
    return this._labelMergeLimit;
  }

  set labelMergeLimit(value) {
    this._labelMergeLimit = value;
  }

  get isSearching() {
    return this._isSearching;
  }

  set isSearching(value) {
    this._isSearching = value;

    this.footerRender();
  }

  get translations() {
    return this._translations;
  }

  set translations(value) {
    this._translations = value;
  }

  get isEditable() {
    return !this._disabled && !this._readonly && !this._loading;
  }

  get selectionMax() {
    return this._selectionMax;
  }

  set selectionMax(value) {
    this._selectionMax = value;
  }

  get hasSelectionMaxError() {
    return this.selectedOptions.length > this.selectionMax;
  }

  clearOption(uuid) {
    const foundOption = this._getItemByUuid(uuid);
    foundOption.selected = false;

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  selectAllOptions() {
    this.options.forEach(option => {
      if (option.disabled) { return; }

      option.selected = true;
    });

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  clearAllOptions() {
    this.options.forEach(option => {
      if (option.disabled) { return; }

      option.selected = false;
    });

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  upsertOption(optionData) {
    const foundOption = this._getItemByUuid(optionData.uuid);
    const index = this._items.indexOf(foundOption);

    if (index > -1) {
      this._items = this._items.slice();
      this._items[index] = optionData;
    } else {
      this._items = this._items.concat(optionData);
    }

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  deleteOption({ detail }) {
    this._items = this._items.filter(option => option.uuid !== detail.uuid);

    this.requestRender();
    this.actionlistRender();
    this.footerRender();
  }

  setTranslations() {
    this._translations = {
      emptyState: translator.translate('components.multiselect.popup.emptyState'),
      clearLabel: translator.translate('components.multiselect.clearLabel')
    };

    this.requestRender();
    this.footerRender();
  }

  _flattenItems(items) {
    return items.flatMap(item => item.type === 'group' ? [item, ...item.items] : [item]);
  }

  _cloneItems(items) {
    return items.flatMap(item => ({ ...item }));
  }

  _getItemByUuid(uuid) {
    return this._items.find(item => item.uuid === uuid);
  }

  _extendItemWithDefaults(item, config = {}) {
    const id = uuid();

    if (item.type === 'group') {
      const groupItems = item.items.map(groupItem => {
        return this._extendItemWithDefaults(groupItem, { group: id });
      });

      return {
        uuid: item.uuid || id,
        type: 'group',
        label: item.label || '',
        items: groupItems || []
      };
    }

    return {
      uuid: item.uuid || id,
      type: 'option',
      value: item.value ?? '',
      content: item.content || '',
      selected: item.selected || false,
      disabled: item.disabled || false,
      group: config.group || null
    };
  }
}

export default EMultiselectState;
