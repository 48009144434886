import { html } from 'uhtml';

class FooterTemplate {
  constructor(emitter) {
    this._emitter = emitter;
  }

  handleEvent(event) {
    if (event.type !== 'click') { return; }

    const action = event.currentTarget.getAttribute('data-action');
    this._emitter.emit(action);
  }

  render() {
    return html`<div class="e-buttongroup">
      <button type="button" class="e-btn" @click="${this}" data-action="cancel">
        <e-translation key="components.criteria.cancelConditionButton"></e-translation>
      </button>
      <button type="button" class="e-btn e-btn-secondary" @click="${this}" data-action="apply">
        <e-translation key="components.criteria.applyConditionButton"></e-translation>
      </button>
    `;
  }
}

export default FooterTemplate;
