import { HTMLCustomElement, formatNumber } from '@emartech/ui-framework-utils';
import { render } from 'uhtml';
import isValidKey from './is-valid-key';
import numberinputTemplate from './template';

const isNumeric = value => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

const isInteger = value => {
  return isNumeric(value) && Math.floor(parseFloat(value)) === parseFloat(value);
};

class ENumberInput extends HTMLCustomElement {
  init() {
    this._refs = {
      wrapper: document.createElement('numberinput-wrapper'),
      input: null
    };
    this._state = {
      value: '',
      defaultValue: '',
      displayValue: '',
      min: null,
      max: null,
      step: 1,
      isFocused: false,
      isValid: true,
      isForceInvalid: false,
      isInteger: false,
      isArrowUpDisabled: false,
      isArrowDownDisabled: false
    };
    this._events = {
      focus: this._onFocus.bind(this),
      keyDown: this._onKeyDown.bind(this),
      input: this._onInput.bind(this),
      change: this._onChange.bind(this),
      blur: this._onBlur.bind(this),
      increase: this._onIncrease.bind(this),
      decrease: this._onDecrease.bind(this)
    };
  }

  connectedCallback() {
    super._cleanupContainer('numberinput-wrapper');
    this.appendChild(this._refs.wrapper);
    this._render();
  }

  static get observedAttributes() {
    return [
      'value', 'name', 'placeholder', 'invalid', 'disabled', 'size', 'integer', 'min', 'max', 'step', 'default-value'
    ];
  }

  set value(value) {
    this._state.value = value;
    this._state.displayValue = formatNumber({ precision: -1 }, value);

    this._checkArrows();
    this._render();
  }

  set defaultValue(value) {
    this._state.defaultValue = isNumeric(value) ? value : '';

    if (!this._state.value) {
      this.value = value;
    }

    this._render();
  }

  set name(value) {
    this._state.name = value;
    this._render();
  }

  set placeholder(value) {
    this._state.placeholder = value;
    this._render();
  }

  set invalid(value) {
    this._state.isForceInvalid = super._convertAttributeToBoolean(value);
    this._render();
  }

  set disabled(value) {
    this._state.disabled = super._convertAttributeToBoolean(value);
    this._render();
  }

  set size(value) {
    const validValues = ['small', 'medium', 'large'];
    const isValueValid = validValues.indexOf(value) !== -1;

    this._state.size = isValueValid ? value : '';
    this._render();
  }

  set integer(value) {
    this._state.isInteger = super._convertAttributeToBoolean(value);
    this._render();
  }

  set min(value) {
    this._state.min = parseFloat(value);
    this._checkArrows();
    this._render();
  }

  set max(value) {
    this._state.max = parseFloat(value);
    this._checkArrows();
    this._render();
  }

  set step(value) {
    this._state.step = Math.abs(parseFloat(value)) || 1;
  }

  get value() {
    const value = parseFloat(this._state.value);
    return isNumeric(value) ? value : '';
  }

  _render() {
    this._validateValue();
    render(this._refs.wrapper, numberinputTemplate(this._state, this._events, this._refs));
  }

  _validateValue() {
    if (this._state.value === '') {
      this._state.isValid = true;
      return;
    }

    if (this._state.isFocused) { return; }

    const isOutOfBoundaries = this._isOutOfMinimumBoundary() || this._isOutOfMaximumBoundary();
    this._state.isValid = isNumeric(this._state.value) && !isOutOfBoundaries;

    if (this._state.isInteger && !isInteger(this._state.value)) {
      this._state.isValid = false;
    }
  }

  _onFocus() {
    this._state.isFocused = true;
    this._render();
  }

  _onKeyDown(event) {
    const selection = {
      start: this._refs.input.selectionStart,
      end: this._refs.input.selectionEnd
    };
    const selectedText = this._refs.input.value.substring(selection.start, selection.end);

    if (!isValidKey(event, this._state.isInteger, selectedText)) {
      event.preventDefault();
    } else if (event.key === 'ArrowUp' || event.key === 'Up') {
      this._onIncrease(event);
    } else if (event.key === 'ArrowDown' || event.key === 'Down') {
      this._onDecrease(event);
    }
  }

  _onInput(event) {
    event.stopPropagation();
    this.value = event.target.value;
    this._dispatchEvent('input');
  }

  _onChange(event) {
    event.stopPropagation();
  }

  _onBlur() {
    this._state.isFocused = false;

    if (this._state.defaultValue !== '' && this._state.value === '') {
      this.value = this._state.defaultValue;
    }

    this._render();
    this._dispatchEvent('change');

    if (!this._state.isValid) {
      this._dispatchEvent('error', 'Value is not valid!');
    }
  }

  _onIncrease(event) {
    event.preventDefault();
    if (this._state.isArrowUpDisabled) { return; };

    this._restoreFocus();
    this._state.isValid = true;

    const increasedValue = this._add(this._baseValue(), this._state.step);

    this.value = this._state.max !== null && increasedValue >= this._state.max ? this._state.max : increasedValue;
    this._dispatchEvent('input');
  }

  _onDecrease(event) {
    event.preventDefault();
    if (this._state.isArrowDownDisabled) { return; };

    this._restoreFocus();
    this._state.isValid = true;

    const decreasedValue = this._add(this._baseValue(), -this._state.step);

    this.value = this._state.min !== null && decreasedValue <= this._state.min ? this._state.min : decreasedValue;
    this._dispatchEvent('input');
  }

  _add(value, step) {
    const valueDecimals = value % 1 ? value.toString().split('.')[1].length : 0;
    const stepDecimals = step % 1 ? step.toString().split('.')[1].length : 0;
    const normalizer = Math.pow(10, Math.max(valueDecimals, stepDecimals));

    return (Math.round(value * normalizer) + Math.round(step * normalizer)) / normalizer;
  }

  _baseValue() {
    if (isNumeric(this._state.value)) {
      if (this._isOutOfMinimumBoundary()) {
        return this._state.min;
      }

      if (this._isOutOfMaximumBoundary()) {
        return this._state.max;
      }

      return parseFloat(this._state.value);
    }

    if (this._state.defaultValue) {
      return this._state.defaultValue;
    }

    if (this._state.min !== null && this._state.min > 0) {
      return this._state.min;
    }

    if (this._state.max !== null && this._state.max < 0) {
      return this._state.max;
    }

    return 0;
  }

  _restoreFocus() {
    if (this._refs.input && (document.activeElement !== this._refs.input)) {
      this._refs.input.focus();
    }
  }

  _checkArrows() {
    this._state.isArrowDownDisabled = this._state.value !== '' && this._isOutOfMinimumBoundary(true);
    this._state.isArrowUpDisabled = this._state.value !== '' && this._isOutOfMaximumBoundary(true);
  }

  _isOutOfMinimumBoundary(isInclusive = false) {
    if (this._state.min === null) { return false; }
    return isInclusive ? this._state.value <= this._state.min : this._state.value < this._state.min;
  }

  _isOutOfMaximumBoundary(isInclusive = false) {
    if (this._state.max === null) { return false; }
    return isInclusive ? this._state.value >= this._state.max : this._state.value > this._state.max;
  }

  _dispatchEvent(type, message) {
    const payload = {
      detail: {
        value: this.value
      }
    };

    if (message) {
      Object.assign(payload, { detail: { message } });
    }

    this.dispatchEvent(new CustomEvent(type, payload));
  }
}

export default ENumberInput;
