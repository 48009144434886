import { html } from 'uhtml';

class Template {
  constructor(emitter) {
    this._emitter = emitter;
  }

  handleEvent(event) {
    event.stopPropagation();
    this._emitter.emit(event.currentTarget.getAttribute('data-action'));
  }

  render(state) {
    return html`
      <e-criteria-condition-content class="e-hoverable" ?disabled="${state.disabled}">
        <div class="e-grid e-grid-wrap">
          <strong>${state.header}</strong>
          ${this._renderValues(state)}
        </div>
        <e-criteria-condition-actions class="e-hoverable__visible">
          <e-tooltip content="${state.translations.editActionTooltip}">
            <button class="e-btn e-btn-onlyicon e-btn-borderless" @click="${this}" data-action="edit">
              <e-icon icon="pencil" type="table"></e-icon>
            </button>
          </e-tooltip>
          <e-tooltip content="${state.translations.deleteActionTooltip}">
            <button class="e-btn e-btn-onlyicon e-btn-borderless" @click="${this}" data-action="delete">
              <e-icon icon="trash-o" type="table"></e-icon>
            </button>
          </e-tooltip>
        </e-criteria-condition-action>
      </e-criteria-condition-content>
    `;
  }

  _renderValues(state) {
    const values = state.valueLabelFormatter ? this._getFormattedValues(state) : state.values.map(value => value.value);
    return values.map(value => html`<e-criteria-condition-value>${value}</e-criteria-condition-value>`);
  }

  _getFormattedValues(state) {
    let formattedValues = state.valueLabelFormatter({
      values: state.values,
      conditionTypeKey: state.typeKey
    });
    return Array.isArray(formattedValues) ? formattedValues : [formattedValues];
  }
}


export default Template;
