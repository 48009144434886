import { html } from 'uhtml';
import classNames from 'clsx';


export class ESliderBubble {
  constructor(component) {
    this.state = component._state;
  }

  createElement() {
    const className = classNames('e-slider-bubble__handle', {
      'e-slider-bubble__handle-disabled': this.state.disabled
    });

    return html.node`
      <div class=${className}>
        <e-icon icon="e-caret-horizontal" color="highlight" size="small"></e-icon>
      </div>
    `;
  }
}

