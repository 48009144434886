import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import { EValidationState } from './state';

export class EValidation extends HTMLCustomElement {
  init() {
    this.state = new EValidationState(this);
  }

  static observedAttributes = ['name'];

  get name() {
    return this.state.name;
  }

  set name(value) {
    this.state.name = value;
    this._dispatchChangeEvent();
  }

  set validation(value) {
    this.state.validation = value;
    this._dispatchChangeEvent();
  }

  connectedCallback() {
    this._dispatchChangeEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('remove', {
      detail: {
        name: this.state.name
      }
    }));
  }

  _dispatchChangeEvent() {
    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      detail: {
        name: this.state.name,
        validation: this.state.validation
      }
    }));
  }
}

export default EValidation;
