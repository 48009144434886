import { EDSComponent, AttributeUtils } from '@emartech/eds-utils-component';
import { render } from 'uhtml';

import { ESeparatorState } from './state.js';
import { ESeparatorEvents } from './events.js';
import { ESeparatorTemplate } from './template.js';

export class ESeparator extends EDSComponent {
  static componentName = 'separator';
  #shadowRoot = null;

  // Lifecycle methods
  constructor() {
    super();

    this.state = new ESeparatorState(this);
    this.events = new ESeparatorEvents(this);
    this.template = new ESeparatorTemplate(this);

    this.#shadowRoot = this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.reflectStringAttribute('type', this.state.type);
    this.reflectStringAttribute('layout', this.state.layout);
    this.requestRender();
  }

  // Attributes
  static observedAttributes = ['layout', 'fullheight', 'type'];

  get layout() {
    return this.state.layout;
  }

  set layout(value) {
    this.state.layout = AttributeUtils.convertToString(value);
    this.reflectStringAttribute('layout', this.state.layout);
  }

  set fullheight(value) {
    this.state.isFullheight = AttributeUtils.convertToBoolean(value);
  }

  get type() {
    return this.state.type;
  }

  set type(value) {
    this.state.type = AttributeUtils.convertToString(value);
    this.reflectStringAttribute('type', this.state.type);
  }

  // Render method
  render() {
    render(this.#shadowRoot, this.template.createElement());
  }
}
